import React, { useState, useEffect } from "react";
import axios from "axios";

const convertLinkLog = (originalUrl) => {
  const url = new URL(originalUrl);
  const originalPath = url.pathname;
  const windowsPath = "D:" + originalPath.replace(/\//g, "\\");
  const encodedPath = encodeURIComponent(windowsPath);
  const newUrl = `http://data4.ikara.co:8112/log?path=${encodedPath}`;
  return newUrl;
};

function TruncatedText({ text, maxLength }) {
  const [isTruncated, setIsTruncated] = useState(true);

  const handleToggle = (e) => {
    e.preventDefault(); // Prevent default anchor behavior
    setIsTruncated(!isTruncated);
  };

      return (
      <td>
        {isTruncated ? (
          <>
            <span
              style={{
                display: "block",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
              }}
            >
              {text.length > maxLength ? text.substring(0, maxLength) + " ..." : text}
            </span>
            {text.length > maxLength && (
              <a
                href="#"
                onClick={handleToggle}
                style={{
                  display: "block",
                  color: "#007bff",
                  cursor: "pointer",
                  marginTop: "5px",
                }}
              >
                Xem thêm
              </a>
            )}
          </>
        ) : (
          <>
            <span>{text}</span>
            <a
              href="#"
              onClick={handleToggle}
              style={{
                display: "block",
                color: "#007bff",
                cursor: "pointer",
                marginTop: "5px",
              }}
            >
              Thu gọn
            </a>
          </>
        )}
      </td>
      );
}

function ClientLogPage() {
  const [logs, setLogs] = useState([]);
  const [filters, setFilters] = useState({
    device: "",
    startDate: "",
    endDate: "",
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(5);

  useEffect(() => {
    const fetchLogs = async () => {
      let urlFilter = "";
      try {
        const params = {
          page: page,
          limit: limit,
        };
        if (!filters.device && !filters.startDate && !filters.endDate) {
          urlFilter = "";
        }
        if (filters.device) {
          urlFilter = `uid=${filters.device}`;
        }
        if (filters.startDate && filters.endDate) {
          urlFilter =
            urlFilter +
            `&startDate=${filters.startDate}&endDate=${filters.endDate}`;
        }
        const response = await axios.get(
          global.config.apiDomain + "/rest/mobile/log/get-list?" + urlFilter,
          {
            params: params,
          }
        );
        const { data } = response.data;
        const { clientLogs, totalPages } = data;
        setTotalPages(totalPages);
        setLogs(clientLogs);
        return;
      } catch (error) {
        console.error("Failed to fetch logs:", error);
      }
    };
    fetchLogs();
  }, [filters, page, limit]);

  const handleSearch = () => {
    const deviceValue = document.getElementById("uid-search").value;
    const startDate = document.getElementById("time-search-start").value;
    const endDate = document.getElementById("time-search-end").value;
    setFilters({
      device: deviceValue,
      startDate: startDate,
      endDate: endDate,
    });
    setPage(1);
    
  };

  const handleResetFilters = () => {
    document.getElementById("uid-search").value = "";
    document.getElementById("time-search-start").value = "";
    document.getElementById("time-search-end").value = "";
    setFilters({
      device: "",
      startDate: "",
      endDate: "",
    });
  };

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <>
      <div
        className="container"
        style={{
          marginTop: "100px",
          marginBottom: "100px",
        }}
      >
        <h1 className="text-center">Client Log Page</h1>
        <div className="filter-container">
          <label htmlFor="uid-search">Search by Uid:</label>
          <input id="uid-search" type="text" placeholder="Nhập uId" />
          <br />
          <label htmlFor="time-search">Start:</label>
          <input id="time-search-start" type="date" placeholder="Nhập time" />
          <br />
          <label htmlFor="time-search">End:</label>
          <input id="time-search-end" type="date" placeholder="Nhập time" />
          <button className="btn" onClick={handleSearch}>
            Tìm Kiếm
          </button>
          <button className="btn" onClick={handleResetFilters}>
            Clear
          </button>
        </div>
        <table
          className="table table-bordered table-hover"
          style={{ marginTop: "20px" }}
        >
          <thead className="table-primary">
            <tr>
              <th>Log ID</th>
              <th>Uid</th>
              <th>Name</th>
              <th>Avatar</th>
              <th>Link Log</th>
              <th>Description</th>
              <th>Add Time</th>
            </tr>
          </thead>
          <tbody>
            {logs &&
              logs.length > 0 &&
              logs.map((log) => (
                <tr key={log.id}>
                  <td>{log.id}</td>
                  <td>{log.user.uid}</td>
                  <td>{log.user.name}</td>
                  <td>
                    <img
                      width="60px"
                      height="60px"
                      src={log.user.profileImageLink}
                      alt="avatar"
                    />
                  </td>
                  <td>
                    <a
                      href={convertLinkLog(log.urlClientLog)}
                      className="btn-yellow"
                    >
                      {convertLinkLog(log.urlClientLog)}
                    </a>
                  </td>
                  <TruncatedText text={log.descriptionBug} maxLength={20} />
                  <td>{new Date(log.addTime).toLocaleString()}</td>
                </tr>
              ))}
          </tbody>
        </table>
        <nav aria-label="Page navigation">
          <ul className="pagination justify-content-end">
            <li
              className={`page-item ${page === 1 ? "disabled" : ""}`}
              style={{ color: page === 1 ? "gray" : "black" }}
            >
              <a
                className="page-link"
                tabIndex="-1"
                onClick={handlePreviousPage}
                style={{ pointerEvents: page === 1 ? "none" : "auto" }}
              >
                Previous
              </a>
            </li>
            {totalPages > 1 &&
              Array.from({ length: totalPages }, (_, index) => (
                <li
                  key={index}
                  className={`page-item ${index + 1 === page ? "active" : ""}`}
                >
                  <a className="page-link" onClick={() => setPage(index + 1)}>
                    {index + 1}
                  </a>
                </li>
              ))}
            <li
              className={`page-item ${page === totalPages ? "disabled" : ""}`}
              style={{ color: page === totalPages ? "gray" : "#007bff" }}
            >
              <a
                className="page-link"
                onClick={handleNextPage}
                style={{ pointerEvents: page === totalPages ? "none" : "auto" }}
              >
                Next
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default ClientLogPage;
