import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import ModalUpdateBannerFullPage from './components/ModalUpdateBannerFull';
import ModalDeleteBannerFull from './components/ModalDeleteBannerFull';

class BannerFullPageManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bannerFulls: [],
            bannerFull: null,
            page: 0,
            statusButtonLoadPage: false,
            loading: false,
            sortBy: 'startDate'
        };

        this.getBannerFulls = this.getBannerFulls.bind(this);
        this.updateBannerFull = this.updateBannerFull.bind(this);
        this.updateBannerList = this.updateBannerList.bind(this);
        this.sortByStartDate = this.sortByStartDate.bind(this);
        this.sortByEndDate = this.sortByEndDate.bind(this);

    }

    componentDidMount() {
        this.getBannerFulls();
    }

    getBannerFulls() {
        this.setState({
            loading: true
        });
        let sortByUrl = this.state.sortBy === 'startDate' ?
            '/rest/bannerFulls/getBannerFulls/' :
            '/rest/bannerFulls/getBannerFulls/enddate/';

        axios.post(global.config.apiDomain + sortByUrl + this.state.page, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (res.data.status === 'OK') {
                    const { bannerFulls } = res.data;
                    let status = true;
                    if (bannerFulls.length < 10) {
                        status = false;
                    }

                    this.setState({
                        bannerFulls: this.state.bannerFulls.concat(bannerFulls),
                        page: this.state.page + 1,
                        statusButtonLoadPage: status,
                        loading: false
                    });
                }
            })
            .catch(error => {
                this.setState({
                    loading: false
                });
                console.error("Error:", error);
            });
    }

    updateBannerFull(event) {
        let index = event.target.name;
        let bannerFull = {
            id: '',
            thumbnail: '',
            url: '',
            linkType: '',
            startDate: '',
            endDate: '',
            enabled: '',
            requiredLevel: 0
        };

        if (index != -1) {
            bannerFull = this.state.bannerFulls[index];
        }

        this.setState({
            bannerFull: bannerFull,
        })
    }

    updateBannerList(newBanner) {
        let bannerFulls = this.state.bannerFulls;

        let index = bannerFulls.findIndex(banner => banner.id === newBanner.id);
        if (index !== -1) {
            bannerFulls[index] = newBanner;
        } else {
            bannerFulls.unshift(newBanner);
        }

        this.setState({
            bannerFulls: bannerFulls,
        })
    }

    sortByStartDate() {
        this.setState({ sortBy: 'startDate', bannerFulls: [], page: 0 }, this.getBannerFulls);
    }

    sortByEndDate() {
        this.setState({ sortBy: 'endDate', bannerFulls: [], page: 0 }, this.getBannerFulls);
    }


    render() {
        const { bannerFulls, bannerFull, statusButtonLoadPage, loading, sortBy } = this.state;
        return (
            <>
                <ModalUpdateBannerFullPage
                    bannerFull={bannerFull}
                    resetList={this.updateBannerList}
                ></ModalUpdateBannerFullPage>

                <ModalDeleteBannerFull
                    bannerFull={bannerFull}
                    resetList={this.updateBannerList}
                ></ModalDeleteBannerFull>

                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí Banner Full</h1>
                        <button
                            className="btn btn-red mb-1"
                            data-toggle="modal"
                            data-target="#modalUpdateBannerFull"
                            onClick={this.updateBannerFull}
                            name="-1"
                        >+ Tạo Mới Banner
                        </button>
                        <table className="table table-hover">
                            <thead>
                                <tr className="">
                                    <th className="align-middle" scope="col">#</th>
                                    <th className="align-middle" scope="col">Thumbnail</th>
                                    <th className="align-middle" scope="col">URL</th>
                                    <th className="align-middle" scope="col">Link Type</th>
                                    <th className="align-middle" scope="col">Level yêu cầu</th>
                                    <th className="align-middle" scope="col">Tạo bởi</th>
                                    <th
                                        scope="col"
                                        onClick={this.sortByStartDate.bind(this)}
                                        style={{
                                            backgroundColor: sortBy === 'startDate' ? '#f0f0f0' : 'inherit',
                                            fontWeight: sortBy === 'startDate' ? 'bold' : 'normal',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Start Date
                                    </th>
                                    <th
                                        scope="col"
                                        onClick={this.sortByEndDate.bind(this)}
                                        style={{
                                            backgroundColor: sortBy === 'endDate' ? '#f0f0f0' : 'inherit',
                                            fontWeight: sortBy === 'endDate' ? 'bold' : 'normal',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        End Date
                                    </th>
                                    <th className="align-middle" scope="col">Trạng thái</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    bannerFulls && bannerFulls.map((item, index) => {
                                        return (
                                            <tr>
                                                <td className='align-center' style={{ verticalAlign: "top !important" }}>{index + 1}</td>
                                                <td className='align-center' style={{ wordWrap: "break-word", maxWidth: 250 }}><img width={250} src={item.thumbnail} /></td>
                                                <td className='align-center' style={{ wordWrap: "break-word", maxWidth: 250 }}>{item.url}</td>
                                                <td className='align-center' style={{ wordWrap: "break-word", maxWidth: 50 }}>{item.linkType ? item.linkType : ''}</td>
                                                <td className='align-center' style={{ wordWrap: "break-word", maxWidth: 50 }}>{item.requiredLevel}</td>
                                                <td className='align-center' style={{ wordWrap: "break-word", maxWidth: 50 }}>{item.createBy}</td>
                                                <td className='align-center' style={{ verticalAlign: "top !important" }}>{new Date(item.startDate).toLocaleString()}</td>
                                                <td className='align-center' style={{ verticalAlign: "top !important" }}>{new Date(item.endDate).toLocaleString()}</td>
                                                <td className='align-center' style={{ verticalAlign: "top !important" }}>{item.enabled ? <span className='text-success'>Đang kích hoạt</span> : <span className='text-danger'>Đã Ẩn</span>}</td>
                                                <td className='align-center'>
                                                    {
                                                        !item.enabled ? (
                                                            <>
                                                                <button
                                                                    className="btn btn-info"
                                                                    onClick={this.updateBannerFull}
                                                                    name={index}
                                                                    data-toggle="modal"
                                                                    data-target="#modalDeleteBannerFull"
                                                                >
                                                                    Kích hoạt
                                                                </button>
                                                            </>

                                                        ) : (
                                                            <>
                                                                <button
                                                                    className="btn btn-success"
                                                                    onClick={this.updateBannerFull}
                                                                    name={index}
                                                                    data-toggle="modal"
                                                                    data-target="#modalUpdateBannerFull"
                                                                >
                                                                    Sửa
                                                                </button>&emsp;
                                                                <button
                                                                    className="btn btn-danger"
                                                                    onClick={this.updateBannerFull}
                                                                    name={index}
                                                                    data-toggle="modal"
                                                                    data-target="#modalDeleteBannerFull"
                                                                >
                                                                    Hủy
                                                                </button>
                                                            </>
                                                        )
                                                    }

                                                </td>
                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </table>
                        {/* <div className='text-center'>
                            {this.state.bannerFulls.length % 20 == 0 ?
                                <button onClick={this.getBannerFulls} className="btn-red">Xem thêm</button> :
                                ""}
                        </div> */}


                        {
                            statusButtonLoadPage ?
                                (<div onClick={this.getBannerFulls} className="view_more w-100 text-center mb-lg-4">
                                    <a className="d-none d-md-block">
                                        <span>{loading ? "ĐANG TẢI..." : "TẢI THÊM"}</span>
                                    </a>
                                </div>) :
                                ('')
                        }
                    </div>
                </div>

            </>
        );
    }
}

export default BannerFullPageManager
