import React, { Component } from 'react';
import axios from 'axios';
import {Link} from "react-router-dom";
import {withTranslation} from 'react-i18next';

import MenuInProfile from '../../components/MenuInProfile';
import MyContext from '../../common/MyContext';

class MyRecordingsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            recordings: []
        }
        console.log(props)

    }

    componentDidMount() {
        axios.post(global.config.apiDomain + '/rest/profile/getRecordings')
            .then(res => {
                const recordings = res.data;
                this.setState({
                    recordings: recordings
                })
            });
    }


    // componentDidUpdate() {
    //     console.log("didupdate")
    //     if(this.state.isFirst) {
    //         this.setState({
    //             isFirst: false
    //         })
    //         this.getImageList();
    //     }
    // }

    render() {
        const { recordings } = this.state;
        const {t} = this.props;
        console.log(recordings);
        return (
            <div id="content" >
                <div className="container">
                    <h2 className="text-sm-center mb-2 mb-md-3 mt-xl-0 mt-4">{t("my_recordings_page.recording", "Bài thu")}</h2>
                    <hr />
                    <div className="row">
                        <div className="col-lg-3">
                            <MenuInProfile></MenuInProfile>
                        </div>
                        <div className="col-lg-9">
                            <div className="row text-center">
                                {recordings.map((recording) => {
                                    return (
                                        <div className="col-xl-4 col-6" key={recording._id}>
                                            <div className="images">
                                                <Link to={recording.onlineRecordingUrl}>
                                                    <span className="overlay" />
                                                    <img 
                                                        src={recording.thumbnailImageUrl != null ? recording.thumbnailImageUrl : recording.song.thumbnailUrl} 
                                                        className="img-item" 
                                                        alt={recording.song.songName} />
                                                </Link>
                                            </div>
                                            <div className="d-flex py-2">
                                                {/* <img 
                                                    src={recording.owner.profileImageLink}
                                                    className="avatar-recording rounded-circle mr-1" /> */}
                                            <h5 className="song-name">
                                                <Link className="color-black-link hover-link" to={recording.onlineRecordingUrl}>{recording.song.songName}</Link>
                                            </h5>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

MyRecordingsPage.contextType = MyContext;
export default withTranslation()(MyRecordingsPage)


