import React, { Component} from 'react'
import {Link} from "react-router-dom";
import axios from 'axios';


class SearchPage extends Component {
    constructor(props) {
        super(props);
    }

    
    componentDidMount() {
        let keyword = this.props.match.params.keyword;
        let json = {
            keywordSearch: keyword,
            currentPageRecording: 0,
            currentPageUser: 0
        };


        axios.post(global.config.apiDomain + '/rest/search', JSON.stringify(json), {
            headers : {
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            console.log(res);
        })
    }



    render() {
        return (
            <div id="content">
                <div className="singer-page">
                    <div className="container">
                        <h1 className="heading_one">Các ca sĩ</h1>
                        <div className="wrap-list-singer">
                        <div className="row list-singer">
                            <div className="col-4 col-md-3 col-xl-2 item item-1">
                            <a href="http://m.okara.vn/ca-si/huong-tram" title="Hương Tràm" className="image">
                                <span className="overlay" />
                                <img src="/data/images/84001375.jpg" alt="Hương Tràm" />
                            </a>
                            <h4 className="text-center singer_name">
                                <a href="http://m.okara.vn/ca-si/huong-tram" title="Hương Tràm">Hương Tràm</a>
                            </h4>
                            </div>
                            <div className="col-4 col-md-3 col-xl-2 item item-2">
                            <a href="http://m.okara.vn/ca-si/min-st319" title="Min St.319" className="image">
                                <span className="overlay" />
                                <img src="/data/images/84002174.jpg" alt="Min St.319" />
                            </a>
                            <h4 className="text-center singer_name">
                                <a href="http://m.okara.vn/ca-si/min-st319" title="Min St.319">Min St.319</a>
                            </h4>
                            </div>
                            <div className="col-4 col-md-3 col-xl-2 item item-3">
                            <a href="http://m.okara.vn/ca-si/quang-le" title="Quang Lê" className="image">
                                <span className="overlay" />
                                <img src="/data/images/84001017.jpg" alt="Quang Lê" />
                            </a>
                            <h4 className="text-center singer_name">
                                <a href="http://m.okara.vn/ca-si/quang-le" title="Quang Lê">Quang Lê</a>
                            </h4>
                            </div>
                            <div className="col-4 col-md-3 col-xl-2 item item-4">
                            <a href="http://m.okara.vn/ca-si/soobin-hoang-son" title="Soobin Hoàng Sơn" className="image">
                                <span className="overlay" />
                                <img src="/data/images/84006282.jpg" alt="Soobin Hoàng Sơn" />
                            </a>
                            <h4 className="text-center singer_name">
                                <a href="http://m.okara.vn/ca-si/soobin-hoang-son" title="Soobin Hoàng Sơn">Soobin Hoàng Sơn</a>
                            </h4>
                            </div>
                            <div className="col-4 col-md-3 col-xl-2 item item-5">
                            <a href="http://m.okara.vn/ca-si/erik" title="Erik" className="image">
                                <span className="overlay" />
                                <img src="/data/images/84006371.jpg" alt="Erik" />
                            </a>
                            <h4 className="text-center singer_name">
                                <a href="http://m.okara.vn/ca-si/erik" title="Erik">Erik</a>
                            </h4>
                            </div>
                            <div className="col-4 col-md-3 col-xl-2 item item-6">
                            <a href="http://m.okara.vn/ca-si/quynh-trang" title="Quỳnh Trang" className="image">
                                <span className="overlay" />
                                <img src="/data/images/84006465.jpg" alt="Quỳnh Trang" />
                            </a>
                            <h4 className="text-center singer_name">
                                <a href="http://m.okara.vn/ca-si/quynh-trang" title="Quỳnh Trang">Quỳnh Trang</a>
                            </h4>
                            </div>
                            <div className="col-4 col-md-3 col-xl-2 item item-7">
                            <a href="http://m.okara.vn/ca-si/son-tung-mtp" title="Sơn Tùng M-Tp" className="image">
                                <span className="overlay" />
                                <img src="/data/images/84005262.jpg" alt="Sơn Tùng M-Tp" />
                            </a>
                            <h4 className="text-center singer_name">
                                <a href="http://m.okara.vn/ca-si/son-tung-mtp" title="Sơn Tùng M-Tp">Sơn Tùng M-Tp</a>
                            </h4>
                            </div>
                            <div className="col-4 col-md-3 col-xl-2 item item-8">
                            <a href="http://m.okara.vn/ca-si/dam-vinh-hung" title="Đàm Vĩnh Hưng" className="image">
                                <span className="overlay" />
                                <img src="/data/images/84001001.jpg" alt="Đàm Vĩnh Hưng" />
                            </a>
                            <h4 className="text-center singer_name">
                                <a href="http://m.okara.vn/ca-si/dam-vinh-hung" title="Đàm Vĩnh Hưng">Đàm Vĩnh Hưng</a>
                            </h4>
                            </div>
                            <div className="col-4 col-md-3 col-xl-2 item item-9">
                            <a href="http://m.okara.vn/ca-si/hari-won" title="Hari Won" className="image">
                                <span className="overlay" />
                                <img src="/data/images/84001295.jpg" alt="Hari Won" />
                            </a>
                            <h4 className="text-center singer_name">
                                <a href="http://m.okara.vn/ca-si/hari-won" title="Hari Won">Hari Won</a>
                            </h4>
                            </div>
                            <div className="col-4 col-md-3 col-xl-2 item item-10">
                            <a href="http://m.okara.vn/ca-si/ho-quang-hieu" title="Hồ Quang Hiếu" className="image">
                                <span className="overlay" />
                                <img src="/data/images/84005146.jpg" alt="Hồ Quang Hiếu" />
                            </a>
                            <h4 className="text-center singer_name">
                                <a href="http://m.okara.vn/ca-si/ho-quang-hieu" title="Hồ Quang Hiếu">Hồ Quang Hiếu</a>
                            </h4>
                            </div>
                            <div className="col-4 col-md-3 col-xl-2 item item-11">
                            <a href="http://m.okara.vn/ca-si/quang-ha" title="Quang Hà" className="image">
                                <span className="overlay" />
                                <img src="/data/images/84001015.jpg" alt="Quang Hà" />
                            </a>
                            <h4 className="text-center singer_name">
                                <a href="http://m.okara.vn/ca-si/quang-ha" title="Quang Hà">Quang Hà</a>
                            </h4>
                            </div>
                        </div>
                        </div>
                        <div className="view_more w-100 text-center mb-lg-4">
                            <a className="d-none d-md-block">
                                <span>Xem thêm ca sĩ</span>
                            </a>
                            <a className="view_more_mobile d-md-none">
                                <i className="fa fa-angle-down" aria-hidden="true" />
                            </a>
                        </div>
                    </div>
                </div>


                <div className="top-hit-page">
                    <div className="container">
                        <h1 className="heading_one">Nhạc HOT</h1>
                        <div className="row list-song">

                            <div className="col-6 col-lg-4 col-xl-3 item item-0">
                                <div className="images">
                                    <Link to={'/'}>
                                        <span className="overlay" />
                                        <img src={'https://picsum.photos/seed/6733582/270/150'} className="img-item" alt="{songName}" />
                                    </Link>
                                </div>
                                <h2 className="song-name">
                                    <Link to={'/'}>aaaaaa</Link>
                                </h2>
                                <div className="singer_names">
                                    <Link to={'/'}>bbbbbb</Link>
                                </div>
                            </div>
                            <div className="col-6 col-lg-4 col-xl-3 item item-0">
                                <div className="images">
                                    <Link to={'/'}>
                                        <span className="overlay" />
                                        <img src={'https://picsum.photos/seed/6733582/270/150'} className="img-item" alt="{songName}" />
                                    </Link>
                                </div>
                                <h2 className="song-name">
                                    <Link to={'/'}>aaaaaa</Link>
                                </h2>
                                <div className="singer_names">
                                    <Link to={'/'}>bbbbbb</Link>
                                </div>
                            </div>
                            <div className="col-6 col-lg-4 col-xl-3 item item-0">
                                <div className="images">
                                    <Link to={'/'}>
                                        <span className="overlay" />
                                        <img src={'https://picsum.photos/seed/6733582/270/150'} className="img-item" alt="{songName}" />
                                    </Link>
                                </div>
                                <h2 className="song-name">
                                    <Link to={'/'}>aaaaaa</Link>
                                </h2>
                                <div className="singer_names">
                                    <Link to={'/'}>bbbbbb</Link>
                                </div>
                            </div>
                            <div className="col-6 col-lg-4 col-xl-3 item item-0">
                                <div className="images">
                                    <Link to={'/'}>
                                        <span className="overlay" />
                                        <img src={'https://picsum.photos/seed/6733582/270/150'} className="img-item" alt="{songName}" />
                                    </Link>
                                </div>
                                <h2 className="song-name">
                                    <Link to={'/'}>aaaaaa</Link>
                                </h2>
                                <div className="singer_names">
                                    <Link to={'/'}>bbbbbb</Link>
                                </div>
                            </div>
                            <div className="col-6 col-lg-4 col-xl-3 item item-0">
                                <div className="images">
                                    <Link to={'/'}>
                                        <span className="overlay" />
                                        <img src={'https://picsum.photos/seed/6733582/270/150'} className="img-item" alt="{songName}" />
                                    </Link>
                                </div>
                                <h2 className="song-name">
                                    <Link to={'/'}>aaaaaa</Link>
                                </h2>
                                <div className="singer_names">
                                    <Link to={'/'}>bbbbbb</Link>
                                </div>
                            </div>
                            <div className="col-6 col-lg-4 col-xl-3 item item-0">
                                <div className="images">
                                    <Link to={'/'}>
                                        <span className="overlay" />
                                        <img src={'https://picsum.photos/seed/6733582/270/150'} className="img-item" alt="{songName}" />
                                    </Link>
                                </div>
                                <h2 className="song-name">
                                    <Link to={'/'}>aaaaaa</Link>
                                </h2>
                                <div className="singer_names">
                                    <Link to={'/'}>bbbbbb</Link>
                                </div>
                            </div>
                        </div>
                            <div className="view_more w-100 text-center mb-lg-4">
                                <a className="d-none d-md-block">
                                    <span>Xem thêm bản thu</span>
                                    </a>
                                <a className="view_more_mobile d-md-none">
                                    <i className="fa fa-angle-down" aria-hidden="true" />
                                </a>
                            </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SearchPage;