import React, { Component } from 'react';
import axios from 'axios';
import '../../../assets/css/newDevice.css';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

export default class ListMvToDay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            originalRecordings: [],
            recordings: [],
            selectedDate: '',
            selectedPinnedStatus: '',
            isLoading: false,
            inputValue: ''
        };

        this.getAllMvToDay = this.getAllMvToDay.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSelectDate = this.handleSelectDate.bind(this);
        this.handlePinnedStatusChange = this.handlePinnedStatusChange.bind(this);
        this.pinMv = this.pinMv.bind(this);
        this.searchById = this.searchById.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        const today = new Date().toISOString().split('T')[0];
        this.setState({ selectedDate: today }, () => {
            this.getAllMvToDay();
        });
    }

    searchById() {
        this.setState({ isLoading: true });
        const { inputValue } = this.state;
        axios.post(global.config.apiDomain + '/rest/recording/find-mv-by-id', inputValue, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            const models = res.data.models || [];
            this.setState({ 
                originalRecordings: models,
                recordings: models,
                isLoading: false
             });
        })
        .catch(error => {
            console.error('Error fetching recordings:', error);
            this.setState({ isLoading: false });
        });
    }

    getAllMvToDay() {
        this.setState({ isLoading: true });
        const { selectedDate } = this.state;
        axios.post(global.config.apiDomain + '/rest/recording/get-list-mv-to-day', selectedDate, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            const models = res.data.models || [];
            this.setState({ 
                originalRecordings: models,
                recordings: models,
                isLoading: false
             });
        })
        .catch(error => {
            console.error('Error fetching recordings:', error);
            this.setState({ isLoading: false });
        });
    }

    pinMv(item) {
        const title = item.isPinnedMvByAd ? 'Bỏ ghim MV này?' : 'Ghim MV này?';
        const confirmButtonText = item.isPinnedMvByAd ? 'Bỏ Ghim' : 'Ghim';
        Swal.fire({
            title: title,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: confirmButtonText,
            cancelButtonText: 'Hủy'
        }).then((result) => {
            if (result.isConfirmed) {
                const json = {
                    id: item._id,
                    isPinnedMvByAd: !item.isPinnedMvByAd
                };
                axios.post(global.config.apiDomain + '/rest/recording/pin-mv', json, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(res => {
                    if (res.data.status === "OK") {
                        toast.success(res.data.message);
                        this.setState(prevState => {
                            const updatedRecordings = prevState.recordings.map(recording =>
                                recording._id === item._id ? { ...recording, isPinnedMvByAd: !recording.isPinnedMvByAd } : recording
                            );
                            const updatedOriginalRecordings = prevState.originalRecordings.map(recording =>
                                recording._id === item._id ? { ...recording, isPinnedMvByAd: !recording.isPinnedMvByAd } : recording
                            );
                            return {
                                recordings: updatedRecordings,
                                originalRecordings: updatedOriginalRecordings
                            };
                        });
                    } else {
                        toast.error(res.data.message);
                    }
                })
                .catch(error => {
                    console.error('Lỗi khi ghim MV:', error);
                });
            }
        });
    }

    handleDateChange(event) {
        this.setState({ selectedDate: event.target.value });
    }

    handleSelectDate() {
        this.getAllMvToDay();
    }

    handlePinnedStatusChange(event) {
        this.setState({ selectedPinnedStatus: event.target.value });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        this.setState({
            inputValue: value
        });
    }

    render() {
        const { recordings, inputValue, selectedPinnedStatus, isLoading } = this.state;
        const filteredRecordings = recordings.filter(recording => {
            const isPinnedValue = recording.isPinnedMvByAd || false;
            return selectedPinnedStatus === '' || isPinnedValue.toString() === selectedPinnedStatus;
        });

        return (
            <>
                <div id="content">
                    <div className="container-fluid">
                        <div className="search-container" style={{ marginTop: '-80px' }}>
                            <input type="date" onChange={this.handleDateChange}></input>
                            <button style={{ minWidth: '90px' }} onClick={this.handleSelectDate}>Chọn ngày</button>


                            <input style={{marginLeft: '320px'}} 
                                type="number" 
                                value={inputValue} 
                                placeholder='Nhập Uid User' 
                                onChange={this.handleInputChange}
                            />
                            <button style={{minWidth: '80px'}} onClick={this.searchById}>Tìm kiếm</button>

                            <select value={this.state.selectedPinnedStatus} onChange={this.handlePinnedStatusChange}>
                                <option value="">Tất cả</option>
                                <option value="true">Đã ghim</option>
                                <option value="false">Chưa ghim</option>
                            </select>
                            <div style={{ minWidth: '80px' }} className="quantity">Số lượng: {filteredRecordings.length}</div>
                        </div>
                        {isLoading ? (
                            <div className="spinner-container">
                                <div className="spinner"></div>
                            </div>
                        ) : filteredRecordings.length > 0 ? (
                            <table className="table table-hover">
                                <thead>
                                    <tr className="text-center">
                                        <th className="align-middle" scope="col">AddTime</th>
                                        <th className="align-middle" scope="col">Id MV</th>
                                        <th className="align-middle" scope="col">Tên bài hát</th>
                                        <th className="align-middle" scope="col">Link nhạc</th>
                                        <th className="align-middle" scope="col">Link video</th>
                                        <th className="align-middle" scope="col">Uid User</th>
                                        <th className="align-middle" scope="col">User name</th>
                                        <th className="align-middle" scope="col">Ghim Mv</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredRecordings.map((item) => {
                                        let addTime2 = new Date(item.addTime2)
                                        return (
                                            <tr key={item.uid}>
                                                <td className="align-middle text-center" scope="col">{addTime2.toLocaleString()}</td>
                                                <td className="align-middle text-center" scope="col">{item._id}</td>
                                                <td className="align-middle text-center" scope="col">{item.songName}</td>
                                                <td className="align-middle text-center" scope="col">
                                                    <a href={item.vocalUrl} target="_blank" rel="noopener noreferrer">Nghe nhạc</a>
                                                </td>
                                                <td className="align-middle text-center" scope="col">
                                                    <a href={item.mixedVideoUrl} target="_blank" rel="noopener noreferrer">Xem video</a>
                                                </td>
                                                <td className="align-middle text-center" scope="col">{item.uidUser}</td>
                                                <td className="align-middle text-center" scope="col">{item.userName}</td>
                                                <td className="align-middle text-center" scope="col">
                                                    <span style={{ color: item.isPinnedMvByAd ? '#27e75d' : '#848d84' }}>{item.isPinnedMvByAd}</span> 
                                                    <i className="fa-solid fa-thumbtack mr-4" 
                                                       style={{ color: item.isPinnedMvByAd ? '#27e75d' : '#848d84', fontSize: '26px', cursor: 'pointer' }}
                                                       onClick={() => this.pinMv(item)}
                                                    ></i>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        ) : (
                            <div className="text-center">
                                <p>Không có Mv nào.</p>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}
