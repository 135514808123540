import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import $ from 'jquery';
import { toast } from 'react-toastify';
import ModalSpecialGift from './components/ModalSpecialGift.js';


export default class UserSpecialGiftPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prevUid: null,
      uid: 0,
      specialGiftPlusList: null,
      user: null,
      specialGiftNo: 0,
      currentPage: 0,
      disabledButton: false,
    }

    this.submit = this.submit.bind(this);
    this.getUserSpecialGiftPlus = this.getUserSpecialGiftPlus.bind(this);
  }


  getUserSpecialGiftPlus(uid, page) {
    axios.post(global.config.apiDomain + '/rest/userSpecialGift/' + uid + "/gift/" + page)
      .then(res => {
        console.log(res);
        if (res.data.status == "OK") {
          toast.success(res.data.message);
          const specialGiftList = res.data.list || [];
          const disable = specialGiftList.length < 20;
          this.setState(prevState => ({
            specialGiftPlusList: prevState.specialGiftPlusList ? prevState.specialGiftPlusList.concat(res.data.list) : res.data.list,
            user: res.data.user,
            specialGiftNo: res.data.specialGiftNo,
            disabledButton: disable,
          }), () => {
            // Accessing specialGiftPlusList here after state is updated
            if (this.state.specialGiftPlusList) {
              console.log("Size of specialGiftPlusList:", this.state.specialGiftPlusList.length);
            }
          });
        } else {
          toast.error(res.data.message);
        }
      });
  }

  submit() {
    let uid = $('#uid').val().trim();
    if (!uid || !Number.isInteger(+uid)) {
      return toast.warning("ID User phải là số");
    }
  
    // Update state
    this.setState({ 
      prevUid: uid,
      uid: uid, 
      specialGiftPlusList: null,
      disableButton: false,
      currentPage: 0, // Reset currentPage
    }, () => {
      // Callback after state is updated
      this.getUserSpecialGiftPlus(uid, this.state.currentPage); // Use this.state.currentPage
    });
  }
  

  loadMore = () => {
    const { uid, currentPage } = this.state;
    if (!uid) {
      return toast.warning("ID User không hợp lệ");
    }
  
    const nextPage = currentPage + 1;
    this.setState({ currentPage: nextPage }, () => {
      this.getUserSpecialGiftPlus(uid, nextPage);
    });
  };
  
  
  render() {
    const { specialGiftPlusList, user, specialGiftNo, disabledButton} = this.state;

    return (
      <>
        <div id="content">
          <div className="container-fluid">
            <ModalSpecialGift
            user={user}
            getUserSpecialGiftPlus={this.getUserSpecialGiftPlus}
            resetData={this.submit}
            ></ModalSpecialGift>
            <h1 className="text-center">Quản lý thẻ user</h1>

            <div className="d-flex justify-content-center mb-3">
            {/* Input field for user ID */}
            <input
              type="text"
              className="form-control col-3 mr-2"
              placeholder="Nhập ID"
              id="uid"
            />
            {/* Input field for page number */}
            <button
              style={{ width: "120px", marginRight: "10px" }}
              className="btn btn-red"
              onClick={this.submit}
            >
              Xác nhận
            </button>
          </div>
            <div className='row'>
              <div className='col-1'></div>

            </div>
            <div className='row'>
              <div className='col-4'>
                {
                  user &&
                  <>
                    <div className='row'>
                      <div className='col-3'></div>
                      <div className='col-6'><img src={user.profileImageLink}></img></div>

                    </div>
                    <br></br>
                    <div className='row'>
                      <div className='col-2'></div>
                      <div className='col-7'>
                        <h5><span className='text-success'>{user.name}</span></h5>
                        <h5>ID: <span className='text-success'>{user.facebookId}</span></h5>
                        <h5>VIP level: <span className='text-danger'>{user.vipInfo.vipLevel}</span></h5>
                        <br></br>

                        <h5>Thẻ xử lý: <span className='text-danger'>{specialGiftNo}</span></h5>
                        <button
                          className='btn btn-primary'
                          data-toggle="modal"
                          data-target="#modalSpecialGift"
                        >
                          Thay đổi Thẻ <i class="fa-solid fa-pen-to-square" style={{ color: "white" }}></i>
                        </button>
                      </div>
                    </div>
                  </>
                }
              </div>
              <div className='col-8'>
                <h3 className="text-center">Lịch sử thẻ</h3>
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Thời gian</th>
                      <th scope="col">Mô tả</th>
                      <th scope="col">Cũ</th>
                      <th scope="col">Số lượng</th>
                      <th scope="col">Mới</th>
                      <th scope="col">Bài thu</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {specialGiftPlusList && specialGiftPlusList.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{new Date(item.addTime).toLocaleString()}</td>
                          <td>{item.description.includes('Mua') ? <p class="text-primary">{item.description}</p> : item.description.includes('Sử dụng') ? <p class="text-info">{item.description}</p> : item.description.includes('vip') ? <p class="text-warning">{item.description}</p>: <p class="text-danger">{item.description}</p>}</td>
                          <td>{item.oldItem}</td>
                          <td><strong>{item.noItem}</strong></td>
                          <td>{item.newItem}</td>
                          <td>{item.recording ? <a href={item.recording.onlineRecordingUrl}>{item.recording.songName}</a> : ''}</td>
                          <td>
                            {item.recording ?
                              <Link to={'/admin/recording-ai/detail/' + item.recording._id}>
                                <i class="fa-solid fa-pen-to-square" style={{ color: "#2ed139" }}></i>
                              </Link>
                              : ''
                            }

                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <div>
                <button
                  style={{ width: "120px" }}
                  className="btn btn-red"
                  onClick={this.loadMore}
                  disabled={disabledButton}
                >
                  Tải thêm
                </button>
                </div>
              </div>

            </div>




          </div>
        </div>
      </>
    );
  }
}