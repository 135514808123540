import React, { Component } from 'react';
import axios from 'axios';

import ModalUpdateCompetition from './components/ModalUpdateCompetition';
import ModalDeleteCompetition from './components/ModalDeleteCompetition';

export default class CompetitionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contests: [],
            competition: null,
        };

        this.getContests = this.getContests.bind(this);
        this.handlerUpdate = this.handlerUpdate.bind(this);
    }

    componentDidMount() {
        this.getContests();
    }

    getContests() {
        axios.post(global.config.apiDomain + '/rest/theVoiceV2/getListTheVoice')
        .then(res => {
            if (res.data.status === 'OK') {
                console.log('getContests');
                console.log(res.data);
                this.setState({
                    contests: res.data.data
                })
            } 
        })
    }

    handlerUpdate(event) {
        let index = event.target.name;
        let competition = {
            _id: 0,
            idEvent: '',
            language: '',
            miniBackgroundUrl: '',
            backgroundCode: {
            backgroundCodeContest: '',
            backgroundCodeRule: '',
            backgroundCodeRound1: '',
            backgroundCodeRound2: '',
            backgroundCodeRound3: '',
            backgroundCodeRank: '',
            backgroundCodeButton: '',
            backgroundCodeFooter: '',
            },
            imgUrl: '',
            name: '',
            describe: '',
            slogan: '',
            categoryId: 0,
            theVoiceRuleFull: {
                titleTime: '',
                detailTime: '',
                titleRule: '',
                detailRule: '',
                titleOther: '',
                detailOther: ''
            },
            numRewardRound2: 0,
            typePermissionAccept: '',
            typeRecordingAccept: '',
            typeRecordingMedia: '',
            timeStartRound1: new Date().toLocaleString("sv", {timeZone: "Asia/Ho_Chi_Minh"}).replace(" ", "T"),
            timeEndRound1: new Date().toLocaleString("sv", {timeZone: "Asia/Ho_Chi_Minh"}).replace(" ", "T"),
            timeEndRound2: new Date().toLocaleString("sv", {timeZone: "Asia/Ho_Chi_Minh"}).replace(" ", "T"),
            limitRecordingRound1: 0,
            limitRecordingRound2: 0,
            maxNumMediaOneUse: 0,
            minLevelExaminer: 0,
            maxMediaForOneExaminer: 0,
            maxExaminerForOneMedia: 0,
            // timeStart:  new Date().toLocaleString("sv", {timeZone: "Asia/Ho_Chi_Minh"}).replace(" ", "T"),
            // timeEnd:  new Date().toLocaleString("sv", {timeZone: "Asia/Ho_Chi_Minh"}).replace(" ", "T"),
            timeShowStart:  new Date().toLocaleString("sv", {timeZone: "Asia/Ho_Chi_Minh"}).replace(" ", "T"),
            timeShowEnd:  new Date().toLocaleString("sv", {timeZone: "Asia/Ho_Chi_Minh"}).replace(" ", "T"),
            giftTheVoiceRound2: [{}, {}, {}, {}, {}],
            isRemoveByAdmin: false,
            minNumMark: 0,
            canApplyExaminer: false,
            backgroundDialogUrl: '',
        };

        if (index != '-1') {
            competition = this.state.contests[index];
            competition = this.convertDateToString(competition);
            
            if (competition.canApplyExaminer == true) {
                competition.canApplyExaminer = '1';
            } else {
                competition.canApplyExaminer = '0';
            }
           
        }
        this.setState({
            competition : competition,
        })
    }

    convertDateToString(competition) {
        competition.timeShowStart = new Date(competition.timeShowStart).toLocaleString("sv", {timeZone: "Asia/Ho_Chi_Minh"}).replace(" ", "T");
        competition.timeShowEnd = new Date(competition.timeShowEnd).toLocaleString("sv", {timeZone: "Asia/Ho_Chi_Minh"}).replace(" ", "T");
        competition.timeStartRound1 = new Date(competition.timeStartRound1).toLocaleString("sv", {timeZone: "Asia/Ho_Chi_Minh"}).replace(" ", "T");
        competition.timeEndRound1 = new Date(competition.timeEndRound1).toLocaleString("sv", {timeZone: "Asia/Ho_Chi_Minh"}).replace(" ", "T");
        competition.timeEndRound2 = new Date(competition.timeEndRound2).toLocaleString("sv", {timeZone: "Asia/Ho_Chi_Minh"}).replace(" ", "T");
        return competition;
    }
   
    render() {
        const { contests, competition } = this.state;
        return (
            <>
                <ModalUpdateCompetition
                    competition={competition}
                    resetList={this.getContests}
                >                
                </ModalUpdateCompetition>
                <ModalDeleteCompetition
                    competition={competition}
                    resetList={this.getContests}
                >                
                </ModalDeleteCompetition>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí Cuộc Thi</h1>
                        <button 
                            className="btn btn-red mb-1" 
                            data-toggle="modal" 
                            data-target="#modalUpdateCompetition"
                            name= '-1' 
                            onClick={this.handlerUpdate}
                            >+ Tạo Mới Cuộc Thi
                        </button>
                        <table className="table table-hover">
                            <thead>
                            <tr className="text-center">
                                <th className="align-middle" scope="col">ID</th>
                                <th className="align-middle" scope="col">Event</th>    
                                <th className="align-middle" scope="col">Tên cuộc thi</th>    
                                <th className="align-middle" scope="col">Chủ đề</th>                          
                                <th className="align-middle" scope="col">Slogan</th>
                                <th className="align-middle" scope="col">Số lượng trao giải</th>
                                <th className="align-middle" scope="col">Thời gian diễn ra</th>
                                <th className="align-middle" scope="col">Luật cuộc thi</th>
                                <th className="align-middle" scope="col">Hẹn giờ đăng</th>
                                <th className="align-middle" scope="col">Bài hát hợp lệ</th>
                                <th className="align-middle" scope="col">Hình thức hợp lệ</th>
                                <th className="align-middle" scope="col">Bài dự thi</th>
                                <th className="align-middle" scope="col">Số bài dự thi của 1 user</th>
                                <th className="align-middle" scope="col">Số bài đc lấy vào vòng 2</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                                {
                                    contests && contests.map((item,index) => (
                                        <tr>
                                            <td>{item.isRemoveByAdmin ? <del>{item._id}</del> : item._id}</td>
                                            <td>{item.isRemoveByAdmin ? <del>{item.idEvent}</del> : item.idEvent}</td>
                                            <td>{item.isRemoveByAdmin ? <del>{item.name}</del> : item.name}</td>
                                            {/* <td>{item.categoryInfo ? item.isRemoveByAdmin ? <del>{item.categoryInfo.name}</del> : item.categoryInfo.name : 'Song ca cùng Thần Tượng'}</td> */}
                                            <td>{item.categoryInfo ? (item.isRemoveByAdmin ? <del>{item.categoryInfo.name}</del> : item.categoryInfo.name): (item.categoryId === 1 ? 'Song ca cùng Thần Tượng' : item.idEvent == 'TheVoice' ? 'SONG CA' : '')}</td>
                                            <td>{item.isRemoveByAdmin ? <del>{item.slogan}</del> : item.slogan}</td>
                                            <td>{item.isRemoveByAdmin ? <del>{item.numRewardRound2}</del> : item.numRewardRound2}</td>
                                            <td>{item.isRemoveByAdmin ? <del>{item.theVoiceRuleFull.titleTime}</del> : item.theVoiceRuleFull.titleTime}</td>
                                            <td>{item.isRemoveByAdmin ? <del>{item.theVoiceRuleFull.titleRule}</del> : item.theVoiceRuleFull.titleRule}</td>
                                            <td>{item.isRemoveByAdmin ? <del>{new Date(item.timeShowStart).toLocaleString()}</del> : new Date(item.timeShowStart).toLocaleString()}</td>
                                            <td>{item.isRemoveByAdmin ? <del>{item.typePermissionAccept}</del> : item.typePermissionAccept}</td>
                                            <td>{item.isRemoveByAdmin ? <del>{item.typeRecordingAccept}</del> : item.typeRecordingAccept}</td>
                                            <td>{item.isRemoveByAdmin ? <del>{item.typeRecordingMedia}</del> : item.typeRecordingMedia}</td>
                                            <td>{item.isRemoveByAdmin ? <del>{item.limitRecordingRound1}</del> : item.limitRecordingRound1}</td>
                                            <td>{item.isRemoveByAdmin ? <del>{item.limitRecordingRound2}</del> : item.limitRecordingRound2}</td>
                                            <td>
                                                {
                                                    item.isRemoveByAdmin ? (
                                                        <button 
                                                        className="btn btn-info"
                                                        onClick={this.handlerUpdate}
                                                        name={index} 
                                                        data-toggle="modal" 
                                                        data-target="#modalDeleteCompetition"
                                                    >
                                                        Mở lại
                                                    </button>
                                                    ) : (
                                                        <>
                                                            <button 
                                                                className="btn btn-success"
                                                                onClick={this.handlerUpdate}
                                                                name={index} 
                                                                data-toggle="modal" 
                                                                data-target="#modalUpdateCompetition"
                                                            >
                                                                Sửa
                                                            </button>&emsp;
                                                            <button 
                                                                className="btn btn-danger"
                                                                onClick={this.handlerUpdate}
                                                                name={index} 
                                                                data-toggle="modal" 
                                                                data-target="#modalDeleteCompetition"
                                                            >
                                                                Xóa
                                                            </button>
                                                        </>
                                                    )
                                                }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}