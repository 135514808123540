import React, { Component } from 'react';
import RecordingItemInUserPage from './RecordingItemInUserPage.js'
import axios from 'axios';
import { withTranslation } from 'react-i18next';
class UserPage extends Component {
  constructor(props) {
		super(props);
		this.state = {
      user : null,
      recordings:[],
      currentPageRecording: 0,
      statusButtonLoadPage: false,
      loading: false
		};

    this.loadMoreRecordings = this.loadMoreRecordings.bind(this);
	}

	componentDidMount(){
    let userId = this.props.match.params.userId;

    let json = {
      userId : userId
    }
    
    axios.post(global.config.apiDomain + '/rest/userpage', JSON.stringify(json), {
      headers : {
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      const {user, recordings} = res.data;
      let status = true;

      if(recordings.length < 12) {
        status = false;
      }

      this.setState({
        user: user,
        recordings : recordings,
        statusButtonLoadPage: status
      });
    })
	}

  
  loadMoreRecordings() {
    const {loading} = this.state;
    if(!loading) {
      this.setState({
        loading: true
      });
      let userId = this.props.match.params.userId;
      let currentPageRecording = this.state.currentPageRecording + 1;
      let json = {
        userId : userId,
        currentPageRecording: currentPageRecording
      }
      
      axios.post(global.config.apiDomain + '/rest/userpage/loadmorerecordings', JSON.stringify(json), {
        headers : {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        console.log(res);
        const {recordings} = res.data;
        let status = true;
  
        if(recordings.length < 12) {
          status = false;
        }
  
        this.setState({
          recordings : this.state.recordings.concat(recordings),
          currentPageRecording: currentPageRecording,
          statusButtonLoadPage : status,
          loading: false
        });
      });
    }
    

    
  }

  render() {
    const {user, recordings, statusButtonLoadPage, loading} = this.state;
    const { t } = this.props;
    if(user == null) {
      return <div id="content"></div>;
    }

    let addTime = '';

    return (
      <>
        <div id="content" style={{paddingTop: '60px'}}>
          <div className="user-detail-page">
            <div className="top">
              <div className="container">
                <div className="wrap-top d-flex align-items-center">
                  <div className="avatar" data-fancybox="images" data-animation-effect="zoom-in-out" 
                    data-mobile data-caption={`<h5 class='mb-0'>${user.name}</h5><p>Ngày tham gia: ${user.addTime} (${recordings.length} bản thu)</p>`} 
                    href={user.profileImageLink} data-width={1020}>
                    <img src={user.profileImageLink} alt={`Avatar của ${user.name}`} />
                  </div>
                  <div className="info">
                    <h1>{user.name}</h1>
                    <p>{t("user_page.add_time","Ngày tham gia")}: {new Date(user.addTime).toLocaleDateString()}</p>
                    <p>ID: {user.uid}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="list-record">
                <h2>{t("user_page.list_recordings","Danh sách bài thu")} </h2>
                <p className="total-record">{recordings.length} {t("user_page.recording","bài thu")}</p>
                <div className="row">
                  {
                    recordings.map((recording, index)=>(
                      <RecordingItemInUserPage key={recording._id}
                        recording={recording}
                        ></RecordingItemInUserPage>
                    ))
                  }
                </div>
                <div className="clearfix" />
              </div>
            </div>
            {
              statusButtonLoadPage ? 
              ( <div onClick={this.loadMoreRecordings} className="view_more w-100 text-center mb-lg-4">
                  <a className="d-none d-md-block">
                    <span>{loading ? t("user_page.loading","Đang tải") + "..." : t("user_page.load_more",'Xem thêm bài thu')}</span>
                  </a>
                  <a className="view_more_mobile d-md-none">
                    <i className="fa fa-angle-down" aria-hidden="true" />
                  </a>
                </div>) : 
              ('')
            }
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(UserPage)