import React, { Component } from 'react';
import axios from 'axios';
import ModalUpdateMinigame from './components/ModalUpdateMinigame';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { toast } from 'react-toastify';
import ModalDeleteMinigame from './components/ModalDeleteMinigame';

export default class MinigameManagerPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            minigames: [],
            minigame: null
        }

        this.getNewMiniGame = this.getNewMiniGame.bind(this);
        this.updateMinigame = this.updateMinigame.bind(this);
        this.getMinigames = this.getMinigames.bind(this);
    }

    componentDidMount() {
        this.getMinigames();
    }

    getMinigames() {
        axios.post(global.config.apiDomain + '/rest/minigame').then(res => {


            this.setState({
                minigames: res.data
            });

            console.log(res.data)
        })
    }

    getNewMiniGame() {
        axios.post(global.config.apiDomain + '/rest/minigame/new').then(res => {

            let minigame = res.data;

            this.setState({
                minigame: minigame
            });

        })
    }

    updateMinigame(minigame) {
        this.setState({
            minigame: minigame
        })
    }

    handleResponse = (data) => {
        console.log(data)
        if (data.accessToken != null) {
            axios.post(global.config.apiDomain + '/rest/minigame/updatePageToken', data.accessToken, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            ).then(res => {
                if (res.data.status == "OK") {
                    toast.success(res.data.message);
                } else {
                    toast.warning(res.data.message);
                }
            })
        }
    }

    updatePageAccessToken(renderProps) {
        return (
            <button
                className="btn btn-red mb-1 ml-2"
                onClick={renderProps.onClick}
            >Update access token
            </button>
        )
    }

    render() {
        const { minigames, minigame } = this.state;

        return (
            <>
                <ModalUpdateMinigame minigame={minigame} resetList={this.getMinigames}></ModalUpdateMinigame>
                <ModalDeleteMinigame minigame={minigame} resetList={this.getMinigames}></ModalDeleteMinigame>
                <div id="content">

                    <div className="container-fluid">
                        <h2 className="text-center">Quản lí minigame</h2>
                        <button
                            className="btn btn-red mb-1"
                            data-toggle="modal"
                            data-target="#modalUpdateMinigame"
                            onClick={this.getNewMiniGame}
                        >+ Tạo minigame
                        </button>
                        <FacebookLogin
                            appId="670920597615866"
                            autoLoad={false}
                            fields="name,email,picture"
                            scope="public_profile,pages_manage_engagement,pages_manage_posts,pages_read_engagement,pages_show_list,pages_read_user_content,manage_pages,pages_read_engagement"
                            isMobile={false}
                            xfbml={true}
                            version="3.1"
                            disableMobileRedirect={true}
                            callback={this.handleResponse}
                            render={(renderProps) => (
                                this.updatePageAccessToken(renderProps)
                            )}
                        />
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th width={200} scope="col">Id</th>
                                    <th scope="col">Thumbnail</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Đáp án</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {minigames.map((minigame, index) => {
                                    return (
                                        <tr key={minigame.id}>
                                            <th scope="row">{minigame.id}</th>
                                            <td><img width={250} src={minigame.thumbnailQuestion} /></td>
                                            <td> {minigame.statusQuestion == null ? ("") : (minigame.statusQuestion.length > 100 ? minigame.statusQuestion.substring(0, 100) + "..." : minigame.statusQuestion)} </td>
                                            <td>{minigame.result}</td>
                                            <td>
                                                <button
                                                    onClick={() => { this.updateMinigame(minigame) }}
                                                    className="btn btn-red"
                                                    data-toggle="modal"
                                                    data-target="#modalUpdateMinigame"
                                                >Sửa</button>
                                                <button
                                                    className="btn btn-red ml-sm-2"
                                                    data-toggle="modal"
                                                    data-target="#modalDeleteMinigame"
                                                    onClick={() => { this.updateMinigame(minigame) }}
                                                >Xóa</button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    }
}