import React, { Component } from 'react';
import RecordingItemInRecordingsPage from '../../components/RecordingItemInRecordingsPage'
import axios from 'axios';
import {withTranslation} from 'react-i18next';
import { Helmet } from 'react-helmet';
class TopDuetRecordingsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recordings: [],
            currentPage: 0,
            statusButtonLoadPage: false,
            loading: false
        };

        this.loadMoreRecordings = this.loadMoreRecordings.bind(this);
    }


    componentDidMount() {
        this.getRecordings(this.state.currentPage);
    }

    getRecordings(pageIndex) {
        
        let json = {
            domain: window.location.hostname,
            currentPage: pageIndex
        }

        axios.post(global.config.apiDomain + '/rest/top-duet', JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            {
                const {recordings} = res.data;
    
                let status = true;
    
                if (recordings.length < 12) {
                    status = false;
                }
    
                this.setState({
                    recordings: this.state.recordings.concat(recordings),
                    currentPage: pageIndex + 1,
                    statusButtonLoadPage: status,
                    loading: false
                });

            }
        }) 
    }


    loadMoreRecordings() {
        const {loading} = this.state;
        if(!loading) {
            this.setState({
                loading: true
            });
            this.getRecordings(this.state.currentPage);
        }
    }

    render() {
        const {recordings, statusButtonLoadPage, loading} = this.state;
        const {t} = this.props;
        return (
            <div id="content">
                <Helmet>
					<title>{t("top_duet_recordings_page.top_duet_recordings", "Song ca")}</title>
				</Helmet>
                <div className="top-hit-page">
                    <div className="container">
                        <h1 className="heading_one">{t("top_duet_recordings_page.top_duet_recordings", "Song ca")}</h1>
                        <div className="row list-song">
                            {
                                recordings.map((recording,index)=>(
                                    <RecordingItemInRecordingsPage 
                                        key={recording._id}
                                        recording={recording}
                                    ></RecordingItemInRecordingsPage>
                                ))
                            }
                        </div>
                        {
                            statusButtonLoadPage ?
                            (<div onClick={this.loadMoreRecordings} className="view_more w-100 text-center mb-lg-4">
                                <a className="d-none d-md-block">
                                    <span>{loading ? t("top_duet_recordings_page.loading","ĐANG TẢI")+"..." : t("top_duet_recordings_page.load_more", "TẢI THÊM")}</span>
                                    </a>
                                <a className="view_more_mobile d-md-none">
                                    <i className="fa fa-angle-down" aria-hidden="true" />
                                </a>
                            </div>) :
                            ('') 
                        }
                        
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(TopDuetRecordingsPage)
