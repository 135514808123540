import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import numeral from 'numeral';
export default class GiftHeader extends Component {
    render() {
        let {totalGift, user}  = this.props;
        if (totalGift == null) totalGift = 0;
        return (
            <li className="LI_9">
                {
                user ? 
                (
                    <div>
                    <div className="A_10"  title={totalGift}><img src={require('../../../assets/images/gift_default.png')} className="IMG_11" alt="" /></div>
                    <p className="P_12"  title={totalGift}>
                        <span className="SPAN_13" />{numeral(totalGift).format('0a')}

                    </p>
                    </div>
                ) : 
                (
                <Link to="/login">
                <div className="A_10"><img src={require('../../../assets/images/gift_default.png')} className="IMG_11" alt="" /></div>
                <p className="P_12" title={totalGift}>
                    <span className="SPAN_13" />{numeral(totalGift).format('0a')}

                </p>
                </Link>
                )
                }
            </li>
        )
    }
}
