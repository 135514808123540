import React, { Component } from 'react';
import MessageItem from './MessageItem';

export default class MessageList extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { messages, user, targetUser, handleTargetUser } = this.props;

        return (
            // <div className="bg-white">
                // <div className="messages-box">
                    <div className="list-group rounded-0">
                        {
                            messages.map((message, index) => (
                                <MessageItem key={index} 
                                            message={message} 
                                            user={user} 
                                            targetUser={targetUser}
                                            handleTargetUser={handleTargetUser}
                                ></MessageItem>
                            ))
                        }
                    </div>
                // </div>
            // </div>

        );
    }
}