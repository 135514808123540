import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';

import ModalUpdateAppPolicy from './components/ModalUpdateAppPolicy';
import ModalDeleteAppPolicy from './components/ModalDeleteAppPolicy';

class AppPolicyPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            appPolicyModel: null,
        };

        this.getAllAppPolicy = this.getAllAppPolicy.bind(this);
        this.createOrUpdateAppPolicy = this.createOrUpdateAppPolicy.bind(this);
        this.deleteOrActiveAppPolicy = this.deleteOrActiveAppPolicy.bind(this);
    }

    componentDidMount() {
        this.getAllAppPolicy();
    }

    getAllAppPolicy() {
        axios.post(global.config.apiDomain + '/rest/app-policy/getAllAppPolicy')
            .then(res => {
                if (res.data.status === 'OK') {
                    this.setState({
                        list: res.data.list
                    })
                }
            })
    }

    createOrUpdateAppPolicy(event) {
        let index = event.target.name;
        let appPolicyModel = null;
        if (index === "-1") {
            appPolicyModel = {
                order: 0,
                name: '',
                link: '',
                app: ''
            }
        } else {
            appPolicyModel = this.state.list[index];
        }

        this.setState({
            appPolicyModel: appPolicyModel
        })

    }

    deleteOrActiveAppPolicy(event) {
        let index = event.target.name;
        let appPolicyModel = this.state.list[index];

        this.setState({
            appPolicyModel: appPolicyModel
        })
    }

    render() {
        const { list, appPolicyModel } = this.state;
        return (
            <>
                <ModalUpdateAppPolicy
                    appPolicyModel={appPolicyModel}
                    resetList={this.getAllAppPolicy}
                >
                </ModalUpdateAppPolicy>
                <ModalDeleteAppPolicy
                    appPolicyModel={appPolicyModel}
                    resetList={this.getAllAppPolicy}
                >
                </ModalDeleteAppPolicy>
                <div id="content">
                    <div className="container">
                        <h1 className="text-center">Quản lí Chính sách App</h1>
                        <button
                            className="btn btn-red mb-1"
                            data-toggle="modal"
                            data-target="#modalUpdateAppPolicy"
                            name="-1"
                            onClick={this.createOrUpdateAppPolicy}
                        >+ Tạo Mới Chính sách
                        </button>
                        <table className="table table-hover">
                            <thead>
                                <tr className="text-center">
                                    <th className="align-middle" scope="col">ID</th>
                                    <th className="align-middle" scope="col">Thứ tự</th>
                                    <th className="align-middle" scope="col">App</th>
                                    <th className="align-middle" scope="col">Tên Mục</th>
                                    <th className="align-middle" scope="col">Link Bài Viết</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    list && list.map((item, index) => {
                                        return (
                                            <tr>
                                                <td className='align-middle text-center'>{item.deleted ? <del>{item.id}</del> : item.id}</td>
                                                <td className='align-middle text-center'>{item.deleted ? <del>{item.order}</del> : <strong>{item.order}</strong>}</td>
                                                <td className='align-middle text-center'>{item.deleted ? <del>{item.app}</del> : <strong>{item.app}</strong>}</td>
                                                <td className='align-middle'>{item.deleted ? <del>{item.name}</del> : item.name}</td>
                                                <td className='align-middle'>{item.deleted ? <del>{item.link}</del> : item.link}</td>
                                                <td className='align-middle'>
                                                    {
                                                        item.deleted ? (
                                                            <button
                                                                className="btn btn-info"
                                                                onClick={this.deleteOrActiveAppPolicy}
                                                                name={index}
                                                                data-toggle="modal"
                                                                data-target="#modalDeleteAppPolicy"
                                                            >
                                                                Mở lại
                                                            </button>
                                                        ) : (
                                                            <>
                                                                <button
                                                                    className="btn btn-success"
                                                                    onClick={this.createOrUpdateAppPolicy}
                                                                    name={index}
                                                                    data-toggle="modal"
                                                                    data-target="#modalUpdateAppPolicy"
                                                                >
                                                                    Sửa
                                                                </button>&emsp;
                                                                <button
                                                                    className="btn btn-warning"
                                                                    onClick={this.deleteOrActiveAppPolicy}
                                                                    name={index}
                                                                    data-toggle="modal"
                                                                    data-target="#modalDeleteAppPolicy"
                                                                >
                                                                    Ẩn
                                                                </button>
                                                            </>
                                                        )
                                                    }

                                                </td>
                                            </tr>
                                        )
                                    })

                                }

                            </tbody>
                        </table>
                    </div>
                </div>

            </>
        );
    }
}

export default AppPolicyPage
