import { toast } from "react-toastify";
import $ from "jquery";
import React, { Component } from "react";
import axios from "axios";

export default class ModalUpdateEvent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idEvent: null,
      eventName: null,
      cupTop: null,
      cupTop1: null,
      cupTop2: null,
      cupTop3: null,
      keyCupTop1: null,
      keyCupTop2: null,
      keyCupTop3: null,
      imgCupTop1: null,
      imgCupTop2: null,
      imgCupTop3: null,
      frameTop: null,
      frameUrlRecoding1: null,
      frameIdRecording1: null,
      frameUrlRecoding2: null,
      frameIdRecording2: null,
      frameUrlRecoding3: null,
      frameIdRecording3: null,
      loading: false,
      gift: null,
      gifts: [],
      frames: [],
    };

    this.uploadThumbnailToS3 = this.uploadThumbnailToS3.bind(this);
    this.uploadAnimationToS3 = this.uploadAnimationToS3.bind(this);
    this.showCupSelectedForEvent = this.showCupSelectedForEvent.bind(this);
    this.showAvatarFrameForEvent = this.showAvatarFrameForEvent.bind(this);
    this.handleChangeEvent = this.handleChangeEvent.bind(this);
    // this.handleSetCup = this.handleSetCup.bind(this);
  }

  handleChangeEvent(){

  }

  componentWillMount(props) {
    // let {eventChange, eventNameChange } = props;
    // if (gifts != null) {
    //   this.setState({
    //     idEvent: eventChange,
    //     eventName: eventNameChange,
    //   });
    // }
  }

  uploadAnimationToS3(event) {
    var target = event.target;
    //10495760
    if (target.files[0].size > 10495760) {
      toast.error("Kích thước file phải dưới 10mb.");
      return;
    }

    this.setState({
      loading: true,
    });

    var gift = this.state.gift;
    var bucketName = "ikara-data/images/events";
    var extension = target.files[0].name.split(".").pop().toLowerCase();
    var keyName = this.guid() + "." + extension;
    var contentType = "image/svga";
    if (extension == "png") contentType = "image/svga";

    var fileReader = new FileReader();

    fileReader.onload = function () {
      var fd = new FormData();
      fd.append("file", target.files[0]);
      fd.append("bucketName", bucketName);
      fd.append("objectKey", keyName);
      fd.append("contentType", contentType);
      $.ajax({
        url: global.config.apiDomain + "/web.UploadFile",
        data: fd,
        processData: false,
        contentType: false,
        type: "POST",
        success: function (data) {
          console.log(data);
          this.setState({
            loading: false,
          });

          gift.animatedUrl =
            "https://data4.ikara.co/data/minio/" +
            bucketName +
            "/" +
            keyName;
          this.state.gift.animatedUrl =
            "https://data4.ikara.co/data/minio/" +
            bucketName +
            "/" +
            keyName;
          this.setState({
            gift: gift,
          });

          $("#show-animation")
            .children()
            .replaceWith(
              '<div style="height: 300px !important" id="demoCanvas"></div>'
            );

          this.loadSVGA2(gift.animatedUrl);
        }.bind(this),
      });
    }.bind(this);

    fileReader.readAsArrayBuffer(target.files[0]);
  }

  uploadThumbnailToS3(event) {
    var target = event.target;
    if (target.files[0].size > 512999) {
      toast.error("Kích thước file phải dưới 500kb.");
      return;
    }

    const fileReader1 = new FileReader();
    fileReader1.readAsDataURL(target.files[0]);

    fileReader1.onload = (fe) => {
      const image = new Image();
      image.src = fe.currentTarget.result;
      image.onload = function (ie) {
        let width = 0,
          height = 0;
        width = this.naturalWidth;
        height = this.naturalHeight;
        if (width != height || height > 256) {
          toast.error("Kích thước ảnh tối đa 256px và Width = Height");
          return;
        }

        this.setState({
          loading: true,
        });

        var gift = this.state.gift;
        var bucketName = "ikara-data/images/events";
        var extension = target.files[0].name.split(".").pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/jpeg";
        if (extension == "png") contentType = "image/png";

        var fileReader = new FileReader();

        fileReader.onload = function () {
          var fd = new FormData();
          fd.append("file", target.files[0]);
          fd.append("bucketName", bucketName);
          fd.append("objectKey", keyName);
          fd.append("contentType", contentType);
          $.ajax({
            url: global.config.apiDomain + "/web.UploadFile",
            data: fd,
            processData: false,
            contentType: false,
            type: "POST",
            success: function (data) {
              console.log(data);
              this.setState({
                loading: false,
              });

              gift.url =
                "https://data4.ikara.co/data/minio/" +
                bucketName +
                "/" +
                keyName;
              this.setState({
                gift: gift,
              });
            }.bind(this),
          });
        }.bind(this);
        fileReader.readAsArrayBuffer(target.files[0]);
      }.bind(this);
    };
  }

  showCupSelectedForEvent(idCupPreview, cupUrl) {}

  showAvatarFrameForEvent(idAvatarFrame, idElementPreview, avatarFrameUrl) {}

  render() {
    const { gifts, frames } = this.state;
    console.log(frames);
    console.log(gifts);
    return (
      <>
        <div>
          <div
            className="modal fade"
            id="modalUpdateEvent"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalCenterTitle">
                    Chọn CUP
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="container p-5">
                  <div className="row">
                    <div className="col-md-9">
                      <input
                        id="changeEventNameInput"
                        class="form-control"
                        placeholder="Nhập tên sự kiện"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        type="text"
                      ></input>
                    </div>
                    <div className="col-md-2">
                      <button className="btn btn-primary"
                      onClick={this.handleChangeEvent()}>
                        Lưu
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-3">CUP TOP 1</div>
                        <div className="col-md-6">
                          <div className="updateTop1">
                            <span></span>
                            <img class="gift-image" id="cupTop1ImgChange"></img>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            id="cup1"
                          >
                            <option selected value={""}>
                              Chọn
                            </option>
                            {gifts.map((gift, index) => {
                              return (
                                <option
                                  value={gift.url}
                                  onClick={() => {
                                    this.setState({
                                      cupTop1: gift.id,
                                      cupTop1: gift.url,
                                    });
                                  }}
                                >
                                  <img src={gift.url} alt="" />
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3">CUP TOP 2</div>
                        <div className="col-md-6">
                          <div className="updateTop2">
                            <span></span>
                            <img class="gift-image" id="cupTop2ImgChange"></img>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            id="cup2"
                          >
                            <option selected value={""}>
                              Chọn
                            </option>
                            {gifts.map((gift, index) => {
                              return (
                                <option
                                  value={gift.url}
                                  onClick={() => {
                                    this.setState({
                                      cupTop2: gift.id,
                                      imgCupTop2: gift.url,
                                    });
                                  }}
                                >
                                  <img src={gift.url} alt="" />
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3">CUP TOP 3</div>
                        <div className="col-md-6">
                          <div className="updateTop3">
                            <span></span>
                            <img class="gift-image" id="cupTop3ImgChange"></img>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            id="cup3"
                          >
                            <option selected value={""}>
                              Chọn
                            </option>
                            {gifts.map((gift, index) => {
                              return (
                                <option
                                  value={gift.url}
                                  onClick={() => {
                                    this.setState({
                                      cupTop3: gift.id,
                                      imgCupTop3: gift.url,
                                    });
                                  }}
                                >
                                  <img src={gift.url} alt="" />
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-3">KHUNG TOP 1</div>
                        <div className="col-md-6">
                          <div className="frameTop1Change">
                            <span></span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            id="cup2"
                          >
                            <option selected value={""}>
                              Chọn
                            </option>
                            {frames.map((frame, index) => {
                              return (
                                <option
                                  value={frame.idPublic}
                                  onClick={() => {
                                    this.setState({
                                      frameIdRecording1: frame.idPublic,
                                      frameIdRecording1: frame.previewImg,
                                    });
                                  }}
                                >
                                  <img src={frame.previewImg} alt="" />
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3">KHUNG TOP 2</div>
                        <div className="col-md-6">
                          <div className="frameTop2Change">
                            <span></span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            id="cup2"
                          >
                            <option selected value={""}>
                              Chọn
                            </option>
                            {frames.map((frame, index) => {
                              return (
                                <option
                                  value={frame.idPublic}
                                  onClick={() => {
                                    this.setState({
                                      frameIdRecording2: frame.idPublic,
                                      frameIdRecording2: frame.previewImg,
                                    });
                                  }}
                                >
                                  <img src={frame.previewImg} alt="" />
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3">KHUNG TOP 3</div>
                        <div className="col-md-6">
                          <div className="frameTop3Change">
                            <span></span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            id="cup2"
                          >
                            <option selected value={""}>
                              Chọn
                            </option>
                            {frames.map((frame, index) => {
                              return (
                                <option
                                  value={frame.idPublic}
                                  onClick={() => {
                                    this.setState({
                                      frameIdRecording3: frame.idPublic,
                                      frameUrlRecoding3: frame.previewImg,
                                    });
                                  }}
                                >
                                  <img src={frame.previewImg} alt="" />
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
