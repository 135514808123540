import React, {Component} from 'react';
import axios from 'axios';
import lodash from 'lodash';
import ModalListUseLuckyGift from './components/ModalListUseLuckyGift';
import ModalGiftInBox from './components/ModalGiftInBox';

export default class LuckyGiftManager extends Component {
    constructor(props) {
        super(props);

        this.state = {
            luckyGifts: [],
            luckyGiftsHistory: [],
            luckyGiftGave: null,
            startDate: null,
            luckyGiftGaveId: null,
            luckyGiftId: null,
            giftsMap: null
        }
        this.getLuckyGiftGaveWithTime = this.getLuckyGiftGaveWithTime.bind(this);
    }

    componentDidMount(){
        const json = {
            startDate: new Date()
        }

        const url = global.config.apiDomain + "/rest/luckyGiftsHistory/getLuckyGiftGaveWithTime";
        axios.post(url, json)
        .then(res =>{
            this.setState({
                luckyGifts: res.data
            })
        })
        .catch(err =>{
            console.log(err)
        })
    }

    getLuckyGiftGaveWithTime(startDate){
        const json = {
            startDate: startDate
        }

        const url = global.config.apiDomain + "/rest/luckyGiftsHistory/getLuckyGiftGaveWithTime";
        axios.post(url, json)
        .then(res =>{
            this.setState({
                luckyGifts: res.data
            })
        })
        .catch(err =>{
            console.log(err)
        })
    }

    // getLuckyGiftHistory(luckyGiftGaveId){
    //     const json = {
    //         luckyGiftGaveId: luckyGiftGaveId
    //     }

    //     const url = global.config.apiDomain + "/rest/getLuckyGiftHistory";
    //     axios.post(url, json)
    //     .then(res =>{
    //         this.setState({
    //             luckyGiftsHistory: res.data
    //         })
    //     })
    //     .catch(err =>{
    //         console.log(err)
    //     })
    // }

    render(){
        const {luckyGifts, luckyGiftGaveId, luckyGiftId, giftsMap, startDate} = this.state;
        // if(luckyGifts.length ==0){
        //     let json = {
        //         id: 1111,
        //         luckyGiftName: "Test",
        //         addTime: "12/12/2012",
        //         noItem: 20,
        //         noItemWin: 18,
        //         privacyLevel: "public",
        //         price: 199,
        //         luckyGiftId: "Test",
        //     }
        //     luckyGifts.push(json)
        // }
        return (
            <>
                <ModalListUseLuckyGift luckyGiftGaveId={luckyGiftGaveId}></ModalListUseLuckyGift>
                <ModalGiftInBox luckyGiftId={luckyGiftId} giftsMap={giftsMap} ></ModalGiftInBox>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lý danh sách phát lì xì</h1>
                        <hr />
                        <div className='w-25 row'>
                            <input type="date" className='form-control mt-2 mb-2 ml-2 mr-2 col-6' 
                            onChange={(e)=>{
                                this.setState({
                                    startDate : e.target.value
                                })
                            }}/>
                            <button className='btn btn-sm btn-outline-success mt-2 mb-2'
                            onClick={()=>{
                                this.getLuckyGiftGaveWithTime(startDate);
                            }}
                            >Tìm kiếm</button>
                        </div>
                        <table className="table table-hover">
                            <thead>
                            <tr>
                                <th width={100} scope="col">STT</th>
                                <th width={100} scope="col">Id</th>
                                <th scope="col">Tên gói lì xì</th>
                                <th scope="col">Thời gian tạo</th>
                                {/* <th scope="col">Tổng số item</th> */}
                                <th scope="col">Số Item có thể nhận</th>
                                <th scope="col">privacyLevel</th>
                                <th scope="col">Giá bán</th>
                                <th scope="col">Quà trong gói</th>
                                <th scope="col">Chi tiết</th>
                            </tr>
                            </thead>
                            <tbody>
                                {luckyGifts.map((item,index)=>{
                                    let showDate = new Date(item.addTime);
                                    return (
                                        <tr>
                                            <td>{index +1}</td>
                                            <td>{item.id}</td>
                                            <td>{item.luckyGiftName}</td>
                                            <td>{showDate.toLocaleString()}</td>
                                            {/* <td>{item.noItem}</td> */}
                                            <td>{item.noItemWin}</td>
                                            <td>{item.privacyLevel}</td>
                                            <td>{item.price}</td>
                                            <td>
                                                <button 
                                                    onClick={()=>{
                                                        this.setState({
                                                            luckyGiftId: item.luckyGiftId,
                                                            giftsMap: item.giftsMap
                                                        })
                                                    }}
                                                    className='btn btn-info'
                                                    data-toggle="modal"
                                                    data-target="#modalGiftInBox"
                                                    >Chi tiết
                                                </button>
                                            </td>
                                            <td>
                                                <button
                                                    onClick={()=>{
                                                        this.setState({
                                                            luckyGiftGaveId: item.id
                                                        })
                                                    }}
                                                    className='btn btn-info'
                                                    data-toggle="modal"
                                                    data-target="#modalListUseLuckyGift"
                                                    >Danh sách nhận quà
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}