import React, { Component } from 'react';
import { Parser, Player } from 'svga'
import SVGA from 'svgaplayerweb'
import axios from 'axios';
import $ from 'jquery';

export default class ModalShowSVGA extends Component {
    constructor(props) {
        super(props);

        this.state = {
            player: null
        }
    }

    loadSVGA2(url) {
        console.log(url);
        var player = new SVGA.Player('#demoCanvas');
        var parser = new SVGA.Parser('#demoCanvas'); 
        parser.load(url, function (videoItem) {
            console.log(videoItem)
            player.setVideoItem(videoItem);
            player.startAnimation();
        })
    }
    
    componentWillReceiveProps(nextProps) {
        if (nextProps.image != null) {
            this.loadSVGA2(nextProps.image);
        }
    }

    render() {
        const { image } = this.props;

        let thisShow = image && image.endsWith(".svga")? "svga" : image && image.endsWith(".svga")? "png" : image != null ? "other" : "none";

        switch( thisShow ){
            case "svga": 
                return (
                    <div className="modal fade" id="modalShowSVGA" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="d-flex justify-content-center">
                                        <div id="demoCanvas" style={{
                                            width: "40vw",
                                            height: "40vw"
                                        }} ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                break;
            case "png" :
                return (
                    <div className="modal fade" id="modalShowSVGA" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="d-flex justify-content-center">
                                        {image != null ? (
                                            <img width={300} src={image} />
                                        )
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                break
            case "other":                  
                let data="";
                image &&  axios.get(image).then(res => data = res.data)                            
                let src = 'data:image/jpeg;base64,' + btoa(data);
                return (
                    <div className="modal fade" id="modalShowSVGA" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="d-flex justify-content-center">
                                        {image != null ? (
                                            <img width={300} src={src} />
                                        )
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                break;
            default:
                return (
                    <div className="modal fade" id="modalShowSVGA" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="d-flex justify-content-center">
                                        {image != null ? (
                                            <img width={300} src={image} />
                                        )
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) 
        }
    }
}