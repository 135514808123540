import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { withTranslation } from 'react-i18next';
import { detectApp } from '../../../common/utils.js';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Base64 } from 'js-base64'
class Banner extends Component {
	constructor(props) {
		super(props);
        this.state = {
            link: "https://play.google.com/store/apps/details?id=co.ikara.v2",
            changeLink: true,
        };
		
    }

	componentDidMount() {
        this.checkLink();
    }

	newGetKey(data, password, password2) {
        var listOfNumbers = "" + password2;
        for (var i = 0 ; i < data.length; i++) {
            if (data.charAt(i) >= '0' && data.charAt(i) <= '9'){
                listOfNumbers = [listOfNumbers.slice(0, 0), data.charAt(i), listOfNumbers.slice(0)].join('');
            }
        }

        var originalKey = null;
      if (listOfNumbers.length > 15) 
        originalKey = listOfNumbers.substring(0,15);
      else
        originalKey = listOfNumbers;
      return Number(originalKey) + Number(password);
    }

    checkLink() {
        let json = {
          url: this.state.link
        };
        console.log("check url: ", this.state.link);
        const jsonBase64 = Base64.encode(JSON.stringify(json));
        var pass = this.newGetKey(jsonBase64, 7123358024, 3564);
        var keys = jsonBase64 + "-" + pass;
        var url = global.config.apiDomain + "/web.v5.getUrlStatus?parameters=" + keys;
        axios.post(url, json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.data.status == "OK") {
                this.setState({
                    changeLink: true,
                })
            } else {
                this.setState({
                    changeLink: false,
                })
            }
            console.log(res);
        }, err => {
            toast.error("Đã có lỗi xảy ra, vui lòng thử lại!");
        });
    }


	render() {

		const { t } = this.props;

		const hostName = window.location.hostname;

		return (
			<div className="banner-default page-home">
					<div className="row align-items-center m-0 h-100">
						<div className={detectApp("lo.okara") 
						? "col-3 d-none d-xl-flex align-items-center h-100 p-3" 
						: "col-3 d-none d-xl-flex align-items-center h-100 p-3"}>
							<img
								className="item-1"
								src={require("../../../assets/images/" + t("home_page.banner.phone_icon", "phone_icon_yokara.png"))} />
						</div>

						<div className={detectApp("lo.okara") 
						? "col-xl-4 col-12 d-flex align-items-center h-100 text-center" 
						: "col-xl-4 col-7 d-flex align-items-center h-100 text-center"}>
							<div>
								<h1 className="font-weight-bold m-0  banner-title">{t("home_page.banner.title", "IKARA - THỔI BÙNG ĐAM MÊ CA HÁT")}</h1>
								<p className="banner-description m-0 d-none d-sm-block">{t("home_page.banner.description", "Tham gia IKARA ngay để cùng tạo nên những bản nhạc, khoảnh khắc ý nghĩa")}</p>
								<div className="row justify-content-center">
								<div className="item-download p-2 ">
									{!detectApp("mi") && 
										(this.state.changeLink ?
											<a target="_blank" href={t("home_page.banner.chplay_url", "https://play.google.com/store/apps/details?id=co.ikara.v2")}>
												<img className="" src={require("../../../assets/images/downloadchplay.png")} />
											</a> :
											<a target="_blank" href={"https://data4.ikara.co/data/minio/ikara-data/android-apk/yokara_10.19.9.apk"}>
												<img className="" src={require("../../../assets/images/downloadchplay.png")} />
											</a>
										)
									}
								</div>
									{(!detectApp("tl.okara") && !detectApp("mz.okara") && !detectApp("ht.okara") && !detectApp("mi")) && (
										<div className="item-download p-2">
										<a target="_blank" href={t("home_page.banner.appstore_url", "https://apps.apple.com/app/id711994026")}>
											<img src={require("../../../assets/images/downloadappstore.png")} />
										</a>
									</div>
									)}
									
								</div>
							</div>
						</div>
						<div className={detectApp("lo.okara")
						? "col-5 d-xl-flex d-none align-items-end h-100" 
						: "col-5 d-flex align-items-end h-100"}>
							<img
								className="item-2 float-bot"
								src={require("../../../assets/images/" + t("home_page.banner.social_icon", "social_icon_yokara.jpg"))} />
						</div>

					</div>

				
			</div>
		);
	}
}

export default withTranslation()(Banner)