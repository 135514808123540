import React, {Component} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';

import bindModel from '../../../common/bindModel.js';

export default class ModalUpdateNotification extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notification: null,
            loading: false
        }

        this.saveNotification = this.saveNotification.bind(this)
    }

    convertDateTime(value) {
        let arr = value.split(",");
        let arrDate = arr[1].trim().split("/");

        arrDate[1] = arrDate[1].length == 1 ? "0" + arrDate[1] : arrDate[1];
        arrDate[0] = arrDate[0].length == 1 ? "0" + arrDate[0] : arrDate[0];
        let date = arrDate[2] + "-" + arrDate[1] + "-" + arrDate[0];
        return date + "T" + arr[0].substring(0,5);

    }

    componentWillReceiveProps(props) {
        let notification = JSON.parse(JSON.stringify(props.notification));
        if(notification != null) {
            notification.timePushNotification = this.convertDateTime(new Date(notification.timePushNotification).toLocaleString());
        }
        
        this.setState({
            notification : notification
        })
    }

    saveNotification() {
        this.setState({
            loading: true
        });

        this.state.notification.timePushNotification += ":00";

        axios.post(global.config.apiDomain + '/rest/notifications/save', JSON.stringify(this.state.notification), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                loading: false
            });

            window.$("#modalUpdateNotification").modal("hide");

            toast.success('Cập nhật thành công'); 
            this.props.resetList();
        }, error => {

        })
    }

    render() {
        const model = bindModel(this);
        const {notification} = this.state;

        return (
            <>
                <div className="modal fade" id="modalUpdateNotification" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật Notification</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {notification ? (<div className="modal-body">
                                <div id="id" className="row mb-2">
                                    <div className="col-3">
                                        <label>ID</label>
                                    </div>
                                    <div className="col-9">
                                        <input 
                                            className="form-control mb-2" 
                                            type="text" 
                                            name="startDate"
                                            readOnly={true}
                                            {...model('notification.id')}/>
                                    </div>
                                </div>
                                <div id="thumbnail" className="row mb-2">
                                    <div className="col-3">
                                        <label>Thumbnail</label>
                                    </div>
                                    <div className="col-9">
                                        <input 
                                            type="file" 
                                            className="custom-file-input" 
                                            id="inputGroupFile03"
                                            onChange={this.uploadThumbnailForWasabi} />
                                        <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile03">Choose file</label>
                                    </div>
                                </div>
                                {notification.thumbnail ? (
                                    <div id="thumbnail" className="row mb-2">
                                        <div className="col-3">
                                        
                                        </div>
                                        <div className="col-9">
                                            <img src={notification.thumbnail} />
                                        </div>
                                    </div>
                                ) : ""}
                            
                                <div id="startDate" className="row mb-2">
                                    <div className="col-3">
                                        <label>Ngày bắt đầu</label>
                                    </div>
                                    <div className="col-9">
                                        <input 
                                            className="form-control mb-2" 
                                            type="datetime-local" 
                                            name="startDate"
                                            {...model('notification.startDate')}/>
                                    </div>
                                </div>
                                <div id="endDate" className="row mb-2">
                                    <div className="col-3">
                                        <label>Ngày kết thúc</label>
                                    </div>
                                    <div className="col-9">
                                        <input 
                                            className="form-control" 
                                            type="datetime-local" 
                                            name="endDate" 
                                            {...model('notification.endDate')}/>
                                    </div>
                                </div>
                                <div id="endDate" className="row mb-2">
                                    <div className="col-3">
                                        <label>Màu nền</label>
                                    </div>
                                    <div className="col-9">
                                        <input 
                                            className="form-control" 
                                            type="color" 
                                            name="endDate" 
                                            // value={eventEntity.startDate} 
                                            {...model('notification.backgroundColor')}/>
                                    </div>
                                </div>
                                <div id="link" className="row mb-2">
                                    <div className="col-3">
                                        <label>Link</label>
                                    </div>
                                    <div className="col-9">
                                        <input 
                                            className="form-control" 
                                            type="text" 
                                            name="url" 
                                            {...model('notification.url')}/>
                                    </div>
                                </div>
                                
                                <div>
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">Package</th>
                                                <th scope="col">Platform</th>
                                                <th scope="col">Min version</th>
                                                <th scope="col">Max version</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {notification.minMaxVersions.map((version, index) => {
                                            return (
                                                <tr key={version.id}>
                                                    <td>{version.packageName}</td>
                                                    <td>{version.platform}</td>
                                                    <td><input className="form-control" value={version.minVersion} {...model('notification.minMaxVersions['+index+'].minVersion')}/></td>
                                                    <td><input className="form-control" value={version.maxVersion} {...model('notification.minMaxVersions['+index+'].maxVersion')}/></td>
                                                </tr>)
                                            })}
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>) : ''}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ? 
                                (
                                <button style={{width: "120px"}} className="btn btn-red" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Xác nhận...
                                </button>
                                ) :
                                (
                                <button  style={{width: "120px"}} type="button" className="btn btn-red" onClick={this.saveNotification}>Xác nhận</button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}