import React, { Component } from 'react';
import axios from 'axios';
import { param } from 'jquery';
import { toast } from 'react-toastify';
import ModalAddShopItemForUser from './ModalAddShopItemForUser';
import SVGA from 'svgaplayerweb'


const $ = window.$;

export default class ModalUserBag extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: "",
            uid: "",
            cursorItemOfUser: "",
            items: null,
            edit: false,
            itemEdit: "",
            allItems: [],
            shopItem: null,
            thisAccount: null,
        }

        this.getItemsOfUser = this.getItemsOfUser.bind(this);
        this.getAllItem = this.getAllItem.bind(this);
        this.removeItemOfUser = this.removeItemOfUser.bind(this);
        this.addItemForUser = this.addItemForUser.bind(this);
        this.userItemForUser = this.userItemForUser.bind(this);
    }

    getAllItem() {
        axios.post(global.config.apiDomain + '/rest/admin/getAllItem', {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                allItems: res.data.shopItems
            });
        })
    }

    getItemsOfUser() {
        const { account } = this.props;
        console.log('getItemsOfUser');
        console.log(account);
        if (account != null) {
            var json = {
                userId: account.id,
                language: 'en.yokara'
            }

            axios.post(global.config.apiDomain + '/rest/admin/getUserBag', json, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                console.log("eeeE", res.data.shopItems);
                this.setState({
                    items: res.data.shopItems,
                });
            })
        }
    }

    addItemForUser(dataRequest) {
        const { account } = this.props;

        if (account != null && dataRequest != null) {
            var json = {
                userId: account.id,
                type: dataRequest.type,
                shopItem: dataRequest
            }
            axios.post(global.config.apiDomain + '/rest/admin/addItemForUser', json, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                toast.success("Thêm vật phẩm thành công")
            }).catch(err => {
                toast.success("thêm vật phẩm thất bại")
            })
        }
    }

    removeItemOfUser(dataRequest) {
        const { account } = this.props;

        if (account != null && dataRequest != null) {
            var json = {
                userId: account.id,
                type: dataRequest.type,
                shopItem: dataRequest
            }
            axios.post(global.config.apiDomain + '/rest/admin/removeItemOfUser', json, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                toast.success("xóa vật phẩm thành công");
                this.getItemsOfUser();
            }).catch(err => {
                toast.success("xóa vật phẩm thất bại")
            })
        }
    }

    userItemForUser(dataRequest) {
        const { account } = this.props;

        if (account != null && dataRequest != null) {
            var json = {
                userId: account.id,
                type: dataRequest.type,
                language: 'en.yokara',
                shopItem: dataRequest
            }
            let url = global.config.apiDomain + '/rest/admin/useItemForUser';

            if (dataRequest.isUse) {
                url = global.config.apiDomain + '/rest/admin/unUseItemForUser';
            }

            console.log(url);
            axios.post(url, json, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                if (dataRequest.isUse) {
                    toast.success("Gỡ vật phẩm thành công");
                } else {
                    toast.success("Đeo vật phẩm thành công")
                }
                this.getItemsOfUser();

            }).catch(err => {
                if (dataRequest.isUse) {
                    toast.success("Gỡ vật phẩm thất bại")
                } else {
                    toast.success("Đeo vật phẩm thất bại")
                }
            })
        }
    }
    loadSVGA4 = (url, itemId) => {
        var player = new SVGA.Player(`#demoCanvas${itemId}`); 
        var parser = new SVGA.Parser(`#demoCanvas${itemId}`);
        parser.load(url, function (videoItem) {
            videoItem.FPS = 1.2;
            console.log(videoItem); 
            player.setVideoItem(videoItem);
            player.startAnimation();
        });
    }   
    

    componentWillMount() {
        this.getAllItem();
    }

    componentDidUpdate(prevProps, prevState) {
        const { items } = this.state;
        if (prevState.items !== this.state.items) {
            this.state.items.forEach(item => {
                if (item.resourceUrl && item.resourceUrl.endsWith("svga")) {
                    this.loadSVGA4(item.resourceUrl, item._id);
                }
            });
        }
        if (items == undefined || items == null) {
            this.getItemsOfUser();
        }
    }
    

    render() {
        const { items, allItems } = this.state;
        const { account } = this.props;

        return (
            <div>
                <ModalAddShopItemForUser
                    account={account}
                    allItems={allItems}
                ></ModalAddShopItemForUser>
                <div className='mt-2 mb-2 row'>
                    <button className='mr-4 ml-4 btn btn-success'
                        data-toggle="modal"
                        data-target="#modalAddShopItemForUser"
                    >Thêm vật phẩm vào túi</button>
                </div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Type</th>
                            <th scope="col">Tên quà</th>
                            <th scope="col">Độ ưu tiên</th>
                            <th scope="col">Giá</th>
                            <th scope="col">Image</th>
                            <th scope="col">Ngày hết hạn</th>
                            <th scope="col" colSpan={2}>Tùy chỉnh</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            items && items.length > 0 ? (
                                items.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item._id}</td>
                                            <td>{item.type}</td>
                                            <td>{item.name}</td>
                                            <td>{item.order}</td>
                                            <td>{item.price}</td>
                                            <td>
                                                {
                                                    item.resourceUrl && item.resourceUrl.endsWith("svga") ? (
                                                        <canvas id={`demoCanvas${item._id}`}></canvas> 
                                                    ) : (
                                                        <img src={item.resourceUrl} width="75" alt="item" />
                                                    )
                                                }
                                            </td>
                                            <td>{new Date(item.myExpiry).toLocaleString()}</td>
                                            <td><button className={`btn ${item.isUse ? " btn-warning" : " btn-success"}`}
                                                onClick={() => {
                                                    let message = `Bạn có chắc là muốn ${item.isUse ? "Gỡ" : "Đeo"} ?`
                                                    if (window.confirm(message)) {
                                                        this.userItemForUser(item)
                                                    }
                                                }}
                                            >{item.isUse ? "Gỡ" : "Đeo"}</button></td>
                                            <td><button className='btn btn-danger'
                                                onClick={() => {
                                                    if (window.confirm("XÓA KHỎI TÚI?")) {
                                                        this.removeItemOfUser(item)
                                                    }
                                                }}
                                            >Xóa</button></td>
                                        </tr>
                                    )
                                })
                            ) : (<></>)
                        }
                    </tbody>
                </table>
            </div>
        )

    }

}