import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default class ModalSurveyStatistics extends Component {
    constructor(props) {
        super(props);

        this.state = {
            statistics: null,
            surveyModal: null,
        }
        this.getSurveyStatistics = this.getSurveyStatistics.bind(this);
    }

    componentWillReceiveProps(props) {
        console.log('ModalSurveyStatistics   ====== componentWillReceiveProps');
        console.log(props.surveyModal);
        this.setState({
            surveyModal: props.surveyModal
        })
        this.getSurveyStatistics(props.surveyModal)
    }

    getSurveyStatistics(surveyModal) {
        let url = global.config.apiDomain + "/rest/survey/getSurveyStatisticsNPS"
        axios.post(url, surveyModal)
            .then(res => {
                console.log('getSurveyStatistics');
                console.log(res);
                this.setState({
                    statistics: res.data
                })
            })
    }

    render() {
        const { statistics, surveyModal } = this.state;

        const colors = [
            '#8B0000', '#B22222', '#C71585', '#D2691E', '#DAA520',
            '#6A5ACD', '#4682B4', '#4169E1', '#2E8B57', '#3CB371', '#2F4F4F'
        ];
                
        // Chuyển đổi statisticsMap sang mảng dữ liệu cho biểu đồ
        const data = statistics ? Object.keys(statistics.statisticsMap).map((key, index) => {
            return {
                name: key,
                votes: statistics.statisticsMap[key],
                percentage: Math.round(statistics.statisticsMap[key] * 100 / statistics.totalAnswers * 100) / 100,
                fill: colors[index % colors.length]
            }
        }) : [];

        const npsData = [
            { name: `Promoters: ${statistics ? statistics.countPromoters : 0}`, value: statistics ? statistics.promoters : 0, fill: '#36A2EB' },
            { name: `Passives: ${statistics ? statistics.countPassives : 0}`, value: statistics ? statistics.passives : 0, fill: '#FFCE56' },
            { name: `Detractors: ${statistics ? statistics.countDetractors : 0}`, value: statistics ? statistics.detractors : 0, fill: '#FF6384' }
        ];        

        return (
            <div>
                <div className="modal fade" id="modalSurveyStatistics" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title text-center w-100" id="exampleModalCenterTitle">Danh sách trả lời</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div id="modal-body">
                                <h4 className='mt-1'>Câu hỏi: <span>{surveyModal && surveyModal.question ? surveyModal.question : ""}</span> </h4>

                                <div className="row">
                                    <div className="col-5">
                                        <ul>
                                            <li>Tổng số câu trả lời: {statistics ? statistics.totalAnswers : 0}</li>
                                            
                                            <ResponsiveContainer width="100%" height={200}>
                                                <BarChart data={npsData} layout="vertical" margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                                    <CartesianGrid strokeDasharray="3 3" />
                                                    <XAxis type="number" domain={[0, 100]} />
                                                    <YAxis type="category" dataKey="name" />
                                                    <Tooltip />
                                                    <Bar dataKey="value" isAnimationActive={false}>
                                                        {npsData.map((entry, index) => (
                                                            <Bar key={`bar-${index}`} fill={entry.fill} />
                                                        ))}
                                                    </Bar>
                                                </BarChart>
                                            </ResponsiveContainer>

                                            <h3 className='text-center w-100'>Net Promoter Score: {statistics ? statistics.NPS : 0}</h3>
                                        </ul>
                                    </div>
                                    <div className="col-7">
                                        <ResponsiveContainer width="100%" height={400}>
                                            <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis 
                                                    tickFormatter={(value) => Math.round(value)} 
                                                    allowDecimals={false} 
                                                    interval="preserveStartEnd"
                                                />
                                                <Tooltip />
                                                <Legend />
                                                <Bar dataKey="votes" isAnimationActive={false}>
                                                    {data.map((entry, index) => (
                                                        <Bar key={`bar-${index}`} fill={entry.fill} />
                                                    ))}
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}