import React, { Component } from 'react';
import axios from 'axios';
import ModalUpdateBanner from './components/ModalUpdateBanner';
import ModalDeleteBanner from './components/ModalDeleteBanner';

const threadTitle = ["#", 
                    "id",
                    "Tiêu đề Banner", 
                    "Thumbnail",
                    "Ngày Thông Báo", 
                    "Ngày bắt đầu", 
                    "Ngày kết thúc", 
                    "Tên vật phẩm", 
                    "Giá bán", 
                    "Giá Khuyến Mãi",
                    "Khuyến mãi kèm theo",
                    "DeepLink",
                    "Action"];
export default class PromoBannerSellMicPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            promoBanners: [],
            banner: null,
            ikaraStoreProduct:[],
        };

        this.getPromoBanners = this.getPromoBanners.bind(this);
        this.addNewBanner = this.addNewBanner.bind(this);
        this.updateBanner = this.updateBanner.bind(this);
        this.deleteOrActivebanner = this.deleteOrActivebanner.bind(this);
    }

    componentDidMount() {
        this.getPromoBanners();
    }

    getPromoBanners() {
        axios.post(global.config.apiDomain + '/rest/promoBannerSellMic/getAllPromoBannerSellMic')
            .then(res => {
                if (res.data.status === 'OK') {
                    this.setState({
                        promoBanners: res.data.promoBannerSellMicModel,
                        ikaraStoreProduct: res.data.ikaraStoreProduct,
                    })
                } else {
                    console.log("bug call api")
                }
            })
    }

    addNewBanner() {
        let banner = {
            promoBannerId: '',
            thumbnail:'',
            bannerTitle: '',
            notifyDay: '',
            startDay: '',
            endDay: '',
            productName: '',
            product_id: '',
            price: '',
            promoPrice: '',
            bonus: '',
            deepLink: '',
            deleted: ''
        };

        this.setState({
            banner: banner,
        })
    }

    updateBanner(event) {
        let index = event.target.name;
        let banner = this.state.promoBanners[index];

        this.setState({
            banner: banner,
        })
    }

    deleteOrActivebanner(event) {
        console.log("check event: " + event.target.name )
        let index = event.target.name;
        let bannerF = this.state.promoBanners[index];
        this.setState({
            banner: bannerF,
        })
    }

    render() {
        const { promoBanners, banner, ikaraStoreProduct } = this.state;
        return (
            <>
                <ModalUpdateBanner
                    banner={banner}
                    ikaraStoreProduct={ikaraStoreProduct}
                    resetList={this.getPromoBanners}
                ></ModalUpdateBanner>
                 <ModalDeleteBanner
                    banner={banner}
                    resetList={this.getPromoBanners}
                 ></ModalDeleteBanner>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí Banner Khuyến Mãi bán hàng</h1>
                        <button
                            className="btn btn-red mb-1"
                            data-toggle="modal"
                            data-target="#modalUpdateBanner"
                            onClick={this.addNewBanner}
                        >+ Tạo Mới Banner Khuyến Mãi
                        </button>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    {
                                        threadTitle && threadTitle.map((title, index) => {
                                            return (
                                                <th 
                                                    className="align-middle" 
                                                    scope="col" 
                                                    key={index}
                                                    >{title}</th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>                            
                                {
                                    promoBanners && promoBanners.map((banner, index) => {
                                        return (
                                            <tr key={banner.id}>
                                                <td className='align-center' style={{ verticalAlign: "top !important" }}>{index + 1}</td>
                                                <td className='align-center' style={{ verticalAlign: "top !important" }}>{banner.promoBannerId}</td>
                                                <td className='align-center' style={{ verticalAlign: "top !important" }}>{banner.bannerTitle}</td>
                                                <td className='align-center' style={{ wordWrap: "break-word", maxWidth: 250 }}><img width={250} src={banner.thumbnail} /></td>
                                                <td className='align-center' style={{ verticalAlign: "top" }}>
                                                    {isNaN(new Date(banner.notifyDay).getTime()) ? "Invalid Date" : new Date(banner.notifyDay).toLocaleString('en-GB')}
                                                </td>
                                                <td className='align-center' style={{ verticalAlign: "top" }}>
                                                    {isNaN(new Date(banner.startDay).getTime()) ? "Invalid Date" : new Date(banner.startDay).toLocaleString('en-GB')}
                                                </td>
                                                <td className='align-center' style={{ verticalAlign: "top" }}>
                                                    {isNaN(new Date(banner.endDay).getTime()) ? "Invalid Date" : new Date(banner.endDay).toLocaleString('en-GB')}
                                                </td>
                                                <td className='align-center' style={{ verticalAlign: "top !important" }}>{banner.productName}</td>
                                                <td className='align-center' style={{ verticalAlign: "top !important" }}>{banner.price}</td>
                                                <td className='align-center' style={{ verticalAlign: "top !important" }}>{banner.promoPrice}</td>
                                                <td className='align-center' style={{ verticalAlign: "top !important" }}>{banner.bonus}</td>
                                                <td className='align-center' style={{ verticalAlign: "top !important" }}>{banner.deepLink}</td>
                                                <td className='align-center'></td>
                                                <td className='align-center'>
                                                    <button
                                                        className="btn btn-success"
                                                        onClick={this.updateBanner}
                                                        name={index}
                                                        data-toggle="modal"
                                                        data-target="#modalUpdateBanner"
                                                    >
                                                        Sửa
                                                    </button>&emsp;
                                                    <button
                                                        className="btn btn-danger"
                                                        onClick={this.deleteOrActivebanner}
                                                        name={index}
                                                        data-toggle="modal"
                                                        data-target="#modalDeleteBanner"
                                                    >
                                                        Xoá
                                                    </button>

                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}