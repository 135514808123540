import React, { Component } from 'react';
import MyContext from '../common/MyContext';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import axios from 'axios';

const $ = window.$;
class UnsubscribeOkaraDialog extends Component {
    constructor(props) {
        super(props);

        this.sendOTP = this.sendOTP.bind(this);

        this.state = {
            loading: false
        }

        this.sendOTP = this.sendOTP.bind(this);
        this.loadUser = this.loadUser.bind(this);
    }

    componentDidMount() {

    }

    loadUser() {
        const json = JSON.stringify({ domain: window.location.hostname});

		axios.post(global.config.apiDomain + '/rest/loginuser', json, {
			headers: {
			  'Content-Type': 'application/json'
			}
		  }).then(res => {
			const {user} = res.data;
			this.context.updateUser(user); 
		  })
    }

    sendOTP() {
        this.setState({
            loading: true
        });
        // $("#modalUnsubscribeOkara").modal("hide");
        // $("#modalConfirmUnsubscribeOkara").modal("show");
        const { user } = this.context;

        const json = {
            facebookId: user.facebookId,
            domain: window.location.hostname
        }

        axios.post(global.config.apiDomain + '/rest/okara/unsubscribe', JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {

            this.setState({
                loading: false
            });

            if (res.data.status == "OK") {
                this.loadUser();
                $("#modalUnsubscribeOkara").modal("hide");
                // $("#modalConfirmUnsubscribeOkara").modal("show");
            } else {
                toast.warning(res.data.message);
            }
        });

    }

    render() {
        const {t} = this.props;
        return (
            <div className="modal fade" id="modalUnsubscribeOkara" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div style={{ "borderRadius": "20px" }} className="modal-content">
                        <div>
                            <button type="button" className="btn float-right" data-dismiss="modal" aria-label="Close">
                                <i className='fa fa-close' />
                            </button>
                        </div>
                        <div style={{ "paddingBottom": "2.0rem", "paddingRight": "2.0rem", "paddingLeft": "2.0rem" }} className="modal-body pt-0">
                            <div className='text-center'>
                                <h4 className='text-danger'>{t("unsubscribe_okara_dialog.title","Thông báo")}</h4>
                            </div>
                            <div className='mt-4 text-center'>
                                <p className='p-0 m-0'>{t("unsubscribe_okara_dialog.notify","Bạn có đồng ý hủy vip không ?")}</p>
                            </div>

                            <div className='mt-5 text-center row'>
                                <div className='col-6'>
                                    <button
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        style={{ "borderRadius": "20px" }}
                                        className='btn w-50 text-white btn-secondary'>{t("unsubscribe_okara_dialog.no","Không")}</button>
                                </div>
                                <div className='col-6'>

                                {this.state.loading ?
                                    (
                                        <button style={{ "borderRadius": "20px", "backgroundColor": "#fa882c" }} className="btn w-50 text-white" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            {t("unsubscribe_okara_dialog.yes","Có")}...
                                        </button>
                                    ) :
                                    (
                                        <button
                                        onClick={this.sendOTP}
                                        id='confirm-button'
                                        className='btn w-50 text-white btn-red'>{t("unsubscribe_okara_dialog.yes","Có")}</button>
                                    )}
                                    
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

}

UnsubscribeOkaraDialog.contextType = MyContext;
export default withTranslation()(UnsubscribeOkaraDialog)