import React, { Component } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Bar, Line } from 'react-chartjs-2';
import 'chart.js/auto';

export default class ModalCompare extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: '',
            endDate: '',
            surveyNPS: [],
            showResultModal: false,
            isLoading: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeResultModal = this.closeResultModal.bind(this);
    }

    handleInputChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }

    handleSubmit() {
        this.setState({ isLoading: true });
        const { startDate,endDate } = this.state;
        if(startDate <= endDate){
        axios.post(global.config.apiDomain + '/rest/survey/getCompareNPS',{ startDate:startDate,endDate:endDate}, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            const models = res.data.models || [];
            this.setState({ 
                surveyNPS: models,
                isLoading: false,
                showResultModal: true
             });
        })
        .catch(error => {
            console.error('Error fetching commissions:', error);
            this.setState({ isLoading: false });
        });}else{
            toast.error("Ngày kết thúc phải lớn hơn hoặc bằng ngày bắt đầu !")
            this.setState({ isLoading: false });
        }
    }

    closeResultModal() {
        this.setState({ showResultModal: false });
    }

    render() {
        const { surveyNPS, showResultModal } = this.state;
        
        const npsData = {
            labels: surveyNPS.map(item => {
                const date = new Date(item.showTime);
                const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
                return `NPS ngày ${formattedDate}`;
            }),
            datasets: [
                {
                    label: 'Chỉ số Detractors',
                    data: surveyNPS.map(item => item.detractors),
                    backgroundColor: '#FF6384',
                    borderColor: '#FF6384',
                    borderWidth: 1
                },
                {
                    label: 'Chỉ số Passives',
                    data: surveyNPS.map(item => item.passives),
                    backgroundColor: '#FFCE56',
                    borderColor: '#FFCE56',
                    borderWidth: 1
                },
                {
                    label: 'Chỉ số Promoters',
                    data: surveyNPS.map(item => item.promoters),
                    backgroundColor: '#36A2EB',
                    borderColor: '#36A2EB',
                    borderWidth: 1
                },
                {
                    label: 'Chỉ số NPS',
                    data: surveyNPS.map(item => item.NPS),
                    backgroundColor: '#4BC0C0',
                    borderColor: '#4BC0C0',
                    borderWidth: 1
                }
            ]            
        };

        const npsDataNPS = {
            labels: surveyNPS.map(item => {
                const date = new Date(item.showTime);
                const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
                return `NPS ngày ${formattedDate}`;
            }),
            datasets: [
                {
                    label: 'Chỉ số NPS',
                    data: surveyNPS.map(item => item.NPS),
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 2,
                    fill: true,
                    tension: 0.4
                }
            ]            
        };

        const options = {
            plugins: {
                legend: {
                    labels: {
                        font: {
                            size: 16  // Kích thước chữ cho các nhãn của biểu đồ
                        }
                    }
                },
                tooltip: {
                    callbacks: {
                        title: function(tooltipItems) {
                            return tooltipItems[0].label;
                        },
                        label: function(tooltipItem) {
                            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
                        }
                    },
                    titleFont: {
                        size: 14  // Kích thước chữ tiêu đề trong tooltip
                    },
                    bodyFont: {
                        size: 14  // Kích thước chữ nội dung trong tooltip
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        font: {
                            size: 14  // Kích thước chữ của nhãn trục X
                        }
                    }
                },
                y: {
                    beginAtZero: true, // Đảm bảo trục Y bắt đầu từ 0
                    stacked: false, // Xếp chồng các cột
                    ticks: {
                        font: {
                            size: 14 // Kích thước chữ của nhãn trục Y
                        }
                    }
                }
            }
        };

        return (
            <div>
                <div className="modal fade" id="modalCompare" tabIndex="-1" role="dialog" aria-labelledby="modalCompareLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="modalCompareLabel">Nhập thời gian để so sánh</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label htmlFor="startDate">Ngày bắt đầu</label>
                                    <input 
                                        type="date" 
                                        className="form-control" 
                                        id="startDate" 
                                        name="startDate" 
                                        value={this.state.startDate} 
                                        onChange={this.handleInputChange} 
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="endDate">Ngày kết thúc</label>
                                    <input 
                                        type="date" 
                                        className="form-control" 
                                        id="endDate" 
                                        name="endDate" 
                                        value={this.state.endDate} 
                                        onChange={this.handleInputChange} 
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Đóng</button>
                                <button type="button" className="btn btn-primary" onClick={this.handleSubmit}>Xác nhận</button>
                            </div>
                        </div>
                    </div>
                </div>

                {showResultModal && (
                    <div className="modal fade show" id="resultModal" role="dialog" aria-labelledby="resultModalLabel" aria-hidden="true" style={{ display: 'block' }}>
                        <div className="modal-dialog" role="document" style={{ maxWidth: '80%' }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="resultModalLabel">Kết quả so sánh NPS</h5>
                                    <button type="button" className="close" onClick={this.closeResultModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                {/* <div className="modal-body" style={{height: '70%', width: '70%' }}>
                                    {surveyNPS.length > 0 ? (
                                        <Bar data={npsDataNPS} options={options}/>
                                    ) : (
                                        <p>Không có dữ liệu để hiển thị.</p>
                                    )}
                                </div> */}
                                <div className="modal-body" style={{height: '70%', width: '70%' }}>
                                    {surveyNPS.length > 0 ? (
                                        <Line data={npsDataNPS} options={options}/>
                                    ) : (
                                        <p>Không có dữ liệu để hiển thị.</p>
                                    )}
                                </div>
                                <div className="modal-body" style={{ height: '500px' }}>
                                    {surveyNPS.length > 0 ? (
                                        <Bar data={npsData} options={options} height={110}/>
                                    ) : (
                                        <p>Không có dữ liệu để hiển thị.</p>
                                    )}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={this.closeResultModal}>Đóng</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}