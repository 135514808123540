/**
 * Validate value is number
 * @method validateNumber
 * @param {*} value
 * @return {Boolean}
 */

export function validateEmpty(value) {
    if(value === "" || value === null) {
        return false;
    }
    return true;
}

export default function validateNumber(value) {
    return (!isNaN(Number(value)) && /^\d+$/.test(value));
}