import React, { Component } from 'react';
import MenuInProfile from '../../components/MenuInProfile';
import MyContext from '../../common/MyContext';
import Firebase from '../../components/Firebase.js'
import {withTranslation} from 'react-i18next';

class MyImagesPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            images: [],
            isFirst: true
        }
        console.log(this.context)
        this.ikaraNode = Firebase.database().ref("ikara");
        this.getImageList = this.getImageList.bind(this);
    }

    componentDidMount() {
        this.setState({

        })
    }

    componentDidUpdate() {
        const {user} = this.context;
        if(this.state.isFirst && user != null) {
            this.state.isFirst = false;
            this.getImageList();
        }
    }

    getImageList() {
        const {user} = this.context;
        this.ikaraNode.child('users').child(user.facebookId).child("images").get().then(snapshot => {
            var value = snapshot.val();
            this.setState({
                images: value
            })
        });
    }

    render() {
        const {t} = this.props;
        return (
            <div id="content" >
                <div className="container">
                    <h2 className="text-center mb-2 mb-md-3 mt-xl-2">{t("my_images_page.image","Hình ảnh")}</h2>
                    <hr />
                    <div className="row">
                        <div className="col-lg-3">
                            <MenuInProfile></MenuInProfile>
                        </div>
                        <div className="col-lg-9">
                            <div className="row text-center">
                                {this.state.images.map((image) => {
                                    return (
                                        <div className = "col-md-3 col-sm-4 col-6 p-2" key={image.id}>
                                            <img className="img-user rounded" src={image.imageUrl} />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

MyImagesPage.contextType = MyContext;
export default withTranslation()(MyImagesPage)
