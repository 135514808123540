import React, {Component} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';
import bindModel from '../../../common/bindModel.js';

const $ = window.$;

export default class ModalAddIcoin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            icoin: 0,
            type: 'MANUALLYADDICOIN',
            reason: '',
        }

        this.addIcoin = this.addIcoin.bind(this);
    }

    addIcoin(event) {
        if(event.key == 'Enter' || event.target.tagName == "BUTTON") {
            this.setState({
                loading: true
            });

            var json = {
                id: this.props.id,
                icoin: this.state.icoin,
                type: this.state.type,
                reason: this.state.reason,
            }

            axios.post(global.config.apiDomain + '/rest/admin/addIcoin', JSON.stringify(json), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                this.setState({
                    loading: false
                });

                if(res.data.status == "OK") {
                    $("#modalAddIcoin").modal("hide");
                    toast.success(res.data.message);
                    this.props.resetAccount(); 
                } else {
                    toast.warning(res.data.message); 
                }
            }, error => {
                this.setState({
                    loading: false
                });
                toast.error("ERROR"); 
            })
        }
    }

    render() {
        const model = bindModel(this);
        const {id} = this.state;

        return (
            <div>
                <div className="modal fade" id="modalAddIcoin" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Thêm icoin</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <input
                                    type="number" 
                                    placeholder="Nhập icoin"
                                    className="form-control"
                                    {...model('icoin')}/>
                                    <select class="form-control mt-2 mb-2" aria-label="Default select example"
                                    onChange={(e)=>{
                                        this.setState({
                                            type: e.target.value
                                        })
                                    }}>
                                        <option value="MANUALLYADDICOIN">MANUALLY ADD ICOIN</option>
                                        {/* <option value="MOMO">MOMO</option>
                                        <option value="PAYPAL">MANUALLY ADD ICOIN</option>
                                        <option value="CARD">MANUALLY ADD ICOIN</option>
                                        <option value="APPLEINAPP">APPLE IN APP</option>
                                        <option value="GOOGLEINAPP">GOOGLE IN APP</option>
                                        <option value="BANK">BANK</option>
                                        <option value="VIETCOMBANK">VIETCOMBANK</option> */}
                                        <option value="BONUS">BONUS</option>
                                    </select>
                                    <input
                                        type="text" 
                                        placeholder="Nhập lý do"
                                        className="form-control"
                                        value={this.state.reason}
                                        onChange={(e)=>{
                                            this.setState({
                                                reason: e.target.value
                                            })
                                    }}/>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ? 
                                (
                                <button style={{width: "120px"}} className="btn btn-red" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Xác nhận...
                                </button>
                                ) :
                                (
                                <button 
                                    style={{width: "120px"}} 
                                    type="button" 
                                    className="btn btn-red"
                                    onClick={this.addIcoin}>Xác nhận</button>    
                                )}                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}