import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import bindModel from '../../../common/bindModel.js';

export default class ModalUpdateBannerFullPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bannerFull: null,
            loading: false
        }
        this.saveBannerFull = this.saveBannerFull.bind(this);
        this.updateStartDate = this.updateStartDate.bind(this);
        this.uploadThumbnailForWasabi = this.uploadThumbnailForWasabi.bind(this);
        this.updateEndDate = this.updateEndDate.bind(this);
    }

    componentWillReceiveProps(props) {
        let bannerFull = JSON.parse(JSON.stringify(props.bannerFull));
        if (bannerFull) {
            if (bannerFull.enabled) {
                bannerFull.enabled = '1';
            } else {
                bannerFull.enabled = '0';
            }

            this.setState({
                bannerFull: bannerFull
            })
        }

    }

    saveBannerFull() {
        let obj = { ...this.state.bannerFull };

        if (obj == null ||
            obj.thumbnail == '' ||
            obj.url == '' ||
            // obj.linkType == '' ||
            obj.startDate == '' ||
            obj.endDate == ''
        ) {
            toast.error("Vui lòng điền đầy đủ thông tin.");
            return;
        }
        if (obj.enabled == '1') obj.enabled = true;
        else obj.enabled = false;

        this.setState({
            loading: true
        });

        axios.post(global.config.apiDomain + '/rest/bannerFulls/createBannerFull', JSON.stringify(obj), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                this.setState({
                    loading: false
                });

                if (res.data.status == "OK") {
                    window.$("#modalUpdateBannerFull").modal("hide");
                    this.props.resetList(res.data.model);
                    toast.success(res.data.message);
                } else {
                    toast.warning(res.data.message);
                }
            })
            .catch(error => {
                this.setState({
                    loading: false
                });
                console.error("Error occurred:", error);
                toast.error("Lỗi server, xử lý lại nha");
            });
    }

    convertDateTime(value) {
        let arr = value.split(",");
        let arrDate = arr[1].trim().split("/");

        arrDate[1] = arrDate[1].length == 1 ? "0" + arrDate[1] : arrDate[1];
        arrDate[0] = arrDate[0].length == 1 ? "0" + arrDate[0] : arrDate[0];
        let date = arrDate[2] + "-" + arrDate[1] + "-" + arrDate[0];
        return date + "T" + arr[0].substring(0, 5);

    }

    updateStartDate(props) {
        let { bannerFull } = this.state;
        let currentTime = props.value
        if (bannerFull) {
            bannerFull.startDate = currentTime;
            this.setState({
                bannerFull: bannerFull
            });
        }

    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    uploadThumbnailForWasabi(event) {
        this.setState({
            loading: true
        });

        var bannerFull = this.state.bannerFull;
        var target = event.target;
        var bucketName = "ikara-data/images/bannerFull";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/jpeg";
        if (extension == "png") contentType = "image/png";
        let file = event.target.files[0];

        var fileReader = new FileReader();
        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', file);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType);
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    this.setState({
                        loading: false
                    });

                    bannerFull.thumbnail = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        bannerFull: bannerFull,
                    });

                }.bind(this),
                error: function(xhr, status, error) {
                    // Error callback
                    console.error('Error:', error);
                    // Optionally, update the state to reflect the error state
                    this.setState({
                        loading: false,
                    });
                    toast.warning('lỗi server, upload lại hí');

                }.bind(this)
            });
        }.bind(this);
        fileReader.readAsArrayBuffer(file);
    }

    updateEndDate(props) {
        let { bannerFull } = this.state;
        let currentTime = props.value
        if (bannerFull) {
            bannerFull.endDate = currentTime;
            this.setState({
                bannerFull: bannerFull
            });
        }

    }

    render() {
        const model = bindModel(this);
        const { bannerFull, loading } = this.state;
        return (
            <div>
                <div className="modal fade" id="modalUpdateBannerFull" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật Banner Full </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {bannerFull ? <div className="modal-body">
                                <div id="thumbnail" className="row mb-2">
                                    <div className="col-3">
                                        <label>Ảnh nền: </label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="custom-file-input"
                                            type="file"
                                            name="thumbnail"
                                            onChange={this.uploadThumbnailForWasabi}
                                        />
                                        <label className="custom-file-label ml-3 mr-3" htmlFor="thumbnail">Choose file</label>
                                    </div>
                                </div>

                                {bannerFull.thumbnail ?
                                    <div className="row mb-2">
                                        <div className="col-3">
                                            <label></label>
                                        </div>
                                        <div className="col-9">
                                            <img width={200} src={bannerFull.thumbnail} alt="#" />
                                        </div>

                                    </div> : <></>}
                                <div id="url" className="row mb-2">
                                    <div className="col-3">
                                        <label>URL</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="url"
                                            placeholder="Nhập url..."
                                            {...model('bannerFull.url')}
                                        />
                                    </div>
                                </div>

                                <div id="linkType" className="row mb-2">
                                    <div className="col-3">
                                        <label>Loại Link</label>
                                    </div>
                                    <div className="col-9">
                                        <select
                                            className="form-control"
                                            type="text"
                                            name="linkType"
                                            {...model('bannerFull.linkType')} 
                                            >
                                            <option value="">Chọn</option>
                                            <option value="MyApp">MyApp</option>
                                            <option value="WebView">WebView</option>
                                            <option value="AnotherApp">AnotherApp</option>
                                        </select>
                                    </div>
                                </div>

                                <div id="requiredLevel" className="row mb-2">
                                    <div className="col-3">
                                        <label>Level Yêu cầu</label>
                                    </div>
                                    <div className="col-9">
                                        <input 
                                            className="form-control" 
                                            type="number" 
                                            name="requiredLevel" 
                                            {...model('bannerFull.requiredLevel')}/>
                                    </div>
                                </div>
                                

                                <div id="startDate" className="row mb-2">
                                    <div className="col-3">
                                        <label>Ngày bắt đầu</label>
                                    </div>
                                    <div className="col-9">
                                        {/* <input
                                        className="form-control mb-2"
                                        type="datetime-local"
                                        name="startDate"
                                        {...model('bannerFull.startDate')}
                                    /> */}
                                        <DateTimePickerComponent
                                            change={this.updateStartDate}
                                            value={new Date(bannerFull.startDate)}
                                        >
                                        </DateTimePickerComponent>
                                    </div>
                                </div>
                                <div id="endDate" className="row mb-2">
                                    <div className="col-3">
                                        <label>Ngày kết thúc</label>
                                    </div>
                                    <div className="col-9">
                                        {/* <input
                                        className="form-control mb-2"
                                        type="datetime-local"
                                        name="endDate"
                                        {...model('bannerFull.endDate')}
                                    /> */}
                                        <DateTimePickerComponent
                                            change={this.updateEndDate}
                                            value={new Date(bannerFull.endDate)}
                                        >
                                        </DateTimePickerComponent>
                                    </div>
                                </div>
                            </div> : <></>}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.saveBannerFull}>Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}