import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
export default function PrivacyPolicyPage() {

  const { t } = useTranslation();

  return (
    <div id="content">
      <Helmet>
					<title>Chính Sách Bảo Mật</title>
				</Helmet>
      <div className="term-page">
        <div className="container">
          <div className="content pb-5">
            <h1>PRIVACY POLICY</h1>
            <p>The {t("privacy_policy_page.app_name","IKARA")} Company (“{t("privacy_policy_page.app_name","IKARA")}” “we” or “our”) cares about your privacy and the security of your information. We want you to be familiar with how we collect, use and disclose information, including personal information. This Privacy Policy describes our practices in conjunction with your access to our digital products and services. These products and services include our websites, email newsletters, social media pages, mobile and other applications owned and operated by us, our offline contacts, and to any other services that display this notice (collectively referred to as the “{t("privacy_policy_page.app_name","IKARA")} Services”). This Privacy Policy does not apply to certain of our products and services that display a different privacy policy.</p>

            <p>The {t("privacy_policy_page.app_name","IKARA")} Services contain links to other websites for your convenience and information. We are not responsible for the privacy practices or the content of those sites.</p>

            <p>Contact Us</p>

            <p>If you have any questions, email us at {t("privacy_policy_page.email_value","support@ikara.co")} or write us at:</p>

            <p>The {t("privacy_policy_page.app_name","IKARA")} Company</p>
            <p>305 Le Hong Phong</p>
            <p>Nha Trang, Khanh Hoa, Vietnam</p>
            <p>We can also be reached by phone at 1900686868</p>

            <h2>1. What Personal Information Do We Gather About You?</h2>


            <p>When you use the {t("privacy_policy_page.app_name","IKARA")} Services by, among other actions, ordering a subscription or other product, providing registration details, setting newsletter preferences, browsing our sites, completing a survey, entering a contest or otherwise interacting with our {t("privacy_policy_page.app_name","IKARA")} Services, we gather personal information. Personal information is information that identifies you as an individual or relates to an identifiable individual. Several different types of personal information can be gathered when you interact with the {t("privacy_policy_page.app_name","IKARA")} Services, depending on the type of product or service being used. Collection of personal information is necessary to delivering you the {t("privacy_policy_page.app_name","IKARA")} Services or to enhance your customer experience.
            </p>
            <p>If you disclose any personal information relating to other people to us or to our service providers in connection with the {t("privacy_policy_page.app_name","IKARA")} Services, you represent that you have the authority to do so and to permit us to use the information in accordance with this Privacy Policy.
              We and our service providers collect personal information in a variety of ways, including:</p>
            <p className="font-weight-bold" >A) Through the {t("privacy_policy_page.app_name","IKARA")} Services.</p>
            <p>i. Registration: When you register for a {t("privacy_policy_page.app_name","IKARA")} Service, we collect personal information. For example, registration on {t("privacy_policy_page.app_name","IKARA")}.co can be done by supplying a unique email address and password, or by linking your Facebook or Google accounts. See below under “From Other Sources” for more information about linking your Facebook or Google Accounts. Registration to an event or conference requires that you complete a form that will include, at a minimum, your name and email address. </p>
            <p>ii. Billing: To subscribe to a {t("privacy_policy_page.app_name","IKARA")} Service or enable payment and donations via the {t("privacy_policy_page.app_name","IKARA")} Services, we collect and use your name, address, telephone number, email address, credit or debit card information and other billing information.</p>
            <p>iii. User-Generated Content and Public Activities (Including Comments and Reader Reviews): We offer you opportunities to engage in public activities on {t("privacy_policy_page.app_name","IKARA")}.co and other {t("privacy_policy_page.app_name","IKARA")} Services. "Public activities" are any actions you take on {t("privacy_policy_page.app_name","IKARA")} Services that are designed to be visible to other users, including comments, recommendations, reader reviews, ratings or any other items that you submit. If you choose to engage in public activities, you should be aware that any personal information you submit can be read, collected and used by other users of these services. We are not responsible for the personal information you choose to submit in the course of your public activities and we have no responsibility to publish, take down, remove or edit any of your public activities or submissions (except as required by applicable laws). </p>
            <p>iv. Contests, Sweepstakes and Special Offers: The {t("privacy_policy_page.app_name","IKARA")} collects personal information, such as your name and email, from you when you participate in sweepstakes, contests or special offers.</p>
            <p>v. User Surveys, User Panels and Market Research: We and our service providers collect personal information from you through voluntary surveys or feedback programs conducted via the {t("privacy_policy_page.app_name","IKARA")} Services, through our service providers’ platforms, by phone, by mail or during in-person interviews or focus groups. We will ask you for your consent to the processing of information at the point of collection. We also conduct similar research for advertisers.
              vi. User Experience Programs: When you interact with our interactive programs and answer questionnaires, we collect personal information about you.
              vii. Contact With Our Call Centers. We collect personal information from you in non-digital contexts, including, for example, when you place an order over the phone or contact customer service.</p>
            <p className="font-weight-bold" >B) From Other Sources.</p>
            <p>i. Publicly available databases, including privately-held marketing and data analytics resources. For example, we may receive demographic information (age, sex, household income, job industry and job title) from these sources.</p>
            <p>ii. Joint marketing third party, when they share the information with us.</p>
            <p>iii. Social-media platforms or other third-party services. If you choose to link your social-media account or account relating to other third-party services to the {t("privacy_policy_page.app_name","IKARA")} Services, you may be asking third parties to send us certain information from those social media accounts or services accounts, and you are authorizing us to collect, store, and use what they send us in accordance with this Privacy Policy. You can disassociate your {t("privacy_policy_page.app_name","IKARA")}.co registration from third-party accounts any time. </p>

            <h2>2. What Do We Do With The Personal Information We Collect About You?</h2>
            <p>We collect, use or otherwise process personal information as allowed under applicable law, including where based on one or more of the following:</p>
            <p>- The consent you provide to us at the point of collection of your personal information;
            </p>
            <p>- The performance of the contract we have with you;</p>
            <p>- The compliance of a legal obligation to which we are subject; or</p>
            <p>- The legitimate interests of the {t("privacy_policy_page.app_name","IKARA")}, a third party or yourself. "Legitimate interest" is a technical term under the regulation. It means that there are good reasons for the processing of your personal information and measures are taken to minimize the impact on your privacy rights and interests. "Legitimate interest" also references our use of your data in ways you would reasonably expect and that have a minimal privacy impact. We have a legitimate interest in collecting and processing personal information, for example: (1) to ensure that our networks and information are secure; (2) to administer and generally conduct business within the {t("privacy_policy_page.app_name","IKARA")}; and (3) to prevent fraud.</p>
            <p className="font-weight-bold" >A) Provide the {t("privacy_policy_page.app_name","IKARA")} Services You Request. We use the information we gather about you to enable your use of the {t("privacy_policy_page.app_name","IKARA")} Services and fulfill your requests for certain products and services, such as providing the {t("privacy_policy_page.app_name","IKARA")} Services’ functionality to you, arranging access to your registered account, providing you with customer service, responding to your inquiries, requests, questions, suggestions, compliments or complaints, completing your transactions, sending you administrative information (such as change of our terms, conditions, or policies) saving your reading list or property search, presenting your crossword-solving statistics, and enabling you to participate in and renew paid services, polls, promotions, surveys, panels or research, and message boards. We will engage in these activities to manage our contractual relationship with you or comply with a legal obligation.</p>
            <p className="font-weight-bold" >B) Analysis and Development of New Products and Services. We perform statistical, demographic and marketing analyses of users of the {t("privacy_policy_page.app_name","IKARA")} Services, and their subscribing and purchasing patterns, so we can analyze or predict our users’ preferences for product and services development purposes, to determine our promotional campaign effectiveness so we can adapt our campaign to the needs and interests of our users, and to generally inform advertisers about the nature of our subscriber base. We use this information for analytical purposes, including analysis to improve customer relationships, to support strategic business decisions and our marketing tactics and to measure and track our brand health. We will engage in these activities to manage our contractual relationship with you, to comply with a legal obligation, or because we have a legitimate interest in doing so. </p>

            <p className="font-weight-bold" >C) Customizing Your Experience. We use the information that we have to better understand you and customize certain features of the {t("privacy_policy_page.app_name","IKARA")} Services to provide you with an enhanced experience including to show you offerings, promotions or products that may be of interest to you, based on the demographic information, type of device you are using to access the {t("privacy_policy_page.app_name","IKARA")} Services, your usage of the {t("privacy_policy_page.app_name","IKARA")} Services and your reading history. We will provide personalized services because we have a legitimate interest. </p>
            <p className="font-weight-bold" >D) Email Newsletters and Other Marketing Activities as well as Facilitating Social Sharing. The {t("privacy_policy_page.app_name","IKARA")} will periodically send you email newsletters or promotional emails about services offered by The {t("privacy_policy_page.app_name","IKARA")} and its advertisers. To opt out of these emails, please see the section labeled How do I opt out from receiving emails? We will use the information we have about you to advertise our services to you on our {t("privacy_policy_page.app_name","IKARA")} Services but also on other platforms. If you choose to use social-sharing functionalities, we will use your information to facilitate such action, and such action -- along with any information you share -- will be covered by the social-media platform’s privacy policy. We will engage in these activities with your consent or where we have a legitimate interest.</p>
            <p className="font-weight-bold" >E) Administrative Purposes. We and our service providers use the information we have about you for the following legitimate business purposes:</p>
            <p>i. For auditing purposes, to verify that our internal processes function as intended and comply with legal, regulatory and contractual requirements;</p>
            <p>ii. For fraud and security monitoring purposes, including, for example, to detect and prevent cyberattacks; and</p>
            <p>iii.To understand and assess satisfaction with our {t("privacy_policy_page.app_name","IKARA")} Services or our customer care team.</p>
            <p>We engage in these activities to manage our contractual relationship with you, to comply with a legal obligation, or because we have a legitimate interest.</p>
            <p className="font-weight-bold" >F) Allowing You to Participate in Sweepstakes, Contests or Other Promotions. We may offer you the opportunity to participate in a sweepstakes, contest or other promotions. Some of these promotions have additional rules containing information about how we will use and disclose your personal information. We use this information to manage our contractual relationship with you.</p>
            <p className="font-weight-bold" >G) Aggregating and/or Anonymising Personal Information. We aggregate and/or anonymize personal information so that it will no longer be considered personally identifying. We do so to generate other data for our use, which we may use and disclose for any purpose.</p>
            <p className="font-weight-bold" >H) User Generated Content and Public Activities. When you share or recommend links to content on a social-media platform (such as Facebook, Google+ and Twitter), that action and any information you share will be covered by that platform’s privacy policy. Any information you disclose in your public activities, along with your screen name or ID, or any image or photo, becomes public.</p>
            <h2>3. With Whom Do We Share The Personal Information We Gather?</h2>
            <p className="font-weight-bold" >A) Sharing Within The {t("privacy_policy_page.app_name","IKARA")}. We share your personal information with our affiliates.</p>
            <p className="font-weight-bold" >B) Sharing With Service Providers. We contract with other companies, agents or contractors (“service providers”) to provide services on our behalf. These services are, for example, credit-card, payment and billing processing, fulfillment of orders, information technology and related infrastructure provision, customer service, ad serving, shipping, email distribution, list processing and analytics, research surveys/interviews/focus group management and analysis, or promotions management. In the course of performing these services, our service providers have access to your information including your personal information.</p>
            <p className="font-weight-bold" >C) Sharing With Other Third Parties. We will not sell, rent, swap or authorize any third party (except our service providers) to use your email address without your permission. Nothing in this Privacy Policy is intended to restrict our use or sharing of aggregated or de-identified information in any way.</p>
            <p>i. If you are a U.S. print subscriber, we may exchange or rent your name and postal mailing address (but not your email address) and certain other information, such as when you first subscribed to The {t("privacy_policy_page.app_name","IKARA")} with other reputable companies that offer marketing information or products through direct mail. If you prefer that we do not share this information, you may opt out by emailing us at {t("privacy_policy_page.email_value","support@ikara.co")}, or write to us at Customer Care, 305 Le Hong Phong, Nha Trang, Khanh Hoa, Vietnam. Please include your account number and phone number in the body of your email or letter, and include "Opt-out" in the subject line.</p>
            <p>ii. We may share personal information, such as name, company and title, about attendees to our live events and conferences with sponsors; if we do, we will notify you when you provide us the information.</p>
            <p>iii. We may also share personal information to third-party sponsors of sweepstakes, contests, and similar promotions and will let you know at the point of collection of your information if we do.</p>
            <p>iv. We may use a third-party payment service to process payments made through the {t("privacy_policy_page.app_name","IKARA")} Services. If you wish to make a payment through the {t("privacy_policy_page.app_name","IKARA")} Services, your personal information will be collected by such third party and not by us, and will be subject to the third party’s privacy policy, rather than this Privacy Policy.  We have no control over, and are not responsible for, this third party’s collection, use and disclosure of your personal information.
              v. We share in aggregate results from research surveys or panels with advertisers.</p>
            <p>vi. We have a legitimate interest in disclosing or transferring your personal information to a third party in the event of any reorganization, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of our business, assets or stock (including in connection with any bankruptcy or similar proceedings). Such third parties may include, for example, an acquiring entity and its advisors.</p>
            <p className="font-weight-bold" >D) Compliance With Legal Process. We may access, preserve and disclose personal information if we are required to do so by law or we have a good faith belief that such action is necessary to (1) comply with the law or with legal process; (2) protect and defend our rights and property; (3) protect against misuse or unauthorized use of the {t("privacy_policy_page.app_name","IKARA")} Services; (4) protect the personal safety or property of our users or the public (among other things, this means that if you provide false information or attempt to pose as someone else, information about you may be disclosed as part of any investigation into your actions); and (5) cooperate with public and government authorities (which may be outside your country of residence).</p>
            <p className="font-weight-bold" >E) By Using the {t("privacy_policy_page.app_name","IKARA")} Services, You May Elect to Disclose Personal Information.</p>
            <p>i) Public Activities: see above for more details on such activities. Public activities may be included in our RSS feeds, APIs and other distribution formats. As a result, your public activities may appear on other websites, applications, blogs, or feeds. </p>
            <p>ii) Through Your Social Sharing Activity: When you connect your {t("privacy_policy_page.app_name","IKARA")} Services account with your social media account, you will share information with your friends associated with your social media account, with other users, and with your social media account provider. By doing so, you authorize us to facilitate this sharing of information, and you understand that the use of shared information will be governed by the social media provider’s privacy policy.</p>
            <h2>4. Other Information Collected Using Technology</h2>
            <p>Other information does not specifically identify you and are collected through technology. It includes tracking information collected by us as well as third parties.</p>
            <p>We use various internet technologies to manage, and track the use of, the {t("privacy_policy_page.app_name","IKARA")} Services. We use and disclose this information for any purpose, except where we are required to do otherwise under applicable law. Information that we collect using these technologies is combined with personal information about you. If we combine information with personal information, we will treat the combined information as personal information.</p>
            <p className="font-weight-bold" >A) Device Information. We collect information about the computer, mobile device or other device you use to access the {t("privacy_policy_page.app_name","IKARA")} Services, such as Internet protocol (IP) addresses (the region or general location where your computer or device is accessing the Internet), geolocation information, unique device identifiers, browser type, browser language and other transactional information.</p>
            <p className="font-weight-bold" >B) Cookies, Beacons, Local Storage and Other Similar Technologies. We use "cookies," Web beacons, tags and scripts, and other similar technologies including local storage objects such as HTML5. These technologies allow us to manage access to and use of the {t("privacy_policy_page.app_name","IKARA")} Services, recognize you and provide personalization, and help us understand how people use the {t("privacy_policy_page.app_name","IKARA")} Services. You will not be able to access certain areas of our websites, including {t("privacy_policy_page.app_name","IKARA")}.co, if your computer does not accept cookies from us. Because there is no accepted standard on how to respond to “do not track” browser-based signals, we do not respond to such signals.</p>
            <p>We transmit website usage information to third parties in order to show you advertising for The {t("privacy_policy_page.app_name","IKARA")} when you visit other sites. For more information about our third-party ad servers, or to learn about your choices about not having this information used to target ads to you, please click here</p>
            <p className="font-weight-bold" >C) Analytics, Log Files and Reading History. As is true of most web sites, we gather certain information automatically and store it in log files. This information include IP address , browser type, operating system and other usage information about the use of the {t("privacy_policy_page.app_name","IKARA")} Services, including a history of the pages you view.</p>
            <p>We automatically combine this collected log-information with other information we collect about you. We do this to improve services we offer you, including customized recommendations, advertising and currency display, to improve marketing, and to track access and use of the {t("privacy_policy_page.app_name","IKARA")} Services across the devices that you use to access the {t("privacy_policy_page.app_name","IKARA")} Services.</p>
            <p>One of the analytics service providers we use is Google Analytics. To learn more about how Google use data, visit https://policies.google.com/privacy/partners. For opt-out options specific to Google Analytics, please visit https://tools.google.com/dlpage/gaoptout. </p>
            <p className="font-weight-bold" >D) Location Information. Some of our mobile applications can deliver content based on your current location if you choose to enable that feature of the app, for example, by use of satellite, cell phone tower, or WiFi signals. If you enable the location-based feature, your current location will be stored locally on your device, which will then be used by the app. If you elect to have a location-based search saved to your history, we will store that information on our servers. If you do not enable the location-based service, or if an app does not have that feature, the app will not transmit to us, and we will not collect or store, location information. The ads in our apps are not targeted to you based on your current GPS location, but they are targeted to you based on your ZIP code or device's IP address.</p>
            <p className="font-weight-bold" >E) Delete Your Personal Data. You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You. Our Service may give You the ability to delete certain information about You from within the Service. You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us. Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so. For any Delete Personal Data request, please contact our Support Team by these steps. Step 1, tap the Message tab. Step 2, select the Support button to chat with Okara Admin. In the other way, you can send your request email to support@mkaraoke.co.mz</p>
            <h2>5. Third-Party Advertising</h2>
            <p>Some of the services and advertisements included in the {t("privacy_policy_page.app_name","IKARA")} Services, including on {t("privacy_policy_page.domain","ikara.co")} and within our mobile apps, are delivered or served by third-party companies, which may collect information about your use of the {t("privacy_policy_page.app_name","IKARA")} Services.</p>
            <p>These companies place or recognize cookies, pixel tags, web beacons or other technology to track certain information about our {t("privacy_policy_page.app_name","IKARA")} Services website users. For example, in the course of serving certain advertisements, an advertiser may place or recognize a unique cookie on your browser in order to collect certain information about your use of the {t("privacy_policy_page.app_name","IKARA")} Services. For another example, an advertiser or ad server may also be able to collect your device’s unique identifier in the course of serving an ad. In many cases, this information could be used to show you ads on other websites based on your interests.
              We do not have access to, nor control over, these third parties' use of cookies or other tracking technologies or how they may be used.</p>
            <p>For example, we use Google to serve advertisements on the {t("privacy_policy_page.app_name","IKARA")} Services, which use the Google Doubleclick cookie, and in some cases, a unique device identifier, to show you ads based on your visit to {t("privacy_policy_page.domain","ikara.co")} and other sites on the Internet. You may opt out of the use of the Google Doubleclick cookie by visiting the Google ad and content network privacy policy. If you would like more information about this practice, and to learn how to opt out of it in desktop and mobile browsers on the particular device on which you are accessing this Privacy Policy, please visit http://optout.aboutads.info/#/ and http://optout.networkadvertising.org/#. You may download the AppChoices app at http://www.aboutads.info/appchoices to opt out in mobile apps.</p>
            <p>Your access to our websites should not be affected if you do not accept cookies served by third parties</p>
            <h2>6. How Do I Opt Out From Receiving Emails?
            </h2>
            <p>You can choose not to receive commercial email messages in the future by either (1) following the "unsubscribe" instructions located near the bottom of each email, or (2) you can email us at {t("privacy_policy_page.email_value","support@ikara.co")}.</p>
            <p className="font-weight-bold" >A) Email Newsletters. The {t("privacy_policy_page.app_name","IKARA")} offers several email newsletters. If you no longer wish to receive a specific newsletter, follow the "unsubscribe" instructions located near the bottom of each newsletter. If you experience difficulty with the “unsubscribe” process, please contact us at {t("privacy_policy_page.email_value","support@ikara.co")} and provide your email address along with the name of the newsletter from which you would like to unsubscribe.</p>
            <p className="font-weight-bold" >B) Mail or Telephone Promotions. If, at any time, you prefer not to receive mail or telephone solicitations originated by The {t("privacy_policy_page.app_name","IKARA")}, or if you prefer that we do not share this information with third parties for marketing purposes, please email us at {t("privacy_policy_page.email_value","support@ikara.co")} or write to us at Customer Care,305 Le Hong Phong, Nha Trang, Khanh Hoa, Vietnam. Please include your account number and phone number in the body of your email or letter, and put "Opt -out" in the subject line.</p>
            <h2>7. Your California Privacy Rights</h2>
            <p>Under the California “Shine The Light” law, California residents may opt-out of our disclosure of personal information to third parties for their direct marketing purposes. As detailed above, you may choose to opt-out of the sharing of your personal information with third parties for marketing purposes at any time by submitting a request in writing to Customer Care,305 Le Hong Phong, Nha Trang, Khanh Hoa, Vietnam or by emailing us at {t("privacy_policy_page.email_value","support@ikara.co")}. Please include your account number and phone number in the body of your email or letter, and put “Opt-out” in the subject line. It is important to note that this opt-out request does not prohibit us from sending you important administrative disclosures for non-marketing purposes. We will comply with your request(s) as soon as reasonably practicable.</p>
            <h2>8. How Do I Access, Change or Update My Personal Information?</h2>
            <p>If you would like to request to review, correct, restrict or delete personal information that you have previously provided to us, object to the processing of personal information, or if you would like to request to receive an electronic copy of your personal information for purposes of transmitting it to another company (to the extent this right to data portability is provided to you by applicable law), you may contact us by writing us at {t("privacy_policy_page.email_value","support@ikara.co")}.
              In your request, please state what personal information you would like to have changed, whether you would like to have your personal information suppressed from our database or otherwise let us know what limitations you would like to put on our use of your personal information. For your protection, we only implement requests with respect to the personal information associated with the particular email address that you use to send us your request, and we may need to verify your identity before implementing your request.</p>
            <p>We will respond to your request consistent with applicable law.</p>
            <p>Please note that we may need to retain certain information for recordkeeping purposes and/or to complete any transactions that you began prior to requesting a change or deletion (e.g., when you make a purchase or enter a promotion, you may not be able to change or delete the Personal Information provided until after the completion of such purchase or promotion).</p>
            <p>If you are a resident of California, under 18 and a registered user of the {t("privacy_policy_page.app_name","IKARA")} Services, you may ask us to remove content or information that you have posted to the {t("privacy_policy_page.app_name","IKARA")} Services by writing to email us at {t("privacy_policy_page.email_value","support@ikara.co")}.</p>
            <p>Please note that your request does not ensure complete or comprehensive removal of the content or information, as, for example, some of your content may have been reposted by another user.</p>
            <p>You may lodge a complaint with a data protection authority for your country or region or where an alleged infringement of applicable data protection law occurs. A list of EU data protection authorities is available at http://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080.</p>
            <h2>9. Sensitive Personal Information</h2>
            <p>Unless specifically requested (for example, in connection with a particular survey), we ask that you not send us, and you not disclose, any sensitive personal information (e.g., social security numbers, information related to racial or ethnic origin, political opinions, religion or other beliefs, health, biometrics or genetic characteristics, criminal background or trade union membership) on or through the {t("privacy_policy_page.app_name","IKARA")} Services or otherwise to us. Any requested information that includes sensitive information about health, political opinions, ethnicity, race, religion, sex life or sexual orientation can be left unanswered.</p>
            <h2>10. Data Retention </h2>
            <p>We retain personal information for as long as needed or permitted in light of the purpose(s) for which it was obtained and consistent with applicable law. The criteria used to determine our retention periods include:</p>
            <p>•	The length of time we have an ongoing relationship with you and provide the {t("privacy_policy_page.app_name","IKARA")} Services to you (for example, for as long as you have an account with us or continue to use the {t("privacy_policy_page.app_name","IKARA")} Services);</p>
            <p>•	Whether there is a legal obligation to which we are subject (for example, certain laws require us to keep records of your transactions for a certain period of time before we can delete them); or</p>
            <p>•	Whether retention is advisable in light of our legal position (for example, in light of applicable statutes of limitations, litigation or regulatory investigations).  </p>
            <h2>11.  Is My Information Protected?</h2>
            <p>We seek to protect personal information with reasonable organizational, technological and physical safeguards commensurate to the sensitivity of the personal information.   Although we take appropriate measures to safeguard your information, we cannot guarantee its absolute security.To further protect your data, we recommend that you use complex passwords for your accounts with us and for accounts with third parties that you link to any of your accounts with us. Please do not use the same passwords you use for {t("privacy_policy_page.app_name","IKARA")} Services for other services or share your password with unauthorized people. If you have reason to believe that your interaction with us is no longer secure, please immediately notify us in accordance with the “Contacting Us” section above.</p>
            <h2>12. Children’s Guidelines</h2>
            <p>We do not knowingly collect or store any personal information from children except as permitted by applicable law.</p>
            <p>If you are a resident of California, USA, under 18 years old and a registered user of the {t("privacy_policy_page.app_name","IKARA")} Services, please see above “How Do I Access, Change or Update My Personal Information?”</p>
            <h2>13. International Transfers</h2>
            <p>The {t("privacy_policy_page.app_name","IKARA")} is headquartered in Vietnam. The information you provide to us or that we obtain as a result of your use of the {t("privacy_policy_page.app_name","IKARA")} Services is collected in your country and subsequently transferred to Vietnam or to another country in which we have facilities or our affiliates operate or in which we engage service providers.</p>
            <p>Some countries that are not members of the European Economic Area (EEA) are recognized by the European Commission as providing an adequate level of data protection according to EEA standards (the full list of these countries is available here). For transfers from the EEA to countries not considered adequate by the European Commission, we have put in place adequate measures, including by ensuring that the recipient is bound by EU Standard Contractual Clauses, to protect your personal data. To obtain a copy of these measures click here.</p>
            <p>In certain circumstances, courts, law enforcement agencies, regulatory agencies or security authorities in those other countries may be entitled to access your personal information.</p>
            <h2>14. Changes to This Privacy Policy</h2>
            <p>We evaluate this privacy policy periodically in light of changing business practices, technology and legal requirements. As a result, it is updated from time to time. Any such changes will be posted on this page. If we make a significant or material change in the way we use or share your personal information, you will be notified via email and/or prominent notice within the {t("privacy_policy_page.app_name","IKARA")} Services at least 30 days prior to the changes taking effect.</p>

          </div>
        </div>
      </div>
    </div>
  );

}

