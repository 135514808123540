import React, {Component} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';

export default class LivestreamDelete extends Component {
    constructor(props) {
        super(props);

        this.state = {
            model: null,
            loading: false
        }

        this.livestreamDelete = this.livestreamDelete.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            model : props.model
        })
    }

    livestreamDelete() {
        this.setState({
            loading: true
        });

        axios.post(global.config.apiDomain + '/rest/livestream/delete', this.state.model)
        .then(res => {
            this.setState({
                loading: false
            });

            if (res.data.status == "OK") {
                window.$("#modalDelete").modal("hide");
                toast.success(res.data.message); 
                this.props.resetList();
            } else {
                toast.warning(res.data.message);
            }
        })
    }

    render() {
        const {model} = this.state;

        return (
            <div>
                <div className="modal fade" id="modalDelete" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">

                            </div>
                            <div className="modal-body">
                                {model && (
                                    <div>
                                        <h5 className="modal-title" id="exampleModalLabel">Bạn có muốn xoá livestream <b>{model.id}</b> không?<b> </b></h5>
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">
                                    Không
                                </button>
                                <button type="button" className="btn btn-danger" onClick={this.livestreamDelete}>
                                    Có
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}