import React, { Component } from 'react';
import {Link} from "react-router-dom";

class RecordingItemInRecordingsPage extends Component {
    
    render() {
   
        const {onlineRecordingUrl, singerName , song, thumbnailImageUrl, owner} = this.props.recording;
        return (
            <div className="col-6 col-lg-4 col-xl-3 item item-0">
                <div className="images">
                    <Link to={onlineRecordingUrl}>
                        <span className="overlay" />
                        <img 
                            src={song.thumbnailUrl} 
                            className="img-item" 
                            alt={song.songName}
                            />
                    </Link>
                </div>
                <h2 className="song-name font-weight-bold">
                    <Link to={onlineRecordingUrl}>{song.songName}</Link>
                </h2>
                <div className="singer_names">
                    <Link to={owner.url}>{owner.name}</Link>
                </div>
            </div>
        )
    }
}
export default RecordingItemInRecordingsPage