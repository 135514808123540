import React, {Component} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';

const AWS = window.AWS;
const $ = window.$;
export default class ModalDeletePromotion extends Component {

    constructor(props) {
        super(props);

        this.state = {
            promotion: null,
            loading: false
        }

        this.deletePromotion = this.deletePromotion.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            promotion : props.promotion
        })
    }

    deletePromotion() {
        this.setState({
            loading: true
        });

        axios.post(global.config.apiDomain + '/rest/promotion/delete', JSON.stringify(this.state.promotion), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                loading: false
            });
            if(res.data.status == "OK") {
                $("#modalAddPromotion").modal("hide");
                toast.success(res.data.message); 
                this.props.resetList();
            } else {
                toast.warning(res.data.message);
            }
        }, error => {
            this.setState({
                loading: false
            });
            toast.error('Cập nhật thất bại'); 
        });
    }

    render() {

        const {promotion} = this.state;

        return (
            <div>

                <div className="modal fade" id="modalDeletePromotion" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Xóa sự kiện</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    {promotion ? (
                        <div className="modal-body">
                        <h3 className="text-center text-danger">Bạn chắc chắn muốn xóa ?</h3>
                    </div>
                    ) : ""}
                    <div className="modal-footer">
                        <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                        {this.state.loading ? 
                            (
                            <button style={{width: "120px"}} className="btn btn-red" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Xác nhận...
                            </button>
                            ) :
                            (
                            <button 
                            style={{width: "120px"}}
                            type="button" 
                            className="btn btn-red"
                            onClick={this.deletePromotion}
                            data-dismiss="modal">Xóa</button>
                            )}
                        
                    </div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}