import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import bindModel from '../../../../../common/bindModel.js';

export default class AddGameType extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            iGameType: null
        }

        this.addGameType = this.addGameType.bind(this);
    }

    addGameType() {
        const {iGameType} = this.state;
        console.log(iGameType);
        if(iGameType != null && iGameType != "") {
            this.props.addGameType(iGameType)
            this.setState({
                iGameType: ""
            })
        } else {
            toast.warn("Vui lòng chọn thể loại game mà bạn muốn thêm !!!")
        }
    }


    render() {
        const model = bindModel(this);
        const {gameTypes} = this.props;

        return (
            <>
                {gameTypes != null && gameTypes.length > 0 &&
                    <div className="row justify-content-center mt-2">
                        <div className="col-4 col-lg-2 my-auto">
                            <label className="m-0">Thêm thể loại game:</label>
                        </div>
                        <div className="col-6 col-lg-4">
                            <select
                                className="form-control"
                                type="text"
                                {...model('iGameType')}
                            >
                                <option value="">Chọn thể loại game muốn thêm</option>
                                {
                                    gameTypes.map((element, i) => {
                                        return <option key={i} value={i}>{element.name}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-2 col-lg-1">
                            <button style={{width: "100px"}} className='btn btn-primary' onClick={this.addGameType}>Thêm <i className="fa fa-plus" aria-hidden="true"></i></button>
                        </div>
                    </div>
                }
            </>
        )
    }

}