import React, { Component } from "react";
import axios from "axios";
import SVGA from "svgaplayerweb";
import $ from "jquery";

export default class ModalSetAvatarFrame extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gifts: [],
    };

    this.getGifts = this.getGifts.bind(this);
    this.setData = this.setData.bind(this);
  }

  componentWillMount() {
    this.getGifts();
  }

  getGifts() {
    let obj = {
      typeString: "AvatarFrame"
    };

    axios.post(global.config.apiDomain + "/rest/shop-items/getItemWithPage", obj)
      .then((res) => {
        let gifts = res.data.listItem;
        this.setState({
          gifts: gifts,
        });

        gifts.map((gift, index) => {
          let idResource = "show-animation_resourceUrl" + index;

          if (gift.resourceUrl != null && gift.resourceUrl.endsWith("svga")) {
            this.loadSVGA4(gift.resourceUrl, idResource);
          } else if (gift.resourceUrl != null) {
            $("#" + idResource).replaceWith(
              '<img style="height: 50px; width: 50px; align: center; " src="' +
              gift.resourceUrl +
              '"  />'
            );
          }
        });
      });
  }

  setData(event) {
    let { model, property } = this.props;
    let index = event.target.name;
    let gift = this.state.gifts[index];
    model[property] = {
      ...model[property],
      frameIdString: gift._id,
      frameUrl: gift.resourceUrl
    };
    this.props.update(model);

    window.$("#modalSetAvatarFrame").modal("hide");
  }

  loadSVGA4(url, canvasId) {
    var parser = new SVGA.Parser("#" + canvasId);
    parser.load(url, function (videoItem) {
      var player = new SVGA.Player("#" + canvasId);
      player.setVideoItem(videoItem);
      // player.setFrame(0, 0, 750, 750);z
      player.setContentMode("AspectFill");
      player.startAnimation();
    });
  }

  render() {
    const { gifts } = this.state;

    return (
      <div>
        <div
          className="modal fade"
          id="modalSetAvatarFrame"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalCenterTitle">
                  Chọn Avatar
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <table className="table table-hover">
                <thead>
                  <tr className="align-middle">
                    <th className="align-middle" scope="col">
                      ID
                    </th>
                    <th className="align-middle" scope="col">
                      Tên
                    </th>
                    <th className="align-middle" scope="col">
                      Hình vật phẩm
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {gifts.map((gift, index) => {
                    let idResource = "show-animation_resourceUrl" + index;
                    return (
                      <tr key={gift.idPublic}>
                        <td>{gift.idPublic}</td>
                        <td>{gift.name}</td>
                        <td style={{ maxHeight: "50px", maxWidth: "50px" }}>
                          <div>
                            <div
                              id={idResource}
                              style={{
                                maxHeight: "50px",
                                maxWidth: "50px",
                                align: "center",
                              }}
                            >
                              <div width={50} height={50} id="canvas"></div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <button
                            name={index}
                            onClick={this.setData}
                            className="btn btn-warning"
                          >Chọn</button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    );
  }
}
