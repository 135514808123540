import React, { Component } from 'react';
import axios from 'axios';
import ModalUpdateBanner from './components/ModalUpdateBanner';
import ModalDeleteBanner from './components/ModalDeleteBanner';
import lodash from 'lodash';

export default class BannerManagerPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            banners: [],
            banner: null,
            page: 0,
            statusButtonLoadPage: false,
            loading: false,
            sortBy: 'startDate'
        }

        this.getNewBanner = this.getNewBanner.bind(this);
        this.updateBanner = this.updateBanner.bind(this);
        this.getBannerList = this.getBannerList.bind(this);
        this.updateBannerList = this.updateBannerList.bind(this);
        this.deleteBanner = this.deleteBanner.bind(this);
        this.sortByStartDate = this.sortByStartDate.bind(this);
        this.sortByEndDate = this.sortByEndDate.bind(this);
    }

    componentDidMount() {
        this.getBannerList();
    }

    getBannerList() {
        this.setState({
            loading: true
        });

        // Chọn endpoint dựa trên giá trị của sortBy
        let endpoint = this.state.sortBy === 'startDate'
            ? '/rest/banners/'
            : '/rest/banners/enddate/';

        axios.post(global.config.apiDomain + endpoint + this.state.page, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                let banners = res.data;
                let status = banners.length >= 10;

                this.setState({
                    banners: this.state.banners.concat(banners),
                    page: this.state.page + 1,
                    statusButtonLoadPage: status,
                    loading: false
                });
            })
            .catch(error => {
                this.setState({
                    loading: false
                });
                console.error("Error:", error);
            });
    }


    getNewBanner() {
        axios.post(global.config.apiDomain + '/rest/banners/create', JSON.stringify(''), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                banner: res.data
            })
        })
    }

    updateBanner(banner) {
        if (!banner) return;
        // // lấy thêm danh sách packages
        axios.post(global.config.apiDomain + '/rest/banners/packages/' + banner.id, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            banner.minMaxVersions = res.data;
            this.setState({
                banner: banner
            })
        })

    }

    updateBannerList(newBanner) {
        let banners = this.state.banners;

        let index = banners.findIndex(banner => banner.id === newBanner.id);
        if (index !== -1) {
            banners[index] = newBanner;
        } else {
            banners.unshift(newBanner);
        }

        this.setState({
            banners: banners,
        })
    }

    deleteBanner(oldBanner) {
        let banners = this.state.banners;

        let index = banners.findIndex(banner => banner.id === oldBanner.id);
        if (index !== -1) {
            banners.splice(index, 1);
        }

        this.setState({
            banners: banners,
        })
    }

    sortByStartDate() {
        this.setState({ sortBy: 'startDate', banners: [], page: 0 }, this.getBannerList);
    }

    sortByEndDate() {
        this.setState({ sortBy: 'endDate', banners: [], page: 0 }, this.getBannerList);
    }


    render() {
        const { banner, banners, statusButtonLoadPage, loading, sortBy } = this.state;
        return (
            <>
                <ModalUpdateBanner
                    banner={banner}
                    resetList={this.updateBannerList}></ModalUpdateBanner>
                <ModalDeleteBanner
                    banner={banner}
                    resetList={this.deleteBanner}></ModalDeleteBanner>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí banner</h1>
                        <button
                            className="btn btn-red mb-1"
                            data-toggle="modal"
                            data-target="#modalUpdateBanner"
                            onClick={this.getNewBanner}
                        >+ Tạo banner
                        </button>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th width={150} scope="col">Id</th>
                                    <th scope="col">Thumbnail</th>
                                    <th scope="col">Link</th>
                                    <th scope="col">LinkType</th>
                                    <th scope="col">Level yêu cầu</th>
                                    <th scope="col">Tạo bởi</th>
                                    <th
                                        scope="col"
                                        onClick={this.sortByStartDate.bind(this)}
                                        style={{
                                            backgroundColor: sortBy === 'startDate' ? '#f0f0f0' : 'inherit',
                                            fontWeight: sortBy === 'startDate' ? 'bold' : 'normal',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Start Date
                                    </th>
                                    <th
                                        scope="col"
                                        onClick={this.sortByEndDate.bind(this)}
                                        style={{
                                            backgroundColor: sortBy === 'endDate' ? '#f0f0f0' : 'inherit',
                                            fontWeight: sortBy === 'endDate' ? 'bold' : 'normal',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        End Date
                                    </th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    banners.map((banner, index) => {
                                        return (
                                            <tr key={banner.id}>
                                                <th scope="row">{banner.id}</th>
                                                <td style={{ width: '400px' }}><img style={{ maxWidth: '100%', height: 'auto' }} src={banner.thumbnail} /></td>
                                                {/* <td> {banner.url == null ? ("") : (banner.url.length > 100 ? banner.url.substring(0, 100) + "..." : banner.url)} </td> */}
                                                <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{banner.url}</td>
                                                <td>{banner.linkType ? banner.linkType : ''}</td>
                                                <td>{banner.requiredLevel}</td>
                                                <td>{banner.createBy}</td>
                                                <td>{new Date(banner.startDate).toLocaleString()}</td>
                                                <td>{new Date(banner.endDate).toLocaleString()}</td>
                                                <td>
                                                    <button
                                                        onClick={() => { this.updateBanner(banner) }}
                                                        className="btn btn-success"
                                                        data-toggle="modal"
                                                        data-target="#modalUpdateBanner"
                                                    >Sửa</button>
                                                    <button
                                                        className="btn btn-danger ml-sm-2"
                                                        data-toggle="modal"
                                                        data-target="#modalDeleteBanner"
                                                        onClick={() => { this.updateBanner(banner) }}
                                                    >Xóa</button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                        {
                            statusButtonLoadPage ?
                                (<div onClick={this.getBannerList} className="view_more w-100 text-center mb-lg-4">
                                    <a className="d-none d-md-block">
                                        <span>{loading ? "ĐANG TẢI..." : "TẢI THÊM"}</span>
                                    </a>
                                </div>) :
                                ('')
                        }
                    </div>
                </div>
            </>
        );
    }
}