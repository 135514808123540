import React, { Component } from 'react';
import {Link} from "react-router-dom";

class TopDuetRecordingsItem extends Component {
	render() {
		const {owner, thumbnailImageUrl, song, viewCounter, onlineRecordingUrl, owner2} = this.props.recording;
		return (
			<div className="col-6 col-lg-4 col-xl-3 item">
				<div className="images">
					<Link to={onlineRecordingUrl}>
						<span className="overlay" />
						<img 
							src={song.thumbnailUrl} 
							className="img-item" 
							alt={song.songName} 
							onError={(e) => {e.target.src = "https://picsum.photos/300/200"}} />
					</Link>
				</div>
				<Link to={onlineRecordingUrl}>
					<h2 className="song-name">
						{song.songName} 
					</h2>
					<div className="singer_names">
						{owner.name} {owner2 != null ? " & " + owner2.name : "" }
					</div>
				</Link>
			</div>
		);
	}
}

export default TopDuetRecordingsItem;