import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';


import bindModel from '../../../common/bindModel.js';

export default class ModalUpdatebannerPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            banner: null,
            previewImg: null,
            loading: false
        }
        this.savebanner = this.savebanner.bind(this);
        this.uploadThumbnailForWasabi = this.uploadThumbnailForWasabi.bind(this);
    }

    componentWillReceiveProps(props) {
        let banner = JSON.parse(JSON.stringify(props.banner));
        this.setState({
            banner : banner
        })
    }

  

    savebanner() {
        let obj = {...this.state.banner};
            obj.deleted = false
        if (obj == null || 
            obj.thumbnail == '' || 
            obj.url == '' || 
            obj.order == '' 
        ){
            toast.error("Vui lòng điền đầy đủ thông tin.");
            return;
        }
console.log("dd"+obj.url);
        this.setState({
            loading: true
        });

        axios.post(global.config.apiDomain + '/rest/bannerSellMicRestful/createBannerSellMic', obj)
        .then(res => {
            this.setState({
                loading: false
            });

            if (res.data.status == "OK") {
                window.$("#modalUpdateBanner").modal("hide");
                this.props.resetList();
                toast.success(res.data.message);
            } else {
                toast.warning(res.data.message);
            }
        }, error => {
            this.setState({
                loading: false
            });
            toast.error(error);
        })
    }
    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    uploadThumbnailForWasabi(event) {

        this.setState({
            loading: true
        });

        var bannerF = this.state.banner;
        var target = event.target;
	    var bucketName = "ikara-data/images/bannerSellMic";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
		var keyName = this.guid() + "." + extension;
		var contentType = "image/jpeg";
		if (extension == "png") contentType = "image/png";
        let file = event.target.files[0];
        console.log(file);

		var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', file);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType);
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                   console.log("data "+data);
                   console.log("bannerF"+bannerF);
                    this.setState({
                        loading: false
                    });

                       bannerF.thumbnail = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        banner : bannerF,
                        previewImg : bannerF.thumbnail
                    });
                }.bind(this),
            });
        }.bind(this);
        fileReader.readAsArrayBuffer(file);
    }

    render() {
        const model = bindModel(this);
        const {banner,loading} = this.state;
        return (
        <div>
            <div className="modal fade" id="modalUpdateBanner" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật Banner Full </h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div id="thumbnail" className="row mb-2">
                                <div className="col-3">
                                    <label>Ảnh nền: </label>
                                </div>
                                <div className={this.state.previewImg? "col-3" : "col-9"}>
                                    <input
                                        className="custom-file-input"
                                        type="file"
                                        name="thumbnail"
                                        onChange= {this.uploadThumbnailForWasabi}
                                    />
                                    <label className="custom-file-label ml-3 mr-3" htmlFor="thumbnail">Choose file</label>
                                </div>
                                {this.state.previewImg ? 
                                <div className="col-6">                                     
                                     <img width={200} src={this.state.previewImg} alt="#" />
                                </div> :<></>}
                            </div>
                            <div id="url" className="row mb-2">
                                <div className="col-3">
                                    <label>URL</label>
                                </div>
                                <div className="col-9">
                                    <input
                                        className="form-control mb-2"
                                        type="text"
                                        name="url"
                                        placeholder="Nhập url..."
                                        {...model('banner.url')}
                                    />
                                </div>
                            </div>
                            <div id="url" className="row mb-2">
                                <div className="col-3">
                                    <label>Độ ưu tiên </label>
                                </div>
                                <div className="col-9">
                                    <input
                                        className="form-control mb-2"
                                        type="number"
                                        name="url"
                                        placeholder="Nhập ưu tiên ..."
                                        {...model('banner.order')}
                                    />
                                </div>
                            </div>
                           
                        </div> 
                        <div className="modal-footer">
                            <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                            {loading ?
                                (
                                    <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Xác nhận...
                                    </button>
                                ) :
                                (
                                    <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.savebanner}>Xác nhận</button>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}