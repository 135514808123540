import React, {Component} from 'react';

export default class ModalShowGiftAnimation extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        const { gift } = this.props;

        return (
            <div className="modal fade" id="modalShowGiftAnimation" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                {gift != null ? (
                                    <img style={{
                                        width: "40vw !important",
                                        height: "40vw !important"
                                    }} src={gift.animatedUrl} />
                                )
                                    : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}