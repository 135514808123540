import React, { Component } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import MessageDetailItem from './MessageDetailItem';

export default class MessageDetailList extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { messageDetails, user, targetUser } = this.props;

        return (
            <>
                {
                    messageDetails.map((messageDetail, index) => (
                        <MessageDetailItem key={index}
                            messageDetail={messageDetail}
                            user={user}
                            targetUser={targetUser}></MessageDetailItem>
                    ))
                }
            </>
        );
    }
}