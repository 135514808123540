import React, {Component} from 'react';
import axios from 'axios';
import bindModel from '../../../../common/bindModel.js';
import { toast } from 'react-toastify';

export default class AddYoutubeMidiPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            param: "https://www.youtube.com/watch?v=VkM8iLSFfd8",
            youtubeId: "",
            loading: false
        }

        this.submit = this.submit.bind(this);
    }

    isValidURL(str) {
        try {
          new URL(str);
          return true;
        } catch (error) {
          return false;
        }
    }

    submit() {
        const {param} = this.state;
        if(param != null && param != "") {
            let youtubeId;
            if(this.isValidURL(param)) {
                const searchParams = new URLSearchParams(param.split('?')[1]);
                if(searchParams.has('v')) {
                    youtubeId = searchParams.get('v');
                }
            } else {
                youtubeId = param
            }

            this.addYoutubeMidi(youtubeId);
        } else {
            toast.warn("Vui lòng nhập thông tin")
        }
    }

    addYoutubeMidi(youtubeId) {
        this.setState({
            loading: true
        });
   
        const url = global.config.apiDomain+'/rest/youtubemidi/add/'+youtubeId;
        axios.post(url).then((res) => {
            const { status, message } = res.data;

            if (status === "OK") {
                toast.success(message);

                this.setState({
                    param: ""
                });
            } else {
                toast.error(message);
            }

            this.setState({
                loading: false
            });
        });
    }


    render() {
        const model = bindModel(this);
        const { loading } = this.state;

        return (
            <div id="content">
                <h2 className='text-center'>Add Beat</h2>
                <div className='row justify-content-center align-items-center' style={{ margin: "130px 0 50px 0" }}>
                    <div className='col-8 col-lg-6'>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="https://www.youtube.com/watch?v=VkM8iLSFfd8 hoặc VkM8iLSFfd8"
                            {...model('param')}
                        />
                    </div>
                    {(loading) ?
                        (
                            <button style={{ width: "120px" }} className="btn btn-primary" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Submit  <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                        ) :
                        (
                            <button style={{ width: "120px" }} type="button" className="btn btn-primary" onClick={this.submit}>Submit  <i className="fa fa-plus" aria-hidden="true"></i></button>
                        )
                    }
                </div>
            </div>
        )
    }
}
