import React, {Component} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';

const $ = window.$;

export default class ModalDeleteImageForAccountManager extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        }
        this.deleteImage = this.deleteImage.bind(this);
    }

    deleteImage() {
        this.setState({
            loading: true
        });

        let json = {
            facebookId : this.props.id,
            firebaseId : this.props.image.id
        }
        console.log("json")
        console.log(json)
        axios.post(global.config.apiDomain + '/rest/admin/deleteImage', JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                loading: false
            });

            $("#modalDeleteImage").modal("hide");

            toast.success('Xóa thành công'); 
            this.props.resetImageList();
        })
    }

    render() {
        return (
            <>
                <div className="modal fade" id="modalDeleteImage" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Xóa hình ảnh</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {this.props.image ? (
                                <div className="modal-body">
                                <h3 className="text-center text-danger">Bạn chắc chắn muốn xóa ?</h3>
                            </div>
                            ) : ""}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ? 
                                (
                                <button style={{width: "120px"}} className="btn btn-red" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Xác nhận...
                                </button>
                                ) :
                                (
                                <button 
                                    style={{width: "120px"}} 
                                    type="button" 
                                    className="btn btn-red"
                                    onClick={this.deleteImage}>Xóa</button>                            
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}