import React, {Component} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';

const $ = window.$;
export default class ModalDeleteBannerFull extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bannerFull: null,
            loading: false
        }

        this.deleteBannerFull = this.deleteBannerFull.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            bannerFull : props.bannerFull
        })
    }

    deleteBannerFull() {
        this.setState({
            loading: true
        });

        axios.post(global.config.apiDomain + '/rest/bannerFulls/deleteBannerFull', JSON.stringify(this.state.bannerFull), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.data.status === 'OK') {
                this.setState({
                    loading: false
                });
    
                $("#modalDeleteBannerFull").modal("hide");
    
                toast.success('Xử lí thành công'); 
                let banner = this.state.bannerFull;
                if (banner.enabled) {
                    banner.enabled = false;
                } else {
                    banner.enabled = true;
                }
                this.props.resetList(banner);
            }
          
        })
    }

    render() {

        const {bannerFull} = this.state;

        return (
            <>
                <div className="modal fade" id="modalDeleteBannerFull" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    {bannerFull && bannerFull.enabled == 1 ? "Ẩn Banner": "Kích hoạt banner"}
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {bannerFull ? (
                                <div className="modal-body">
                                <h3 className="text-center text-danger">
                                {bannerFull.enabled == 1 ? "Bạn chắc chắn muốn ẩn banner": "Bạn chắc chắn muốn ẩn kích hoạt banner"}
                                </h3>
                            </div>
                            ) : ""}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ? 
                                (
                                <button style={{width: "120px"}} className="btn btn-red" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Xác nhận...
                                </button>
                                ) :
                                (
                                <button 
                                    style={{width: "120px"}} 
                                    type="button" 
                                    className="btn btn-red"
                                    onClick={this.deleteBannerFull}>
                                        {bannerFull && bannerFull.enabled == 1 ?"Ẩn":"kích hoạt"}
                                    </button>                            
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}