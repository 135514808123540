import React, { Component } from 'react';
import axios from 'axios';
import './../../assets/css/newDevice.css';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
export default class ManagerDeadAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            originalRecordings: [],
            recordings: [],
            page: 0,
            statusButtonLoadPage: false,
            isLoading: false,
            isInitialLoading: true,
            selectedDate: '',
            uid: null,
            error: null
        };
        this.getAccountList = this.getAccountList.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleUidChange = this.handleUidChange.bind(this);
        this.getAccountByUid = this.getAccountByUid.bind(this);
    }
    componentDidMount() {
        this.getAccountList();
    }
    getAccountList() {
        const { isInitialLoading, selectedDate } = this.state;
        if (!isInitialLoading) {
            this.setState({
                isLoading: true
            });
        }
        
        // Chuyển đổi định dạng YYYY-MM thành MM/YYYY
        const formattedYear = selectedDate ? `${selectedDate.split('-')[1]}/${selectedDate.split('-')[0]}` : ''; 
        
        axios.post(global.config.apiDomain + `/rest/admin/dead-account/${this.state.page}?year=${formattedYear}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            let recordings = res.data;
            let status = true;
            if (recordings.length < 1) {
                status = false;
            }
            this.setState((prevState) => ({
                recordings: prevState.recordings.concat(recordings),
                page: prevState.page + 1,
                statusButtonLoadPage: status,
                isLoading: false,
                isInitialLoading: false,
                error: null
            }));
        })
        .catch(error => {
            this.setState({
                isLoading: false,
                isInitialLoading: false,
                error: error.response.data.message
            });
            console.error("Error:", error);
        });
    }
    
    handleDateChange(event) {
        this.setState({ selectedDate: event.target.value, error: null });
    }
    handleUidChange(event) {
        this.setState({ uid: event.target.value});
    }
    getAccountByUid(uid) {
        axios.post(global.config.apiDomain + '/rest/admin/getDeadAccount', {
            id: uid
        })
        .then(res => {
            let recordings = res.data;
            let status = false;
            this.setState((prevState) => ({
                recordings: prevState.recordings.concat(recordings),
                page: prevState.page + 1,
                statusButtonLoadPage: status,
                isLoading: false,
                isInitialLoading: false,
                error: null
            }));
        })
        .catch(error => {
            this.setState({ recordings: null, error: "Không tìm thấy tài khoản với UID này." });
            console.error("Error fetching account:", error);
        });
    }
    handleSearch() {
        const { selectedDate, uid } = this.state;
        if (uid) {
            // Nếu nhập UID, gọi getAccountByUid và reset selectedDate
            this.setState({ 
                page: 0, 
                recordings: [], 
                isInitialLoading: true, 
                statusButtonLoadPage: false, 
                error: null, 
                selectedDate: ''
            }, () => {
                this.getAccountByUid(uid);
            });
        } else if (selectedDate) {
            this.setState({ 
                page: 0, 
                recordings: [], 
                isInitialLoading: true, 
                statusButtonLoadPage: false, 
                error: null 
            }, () => {
                this.getAccountList();
            });
        } else {
            this.setState({ error: "Vui lòng chọn tháng và năm hợp lệ hoặc nhập UID." });
        }
    }
    
    render() {
        const { recordings, statusButtonLoadPage, isLoading, isInitialLoading, selectedDate, error, uid } = this.state;

        const today = new Date();
        const lastYear = today.getFullYear() - 1;
        const maxDate = `${lastYear}-${("0" + (today.getMonth() + 1)).slice(-2)}`;

        return (
            <>
                <div id="content">
                    <div className="container-fluid">
                        <div className="container-fluid">
                            <h1 className="text-center">Danh sách Account ngừng hoạt động</h1>
                        </div>
                        <div className="search-container">
                            <input
                                type="month"
                                value={selectedDate}
                                onChange={this.handleDateChange}
                                max={maxDate}
                                placeholderText="Chọn tháng/năm"
                                style={{ width: '190px' }}
                            />
                            <input style={{width: '190px'}}
                                type="number"
                                value={uid}
                                onChange={this.handleUidChange}
                                placeholder="Nhập uid"
                            />
                            <button onClick={this.handleSearch} className="btn btn-primary" style={{maxWidth: '120px', minWidth: '90px'}}>Tìm kiếm</button>
                            <div style={{ minWidth: '80px', display: 'flex', justifyContent: 'end', marginRight: '20px', fontWeight: 'bold'}} className="quantity">Số lượng: {recordings.length != null ? recordings.length : 0}</div>
                        </div>
                        {error && (
                            <div className="text-danger">{error}</div>
                        )}
                        {isInitialLoading ? (
                            <div className="spinner-container">
                                <div className="spinner"></div>
                            </div>
                        ) : recordings.length > 0 ? (
                            <table className="table table-hover">
                                <thead>
                                    <tr className="text-center">
                                        <th className="align-middle" scope="col">Last access</th>
                                        <th className="align-middle" scope="col">Uid</th>
                                        <th className="align-middle" scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {recordings.map((item) => {
                                        let lastAccess = new Date(item.lastAccess)
                                        return (
                                            <tr key={item.uid}>
                                                <td className="align-middle text-center" scope="col">{lastAccess.toLocaleString()}</td>
                                                <td className="align-middle text-center" scope="col">{item.uid}</td>
                                                <td className="align-middle text-center" scope="col">
                                                    <button
                                                        className='btn btn-success ml-2 mr-2'
                                                        onClick={() => window.open(`/admin/list-recordings/${item.uid}`, '_blank')}
                                                    >
                                                        Danh sách bài thu
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        ) : (
                            <div className="text-center">
                                <p>Không có Account nào.</p>
                            </div>
                        )}
                        {
                            statusButtonLoadPage ?
                                (<div onClick={this.getAccountList} className="view_more w-100 text-center mb-lg-4">
                                    <a className="d-none d-md-block">
                                        <span>{isLoading ? "ĐANG TẢI..." : "TẢI THÊM"}</span>
                                    </a>
                                </div>) :
                                ('')
                        }
                    </div>
                </div>
            </>
        );
    }
}