import React, { Component } from 'react';
import '../../assets/css/ManagerNewDeviceAndUser.css';
import ListMvToDay from './components/ListMvToDay';
import ListHotMV from './components/ListHotMv';

export default class ManagerHotMv extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showListMvToDay: true,
            showListHotMV: false,
        };

        this.handleToggleView = this.handleToggleView.bind(this);
    }

    handleToggleView() {
        this.setState(prevState => ({
            showListMvToDay: !prevState.showListMvToDay,
            showListHotMV: !prevState.showListHotMV,
        }));
    }

    render() {
        const { showListMvToDay, showListHotMV } = this.state;
        const buttonClass = showListHotMV ? 'btn-toggle-view active' : 'btn-toggle-view';
    
        return (
            <>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lý MV</h1>
                        <button 
                            className={buttonClass}
                            onClick={this.handleToggleView}
                        >
                            Xem danh sách Ghim
                        </button>
                    </div>
                    {showListMvToDay && <ListMvToDay />}
                    {showListHotMV && <ListHotMV />}
                </div>
            </>
        );
    }
}
