import React, { Component } from 'react';
import axios from 'axios';

export default class ModalSetCup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            gifts: []
        }

        this.getGifts = this.getGifts.bind(this);
        this.setData = this.setData.bind(this);
    }

    componentWillMount() {
        this.getGifts();
    }

    getGifts() {
        axios.post(global.config.apiDomain + '/rest/gifts')
            .then(res => {
                this.setState({
                    gifts: res.data,
                });
            });
    }

    setData(event) {
        let { model, property } = this.props;
        let index = event.target.name;
        let gift = this.state.gifts[index];
        model[property] = {
            ...model[property],
            keyCup: gift.id,
            cupUrl: gift.url
        };
        this.props.update(model);

        window.$("#modalSetCup").modal("hide");
    }

    render() {
        const { gifts } = this.state;

        return (
            <div>
                <div className="modal fade" id="modalSetCup" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Chọn CUP</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <table className="table table-hover">
                                <thead>
                                    <tr className="align-middle">
                                        <th className="align-middle" scope="col">ID</th>
                                        <th className="align-middle" scope="col">Name</th>
                                        <th className="align-middle" scope="col">Image</th>
                                        <th className="align-middle" scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {gifts && gifts.map((gift, index) => (
                                        <tr key={gift.id}>
                                            <th scope="row">{gift.id}</th>
                                            <td>{gift.name}</td>
                                            <td> <img className="gift-image" src={gift.url}></img>  </td>
                                            <td>
                                                <button
                                                    name={index}
                                                    onClick={this.setData}
                                                    className="btn btn-warning"
                                                >Chọn</button>
                                            </td>
                                        </tr>
                                    )
                                    )}
                                </tbody>
                            </table>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.saveGift}>Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}