import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';

import ModalUpdateCoupleDecoration from './components/ModalUpdateCoupleDecoration';
import ModalDeleteCoupleDecoration from './components/ModalDeleteCoupleDecoration';

class CouplePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            decorationList: [],
            decorationModel: null,
        };

        this.getAllCoupleDecoration = this.getAllCoupleDecoration.bind(this);
        this.createOrUpdateCoupleDecoration = this.createOrUpdateCoupleDecoration.bind(this);
        this.deleteOrActiveCoupleDecoration = this.deleteOrActiveCoupleDecoration.bind(this);
    }

    componentDidMount() {
        this.getAllCoupleDecoration();
    }

    getAllCoupleDecoration() {
        axios.post(global.config.apiDomain + '/rest/couple/getAllCoupleDecoration')
            .then(res => {
                if (res.data.status === 'OK') {
                    this.setState({
                        decorationList: res.data.decorationList
                    })
                }
            })
    }

    createOrUpdateCoupleDecoration(event) {
        let index = event.target.name;
        let decorationModel = null;
        if (index === "-1") {
            decorationModel = {
                _id: 0,
                castleName: '',
                castleUrl: '',
                backgroundColorCode: '',
                frameColorCode: '',
                lightColorCode: '',
                sweetHeartUrl: '',
            }
        } else {
            decorationModel = this.state.decorationList[index];
        }

        this.setState({
            decorationModel: decorationModel
        })

    }

    deleteOrActiveCoupleDecoration(event) {
        let index = event.target.name;
        let decorationModel = this.state.decorationList[index];

        this.setState({
            decorationModel: decorationModel
        })
    }

    render() {
        const { decorationList, decorationModel } = this.state;
        return (
            <>
                <ModalUpdateCoupleDecoration
                    decorationModel={decorationModel}
                    resetList={this.getAllCoupleDecoration}
                >
                </ModalUpdateCoupleDecoration>
                <ModalDeleteCoupleDecoration
                    decorationModel={decorationModel}
                    resetList={this.getAllCoupleDecoration}
                >
                </ModalDeleteCoupleDecoration>
                <div id="content">
                    <div className="container">
                        <h1 className="text-center">Quản lí Trang trí Cặp đôi Theo Level</h1>
                        <button
                            className="btn btn-red mb-1"
                            data-toggle="modal"
                            data-target="#modalUpdateCoupleDecoration"
                            name="-1"
                            onClick={this.createOrUpdateCoupleDecoration}
                        >+ Tạo Mới Trang trí
                        </button>
                        <table className="table table-hover">
                            <thead>
                                <tr className="text-center">
                                    <th className="align-middle" scope="col">Level</th>
                                    <th className="align-middle" scope="col">Tên lâu đài</th>
                                    <th className="align-middle" scope="col">Ảnh lâu đài</th>
                                    <th className="align-middle" scope="col">Trái tim</th>
                                    <th className="align-middle" scope="col">File Json</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    decorationList && decorationList.map((item, index) => {
                                        let fileName = item.sweetHeartJson != null ? item.sweetHeartJson.substring(item.sweetHeartJson.lastIndexOf("/"),item.sweetHeartJson.length) : "";
                                        return (
                                            <tr>
                                                <td className='align-middle text-center'>{item.deleted ? <del>{item._id}</del> : <strong>{item._id}</strong>}</td>
                                                <td className='align-middle'>{item.deleted ? <del>{item.castleName}</del> : item.castleName}</td>
                                                <td align="center" style={{ height: '200px', width: '400px', backgroundColor: item.backgroundColorCode }}>
                                                    <img
                                                        src={item.castleUrl}
                                                        style={{ width: '160px' }}
                                                        alt={item.castleName}
                                                    />
                                                </td>
                                                <td className='align-middle text-center'>
                                                    {item.sweetHeartUrl != null && item.sweetHeartUrl.endsWith(".json") ? "Trái tim" :<img
                                                        src={item.sweetHeartUrl}
                                                        style={{ width: '90px' }}
                                                        alt={item.castleName}
                                                    />}                                               
                                                </td>
                                                <td className='align-middle text-center'>
                                                    {item.sweetHeartJson != null && <a href={item.sweetHeartJson}>{fileName}</a>}                                              
                                                </td>
                                                <td className='align-middle'>
                                                    {
                                                        item.deleted ? (
                                                            <button
                                                                className="btn btn-info"
                                                                onClick={this.deleteOrActiveCoupleDecoration}
                                                                name={index}
                                                                data-toggle="modal"
                                                                data-target="#modalDeleteCoupleDecoration"
                                                            >
                                                                Mở lại
                                                            </button>
                                                        ) : (
                                                            <>
                                                                <button
                                                                    className="btn btn-success"
                                                                    onClick={this.createOrUpdateCoupleDecoration}
                                                                    name={index}
                                                                    data-toggle="modal"
                                                                    data-target="#modalUpdateCoupleDecoration"
                                                                >
                                                                    Sửa
                                                                </button>&emsp;
                                                                <button
                                                                    className="btn btn-danger"
                                                                    onClick={this.deleteOrActiveCoupleDecoration}
                                                                    name={index}
                                                                    data-toggle="modal"
                                                                    data-target="#modalDeleteCoupleDecoration"
                                                                >
                                                                    Xóa
                                                                </button>
                                                            </>
                                                        )
                                                    }

                                                </td>
                                            </tr>
                                        )
                                    })

                                }

                            </tbody>
                        </table>
                    </div>
                </div>

            </>
        );
    }
}

export default CouplePage
