import React, { Component } from 'react';
import axios from 'axios';
import { param } from 'jquery';
import { toast } from 'react-toastify';

export default class ModalCommission extends Component {
    constructor(props) {
        super(props);
        this.state = {
            commissionsByMonth: null
        }
        this.getAllCommissionOfSeller = this.getAllCommissionOfSeller.bind(this);
    }

    getAllCommissionOfSeller() {
        let account = this.props.account;
        if (account) {
            var json = {
                id: account.id,
            }
            axios.post(global.config.apiDomain + '/rest/admin/getListCommission', json, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                const commissionsByMonth = {};
                res.data.models.forEach(commission => {
                    if (!commissionsByMonth[commission.dateView]) {
                        commissionsByMonth[commission.dateView] = [commission];
                    } else {
                        commissionsByMonth[commission.dateView].push(commission);
                    }
                });
                this.setState({ commissionsByMonth });
            })
            .catch(error => {
                console.error('Error fetching commissions:', error);
            });
        }
    }

    componentDidUpdate() {
        const { commissionsByMonth } = this.state;
        if (commissionsByMonth == undefined || commissionsByMonth == null) {
            this.getAllCommissionOfSeller();
        }
    }

    render() {
        const { commissionsByMonth } = this.state;
        if (!commissionsByMonth) {
            return <div></div>;
        }

        const monthlyCommissionRows = Object.keys(commissionsByMonth).map(dateViewTimestamp => {
            const dateView = new Date(parseInt(dateViewTimestamp));
            const month = dateView.getMonth()+1;
            const year = dateView.getFullYear();
            const formattedDate = `${month}/${year}`;
            
            const commissions = commissionsByMonth[dateViewTimestamp];
            const totalCommission = commissions.reduce((total, commission) => total + commission.commission, 0);
            
            return (
                <React.Fragment key={dateViewTimestamp}>
                    <tr style={{backgroundColor: '#FFFF66'}}>
                        <td colSpan="1"></td>
                        <td colSpan="3">Tháng {formattedDate}</td>
                        <td colSpan="1">Tổng hoa hồng: {totalCommission}</td>
                    </tr>
                    {commissions.map((commission, index) => {
                        const addTime = new Date(commission.addTime);
                        return (
                            <tr key={index}>
                                <td>{commission.id}</td>
                                <td>{addTime.toLocaleString()}</td>
                                <td>{commission.money}</td>
                                <td>{commission.iCoin}</td>
                                <td>{commission.commission}</td>
                            </tr>
                        );
                    })}
                </React.Fragment>
            );
        });
    
        return (
            <div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Id</th>
                            <th scope="col">Thời gian nạp</th>
                            <th scope="col">Số tiền nạp</th>
                            <th scope="col">Icoin thực tế (Không tính bonus)</th>
                            <th scope="col">Hoa hồng được hưởng</th>
                        </tr>
                    </thead>
                    <tbody>
                        {monthlyCommissionRows}
                    </tbody>
                </table>
            </div>
        )
    }
}
