import React, {Component} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';

const $ = window.$;
export default class ModalUnbanAcc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }

        this.unbanAcc = this.unbanAcc.bind(this);
    }

    unbanAcc() {
        this.setState({
            loading: true
        })
        let json = {
            id: this.props.account.id,
        }
        axios.post(global.config.apiDomain + '/rest/admin/unban', JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            
            this.setState({
                loading: false
            })
            if(res.data.status == "OK") {
                $('#modalUnbanAcc').modal('hide');
                this.props.resetAccount();
                toast.success(res.data.message);
            } else {
                toast.warning(res.data.message);
            }
        }, err => {
            this.setState({
                loading: false
            })
            toast.error("Khóa tài khoản thất bại");
        })

    }


    render() {
        return (
            <div className="modal fade" id="modalUnbanAcc" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog  modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel"> {this.props.account.banType === "banSpeech" ? ("Mở cấm phát ngôn") : ("Mở khóa tài khoản")}    </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {this.props.account? 
                    (
                        <>
                        <p className="font-weight-bold">Ngày mở khóa: {new Date(this.props.account.bannedExpired).toLocaleString()}</p>
                        <p className="font-weight-bold">Lí do: {this.props.account.reason}</p>                   
                        <h4 className="text-center">Vui lòng <span  className="font-weight-bold" >xác nhận</span> để cấm!</h4>
                        </>
                    ) : (
                        ""
                    )
                    }
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                    {this.state.loading ? 
                    (
                    <button style={{width: "120px"}} className="btn btn-red" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Xác nhận...
                    </button>
                    ) :
                    (
                    <button style={{width: "120px"}} type="button" className="btn btn-red" onClick={this.unbanAcc}>Xác nhận</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
    }
}