import React, { Component } from 'react';
import axios from 'axios';

import ModalUpdateNotification from './components/ModalUpdateNotification.js';

export default class PushNotificationPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notifications: [],
            notification: null
        }

        this.getNewNotification = this.getNewNotification.bind(this);
        this.updateNotification = this.updateNotification.bind(this);
        this.getNotificationList = this.getNotificationList.bind(this);

    }

    componentWillMount() {
        this.getNotificationList();
    }

    getNotificationList() {
        axios.post(global.config.apiDomain + '/rest/notification')
            .then(res => {
                console.log(res.data)
                let notifications = [];

                this.setState({
                    notifications: notifications
                })
            });
    }

    getNewNotification() {
        axios.post(global.config.apiDomain + '/rest/notification/new', JSON.stringify(''), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                notification: res.data
            })
        })
    }

    updateNotification(notification) {
        this.setState({
            notification: notification
        })
    }

    render() {
        const { notifications, notification } = this.state;
        return (
            <>
                <ModalUpdateNotification notification={notification}></ModalUpdateNotification>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí notification</h1>
                        <button
                            className="btn btn-red mb-1"
                            data-toggle="modal"
                            data-target="#modalUpdateNotification"
                            onClick={this.getNewNotification}
                        >+ Tạo Notification
                        </button>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th width={200} scope="col">Id</th>
                                    <th scope="col">Title</th>
                                    <th scope="col">Link</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {notifications.map((notification, index) => {
                                    return (
                                        <tr key={notification.id}>
                                            <th scope="row">{notification.id}</th>
                                            <td>{notification.title}</td>
                                            <td> {notification.url == null ? ("") : (notification.url.length > 100 ? notification.url.substring(0, 100) + "..." : notification.url)} </td>
                                            <td>
                                                <button
                                                    onClick={() => { this.updateNotification(notification) }}
                                                    className="btn btn-red"
                                                    data-toggle="modal"
                                                    data-target="#modalUpdateBanner"
                                                >Sửa</button>
                                                <button
                                                    className="btn btn-red ml-sm-2"
                                                    data-toggle="modal"
                                                    data-target="#modalDeleteBanner"
                                                    onClick={() => { this.updateNotification(notification) }}
                                                >Xóa</button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    }
}