import React, { Component } from 'react';
import MyContext from '../../../common/MyContext.js';
import CopyToClipboard from 'react-copy-to-clipboard';

export default class ModalBuyIconForBanking extends Component {
    render() {
        const {user} = this.context;
        var id = "XXXXXX";
        if(user != null) {
            id = user.uid;
        }

        return (
            <div>
                <div className="modal fade" id="modalBuyIconForBanking" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Banking</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body bg-light">
                                <div className="border rounded p-2 bg-white">
                                    <p className="font-weight-bold">Bưới 1/2: Chuyển khoản trực tiếp</p>
                                    <p>Chuyển khoản số tiền tương ứng với số iCoin cần mua tới tài khoản sau: </p>
                                    <p>Ngân hàng Vietcombank - chi nhánh Thăng Long, Hà Nội</p>
                                    <p><span>Tên chủ tài khoản:</span> <span className="float-right mr-5">Hồ Hồng Anh </span></p>
                                    <p><span>Số tài khoản:</span> 
                                    <span className="float-right">
                                    0491000096406
                                    <CopyToClipboard
                                        text="0491000096406">
                                        <button className="btn btn-secondary ml-2"><i className="fa fa-copy"></i></button>
                                    </CopyToClipboard>
                                    </span>
                                    </p>
                                    <p><span>Nội dung chuyển khoản:</span> 
                                    <span className="float-right">ID {id}
                                    <CopyToClipboard
                                        text={"ID " + id}>
                                        <button className="btn btn-secondary ml-2"><i className="fa fa-copy"></i></button>
                                    </CopyToClipboard></span></p>
                                </div>
                                <div className="border rounded p-2 bg-white mt-3">
                                    <p className="font-weight-bold">Bưới 2/2: Nhận iCoin</p>
                                    <p className="">Bạn sẽ nhận được iCoin ngay khi tài khoản của iKara nhận được tiền.</p>
                                    <p>
                                    Nếu cần hỗ trợ thêm về giao dịch hãy liên hệ đến &nbsp;
                                    <a href="https://www.facebook.com/H%E1%BB%97-tr%E1%BB%A3-thanh-to%C3%A1n-Ikara-Yokara-105079051501582">IKARA YOKARA</a></p>
                                </div>
                                <p className="text-danger mt-2 m-0">Mỗi 1000đ bạn chuyển sẽ nhận được 14 iCoin</p>


                            </div>
                            {/* <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                <button type="button" className="btn btn-red">Save changes</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ModalBuyIconForBanking.contextType = MyContext;