import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import $ from 'jquery';
import styles from "./css/downloadpage.css";
import { Helmet } from 'react-helmet';
import { detectApp } from '../../common/utils.js';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Base64 } from 'js-base64'
class DownloadPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            link: "https://play.google.com/store/apps/details?id=co.ikara.v2",
            changeLink: false,
        }
    }

    componentDidMount() {
        $("#footer").hide();
        this.handleComeback();
        this.checkLink();
    }

    componentWillUnmount() {
        $("#footer").show();
    }

    newGetKey(data, password, password2) {
        var listOfNumbers = "" + password2;
        for (var i = 0 ; i < data.length; i++) {
            if (data.charAt(i) >= '0' && data.charAt(i) <= '9'){
                listOfNumbers = [listOfNumbers.slice(0, 0), data.charAt(i), listOfNumbers.slice(0)].join('');
            }
        }

        var originalKey = null;
      if (listOfNumbers.length > 15) 
        originalKey = listOfNumbers.substring(0,15);
      else
        originalKey = listOfNumbers;
      return Number(originalKey) + Number(password);
    }

    handleComeback() {
        const urlParams = new URLSearchParams(window.location.search);
        const type = urlParams.get('type');
        const uid = urlParams.get('uid');
    
        if (type && uid) {
            axios.get(`${global.config.apiDomain}/rest/user/comeback`, {
                params: { type, uid }
            })
            .then(response => {
                if (response.data.status === 'OK') {
                    const deeplink = response.data.data.deeplink;
                    if (deeplink) {
                        // Chuyển hướng tới deeplink được trả về từ API
                        window.location.href = deeplink;
                    }
                } else {
                    console.error('Có lỗi xảy ra khi ghi nhận comeback');
                }
            })
            .catch(error => {
                console.error('Lỗi khi gọi API comeback:', error);
            });
        }
    }

    checkLink() {
        let json = {
          url: this.state.link
        };
        console.log("check url: ", this.state.link);
        const jsonBase64 = Base64.encode(JSON.stringify(json));
        var pass = this.newGetKey(jsonBase64, 7123358024, 3564);
        var keys = jsonBase64 + "-" + pass;
        var url = global.config.apiDomain + "/web.v5.getUrlStatus?parameters=" + keys;
        axios.post(url, json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.data.status == "OK") {
                this.setState({
                    changeLink: false,
                })
            } else {
                this.setState({
                    changeLink: true,
                })
            }
            console.log(res);
        }, err => {
            toast.error("Đã có lỗi xảy ra, vui lòng thử lại!");
        });
    }

    render() {

        const { t } = this.props;

        return (
            // <div id="downloadpage" style={{padding: "20px"}}>
            //     <div id="content">
            //         <div className="container ">
            //             <div className="text-center text-white">
            //                 <h3 style={{fontSize: "35px"}}><strong>{t("banner.title", "IKARA - THỔI BÙNG ĐAM MÊ CA HÁT")}</strong></h3>
            //                 <p style={{fontSize: "20px"}}>{t("banner.description", "Tham gia IKARA ngay để cùng tạo nên những bản nhạc, khoảnh khắc ý nghĩa")}</p>
            //             </div>
            //             <div className="row">
            //                 <div className="col-6 col-lg-4 p-md-5 p-2 d-none d-sm-block">
            //                     <img src={require("../../assets/images/item-thumbnail3.png")} />
            //                 </div>
            //                 <div className="col-12 col-sm-6 col-lg-5 d-flex align-items-center">
            //                     <div className="w-100 text-center">
            //                         <div className="p-3">
            //                             <a target="_blank" href={t("appStoreUrl", "https://apps.apple.com/app/id711994026")}>
            //                             <img className="download-icon" src={require("../../assets/images/downloadappstore.png")} />
            //                             </a>
            //                         </div>
            //                         <div className="p-3">
            //                             <a target="_blank" href={t("chPlayUrl", "https://play.google.com/store/apps/details?id=co.ikara.v2")}>
            //                             <img className="download-icon" src={require("../../assets/images/downloadchplay.png")} />
            //                             </a>
            //                         </div>
            //                         <div className="d-block p-3 d-lg-none">
            //                             <img width={200} src={require("../../assets/images/qr-android.png")} />
            //                         </div>
            //                     </div>
            //                 </div>
            //                 <div className="col-3 d-none d-lg-flex align-items-center">
            //                     <div className="p-3">
            //                         <img width={200} src={require("../../assets/images/qr-android.png")} />
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>

            //     </div>
            // </div>

            <div id={`content`} className={styles.downloadPage}>
                <Helmet>
					<title> {t("download_page.download", "Tải về")} </title>
				</Helmet>
                <div className="container">
                    <div className={styles.rhythmItem}>
                        <img src={require("../../assets/images/download_page_rhythm_item.png")} />
                    </div>
                    <div className="row align-items-center">
                        <div className="col-sm-5 d-sm-block d-none"> 
                            <div className={styles.phoneItem}>
                                <img className={styles.phoneItemImg} src={require("../../assets/images/" + t('download_page.phone_icon', "download_page_phone_item_ikara.png"))} />
                            </div>
                        </div>
                        <div className="col-12 col-sm-7 text-center p-lg-5 pt-5">
                            <div className="logo d-sm-block d-none">
                                <img src={require("../../assets/images/" + t('download_page.logo', "yokara-logo-footer.png"))} />
                            </div>
                            <div className="mt-3">
                                <h2 className="text-white"><strong>{t("download_page.title", "IKARA - THỔI BÙNG ĐAM MÊ CA HÁT")}</strong></h2>
                            </div>

                            <div className="mt-3">
                                <h3 className="text-white"><strong>{t("download_page.download_free","⇛ TẢI MIỄN PHÍ ⇚")}</strong></h3>
                            </div>

                            <div className="d-flex justify-content-center">
                                <div className="col-6">
                                    <div className={styles.buttonDownload}>
                                        {this.state.changeLink ?  
                                         <a target="_blank" href={"https://data4.ikara.co/data/minio/ikara-data/android-apk/yokara_10.19.9.apk"}>
                                         <img src={require("../../assets/images/downloadchplay.png")} />
                                         </a>
                                        : <a target="_blank" href={t("download_page.chplay_url", "https://play.google.com/store/apps/details?id=co.ikara.v2")}>
                                        <img src={require("../../assets/images/downloadchplay.png")} />
                                        </a>}
    
                                    </div>
                                    <div className="mt-3">
                                        <img width={200} src={require("../../assets/images/" + t('download_page.chplay_qr', "qr_chplay_ikara.svg"))} />
                                    </div>
                                </div>
                                {(!detectApp("tl.okara") && !detectApp("mz.okara") && !detectApp("ht.okara")) && (
                                    <div className="col-6">
                                    <div className={styles.buttonDownload}>
                                        <a target="_blank" href={t("download_page.appstore_url", "https://apps.apple.com/app/id711994026")}>
                                        <img src={require("../../assets/images/downloadappstore.png")} />
                                        </a>
                                    </div>
                                    <div className="mt-3">
                                        <img width={200} src={require("../../assets/images/" + t('download_page.appstore_qr', "qr_appstore_ikara.svg"))} />
                                    </div>
                                </div>
                                )}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default withTranslation()(DownloadPage)