import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const RoomListModal = ({ show, onHide, roomList }) => (
    <Modal show={show} onHide={onHide} centered>
        <div className="modal-header">
            <h5 className="modal-title">Danh sách phòng</h5>
            <button type="button" className="close" data-dismiss="modal" onClick={onHide}>
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <Modal.Body>
            <ul>
                {roomList.map((room, index) => (
                    <li key={index}>
                        <td>{room.uid}</td>
                        <td>- {room.name}</td>
                    </li>
                ))}
            </ul>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>Đóng</Button>
        </Modal.Footer>
    </Modal>
);

export default RoomListModal;