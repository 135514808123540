import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import SVGA from 'svgaplayerweb'
import $ from 'jquery';
import bindModel from '../../../common/bindModel';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';


export default class HashtagCreate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            model: null,
            loading: false,
        }
        this.saveHashtag = this.saveHashtag.bind(this);
    }
    componentWillReceiveProps(props) {
        const { model } = props;

        if (model != null) {
            this.setState({
                model: model,
            })
        }
    }


    saveHashtag() {
        let obj = { ...this.state.model };
        if (
            !obj.id
        ) {
            toast.warning("Nhập ID hashtag");
            return
        }
        this.setState({
            loading: true
        });
        axios.post(global.config.apiDomain + "/rest/hashtag/createOrUpdate", obj, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                console.log(res);

                this.setState({
                    loading: false
                });
                if (res.data && res.data.status == "OK") {
                    window.$("#modalCreate").modal("hide");
                    toast.success(res.data.message);
                    this.props.resetList();
                } else {
                    toast.error(res.data.message)
                }
            })
    }

    render() {
        const bind = bindModel(this);
        const { model } = this.state;
        return (
            <div>
                <div className="modal fade" id="modalCreate" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">{model && model.id ? "Cập nhật Hashtag" : "Thêm mới Hashtag"}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div id="id" className="row mb-2">
                                    <div className="col-3">
                                        <label>ID hashtag</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="id"
                                            {...bind('model.id')}
                                            placeholder="Nhập tên hashtag..."
                                        />
                                    </div>
                                </div>
                                <div id="description" className="row mb-2">
                                    <div className="col-3">
                                        <label>Mô tả</label>
                                    </div>
                                    <div className="col-9">
                                        <textarea class="form-control mb-2" type="text"
                                            {...bind('model.description')}
                                            placeholder="Nhập mô tả...">
                                        </textarea>
                                    </div>
                                </div>  

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red"
                                            onClick={this.saveHashtag}
                                        >Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}