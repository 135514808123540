import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import MyContext from '../common/MyContext'
import { withTranslation } from 'react-i18next';
import { setCookie } from '../common/cookie.js';
import { detectApp } from '../common/utils.js';
import bindModel from '../common/bindModel.js';
import { toast } from 'react-toastify';
const $ = window.$;
class PhoneVetificationDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            phone: "",
            loading: false
        }

        this.sendOTP = this.sendOTP.bind(this);
    }

    sendOTP() {

        this.setState({
            loading: true
        })

        let { userTemp } = this.context;
        const { phone } = this.state;
        const json = {
            facebookId: userTemp ? userTemp.facebookId : null,
            domain: window.location.hostname,
            phone: phone
        }


        axios.post(global.config.apiDomain + '/rest/okara/phoneVetification', JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                loading: false
            });
            if (res.data.status == "OK") {

                $("#modalPhoneVetification").modal("hide");
                $("#modalConfirmPhoneVetification").modal("show");
                if (userTemp) {
                    userTemp.phoneNumber = phone;
                } else {
                    userTemp = {
                        phoneNumber: phone
                    }
                }

                this.context.updateUserTemp(userTemp);
            } else {
                toast.warning(res.data.message);
            }
        });
    }

    render() {
        const model = bindModel(this);
        const { t } = this.props;
        return (
            <div className="modal fade" id="modalPhoneVetification" data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button type="button" className="close d-flex justify-content-end mr-2" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                        <div className="modal-body">
                            <div className='p-5'>
                                <div className='text-center'>
                                    <img src={require('../assets/images/phone_vetification.svg')} />
                                </div>
                                <div className='text-center mt-3'>
                                    <p><strong>{t("phone_vetification_dialog.title_1", "Xác thực số điện thoại")}</strong></p>
                                    <p>{t("phone_vetification_dialog.title_2", "Nhập số điện thoại của bạn để nhận mã xác thực")}</p>
                                </div>
                                <div className=''>
                                    <input {...model('phone')} className='form-control' placeholder={t("phone_vetification_dialog.input_phone", "Nhập Số điện thoại")} />
                                </div>


                                {this.state.loading ?
                                    (
                                        <div className='text-center mt-3'>
                                            <button className="btn btn-red w-100" type="button" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                {t("phone_vetification_dialog.send_otp", "Xác nhận")}...
                                            </button>
                                        </div>
                                    ) :
                                    (
                                        <div className='text-center mt-3'>
                                            <button onClick={this.sendOTP} type="button" className="btn btn-red w-100">{t("phone_vetification_dialog.send_otp", "Gửi OTP")}</button>
                                        </div>
                                    )}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

PhoneVetificationDialog.contextType = MyContext;
export default withTranslation()(PhoneVetificationDialog)