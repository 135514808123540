import React, { Component } from 'react';
import { Parser, Player } from 'svga'
import SVGA from 'svgaplayerweb'

import $ from 'jquery';

export default class ModalShowSVGA extends Component {
    constructor(props) {
        super(props);

        this.state = {
            player: null
        }
    }

    loadSVGA2(url) {
        console.log(url);
        var player = new SVGA.Player('#demoCanvas');
        var parser = new SVGA.Parser('#demoCanvas'); // Must Provide same selector eg:#demoCanvas IF support IE6+
        parser.load(url, function (videoItem) {
            console.log(videoItem)
            player.setVideoItem(videoItem);
            player.startAnimation();
        })
    }
    
    componentWillReceiveProps(nextProps) {
        if (nextProps.decorationItem != null) {
            this.loadSVGA2(nextProps.decorationItem.animatedUrl);
        }
    }

    render() {
        console.log("render")
        const { decorationItem } = this.props;

        return (
            <div className="modal fade" id="modalShowDecorationItemType" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="d-flex justify-content-center">
                                <div id="demoCanvas" style={{
                                    width: "40vw",
                                    height: "40vw"
                                }} ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}