import React, { Component } from 'react';
import axios from 'axios';
import '../../../assets/css/newDevice.css';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

export default class ListRecordingOfAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            originalRecordings: [],
            recordings: [],
            isLoading: false,
            selectedRecordingType: "",
            selectedPrivacyLevel: ""
        };

        this.listRecording = this.listRecording.bind(this);
        this.handleRecordingTypeChange = this.handleRecordingTypeChange.bind(this);
        this.handlePrivacyLevelChange = this.handlePrivacyLevelChange.bind(this);
    }

    componentDidMount() {
        this.listRecording();
    }

    listRecording() {
        this.setState({ isLoading: true });
        const { match } = this.props;
        const uid = match.params.uid;
        axios.post(global.config.apiDomain + `/rest/recording/list-recording-of-account/${uid}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            const models = res.data.models || [];
            this.setState({ 
                originalRecordings: models,
                recordings: models,
                isLoading: false
             });
        })
        .catch(error => {
            console.error('Error fetching recordings:', error);
            this.setState({ isLoading: false });
        });
    }

    handleRecordingTypeChange(event) {
        this.setState({ selectedRecordingType: event.target.value });
    }

    handlePrivacyLevelChange(event) {
        this.setState({ selectedPrivacyLevel: event.target.value });
    }

    render() {
        const { recordings, isLoading, selectedRecordingType, selectedPrivacyLevel } = this.state;

        const filteredRecordings = recordings.filter(item => {
            if (selectedRecordingType && item.recordingType !== selectedRecordingType) {
                return false;
            }
            if (selectedPrivacyLevel && item.privacyLevel !== selectedPrivacyLevel) {
                return false;
            }
            return true;
        });

        return (
            <>
                <div id="content">
                    <div className="container-fluid">
                        <div className="container-fluid">
                            <h1 className="text-center">Danh sách bài thu</h1>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'end', marginTop: '-55px', marginBottom: '20px' }}>
                            <Link to="/admin/dead-account-manager" className="btn btn-primary">Trở về</Link>
                        </div>
                        <div className="search-container">
                            <select value={selectedRecordingType} onChange={this.handleRecordingTypeChange}>
                                <option value="">Loại bài thu</option>
                                <option value="AUDIO">Audio</option>
                                <option value="VIDEO">Video</option>
                            </select>
                            <select value={selectedPrivacyLevel} onChange={this.handlePrivacyLevelChange}>
                                <option value="">Quyền riêng tư</option>
                                <option value="PUBLIC">Public</option>
                                <option value="PRIVATE">Private</option>
                            </select>
                            <div style={{ minWidth: '80px' }} className="quantity">Số lượng: {filteredRecordings.length}</div>
                        </div>
                        {isLoading ? (
                            <div className="spinner-container">
                                <div className="spinner"></div>
                            </div>
                        ) : filteredRecordings.length > 0 ? (
                            <table className="table table-hover">
                                <thead>
                                    <tr className="text-center">
                                        <th className="align-middle" scope="col">Ngày thêm</th>
                                        <th className="align-middle" scope="col">Id bài thu</th>
                                        <th className="align-middle" scope="col">Tên bài hát</th>
                                        <th className="align-middle" scope="col">Link nhạc</th>
                                        <th className="align-middle" scope="col">Link video</th>
                                        <th className="align-middle" scope="col">Uid User</th>
                                        <th className="align-middle" scope="col">Loại bài thu</th>
                                        <th className="align-middle" scope="col">Quyền riêng tư</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredRecordings.map((item) => {
                                        let addTime2 = new Date(item.addTime2)
                                        return (
                                            <tr key={item.uid}>
                                                <td className="align-middle text-center" scope="col">{addTime2.toLocaleString()}</td>
                                                <td className="align-middle text-center" scope="col">{item._id}</td>
                                                <td className="align-middle text-center" scope="col">{item.songName}</td>
                                                <td className="align-middle text-center" scope="col">
                                                    <a href={item.vocalUrl} target="_blank" rel="noopener noreferrer">Nghe nhạc</a>
                                                </td>
                                                <td className="align-middle text-center" scope="col">
                                                    <a href={item.mixedVideoUrl} target="_blank" rel="noopener noreferrer">Xem video</a>
                                                </td>
                                                <td className="align-middle text-center" scope="col">{item.uidUser}</td>
                                                <td className="align-middle text-center" scope="col">{item.recordingType}</td>
                                                <td className="align-middle text-center" scope="col">{item.privacyLevel}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        ) : (
                            <div className="text-center">
                                <p>Không có Mv nào.</p>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    }
}
