import React, { Component } from 'react';
import Firebase from '../../../components/Firebase.js';
import LoadMoreComments from './LoadMoreComments.js';
import CommentAndGiftCommentLine from './CommentAndGiftCommentLine.js';
import MyContext from '../../../common/MyContext.js';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { withTranslation } from 'react-i18next';


const $ = window.$;

class CommentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFirst: true,
            comments: [],
            totalComment: 0,
            loadMore: true,
            globalLastOne: null,
            toggleComment: false,
            toggleCommentId: 0,
            startIndex: 0
        };
        this.ikaraNode = Firebase.database().ref("ikara");
        this.loadComments = this.loadComments.bind(this);
        this.addComment = this.addComment.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            isFirst: true,
            comments: [],
            totalComment: 0,
            loadMore: true,
            globalLastOne: null,
            toggleComment: false,
            toggleCommentId: 0,
            startIndex: 0
        });
    }

    addComment(param) {
        var { recording } = this.props;
        var self = this;

        if (param) {
            this.ikaraNode.child('recordingComments').child(recording._id).child(param).get().then(snapshot => {
                var childs = snapshot.val().childs;
                var newChilds = [];
                for (let i in childs) {
                    childs[i].key = i;
                    newChilds.push(childs[i]);
                }

                for (let i = 0; i < self.state.comments.length; i++) {
                    if (self.state.comments[i].key == param) {
                        self.state.comments[i].childs = newChilds;
                    }
                }

                self.setState({
                    comments: self.state.comments
                });
            });
        } else {
            function loadSnapshot(snapshot) {
                snapshot.forEach(function (commentSnapshot) {
                    try {
                        var comment = commentSnapshot.val();
                        comment.userProfile = comment.userProfile.replace("http://", window.protocol + '//');
                        comment.key = commentSnapshot.key;
                        self.state.comments.unshift(comment);
                    } catch (e) { }
                });
                self.setState({
                    comments: self.state.comments
                });
            }

            this.ikaraNode.child('recordingComments').child(this.props.recording._id).orderByChild('dateTime').limitToLast(1).once('value').then(loadSnapshot);
        }
    }

    componentDidUpdate() {
        const { recording } = this.props;
        const { isFirst } = this.state;
        if (recording != null && isFirst == true) {
            this.state.isFirst = false;
            this.loadComments();
        }
    }

    componentDidMount() {
        $("#exampleModalDonate").on("hidden.bs.modal", function () {
            this.setState({
                giftSelected: null
            });
        }.bind(this));

        this.setState({})
    }

    loadComments() {
        const { globalLastOne } = this.state;
        const { recording, noCommentPerRequest } = this.props;
        const { location } = window;
        var self = this;

        function loadSnapshot(snapshot) {
            var i = 0;
            var newComments = []
            snapshot.forEach(function (commentSnapshot) {
                try {
                    var comment = commentSnapshot.val();
                    comment.userProfile = comment.userProfile.replace("http://", location.protocol + '//');
                    comment.key = commentSnapshot.key;

                    var commentChildList = [];
                    if (comment.childs != null) {
                        for (let i in comment.childs) {
                            comment.childs[i].key = i;
                            commentChildList.push(comment.childs[i])
                        }
                        comment.childs = commentChildList;
                    }
                    if (!self.state.comments.some(o => o.key == comment.key)) {
                        newComments.unshift(comment);
                    }

                    if (i == 0)
                        self.state.globalLastOne = comment.dateTime;
                    i++;
                } catch (e) { }
            });

            self.state.comments = self.state.comments.concat(newComments);

            if (i == noCommentPerRequest + 1) {
                self.state.loadMore = true;
            } else {
                self.state.loadMore = false;
            }
            self.setState({
                comments: self.state.comments,
                loadMore: self.state.loadMore
            });
        }

        this.ikaraNode.child('recordings').child(recording._id).child("commentCounter").on('value', function (snapshot) {
            if (snapshot.val() == null) {
                self.setState({
                    totalComment: 0
                })
            } else {
                self.setState({
                    totalComment: snapshot.val()
                })
            }
        });

        if (globalLastOne == null)
            this.ikaraNode.child('recordingComments').child(recording._id).orderByChild('dateTime').limitToLast(noCommentPerRequest + 1).once('value').then(loadSnapshot);
        else
            this.ikaraNode.child('recordingComments').child(recording._id).orderByChild('dateTime').endAt(globalLastOne - 1).limitToLast(noCommentPerRequest + 1).once('value').then(loadSnapshot);
    }

    toggle(param) {
        if (param !== this.state.toggleCommentId && this.state.toggleComment) {
            this.setState({
                toggleCommentId: param
            })
        } else {
            this.setState({
                toggleComment: !this.state.toggleComment,
                toggleCommentId: param
            })
        }
    }

    loadUser() {
        const json = JSON.stringify({ domain: window.location.hostname });

        axios.post(global.config.apiDomain + '/rest/loginuser', json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            const { user } = res.data;
            this.context.updateUser(user);
        })
    }

    render() {
        const { recording } = this.props;
        const { user } = this.props;
        const { comments, toggleComment, toggleCommentId, loadMore } = this.state;

        return (
            <div className="bot-commentSection">
                <div className="_2pi8">
                    <div className="_4k-6">
                        {
                            comments.map((comment) => (
                                recording.owner.facebookId.includes("KAKA") ? (
                                    <CommentAndGiftCommentLine
                                        key={comment.key}
                                        comment={comment}
                                        recording={recording}
                                        user={user}
                                        toggleComment={toggleComment}
                                        toggleCommentId={toggleCommentId}
                                        toggleFunc={this.toggle}
                                        addNewComment={this.addComment}
                                    ></CommentAndGiftCommentLine>
                                ) : (
                                    comment.userId === user.facebookId && (
                                        <CommentAndGiftCommentLine
                                            key={comment.key}
                                            comment={comment}
                                            recording={recording}
                                            user={user}
                                            toggleComment={toggleComment}
                                            toggleCommentId={toggleCommentId}
                                            toggleFunc={this.toggle}
                                            addNewComment={this.addComment}
                                        ></CommentAndGiftCommentLine>
                                    )
                                ))
                            )
                        }
                        <div className="_4k-6">
                            {loadMore ?
                                (<LoadMoreComments loadMoreCommentsFunction={this.loadComments}></LoadMoreComments>) :
                                ('')
                            }
                        </div>
                    </div>
                    <div className="_5lm5 _2pi3 _3-8y">
                        <div direction="left" className="clearfix">
                            <div className="_ohe lfloat">
                                <i alt="" className="img _8o _8r img sp_Zf93eLkohoS sx_97c3ab">
                                </i>
                            </div>
                            <div>
                                <div className="_42ef _8u">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

CommentPage.contextType = MyContext;

export default withRouter(withTranslation()(CommentPage));
