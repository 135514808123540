import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';

import bindModel from '../../../common/bindModel.js';
import SVGA from 'svgaplayerweb'
import { update } from 'lodash';
export default class ModalUpdateVipPrivilege extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vipPrivileges: [],
            Gifts: [],
            vipPrivilege: null,
            loading: false,
            update: 0,
            url: '',
            gift: '',
            type:'',
            Types:[],
        }
        // this.GiftImg=this.GiftImg.bind(this)
        this.saveVipPrivilege = this.saveVipPrivilege.bind(this);
        // this.listGift = this.listGift.bind(this);
        this.uploadAnimationToS5 = this.uploadAnimationToS5.bind(this);
    }

    componentWillReceiveProps(props) {
        $("#iconPreview").children("svg").remove();

        let vipPrivilege = props.vipPrivilege;
        let Gifts = []
        let Types =[]
        if (props.vipPrivilege) {
            Gifts = props.vipPrivilege.arrayGiftID;
            Types=  props.vipPrivilege.types;
        }
        

        let update = props.update
        this.setState({
            vipPrivilege: vipPrivilege,
            update: update,
            Gifts: Gifts,
            Types:Types
        })


    }

    removeGift = (e) => {
        let index = e.target.value
        const newGifts = [...this.state.Gifts];
        var newTypes =[...this.state.Types]
        newTypes.splice(index,1)
        newGifts.splice(index, 1);
        this.setState({ Gifts: newGifts ,Types:newTypes});
    }
    selectType = (e) =>{
        e.preventDefault();
        let inputType=e.target.value
        this.setState({
            type:inputType
        })

    }
    addGift = (e) => {
        e.preventDefault();
        if(this.state.type =='' ){
            toast.warning("Vui lòng chọn loại quà ");
        }else{
        axios.post(global.config.apiDomain + '/rest/eventLevelPrivilege/giftExist', {
            description: this.state.gift,
            type : this.state.type
        })
            .then(res => {
                this.setState({
                    loading: false
                });

                if (res.data.status == "OK") {
                    console.log("vào đây rồi ")
                    var newGifts = []
                    var newTypes =[]
                    if (this.state.Gifts) {
                        newGifts = [...this.state.Gifts]
                        newTypes= [...this.state.Types]
                    }

                    if (this.state.gift) {
                        newGifts.push(this.state.gift)
                        newTypes.push(this.state.type)
                        this.setState({
                            Gifts: newGifts,
                            Types:newTypes,
                            gift: '',
                            type:''
                        })
                    };

                } else {
                    toast.warning(res.data.message);
                }
            }, error => {
                this.setState({
                    loading: false
                });
                toast.error(error);
            })
        }

    }
    handleInputChange = (e) => {
        let input = e.target.value
        this.setState({
            gift: input
        });
    }
    saveVipPrivilege = () => {
        console.log(this.state.update)
        let obj = { ...this.state.vipPrivilege };
        let MyGift = [...this.state.Gifts]
        this.setState({
            loading: true
        });
        if (this.state.update == 1) {
            axios.post(global.config.apiDomain + '/rest/eventLevelPrivilege/update', {
                levelRequirement: obj.levelRequirement,
                arrayGiftID: [...this.state.Gifts],
                types:[...this.state.Types],
                description: obj.description,
                thumbnai:obj.thumbnai,
                thumbNaiCard:obj.thumbNaiCard,
            })
                .then(res => {
                    this.setState({
                        loading: false,
                        update: 0,
                    });

                    if (res.data.status == "OK") {
                        window.$("#modalUpdateVipPrivilege").modal("hide");
                        this.props.resetList();
                        toast.success(res.data.message);
                    } else {
                        toast.warning(res.data.message);
                    }   
                }, error => {
                    this.setState({
                        loading: false
                    });
                    toast.error(error);
                })
        }
        else {
            if (obj == null ||
                MyGift == null ||
                obj.levelRequirement == null
            ) {
                toast.error("Vui lòng điền đầy đủ thông tin.");
                return;
            }
            this.setState({
                loading: false,
            });
            axios.post(global.config.apiDomain + '/rest/eventLevelPrivilege/create', {
                levelRequirement: obj.levelRequirement,
                arrayGiftID: [...this.state.Gifts],
                types:[...this.state.Types],
                thumbnai:obj.thumbnai,
                thumbNaiCard:obj.thumbNaiCard,
                description: obj.description,
            })
                .then(res => {
                    if (res.data.status == "OK") {
                        window.$("#modalUpdateVipPrivilege").modal("hide");
                        this.props.resetList();
                        toast.success(res.data.message);
                    } else {
                        toast.warning(res.data.message);
                    }
                }, error => {
                    this.setState({
                        loading: false
                    });
                    toast.error(error);
                })
        }
    }
    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }
    loadSVGA4(url) {
        var player = new SVGA.Player('#demoCanvas4');
        var parser = new SVGA.Parser('#demoCanvas4');
        parser.load(url, function (videoItem) {
            console.log(videoItem)
            player.setVideoItem(videoItem);
            player.startAnimation();
        })
    }
    uploadAnimationToS5(event) {
        var target = event.target;
        //10495760
        if (target.files[0].size > 1049576) {
            toast.error("Kích thước file phải dưới 1mb.");
            return;
        }

        // this.setState({
        //     loading: true
        // });


        var obj = { ...this.state.vipPrivilege };
        var bucketName = "ikara-data/images/avatarFarme";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";

        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    // this.setState({
                    //     loading: false
                    // });
                    if(this.state.type == "SpecialGift"){
                    obj.thumbNaiCard = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;}else{
                        obj.thumbnai = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    }
                    // this.state.gift.thumbnailUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        vipPrivilege:obj
                    });
                        console.log(obj)
                    if(this.state.type == "SpecialGift" && obj.thumbNaiCard !=null && obj.thumbNaiCard.endsWith("svga")){
                        $("#show-animation_thumbnailUrl").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas4\"></div>");
                        this.loadSVGA4(obj.thumbNaiCard);
                    }else if (obj.thumbNaiCard != null) {
                        $("#show-animation_thumbnailUrl").children().replaceWith("<img style=\"height: 300px !important\" src=\"" + obj.thumbNaiCard + "\"  />");
                    }
                    if ( this.state.type == "VipDate" && obj.thumbnai != null && obj.thumbnai.endsWith("svga")) {
                        $("#show-animation_thumbnailUrl").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas4\"></div>");
                        this.loadSVGA4(obj.thumbnai);
                    } else if (obj.thumbnai != null) {
                        $("#show-animation_thumbnailUrl").children().replaceWith("<img style=\"height: 300px !important\" src=\"" + obj.thumbnai + "\"  />");
                    }
                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);

    }

    render() {
        const model = bindModel(this);
        const { loading, vipPrivilege, Gifts, update ,type,Types} = this.state;

        return (
            <div>
                <div className="modal fade" id="modalUpdateVipPrivilege" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật Quà Level Chẵn</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div id="levelRequirement" className="row mb-2">
                                    <div className="col-3">
                                        <label>Level yêu cầu</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="number"
                                            name="levelRequirement"
                                            placeholder="Nhập level yêu cầu..."
                                            {...model('vipPrivilege.levelRequirement')}
                                        />
                                    </div>
                                </div>
                                <div id="levelRequirement" className="row mb-2">
                                    <div className="col-3">
                                    <select value={type} onChange={ (e)=> this.selectType(e)}>
                                        <option value="">--Chọn loại quà--</option>
                                        <option value="Gift">Quà </option>
                                        <option value="AvatarFrame">Khung avatar</option>
                                        <option value="CommentFrame">Khung Bình Luận</option>
                                        <option value="RoomEntryEffect">Hiệu ứng vào phòng</option>
                                        <option value="UserDecoration">Trang trí cá nhân</option>
                                        <option value="PersonalCard"> Trang trí thẻ cá nhân </option>
                                        <option value="SingEffect">Hiệu ứng khi hát</option>
                                        <option value="SpecialGift">Thẻ xử lí Vip</option>
                                        <option value="VipDate">Ngày Vip</option>
        {/* Add more options as needed */}
      </select>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="giftID"
                                            autocomplete="off"
                                            value={this.state.gift}
                                            placeholder="Nhập ID quà tặng"
                                            onChange={(e) => this.handleInputChange(e)}
                                        /><div id="thumbnailUrl" className="row mb-2">
                                        <div className="col-3">
                                            <label>Hình mô tả</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id="inputGroupFile05"
                                                onChange={this.uploadAnimationToS5}
                                            />
                                            <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile05">Choose file</label>
                                        </div>
                                        <div className="row mb-2">
                                        <div className="col-3">
                                        </div>
                                        <div id="show-animation_thumbnailUrl" className="col-9" style={{ maxHeight: "300px" }}>
                                            <div width={500} height={500} id="canvas"></div>
                                        </div>
                                    </div>
                                    </div>
                                        <button className='btn-red' onClick={(e) => this.addGift(e)}
                                        >Thêm</button>
                                    </div>


                                </div>
                                <div className="row mb-2">
                                    <div className="col-9">
                                        <label className="col-9">Danh sách quà tặng</label>
                                    </div>
                                </div>
                            </div>

                            {
                                Array.isArray(Gifts) && Gifts.map((item, index) => {
                                    return (<div className="row mb-2">
                                        <label className="col-3 ml-2">Quà số {index + 1} : </label>
                                        <p className="col-6 ml-1">{Types[index]} : {item}</p>
                                        <button className='btn-danger'
                                            style={{ height: 'auto', width: 'auto' }}
                                            onClick={(e) => this.removeGift(e)}
                                            value={index}>Xóa</button>
                                    </div>
                                    )
                                })}


                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.saveVipPrivilege}>Xác nhận</button>
                                    )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        );
    }
}
//    "postbuild":  "rm -r /Users/quhuy/ikaragae/src/main/webapp/react-source && mkdir /Users/quhuy/ikaragae/src/main/webapp/react-source && mv -v build/* /Users/quhuy/ikaragae/src/main/webapp/react-source",
