import React, { Component } from 'react';
import axios from 'axios';
import Chart from 'chart.js/auto'
import { Bar } from 'react-chartjs-2'
import lodash from 'lodash';
export default class RankItemPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            monthCode: 'prevMonth',
            listLabelSortByTotalPrice: [],
            listdataSortByTotalPrice: [],
            listLabelSortByCounter: [],
            listDataSortByCounter: [],
        }

        this.changeMonthCode = this.changeMonthCode.bind(this);
        this.getList = this.getList.bind(this);
    }

    componentWillMount() {
        this.getList(this.state.monthCode);
    }

    getList(monthCode) {
        console.log('/rest/shop-items/getTopItems/' , monthCode);
        // let listItem = [];
        // if (monthCode === 'prevMonth')
        //     listItem = [
        //         {
        //             "_id": 4837339582300160,
        //             "name": "Vòng xoay",
        //             "expiry": 10,
        //             "previewImg": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/avatarFarme/821bab57-26d7-aed0-3472-74439c409efa.svga",
        //             "resourceUrl": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/avatarFarme/5492ca8c-fcef-f002-a36d-3f70736146f0.svga",
        //             "type": "AvatarFrame",
        //             "price": 456,
        //             "lvRequire": 0,
        //             "vipRequire": 0,
        //             "order": 23,
        //             "idPublic": 4837339582300160,
        //             "autoRenew": false,
        //             "vipDiscount": 0.0,
        //             "isUse": false,
        //             "numHoursExpiry": 0,
        //             "isItemEvent": false,
        //             "isRemoveByAdmin": false,
        //             "counter": 5,
        //             "totalPrice": 2280
        //         },
        //         {
        //             "_id": 5192226992160768,
        //             "name": "Vương miện 1",
        //             "expiry": 10,
        //             "previewImg": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/avatarFarme/185b04af-c18d-859c-3616-3a61c1be05b4.png",
        //             "resourceUrl": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/avatarFarme/721f8948-f6e0-4e88-72df-d8d65e5b16d3.png",
        //             "type": "AvatarFrame",
        //             "price": 300,
        //             "lvRequire": 0,
        //             "vipRequire": 0,
        //             "order": 20,
        //             "idPublic": 5192226992160768,
        //             "autoRenew": false,
        //             "vipDiscount": 0.0,
        //             "isUse": false,
        //             "numHoursExpiry": 0,
        //             "isItemEvent": false,
        //             "isRemoveByAdmin": false,
        //             "counter": 3,
        //             "totalPrice": 900
        //         },
        //         {
        //             "_id": 4593847350198272,
        //             "name": "Cánh bướm",
        //             "expiry": 10,
        //             "previewImg": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/avatarFarme/259f7c5c-2387-91ec-7c4d-dca1b60ed333.png",
        //             "resourceUrl": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/avatarFarme/7c17dc57-92fc-affc-fe7d-9135f0732fd3.svga",
        //             "type": "AvatarFrame",
        //             "price": 555,
        //             "lvRequire": 0,
        //             "vipRequire": 0,
        //             "order": 200,
        //             "idPublic": 4593847350198272,
        //             "autoRenew": false,
        //             "vipDiscount": 0.0,
        //             "isUse": false,
        //             "numHoursExpiry": 0,
        //             "isItemEvent": false,
        //             "isRemoveByAdmin": false,
        //             "counter": 2,
        //             "totalPrice": 1110
        //         },
        //         {
        //             "_id": 4656311701995520,
        //             "name": "Tài Lộc",
        //             "expiry": 10,
        //             "previewImg": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/avatarFarme/b4269a00-7a87-5841-949c-fe4cd6df8c40.png",
        //             "resourceUrl": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/avatarFarme/820d02c4-ec67-9b17-6740-443f545cfcaf.png",
        //             "type": "AvatarFrame",
        //             "price": 250,
        //             "lvRequire": 0,
        //             "vipRequire": 0,
        //             "order": 1,
        //             "idPublic": 4656311701995520,
        //             "autoRenew": false,
        //             "vipDiscount": 0.0,
        //             "isUse": false,
        //             "numHoursExpiry": 0,
        //             "isItemEvent": false,
        //             "isRemoveByAdmin": false,
        //             "counter": 1,
        //             "totalPrice": 250
        //         },
        //         {
        //             "_id": 4676952618565632,
        //             "name": "Băng tuyết",
        //             "expiry": 1,
        //             "previewImg": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/avatarFarme/ca93c81a-c816-f649-6d2c-cbe113a00ec8.png",
        //             "resourceUrl": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/avatarFarme/b315b510-07ec-9d95-651e-c91c0eab131b.svga",
        //             "type": "AvatarFrame",
        //             "price": 600,
        //             "lvRequire": 0,
        //             "vipRequire": 0,
        //             "order": 10,
        //             "idPublic": 4676952618565632,
        //             "autoRenew": false,
        //             "vipDiscount": 0.0,
        //             "isUse": false,
        //             "numHoursExpiry": 0,
        //             "isItemEvent": false,
        //             "isRemoveByAdmin": false,
        //             "counter": 1,
        //             "totalPrice": 600
        //         }
        //     ];
        // else
        // listItem = [
        //     {
        //         "_id": 4837339582300160,
        //         "name": "Vòng xoay",
        //         "expiry": 10,
        //         "previewImg": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/avatarFarme/821bab57-26d7-aed0-3472-74439c409efa.svga",
        //         "resourceUrl": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/avatarFarme/5492ca8c-fcef-f002-a36d-3f70736146f0.svga",
        //         "type": "AvatarFrame",
        //         "price": 456,
        //         "lvRequire": 0,
        //         "vipRequire": 0,
        //         "order": 23,
        //         "idPublic": 4837339582300160,
        //         "autoRenew": false,
        //         "vipDiscount": 0.0,
        //         "isUse": false,
        //         "numHoursExpiry": 0,
        //         "isItemEvent": false,
        //         "isRemoveByAdmin": false,
        //         "counter": 15,
        //         "totalPrice": 2280
        //     },
        //     {
        //         "_id": 5192226992160768,
        //         "name": "Vương miện 1",
        //         "expiry": 10,
        //         "previewImg": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/avatarFarme/185b04af-c18d-859c-3616-3a61c1be05b4.png",
        //         "resourceUrl": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/avatarFarme/721f8948-f6e0-4e88-72df-d8d65e5b16d3.png",
        //         "type": "AvatarFrame",
        //         "price": 300,
        //         "lvRequire": 0,
        //         "vipRequire": 0,
        //         "order": 20,
        //         "idPublic": 5192226992160768,
        //         "autoRenew": false,
        //         "vipDiscount": 0.0,
        //         "isUse": false,
        //         "numHoursExpiry": 0,
        //         "isItemEvent": false,
        //         "isRemoveByAdmin": false,
        //         "counter": 3,
        //         "totalPrice": 900
        //     }
        // ];
        // let listSortByTotalPrice = lodash.orderBy(listItem, "totalPrice", 'desc');
        // let listLabelSortByTotalPrice = listSortByTotalPrice.map(e => e.name);
        // let listdataSortByTotalPrice = listSortByTotalPrice.map(e => e.totalPrice);

        // let listSortByCounter = lodash.orderBy(listItem, "counter", 'desc');
        // let listLabelSortByCounter = listSortByCounter.map(e => e.name);
        // let listDataSortByCounter = listSortByCounter.map(e => e.counter);

        // this.setState({
        //     listLabelSortByTotalPrice: listLabelSortByTotalPrice,
        //     listdataSortByTotalPrice: listdataSortByTotalPrice,

        //     listLabelSortByCounter: listLabelSortByCounter,
        //     listDataSortByCounter: listDataSortByCounter
        // });

        axios.post(global.config.apiDomain + '/rest/shop-items/getTopItems/' + monthCode)
        .then(res => {
            console.log(res);
            let listItem = res.data.listItem;
            let listSortByTotalPrice = lodash.orderBy(listItem, "totalPrice", 'desc');
            let listLabelSortByTotalPrice = listSortByTotalPrice.map(e => e.name);
            let listdataSortByTotalPrice = listSortByTotalPrice.map(e => e.totalPrice);
    
            let listSortByCounter = lodash.orderBy(listItem, "counter", 'desc');
            let listLabelSortByCounter = listSortByCounter.map(e => e.name);
            let listDataSortByCounter = listSortByCounter.map(e => e.counter);
    
            this.setState({
                listLabelSortByTotalPrice: listLabelSortByTotalPrice,
                listdataSortByTotalPrice: listdataSortByTotalPrice,
    
                listLabelSortByCounter: listLabelSortByCounter,
                listDataSortByCounter: listDataSortByCounter
            });
        });
    }

    changeMonthCode(e) {
        let monthCode = e.target.dataset.id;
        console.log('e.target.dataset.id: ', e.target.dataset.id);
        this.getList(monthCode);
    }


    render() {
        const { listLabelSortByTotalPrice, listdataSortByTotalPrice, listLabelSortByCounter, listDataSortByCounter } = this.state;

        return (
            <div id="content">
                <div>
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <a className="nav-item nav-link active" id="nav-last-month-tab" data-toggle="tab" href="#nav-last-month" role="tab" aria-controls="nav-home" aria-selected="true" onClick={this.changeMonthCode} data-id="prevMonth">Tháng trước</a>
                            <a className="nav-item nav-link " id="nav-this-month-tab" data-toggle="tab" href="#nav-this-month" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={this.changeMonthCode} data-id="currentMonth">Tháng hiện tại</a>
                        </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-last-month" role="tabpanel" aria-labelledby="nav-last-month-tab">
                            <div>
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="pills-counter-last-month-tab" data-toggle="pill" href="#pills-counter-last-month" role="tab" aria-controls="pills-counter-last-month" aria-selected="true">Biểu đồ theo iCoin</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-icoin-last-month-tab" data-toggle="pill" href="#pills-icoin-last-month" role="tab" aria-controls="pills-icoin-last-month" aria-selected="false">Biểu đồ theo lượt mua</a>
                                    </li>
                                </ul>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-counter-last-month" role="tabpanel" aria-labelledby="pills-counter-last-month-tab">
                                        <Bar
                                            data={{
                                                labels: listLabelSortByTotalPrice,
                                                datasets: [{
                                                    data: listdataSortByTotalPrice,
                                                    backgroundColor: ["rgba(255, 99, 132, 1)", "rgba(53, 162, 235, 1)"],
                                                    barThickness: 13

                                                }]
                                            }}
                                            // height={400}
                                            options={{
                                                indexAxis: 'y',
                                                responsive: true,
                                                plugins: {
                                                    legend: {
                                                        display: false,
                                                    },
                                                    title: {
                                                        display: true,
                                                        text: 'Biểu đồ theo lượng tiêu thụ iCoin',

                                                    },
                                                },
                                                scales: {
                                                    y: {
                                                        beginAtZero: true
                                                    }
                                                }
                                                //  ,
                                                //  interaction: {
                                                //      mode: "dataset",
                                                //      intersect: true
                                                //  }
                                            }}
                                        >
                                        </Bar>
                                    </div>
                                    <div className="tab-pane fade" id="pills-icoin-last-month" role="tabpanel" aria-labelledby="pills-icoin-last-month-tab">
                                        <Bar
                                            data={{
                                                labels: listLabelSortByCounter,
                                                datasets: [{
                                                    data: listDataSortByCounter,
                                                    backgroundColor: ["rgba(255, 99, 132, 1)", "rgba(53, 162, 235, 1)"],
                                                    barThickness: 12,

                                                }]
                                            }}
                                            // height={400}
                                            options={{
                                                indexAxis: "y",
                                                responsive: true,
                                                plugins: {
                                                    legend: {
                                                        display: false,
                                                    },
                                                    title: {
                                                        display: true,
                                                        text: 'Biểu đồ theo lượt mua',

                                                    },
                                                }
                                            }}
                                        >
                                        </Bar>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}