import React, { Component } from 'react';
import axios from 'axios';
import bindModel from '../../common/bindModel.js';

export default class GameBettingHistoryManagerPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            uid: null,
            page: 0,
            startDate: new Date(),
            endDate: new Date(),
            bettingHistories: [],
            loading: false,
            statusButtonLoadPage: false,
        }
        this.fetchBettingHistories = this.fetchBettingHistories.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.reset = this.reset.bind(this);
    }

    fetchBettingHistories() {
        const {startDate, endDate, uid} = this.state;
        
        this.setState({
            loading: true
        });

        const start = new Date(startDate);
        const isoStartDate = start.getTime();
        const end = new Date(endDate);
        const isoEndDate = end.getTime();

        axios.get(global.config.apiDomain + '/rest/luckydice?' + "startDate=" 
            + isoStartDate + "&endDate=" + isoEndDate + "&uid=" + uid)
            .then(res => {
                this.setState({
                    bettingHistories: res.data
                })
            })
            .catch(error => {
                console.error("Error:", error);
            });;
    }

    // componentDidMount() {
    //     let id = new URLSearchParams(this.props.location.search).get("id");
    //     this.state.id = id;
    //     // if (validateNumber(this.state.id)) {
    //     if (id !== "") {
    //         this.getAccount();
    //     }
    // }

    handleChange(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value;

        this.setState({
            [name]: value
        });
    }

    reset() {
        this.setState({
            uid: null,
            page: 0,
            startDate: new Date(),
            endDate: new Date(),
            // smsList: this.state.smsListTemp
        });
    }

    render() {
        const model = bindModel(this);
        return (
            <>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí lịch sử đặt cược lucky dice</h1>
                        <div className='input-group mb-3'>
                            <input
                                name="uid"
                                value={this.state.uid}
                                className="form-control col-md-1 col-3"
                                onChange={this.handleChange}
                            />
                            <input type="datetime-local" className='form-control col-2'
                                onChange={(e) => {
                                    this.setState({
                                        startDate: e.target.value
                                    })
                                }} />
                            <input type="datetime-local" className='form-control col-2'
                                onChange={(e) => {
                                    this.setState({
                                        endDate: e.target.value
                                    })
                                }} />
                            <div className="input-group-append">
                                <button
                                    className="btn btn-outline-success"
                                    type="button"
                                    id="button-addon2"
                                    onClick={this.fetchBettingHistories}>
                                    <i className="fa fa-search"></i>
                                </button>
                                <button
                                    className="btn btn-outline-danger"
                                    type="button"
                                    id="button-addon2"
                                    onClick={this.reset}>
                                    <i className="fas fa-sync"></i>
                                </button>
                            </div>
                        </div>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">STT</th>
                                    <th scope="col">Thời gian</th>
                                    <th scope="col">BIG</th>
                                    <th scope="col">SMALL</th>
                                    <th scope="col">TRIPLE</th>
                                    <th scope="col">Kết quả</th>
                                    <th scope="col">Số tiền thắng</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.bettingHistories.map((bettingHistory, index) => {
                                    return (
                                        <tr key={bettingHistory.id}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{new Date(bettingHistory.lastUpdate).toLocaleString()}</td>
                                            <td> {bettingHistory.big} </td>
                                            <td> {bettingHistory.small} </td>
                                            <td> {bettingHistory.triple} </td>
                                            <td> {bettingHistory.result + " (" + bettingHistory.dice1 + ","  + bettingHistory.dice2 + ","  + bettingHistory.dice3 + ")"} </td>
                                            <td> {bettingHistory.icoinResult} </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}
