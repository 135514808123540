import React, { Component } from 'react';
import bindModel from '../../../common/bindModel.js';
import axios from 'axios';
import { toast } from 'react-toastify';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars'; 

export default class ModalUpdateNotify extends Component {

    constructor(props) {
        super(props);
        this.state = {
            notify: null,
            loading: false,
        }

        this.savenotify = this.savenotify.bind(this);
        this.updateTime = this.updateTime.bind(this);
    }
    componentWillReceiveProps(props) {
        let { notify } = props;
        if (notify != null) {

            if (notify.timer != null) {
                notify.timer =  new Date(notify.timer);
            }
            console.log(notify.timer)

            this.setState({
                notify: notify
            });
        }
    }


    savenotify() {
        const { notify } = this.state;
        console.log(notify);
        this.setState({
            loading: true
        });

            axios.post("https://www.ikara.co/rest/notification/save ", JSON.stringify(notify), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                this.setState({
                    loading: false
                });
                 
                if (res.data.status == "OK") {
                    window.$("#modalUpdateNotify").modal("hide");
                    this.props.resetList();
                    toast.success(res.data.message);
                } else {
                    toast.warning(res.data.message);
                }
            }, error => {
                this.setState({
                    loading: false
                });
                toast.error(error);
            })
        
    }

    updateTime(props) {
        //console.log(typeof props.value );
        let {notify} = this.state;
        let currentTime = props.value
        notify.timer = currentTime;
        this.setState({
            notify: notify
        });
    }
    render() {
        const model = bindModel(this);
        const { notify } = this.state;
        
        return (
            <div>   
                <div className="modal fade" id="modalUpdateNotify" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Cài đặt thông báo</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {notify === null ? "": (
                                <div className="modal-body">
                                <div id="id" className="row mb-2">
                                    <div className="col-3">
                                        <label>ID</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="platform"
                                            readOnly
                                            placeholder="Nhập tiêu đề..."
                                            {...model('notify.id')} 
                                        />
                                    </div>
                                </div>
                                <div id="title" className="row mb-2">
                                    <div className="col-3">
                                        <label>Tiêu đề</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="platform"
                                            placeholder="Nhập tiêu đề..."
                                            {...model('notify.title')} 
                                        />
                                    </div>
                                </div>
                                <div id="message" className="row mb-2">
                                    <div className="col-3">
                                        <label>Nội dung</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="title"
                                            placeholder="Nhập nội dung..."
                                            {...model('notify.message')} 
                                        />
                                    </div>
                                </div>
                                <div id="link" className="row mb-2">
                                    <div className="col-3">
                                        <label>URL</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="title"
                                            placeholder="Nhập URL..."
                                            {...model('notify.link')} 
                                        />
                                    </div>
                                </div>
                                <div id="timer" className="row mb-2">
                                    <div className="col-3">
                                        <label>Thời gian</label>
                                    </div>
                                    <div className="col-9">
                                        <DateTimePickerComponent 
                                            change= {this.updateTime}
                                            value = {new Date(notify.timer)}
                                        >    
                                        </DateTimePickerComponent>
                                    </div>
                                </div>
                                <div id="app" className="row mb-2">
                                    <div className="col-3">
                                        <label>APP</label>
                                    </div>
                                    <div className="col-9">
                                        <select
                                            className="form-control"
                                            type="text"
                                            name="notify.show"
                                            {...model('notify.app')} 
                                        >
                                            <option value="ALL">ALL</option>
                                            <option value="IKARA">IKARA</option>
                                            <option value="YOKARA">YOKARA</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            )}
                                
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.savenotify}>Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}