import React, { Component } from 'react';
import axios from 'axios';
import ModalHandleErrorTransaction from './components/ModalHandleErrorTransaction';

export default class TransactionManagerPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            smsList: [],
            smsListTemp: [],
            idSmsProcess: null,
            uidProcess: null,
            showProcessModal: null,
            addTime: "",
            sender: "",
            status: "",
            dateStart :null,
            dateEnd : null,
        }

        this.setIdSms = this.setIdSms.bind(this);
        this.getSmsList = this.getSmsList.bind(this);
        this.search = this.search.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.reset = this.reset.bind(this);
    }

    componentWillMount() {
        this.getSmsList();
    }

    getSmsList() {
        axios.post(global.config.apiDomain + '/rest/recharge', {
            dateStart: null,
            dateEnd : null
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                smsList: res.data,
                smsListTemp: res.data
            })
        });
    }

    setIdSms(sms) {
        this.setState({
            idSmsSelected: sms.id,
            sender: sms.sender
        });
    }

    search() {
        let { smsList, smsListTemp, addTime, sender, status ,dateStart, dateEnd } = this.state;
        var data ;
        var date_1 = dateEnd
        if(date_1 == null){
            const date = new Date();
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();

            if (month < 10 ) {
                month = "0" + month;
            }
             date_1 = year + "-" + month + "-" + day;
        }
        axios.post(global.config.apiDomain + '/rest/recharge', {
            dateStart : dateStart,
            dateEnd : date_1
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
           var smsList_1 = res.data

        console.log("log list");

        console.log(smsList_1
        );        
        if(smsList_1 == null){
            return;
        }
        // smsList = smsList.filter(e => {
        //     const date = new Date(e.addTime);
        //     let year = date.getFullYear();
        //     let month = date.getMonth() + 1;
        //     let day = date.getDate();

        //     if (month < 10 ) {
        //         month = "0" + month;
        //     }
        //     let compareDate = year + "-" + month + "-" + day;
        //     return addTime === compareDate;
        //     // e.addTime.includes(ad)
        //     // 2024-01-10
        // });
        smsList_1 = smsList_1.filter(e => e.sender.includes(sender));
        smsList_1 = smsList_1.filter(e => e.status.includes(status));
        console.log(smsList_1);
        
        this.setState({
            smsList: smsList_1
        });
            });
    }

    reset() {
        this.setState({
            addTime: "",
            sender: "",
            status: "",
            dateStart:null,
            dateEnd:null,
            smsList: this.state.smsListTemp
        });
    }

    handleChange(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value;

        this.setState({
            [name]: value
        });
    }


    render() {
        return (
            <>
                <ModalHandleErrorTransaction resetList={this.getSmsList} id={this.state.idSmsSelected} sender={this.state.sender}></ModalHandleErrorTransaction>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí giao dịch</h1>
                        <div className='input-group mb-3'>
                            <select
                                name="sender"
                                value={this.state.sender}
                                className="form-control col-md-1 col-3"
                                onChange={this.handleChange}>
                                <option value="">ALL</option>
                                <option value="Vietcombank">VCB</option>
                                <option value="MOMO">Momo</option>
                            </select>
                            <select
                                name="status"
                                value={this.state.status}
                                className="form-control col-md-1 col-3"
                                onChange={this.handleChange}>
                                <option value="">ALL</option>
                                <option value="SUCCESS">SUCCESS</option>
                                <option value="FAILED">FAILED</option>
                            </select>
                            <input type="date" className='form-control col-1'
                                onChange={(e) => {
                                    this.setState({
                                        dateStart: e.target.value
                                    })
                                }} />
                                <input type="date" className='form-control col-1'
                                onChange={(e) => {
                                    this.setState({
                                        dateEnd: e.target.value
                                    })
                                }} />
                            <div className="input-group-append">
                                <button
                                    className="btn btn-outline-success"
                                    type="button"
                                    id="button-addon2"
                                    onClick={this.search}>
                                    <i className="fa fa-search"></i>
                                </button>
                                <button
                                    className="btn btn-outline-danger"
                                    type="button"
                                    id="button-addon2"
                                    onClick={this.reset}>
                                    <i className="fas fa-sync"></i>
                                </button>
                            </div>
                        </div>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">STT</th>
                                    <th scope="col">Thời gian</th>
                                    <th scope="col">Số tiền</th>
                                    <th scope="col">UID</th>
                                    <th scope="col">Nguồn</th>
                                    <th scope="col">Nội dung</th>
                                    <th scope="col">Trạng thái</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.smsList.map((sms, index) => {
                                    return (
                                        <tr key={sms.id}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{new Date(sms.addTime).toLocaleString()}</td>
                                            <td> {sms.amount} </td>
                                            <td> {sms.uid} </td>
                                            <td> {sms.sender} </td>
                                            <td width={200}> {sms.message} </td>
                                            <td> {sms.status} </td>
                                            <td>
                                                {sms.status === "FAILED" ?
                                                    <button
                                                        onClick={() => this.setIdSms(sms)}
                                                        className="btn btn-red"
                                                        data-toggle="modal"
                                                        data-target="#modalHandleRecharge"
                                                    >Xử lí</button> : <></>}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}