import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';
import bindModel from '../../../common/bindModel.js';

export default class ModalKickOutLiveRoom extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            liveRoomUid: ""
        }

        this.kick = this.kick.bind(this);
    }

    kick() {
        this.setState({
            loading: true
        });

        var json = {
            facebookId: this.props.id,
            liveRoomUid: this.state.liveRoomUid
        }

        axios.post(global.config.apiDomain + '/rest/admin/kickOutLiveRoom', JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                loading: false,
                liveRoomUid: ""
            });

            if (res.data.status == "OK") {
                window.$("#modalKickOutLiveRoom").modal("hide");
                toast.success(res.data.message);
            } else {
                toast.warning(res.data.message);
            }
        }, error => {
            this.setState({
                loading: false
            });
            toast.error("ERROR");
        })

    }

    render() {
        const model = bindModel(this);
        const { id } = this.state;

        return (
            <div>
                <div className="modal fade" id="modalKickOutLiveRoom" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Kick Out Live Room</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <input
                                    onKeyPress={(event) => {
                                        if(event.key == "Enter") {
                                            this.kick();
                                        }
                                    }}
                                    type="number"
                                    placeholder="Nhập live room ID"
                                    className="form-control"
                                    {...model('liveRoomUid')} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button
                                            style={{ width: "120px" }}
                                            type="button"
                                            className="btn btn-red"
                                            onClick={this.kick}>Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
