import React, { Component } from 'react';

export default class ModalConfirmBuyiCoinForMobileCard extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div>
                <div className="modal fade" id="modalConfirmBuyCard"  data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Thông tin thẻ cào của bạn</h5>
                                <button 
                                    type="button" 
                                    className="close" 
                                    // data-dismiss="modal" 
                                    onClick={this.props.close}
                                    aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Nhà mạng: <strong>{this.props.card.cardId}</strong></p>
                                <p>Số serial: <strong>{this.props.card.serial}</strong></p>
                                <p>Mã thẻ: <strong>{this.props.card.pin}</strong></p>
                                <p>Mệnh giá: <strong>{this.props.card.amount}</strong></p>
                                <h4 className="text-danger">Lưu ý: Chọn đúng mệnh giá và nhà mạng. Chọn sai mệnh giá sẽ mất thẻ</h4>
                            </div>
                            <div className="modal-footer">
                                <button 
                                    type="button" 
                                    className="btn btn-red" 
                                    // data-dismiss="modal"
                                    onClick={this.props.back}
                                    >Lùi lại</button>
                                {this.props.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button
                                            style={{ width: "120px" }}
                                            type="button"
                                            className="btn btn-red"
                                            onClick={this.props.submit}
                                            >Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}