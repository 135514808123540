import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';

import bindModel from '../../../common/bindModel.js';

export default class ModalUpdateVipPrivilege extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vipPrivileges: [],
            vipPrivilege: null,
            loading: false
        }
        this.uploadAnimationToS3 = this.uploadAnimationToS3.bind(this);
        this.uploadAnimationToS4 = this.uploadAnimationToS4.bind(this);
        this.saveVipPrivilege = this.saveVipPrivilege.bind(this);
    }

    componentWillReceiveProps(props) {
        $("#iconPreview").children("svg").remove();

        let vipPrivilege = props.vipPrivilege;
        this.setState({
            vipPrivilege: vipPrivilege,
        })

        if (vipPrivilege != null && vipPrivilege.icon !== '') {
            this.renderSvg(vipPrivilege.icon);
        }
        
    }

    saveVipPrivilege() {
        let obj = {...this.state.vipPrivilege};

        if (obj == null || 
            obj.idTab == '' || 
            obj.name == '' || 
            // obj.description == '' || 
            // obj.detail == '' || 
            obj.icon == '' || 
            obj.levelRequirement == 0 || 
            obj.image == '' || 
            obj.isActive == '' || 
            obj.index == 0 
        ){
            toast.error("Vui lòng điền đầy đủ thông tin.");
            return;
        }

        if (obj.isActive == '1') {
            obj.isActive = true;
        } else {
            obj.isActive = false;
        }

        this.setState({
            loading: true
        });

        axios.post(global.config.apiDomain + '/rest/privileges/createOrUpdateVipPrivilege', obj)
        .then(res => {
            this.setState({
                loading: false
            });

            if (res.data.status == "OK") {
                window.$("#modalUpdateVipPrivilege").modal("hide");
                this.props.resetList();
                toast.success(res.data.message);
            } else {
                toast.warning(res.data.message);
            }
        }, error => {
            this.setState({
                loading: false
            });
            toast.error(error);
        })
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    uploadAnimationToS3(event) {
        let target = event.target;
        if (target.files[0].size > 10495760) {
            toast.error("Kích thước file phải dưới 10mb.");
            return;
        }

        // Allowing file type
        let allowedExtensions = /(\.svg)$/i;

        if (!allowedExtensions.exec(target.value)) {
            toast.error("Chỉ upload file svg");
            return ;
        }

        this.setState({
            ...this.state,
            loading: true
        });

        let property = target.name;

        let vipPrivilege = this.state.vipPrivilege;
        let bucketName = "ikara-data/images/vipPrivilege";
        let extension = target.files[0].name.split('.').pop().toLowerCase();
        let keyName = this.guid() + "." + extension;
        let contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";

        let fileReader = new FileReader();

        fileReader.onload = function () {
            let fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: async function (data) {
                    this.setState({
                        loading: false
                    });
                    this.setState({
                        vipPrivilege: {
                            ...vipPrivilege,
                            [property]: 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName
                        }
                    });
                    let text = await this.getAndModifySVG('https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName);
                    $("#iconPreview").html(function() {
                        return text.documentElement;
                    });
                }.bind(this),
            });
        }.bind(this);
        fileReader.readAsArrayBuffer(target.files[0]);
    }

    uploadAnimationToS4(event) {
        let target = event.target;
        if (target.files[0].size > 10495760) {
            toast.error("Kích thước file phải dưới 10mb.");
            return;
        }

        // Allowing file type
        let allowedExtensions = /(\.mp4)$/i;
        let contentType = "image/svga";
        if (!allowedExtensions.exec(target.value)) {
            contentType = "video/mp4";
        }

        this.setState({
            ...this.state,
            loading: true
        });

        let property = target.name;

        let vipPrivilege = this.state.vipPrivilege;
        let bucketName = "ikara-data/images/vipPrivilege";
        let extension = target.files[0].name.split('.').pop().toLowerCase();
        let keyName = this.guid() + "." + extension;
        
        if (extension == "png") contentType = "image/svga";

        let fileReader = new FileReader();

        fileReader.onload = function () {
            let fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    this.setState({
                        loading: false
                    });
                    this.setState({
                        vipPrivilege: {
                            ...vipPrivilege,
                            [property]: 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName
                        }
                    });
                }.bind(this),
            });
        }.bind(this);
        fileReader.readAsArrayBuffer(target.files[0]);
    }

    async getAndModifySVG(url) {
        const response = await fetch(url);
        const str = await response.text();
        return (new window.DOMParser()).parseFromString(str, "text/xml");
    }

    async renderSvg(url) {
        let text = await this.getAndModifySVG(url);
        $("#iconPreview").html(function() {
            return text.documentElement;
        });
    }

 

    render() {
        const model = bindModel(this);
        const { loading, vipPrivilege } = this.state;

        return (
            <div>
                <div className="modal fade" id="modalUpdateVipPrivilege" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật Đặc quyền Vip</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div id="idTab" className="row mb-2">
                                    <div className="col-3">
                                        <label>Mã đặc quyền</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="idTab"
                                            placeholder="Nhập mã đặc quyền..."
                                            {...model('vipPrivilege.idTab')}
                                        />
                                    </div>
                                </div>
                                <div id="name" className="row mb-2">
                                    <div className="col-3">
                                        <label>Tên đặc quyền</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="name"
                                            placeholder="Nhập tên đặc quyền..."
                                            {...model('vipPrivilege.name')}
                                        />
                                    </div>
                                </div>
                                <div id="icon" className="row mb-2">
                                    <div className="col-3">
                                        <label>Icon</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            name="icon"
                                            accept=".svg" 
                                            onChange={this.uploadAnimationToS3}
                                        />
                                        <label className="custom-file-label ml-3 mr-3" htmlFor="icon">Choose file</label>
                                    </div>
                                </div>
                                <div id="image" className="row mb-2">
                                    <div className="col-3">
                                        <label>Ảnh minh họa</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            name="image"
                                            onChange={this.uploadAnimationToS4}
                                        />
                                        <label className="custom-file-label ml-3 mr-3" htmlFor="image">Choose file</label>
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label></label>
                                    </div>
                                    <div className="col-4">
                                        <label>Icon</label>
                                    </div>
                                    <div className="col-4">
                                        <label>Ảnh Minh họa</label>
                                    </div>
                                </div>
                                
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label></label>
                                    </div>
                                    <div id='iconPreview' className="col-4">
            
                                    </div>

                                    <div className="col-4">
                                        {(this.props.vipPrivilege && vipPrivilege.image) ? (
                                            <>
                                                <img src={vipPrivilege.image} />
                                            </>
                                        ) : ""}
                                    </div>
                                </div>
                                <div id="description" className="row mb-2">
                                    <div className="col-3">
                                        <label>Mô tả</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="description"
                                            placeholder="Nhập mô tả..."
                                            {...model('vipPrivilege.description')}
                                        />
                                    </div>
                                </div>
                                <div id="detail" className="row mb-2">
                                    <div className="col-3">
                                        <label>Mô tả Chi tiết</label>
                                    </div>
                                    <div className="col-9">
                                        <textarea 
                                            className="form-control mb-2"
                                            type="text"
                                            name="detail"
                                            placeholder="Nhập Mô tả Chi tiết..."
                                            {...model('vipPrivilege.detail')}
                                        />
                                    </div>
                                </div>
                                <div id="levelRequirement" className="row mb-2">
                                    <div className="col-3">
                                        <label>Level yêu cầu</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="number"
                                            name="levelRequirement"
                                            placeholder="Nhập level yêu cầu..."
                                            {...model('vipPrivilege.levelRequirement')}
                                        />
                                    </div>
                                </div>
                                <div id="index" className="row mb-2">
                                    <div className="col-3">
                                        <label>Thứ tự hiển thị</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="number"
                                            name="index"
                                            placeholder="Nhập Thứ tự hiển thị..."
                                            {...model('vipPrivilege.index')}
                                        />
                                    </div>
                                </div>
                                <div id="isActive" className="row mb-2">
                                    <div className="col-3">
                                        <label>Trạng thái</label>
                                    </div>
                                    <div className="col-9">
                                        <select
                                            className="form-control"
                                            type="text"
                                            name="isActive"
                                            {...model('vipPrivilege.isActive')}
                                        >
                                            <option value=''>Chọn trạng thái</option>
                                            <option value='1'>Kích hoạt</option>
                                            <option value='0'>Ẩn</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.saveVipPrivilege}>Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}