import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import { Parser, Player } from 'svga'
import SVGA from 'svgaplayerweb'
import { toast } from 'react-toastify';
import ModalUpdateScreen from './components/ModalUpdateScreen';

export default class ScreenManagerPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            screens:[],
            screen:null,
            loading: false
        }
        this.getAllScreenInApp = this.getAllScreenInApp.bind(this);
    }

    getAllScreenInApp(){
        let url =  global.config.apiDomain + "/rest/screens/getAllScreen"
        axios.post(url)
        .then(res =>{
            this.setState({
                screens: res.data.screenModels
            })
        }).catch(er =>{
            this.setState({
                screens: [
                    {
                      "id": 5047931296940032,
                      "screenName": "",
                      "description": "",
                      "thumbnailUrl": "",
                      "deleted": false
                    },
                    {
                      "id": 5502247099039744,
                      "screenName": "",
                      "description": "không",
                      "thumbnailUrl": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/screens/51e72d34-0272-f746-6c31-f79d42331fd5.png",
                      "deleted": true
                    },
                    {
                      "id": 6394789829148672,
                      "screenName": "",
                      "description": "không",
                      "thumbnailUrl": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/screens/870b7994-efad-de9c-01fc-6762795f9101.jpg",
                      "deleted": true
                    }
                  ]
            })
        })
    }

    componentDidUpdate(){
        const { screens, screen } = this.state;
        screens.map((screen, index) =>{
            // console.log(screen)
        })
   
    }

    componentDidMount(){
        this.getAllScreenInApp();
    }

    render(){
        const { screens,screen } =this.state;
        return (
            <>
            <ModalUpdateScreen screen={screen}  screens={screens}></ModalUpdateScreen>
            <div id="content">
                <div className="container-fluid">
                    <h1 className="text-center">Quản lí màn hình trong App</h1>
                    <button className='btn btn-success mb-2'
                    data-toggle="modal"
                    data-target="#ModalUpdateScreen"
                    onClick={()=>{
                        this.setState({
                            screen:null
                        })
                    }}
                    >Thêm Mới</button>
                    <table className="table table-hover">
                        <thead>
                            <tr className="text-center">
                                <th className="align-middle" scope="col">STT</th>
                                <th className="align-middle" scope="col">ID</th>
                                <th className="align-middle" scope="col">Tên màn hình</th>
                                {/* <th className="align-middle" scope="col">Mô tả</th> */}
                                <th className="align-middle" scope="col">Hình ảnh mô tả</th>
                                <th className="align-middle" scope="col">Trạng thái</th>
                                <th className="align-middle" scope="col">Chỉnh sửa</th>
                            </tr>
                        </thead>
                        <tbody>
                            {screens && screens.map((item, index)=>{
                                return (
                                <tr>
                                    <td className="align-middle text-center" scope="col">{index +1}</td>
                                    <td className="align-middle text-center" scope="col">{item.id}</td>
                                    <td className="align-middle text-center" scope="col">{item.screenName}</td>
                                    {/* <td className="align-middle text-center" scope="col">{item.description}</td> */}
                                    <td className="align-middle text-center" scope="col">
                                        <img src={item.thumbnailUrl} alt="" style={{ width : "100px"}} />
                                    </td>
                                    <td className="align-middle text-center" scope="col">{item.deleted ?"Không khả dụng" :"Khả dụng"}</td>
                                    <td className="align-middle text-center" scope="col">
                                        <button className='btn btn-info'
                                        data-toggle="modal"
                                        data-target="#ModalUpdateScreen"
                                        onClick={()=>{
                                            this.setState({
                                                screen: item
                                            })
                                        }}
                                        >Chỉnh sửa</button>
                                    </td>
                                </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
        );
    }
}