import React, {Component} from 'react';
import {Link} from "react-router-dom";

export default class UserItemInUsersPage extends Component {
    render() {
        const {user} = this.props;
        let username = user.name;
        let urlAvatar = user.profileImageLink;

        return (
            <div className="col-4 col-md-3 col-xl-2 item item-0">
                  <Link to={user.url} title={username} className="image">
                    <span className="overlay" />
                    <img src={urlAvatar} alt={username} />
                  </Link>
                  <h4 className="text-center singer_name">
                    <Link to={user.url} href="http://m.okara.vn/ca-si/le-quyen" title={username}>{username.length > 14 ? username.substring(0, 11) + '...' : username}</Link>
                  </h4>
                </div>
        )
    }
}