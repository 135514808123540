import React, { Component } from 'react';
import axios from 'axios';
import Chart from 'chart.js/auto'
import { Bar } from 'react-chartjs-2'
import lodash from 'lodash';
export default class RankGiftPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            giftSortBuyPrice: []
        }

    }

    componentDidMount() {
        this.getGiftsLastMonth();
        this.getGiftsThisMonth();
    }

    getGiftsThisMonth() {
        axios.post(global.config.apiDomain + '/rest/gifts/getTopGifts', 1, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(res => {
                console.log(res.data)
                let gifts = res.data;
                let giftSortBuyPrice = gifts.map(e => e.buyPrice = e.buyPrice * e.counter);
                giftSortBuyPrice = lodash.orderBy(gifts, "buyPrice", 'desc');

                let labelGiftSortBuyPrice = giftSortBuyPrice.map(e => e.name);
                let dataGiftSortBuyPrice = giftSortBuyPrice.map(e => e.buyPrice);

                let giftSortByCounter = lodash.orderBy(gifts, "counter", 'desc');
                let labelGiftSortByCounter = giftSortByCounter.map(e => e.name);
                let dataGiftSortByCounter = giftSortByCounter.map(e => e.counter);

                this.setState({
                    gifts: res.data,

                    giftSortBuyPrice: giftSortBuyPrice,
                    labelGiftSortBuyPrice: labelGiftSortBuyPrice,
                    dataGiftSortBuyPrice: dataGiftSortBuyPrice,

                    giftSortByCounter: giftSortByCounter,
                    labelGiftSortByCounter: labelGiftSortByCounter,
                    dataGiftSortByCounter: dataGiftSortByCounter
                });
            });
    }

    getGiftsLastMonth() {
        axios.post(global.config.apiDomain + '/rest/gifts/getTopGifts', 2, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => {
                console.log(res.data)
                let gifts = res.data;
                let giftSortBuyPriceLastMonth = gifts.map(e => e.buyPrice = e.buyPrice * e.counter);
                giftSortBuyPriceLastMonth = lodash.orderBy(gifts, "buyPrice", 'desc');

                let labelGiftSortBuyPriceLastMonth = giftSortBuyPriceLastMonth.map(e => e.name);
                let dataGiftSortBuyPriceLastMonth = giftSortBuyPriceLastMonth.map(e => e.buyPrice);

                let giftSortByCounterLastMonth = lodash.orderBy(gifts, "counter", 'desc');
                let labelGiftSortByCounterLastMonth = giftSortByCounterLastMonth.map(e => e.name);
                let dataGiftSortByCounterLastMonth = giftSortByCounterLastMonth.map(e => e.counter);

                this.setState({
                    gifts: res.data,

                    giftSortBuyPriceLastMonth: giftSortBuyPriceLastMonth,
                    labelGiftSortBuyPriceLastMonth: labelGiftSortBuyPriceLastMonth,
                    dataGiftSortBuyPriceLastMonth: dataGiftSortBuyPriceLastMonth,

                    giftSortByCounterLastMonth: giftSortByCounterLastMonth,
                    labelGiftSortByCounterLastMonth: labelGiftSortByCounterLastMonth,
                    dataGiftSortByCounterLastMonth: dataGiftSortByCounterLastMonth
                });
            });
    }

    render() {
        const { giftSortBuyPrice, dataGiftSortBuyPrice, labelGiftSortBuyPrice, labelGiftSortByCounter, dataGiftSortByCounter,
                giftSortBuyPriceLastMonth, dataGiftSortBuyPriceLastMonth, labelGiftSortBuyPriceLastMonth, labelGiftSortByCounterLastMonth, dataGiftSortByCounterLastMonth } = this.state;
        return (
            <div id="content">
                <div>
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <a className="nav-item nav-link active" id="nav-last-month-tab" data-toggle="tab" href="#nav-last-month" role="tab" aria-controls="nav-home" aria-selected="true">Tháng trước</a>
                            <a className="nav-item nav-link " id="nav-this-month-tab" data-toggle="tab" href="#nav-this-month" role="tab" aria-controls="nav-profile" aria-selected="false">Tháng hiện tại</a>
                        </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-last-month" role="tabpanel" aria-labelledby="nav-last-month-tab">
                            <div>
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="pills-counter-last-month-tab" data-toggle="pill" href="#pills-counter-last-month" role="tab" aria-controls="pills-counter-last-month" aria-selected="true">Biểu đồ theo iCoin</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-icoin-last-month-tab" data-toggle="pill" href="#pills-icoin-last-month" role="tab" aria-controls="pills-icoin-last-month" aria-selected="false">Biểu đồ theo lượt mua</a>
                                    </li>
                                </ul>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-counter-last-month" role="tabpanel" aria-labelledby="pills-counter-last-month-tab">
                                        <Bar
                                            data={{
                                                labels: labelGiftSortBuyPriceLastMonth,
                                                datasets: [{
                                                    data: dataGiftSortBuyPriceLastMonth,
                                                    backgroundColor: ["rgba(255, 99, 132, 1)", "rgba(53, 162, 235, 1)"],
                                                    barThickness: 13

                                                }]
                                            }}
                                            height={400}
                                            options={{
                                                indexAxis: 'y',
                                                responsive: true,
                                                plugins: {
                                                    legend: {
                                                        display: false,
                                                    },
                                                    title: {
                                                        display: true,
                                                        text: 'Biểu đồ theo lượng tiêu thụ iCoin',

                                                    },
                                                },
                                                scales: {
                                                    y: {
                                                        beginAtZero: true
                                                    }
                                                }
                                                //  ,
                                                //  interaction: {
                                                //      mode: "dataset",
                                                //      intersect: true
                                                //  }
                                            }}
                                        >
                                        </Bar>
                                    </div>
                                    <div className="tab-pane fade" id="pills-icoin-last-month" role="tabpanel" aria-labelledby="pills-icoin-last-month-tab">
                                        <Bar
                                            data={{
                                                labels: labelGiftSortByCounterLastMonth,
                                                datasets: [{
                                                    data: dataGiftSortByCounterLastMonth,
                                                    backgroundColor: ["rgba(255, 99, 132, 1)", "rgba(53, 162, 235, 1)"],
                                                    barThickness: 12,

                                                }]
                                            }}
                                            height={400}
                                            options={{
                                                indexAxis: "y",
                                                responsive: true,
                                                plugins: {
                                                    legend: {
                                                        display: false,
                                                    },
                                                    title: {
                                                        display: true,
                                                        text: 'Biểu đồ theo lượt mua',

                                                    },
                                                }
                                            }}
                                        >
                                        </Bar>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-this-month" role="tabpanel" aria-labelledby="nav-this-month-tab">
                            <div>
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="pills-counter-this-month-tab" data-toggle="pill" href="#pills-counter-this-month" role="tab" aria-controls="pills-counter-this-month" aria-selected="true">Biểu đồ theo iCoin</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="pills-icoin-this-month-tab" data-toggle="pill" href="#pills-icoin-this-month" role="tab" aria-controls="pills-icoin-this-month" aria-selected="false">Biểu đồ theo lượt mua</a>
                                    </li>
                                </ul>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-counter-this-month" role="tabpanel" aria-labelledby="pills-counter-this-month-tab">
                                        <Bar
                                            data={{
                                                labels: labelGiftSortBuyPrice,
                                                datasets: [{
                                                    data: dataGiftSortBuyPrice,
                                                    backgroundColor: ["rgba(255, 99, 132, 1)", "rgba(53, 162, 235, 1)"],
                                                    barThickness: 13

                                                }]
                                            }}
                                            height={400}
                                            options={{
                                                indexAxis: 'y',
                                                responsive: true,
                                                plugins: {
                                                    legend: {
                                                        display: false,
                                                    },
                                                    title: {
                                                        display: true,
                                                        text: 'Biểu đồ theo lượng tiêu thụ iCoin',

                                                    },
                                                },
                                                scales: {
                                                    y: {
                                                        beginAtZero: true
                                                    }
                                                }
                                                //  ,
                                                //  interaction: {
                                                //      mode: "dataset",
                                                //      intersect: true
                                                //  }
                                            }}
                                        >
                                        </Bar>
                                    </div>
                                    <div className="tab-pane fade" id="pills-icoin-this-month" role="tabpanel" aria-labelledby="pills-icoin-this-month-tab">
                                        <Bar
                                            data={{
                                                labels: labelGiftSortByCounter,
                                                datasets: [{
                                                    data: dataGiftSortByCounter,
                                                    backgroundColor: ["rgba(255, 99, 132, 1)", "rgba(53, 162, 235, 1)"],
                                                    barThickness: 12,

                                                }]
                                            }}
                                            height={400}
                                            options={{
                                                indexAxis: "y",
                                                responsive: true,
                                                plugins: {
                                                    legend: {
                                                        display: false,
                                                    },
                                                    title: {
                                                        display: true,
                                                        text: 'Biểu đồ theo lượt mua',

                                                    },
                                                }
                                            }}
                                        >
                                        </Bar>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        )
    }
}