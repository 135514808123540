import React, { Component } from 'react'
import numeral from 'numeral';
import {Link} from 'react-router-dom';
export default class GiftLine extends Component {
    render() {
        const {gift,index} = this.props;
        let profileImageLink = gift.userProfile;
        let totalScore= gift.totalScore;
        var classForCounter = 'SPAN_40';
        if (index == 0) classForCounter = 'SPAN_17';
        if (index == 1) classForCounter = 'SPAN_23';
        if (index == 2) classForCounter = 'SPAN_29';
        let order = classForCounter; 
        let randomFbAvatar = "https://storage.googleapis.com/ikara-storage/randomavatar/" + (gift.key%100) + ".jpg";

        return (
            <li className="LI_14">
                <div className="A_15" title={gift.userName}>
                    <Link to={"/user/" + gift.key}>
                    <img src={profileImageLink} className="IMG_16" alt="" onError={(e)=>{e.target.onerror = null; e.target.src=randomFbAvatar}} />
                    </Link>
                    <span className={order} />
                    </div>
                <p className="P_18" title={gift.totalScore}>
                    <span className="SPAN_19" />{numeral(gift.totalScore).format('0a')}
                </p>
            </li>
        )
    }
}
