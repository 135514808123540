import React, { Component } from 'react';
import {Link} from "react-router-dom";
import { withTranslation } from 'react-i18next';

import TopDuetRecordingsItem from './TopDuetRecordingsItem';

class TopDuetRecordings extends Component {
	render() {
		const {topDuetRecordings, t} = this.props;

		return (
			<div className="chuyenmuc nhachot">
				<h2 className="subtitle">
					<Link to="/top-duet-recordings"> {t("home_page.top_duet_recordings","Song ca")} </Link>
					<i className="fa fa-angle-right" aria-hidden="true" />
				</h2>
				<div className="row nhachot-content list-song">
					{topDuetRecordings && topDuetRecordings.map((recording,index) => (
					<TopDuetRecordingsItem 
						key={recording._id}
						recording={recording}
						></TopDuetRecordingsItem>
					))}
				</div>
			</div>
		);
	}
}

export default withTranslation()(TopDuetRecordings)
