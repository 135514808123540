import React, { Component } from 'react';
import Moment from 'react-moment';
import "../css/message.css";


export default class MessageItem extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        const { message, user, targetUser, handleTargetUser } = this.props;

        var lastMessage = message.lastMessage;

        if (lastMessage instanceof Object) {
            lastMessage = JSON.stringify(lastMessage)
        };

        return (
            <>
                <a onClick={() => handleTargetUser(message)}
                    className={message.fromUserId == targetUser.fromUserId
                        ? "border border-light list-group-item list-group-item-action rounded-0 active-bot text-white"
                        : "border border-light list-group-item list-group-item-action list-group-item-light rounded-0"}
                    id={message.lastUserId && !message.lastUserId.includes("KAKA-") ? "lastmessage" : ""}
                >
                    <div className="media">
                        <img width={50} className="message-item-img rounded-circle" src={message.userProfile} alt="user" />
                        <div className="media-body ml-4">
                            <div className="d-flex align-items-center justify-content-between mb-1">
                                <h6 className="mb-0 user-chat-name">{message.userName}</h6>
                            </div>
                            <small className="small font-weight-bold">
                                {message.dateTime != null && <Moment
                                    fromNow
                                    tz="Asia/Ho_Chi_Minh"
                                    locale="vi"
                                >{message.dateTime}
                                </Moment>}
                            </small>
                            <p className="mb-0 text-small">
                                {(lastMessage && lastMessage.length > 35)
                                    ? lastMessage.substring(0, 35) + "..."
                                    : lastMessage}
                            </p>
                        </div>
                        <a href={`/admin/account-manager?id=${targetUser.fromUserId}`} target="_blank">
                            <i className="fa-solid fa-user"></i>
                        </a>
                    </div>

                </a>
            </>
        )
    }
}