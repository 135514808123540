import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';
import bindModel from '../../../common/bindModel.js';
import { takeRight } from 'lodash';

// const $ = window.$;

export default class ModalUpdateCompetition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            realGifts: [],
            categories: [],
            cups: [],
            avas: [],
            commentFrames: [],
            roomEntryEffects: [],
            userDecorations: [],
            personalCards: [],
            medalDecoration: [],
            frameDecoration: [],
            singEffects: [],
            competition: null,
            previewImg: null,
            loading: false,
            deepARStickerModels: [],
            choiceStickerDeepAr: [],
            countDeepAr: 1,
            strDeepAr: "",
            preValue: null,
            choiceHashtag: [],
            models: [],
            strHashtag: "",
            countHashtag: 1,
        }
        this.getAllRealGift = this.getAllRealGift.bind(this);
        this.getCategories = this.getCategories.bind(this);
        this.getCups = this.getCups.bind(this);
        this.getAvas = this.getAvas.bind(this);
        this.getDecorationItemType = this.getDecorationItemType.bind(this);
        this.loadItem = this.loadItem.bind(this);

        this.uploadAnimationToS3 = this.uploadAnimationToS3.bind(this);
        this.uploadAnimationToS4 = this.uploadAnimationToS4.bind(this);
        this.uploadAnimationToS5 = this.uploadAnimationToS5.bind(this);

        this.showImgPreview = this.showImgPreview.bind(this);
        this.saveCompetition = this.saveCompetition.bind(this);
        this.handleChoiceDeepAr = this.handleChoiceDeepAr.bind(this);
        this.handleChoiceHashtag = this.handleChoiceHashtag.bind(this);
        this.handleShowChoice = this.handleShowChoice.bind(this);
        this.handleShowHashtag = this.handleShowHashtag.bind(this);

        this.handleDeleteDeepAr = this.handleDeleteDeepAr.bind(this);
    }

    componentWillReceiveProps(props) {
        console.table(this.state.competition);

        $("#choiceStickerDeepAr").html("");
        $("#choiceHashtag").html("");

        setTimeout(() => {
            const { strDeepAr,strHashtag } = this.state;
            let competition = props.competition;
            if (competition && !competition.backgroundCode) {
                competition.backgroundCode = {
                    backgroundCodeContest: '',
                    backgroundCodeRule: '',
                    backgroundCodeRound1: '',
                    backgroundCodeRound2: '',
                    backgroundCodeRound3: '',
                    backgroundCodeRank: '',
                    backgroundCodeButton: '',
                    backgroundCodeFooter: '',
                }
            }

            let number = 1;
            let arr = [];
            let array = [];
            if (competition && competition.stickersDeepAr && competition.stickersDeepAr.length > 0) {
                for (let i = 0; i < competition.stickersDeepAr.length; i = i + 1) {
                    arr.push(competition.stickersDeepAr[i]._id);
                }
            }
            if (competition && competition.stickersDeepAr && competition.stickersDeepAr.length > 0) {
                for (let i = 0; i < competition.stickersDeepAr.length; i = i + 1) {
                    let id = "selectDeepAr" + number;
                    $("#choiceStickerDeepAr").append(
                        `<div class="row">
                        <div class="col-6">
                        <select class="form-control" name="selectDeepAr${number}" id="selectDeepAr${number}">
                        ${strDeepAr}
                        </select>
                        </div>
                        <div class="col-6">
                        <button class="btn btn-danger" id="deleteDeepAr${number}" name=${number}>Xóa</button>
                        </div>
                        </div>`)

                    $(`#selectDeepAr${number}`).val(competition.stickersDeepAr[i]._id).change();
                    let idC = 0;
                    $(`#selectDeepAr${number}`).on('focusin', function () {
                        console.log("Saving value " + $(this).val());
                        idC = $(this).val();
                    });
                    $(`#selectDeepAr${number}`).on("change", (e) => {
                        this.handleChoiceDeepAr(e, idC)
                    })

                    $(`#deleteDeepAr${number}`).on("click", () => {
                        let element = $(`#${id}`);
                        let valueOfElement = element.val();
                        let index = arr.indexOf(valueOfElement)
                        if (index >= 0) {
                            arr.splice(index, 1);
                        }
                        this.setState({
                            choiceStickerDeepAr: arr
                        })
                        element.val('-1').change();
                    })
                    number += 1;
                }
                this.setState({
                    countDeepAr: number
                })
            }
            if (competition && competition.hashtags && competition.hashtags.length > 0) {
                for (let i = 0; i < competition.hashtags.length; i ++) {
                    array.push(competition.hashtags[i]);
                }
            }
            console.log("arrayd",array);
            if (competition && competition.hashtags && competition.hashtags.length > 0) {
                for (let i = 0; i < competition.hashtags.length; i++) {
                    let id = "selectHashtag" + number;
                    $("#choiceHashtag").append(
                        `<div class="row">
                        <div class="col-6">
                        <select class="form-control" name="selectHashtag${number}" id="selectHashtag${number}">
                        ${strHashtag}
                        </select>
                        </div>
                        <div class="col-6">
                        <button class="btn btn-danger" id="deleteDeepAr${number}" name=${number}>Xóa</button>
                        </div>
                        </div>`)

                    $(`#selectHashtag${number}`).val(competition.hashtags[i]).change();
                    let idC = 0;
                    $(`#selectHashtag${number}`).on('focusin', function () {
                        console.log("Saving value10 " + $(this).val());
                        idC = $(this).val();
                    });
                    $(`#selectHashtag${number}`).on("change", (e) => {
                        this.handleChoiceHashtag(e, idC)
                    })

                    $(`#deleteDeepAr${number}`).on("click", () => {
                        let element = $(`#${id}`);
                        let valueOfElement = element.val();
                        let index = array.indexOf(valueOfElement)
                        if (index >= 0) {
                            array.splice(index, 1);
                        }
                        this.setState({
                            choiceHashtag: array
                        })
                        element.val('-1').change();
                    })
                    number += 1;
                }
                this.setState({
                    countHashtag: number
                })
            }
            if (competition && competition.stickersDeepAr && competition.stickersDeepAr.length == 0 || competition && competition.stickersDeepAr == null) {
                this.setState({
                    countDeepAr: 1
                })
            }

            this.setState({
                competition: competition,
                previewImg: props.previewImg,
                choiceStickerDeepAr: arr,
                choiceHashtag:array
            })
            // change day for event game
            if (competition && competition.timeEndRound1 && competition.timeEndRound2 && competition.idEvent == "GAME") {
                this.setState({
                    competition: {...competition, timeEndRound1: competition.timeEndRound2},
                })
            }


        })
    }
    loadItem() {
        axios.post(global.config.apiDomain + "/rest/deepAREffect/getAll")
            .then(res => {
                if (res.data.status == "OK") {
                    let str = `<option value=${-1}>Chọn hiệu ứng</option>`;
                    if (res.data && res.data.deepAREffectModels) {
                        res.data.deepAREffectModels.map((deepAr, index) => {
                            str += `<option value=${deepAr._id}>${deepAr.name}</option>`
                        })
                    }
                    console.log("deepAREffect", res.data)
                    this.setState({
                        deepARStickerModels: res.data.deepAREffectModels,
                        strDeepAr: str
                    })
                }
            })

    }

    loadHashtag() {
        axios.post(global.config.apiDomain + "/rest/hashtag/getAllHashtag")
            .then(res => {
                if (res.data.status == "OK") {
                    let str = `<option value=${-1}>Chọn hashtag</option>`;
                    if (res.data && res.data.models) {
                        res.data.models.map((model, index) => {
                            str += `<option value=${model.id}>${model.id}</option>`
                        })
                    }
                    this.setState({
                        models: res.data.models,
                        strHashtag: str
                    })
                }
            })

    }

    handleChoiceDeepAr(event, preValue) {
        const { choiceStickerDeepAr } = this.state;
        let idChoice = event.target.value;
        let arr = choiceStickerDeepAr;

        if (arr.includes(idChoice) && idChoice != -1) {
            toast.warning("Đã được chọn")
            event.target.value = preValue;
            return
        }

        if (idChoice != -1 && idChoice != 0) {
            arr.push(idChoice)
            this.setState({
                choiceStickerDeepAr: arr,
            })
        }
    }

  handleChoiceHashtag(event, preValue) {
    const { choiceHashtag } = this.state;
    let idChoice = event.target.value;
    console.log("idChoice",idChoice);
    let arr = choiceHashtag;
    console.log("arrarr",arr);

    if (arr.includes(idChoice)&& idChoice != -1) {
        toast.warning("Đã được chọn")
        event.target.value = preValue;
        return
    }
    if (idChoice != -1 && idChoice != 0) {

        arr.push(idChoice)
        this.setState({
            choiceHashtag: arr,
        })
    }
}


    handleDeleteDeepAr(event) {
        const { choiceStickerDeepAr } = this.state;
        let countDeepAr = event.target.name
        let element = $(`#${countDeepAr}`);
        let valueOfElement = element.val()

        let arr = choiceStickerDeepAr;
        let index = arr.indexOf(valueOfElement)
        if (index != -1) {
            arr.splice(index, 1);
        }

        this.setState({
            choiceStickerDeepAr: arr
        })
        element.val('-1').change();
    }
    handleShowChoice() {
        const { strDeepAr, countDeepAr, deepARStickerModels, choiceStickerDeepAr } = this.state;
        if (deepARStickerModels == null) {
            toast.warning("Không có sticker để hiển thị")
            return
        }

        let arr = deepARStickerModels;
        if (countDeepAr > arr.length) {
            if (countDeepAr == 1) {
                toast.warning("Không có sticker để hiển thị")
            } else {
                toast.warning("Đã hiển thị tối đa số lượng sticker")
            }
            return
        }

        $("#choiceStickerDeepAr").append(
            `<div class="row">
            <div class="col-6">
            <select class="form-control" name="selectDeepAr${countDeepAr}" id="selectDeepAr${countDeepAr}">
            ${strDeepAr}
            </select>
            </div>
            <div class="col-6">
            <button class="btn btn-danger" id="deleteDeepAr${countDeepAr}">Xóa</button>
            </div>
            </div>`)

        $(`#selectDeepAr${countDeepAr}`).on("change", this.handleChoiceDeepAr)
        $(`#deleteDeepAr${countDeepAr}`).on("click", () => {
            let element = $(`#selectDeepAr${countDeepAr}`);
            let valueOfElement = element.val();
            element.val('-1').change();
            let arr = choiceStickerDeepAr;
            let index = arr.indexOf(valueOfElement)
            if (index >= 0) {
                arr.splice(index, 1);
            }

            this.setState({
                choiceStickerDeepAr: arr
            })
        })
        let number = countDeepAr;
        this.setState({
            countDeepAr: number + 1
        })
    }

    handleShowHashtag() {
        const { strHashtag, countHashtag, models, choiceHashtag, preValue} = this.state;
        if (models == null) {
            toast.warning("Không có hashtag để hiển thị")
            return;
        }
        let arr = models;
        if (countHashtag > arr.length) {
            if (countHashtag === 1) {
                toast.warning("Không có hashtag để hiển thị")
            } else {
                toast.warning("Đã hiển thị tối đa số lượng hashtag")
            }
            return;
        }
        const dropdownId = `selectHashtag${countHashtag}`;

        $("#choiceHashtag").append(
            `<div class="row">
            <div class="col-6">
            <select class="form-control" name="${dropdownId}" id="${dropdownId}">
            ${strHashtag}
            </select>
            </div>
            <div class="col-6">
            <button class="btn btn-danger" id="deleteHashtag${countHashtag}" onclick="removeHashtag('${dropdownId}')">Xóa</button>
            </div>
            </div>`
        );

        // $(`#${dropdownId}`).on("change", () => this.handleChoiceHashtag);
        $(`#${dropdownId}`).on("change", this.handleChoiceHashtag)

        // $(`#${dropdownId}`).on("change", (event) => this.handleChoiceHashtag(event, preValue))
        $(`#deleteHashtag${countHashtag}`).on("click", () => {
            let element = $(`#${dropdownId}`);
            let valueOfElement = element.val();
            element.val('-1').change();
            let array = choiceHashtag;
            let index = array.indexOf(valueOfElement)
            if (index >= 0) {
                array.splice(index, 1);
            }

            this.setState({ 
                choiceHashtag: array
            })
        })
        
        let number = countHashtag;
        this.setState({
            countHashtag: number + 1
        });
    }

    componentDidMount() {
        this.getAllRealGift();
        this.getCategories();
        this.getCups();
        this.getAvas();
        this.getDecorationItemType();
        this.loadItem();
        this.loadHashtag();

        $("#choiceStickerDeepAr").html("");
        $("#choiceHashtag").html("");
    }

    getCategories() {
        let modal = {
            isDelete: false
        };

        axios.post(global.config.apiDomain + '/rest/categories/getAllCategory', modal).then((res) => {
            this.setState({
                categories: res.data.categories
            });
        }).catch((error) => {
            console.log('error get categories ====');
            console.log(error);
        });
    }
    getCups() {
        axios.post(global.config.apiDomain + '/rest/gifts').then(res => {
            this.setState({
                cups: res.data,
            });
        });
    }
    getDecorationItemType() {
        let url = global.config.apiDomain + "/rest/decorationItemsType/getAllItems";
        axios.post(url)
            .then(res => {
                let decorations = [];
                let medal = [];
                for (let i = 0; i < res.data.decorationItemTypes.length; i++) {
                    if (res.data.decorationItemTypes[i].type == "FRAME") {
                        decorations.push(res.data.decorationItemTypes[i]);
                    }
                    if (res.data.decorationItemTypes[i].type == "MEDAL") {
                        medal.push(res.data.decorationItemTypes[i]);
                    }
                }
                this.setState({
                    frameDecoration: decorations,
                    medalDecoration: medal,
                })
            })
    }
    getAllRealGift() {
        let url = global.config.apiDomain + "/rest/realGift/getAllRealGift";
        axios.post(url)
            .then(res => {
                this.setState({
                    realGifts: res.data.models,
                })
            })
    }
    getAvas() {
        axios.post(global.config.apiDomain + '/web.v5.GetListShopItem').then(res => {
            let avatarFrameList = [];
            let userDecorationList = [];
            let commentFrameList = [];
            let personalCardList = [];
            let roomEntryEffectList = [];
            let singEffectsList = [];

            for (let i = 0; i < res.data.listItem.length; i++) {
                let type = res.data.listItem[i].type;
                switch (type) {
                    case "CommentFrame":
                        commentFrameList.push(res.data.listItem[i])
                        break;
                    case "RoomEntryEffect":
                        roomEntryEffectList.push(res.data.listItem[i])
                        break;
                    case "UserDecoration":
                        userDecorationList.push(res.data.listItem[i])
                        break;
                    case "PersonalCard":
                        personalCardList.push(res.data.listItem[i])
                        break;
                    case "SingEffect":
                        singEffectsList.push(res.data.listItem[i])
                        break;
                    default: avatarFrameList.push(res.data.listItem[i])
                }
            }
            this.setState({
                ...this.state,
                avas: avatarFrameList,
                commentFrames: commentFrameList,
                roomEntryEffects: roomEntryEffectList,
                userDecorations: userDecorationList,
                personalCards: personalCardList,
                singEffects: singEffectsList,
            });
        })
    }

    saveCompetition() {
        const { choiceStickerDeepAr, deepARStickerModels, choiceHashtag, models } = this.state;
        let obj = { ...this.state.competition };

        if (
            obj == undefined ||
            !obj.idEvent ||
            !obj.language ||
            !obj.miniBackgroundUrl ||
            !obj.name ||
            !obj.timeShowStart ||
            !obj.timeShowEnd
        ) {
            console.table(obj);
            return toast.error('Nhập đầy đủ thông tin CÀI ĐẶT CHÍNH');
        }

        if ((obj.idEvent == "TheVoice" || obj.idEvent == "GAME") && (
            !obj.imgUrl ||
            !obj.backgroundDialogUrl ||
            !obj.describe ||
            !obj.slogan ||
            !obj.theVoiceRuleFull.titleTime ||
            !obj.theVoiceRuleFull.detailTime ||
            !obj.theVoiceRuleFull.titleRule ||
            !obj.theVoiceRuleFull.detailRule ||
            !obj.theVoiceRuleFull.titleOther ||
            !obj.theVoiceRuleFull.detailOther ||
            !obj.numRewardRound2 ||
            !obj.backgroundCode ||
            !obj.backgroundCode.backgroundCodeContest ||
            !obj.backgroundCode.backgroundCodeRule ||
            !obj.backgroundCode.backgroundCodeRound1 ||
            !obj.backgroundCode.backgroundCodeRank ||
            !obj.backgroundCode.backgroundCodeButton ||
            !obj.backgroundCode.backgroundCodeFooter
        )) {
            console.table(obj);
            return toast.error('Nhập đầy đủ thông tin CÀI ĐẶT CHÍNH cho TheVoice và GAME');
        } 

        if (obj.idEvent == "TheVoice" && (
            !obj.typePermissionAccept ||
            !obj.typeRecordingAccept ||
            !obj.typeRecordingMedia
        )) {
            console.table(obj);
            return toast.error('Nhập đầy đủ thông tin CÀI ĐẶT CHÍNH cho TheVoice');
        } 


        if ( obj.idEvent == "TheVoice" && 
            (!obj.timeStartRound1 ||
            !obj.timeEndRound1 ||
            !obj.timeEndRound2 ||
            !obj.limitRecordingRound1 ||
            !obj.limitRecordingRound2 ||
            !obj.maxNumMediaOneUse ||
            !obj.minLevelExaminer ||
            !obj.maxMediaForOneExaminer ||
            !obj.maxExaminerForOneMedia ||
            !obj.minNumMark ||
            !obj.canApplyExaminer ||
            (obj.canApplyExaminer != '0' && obj.canApplyExaminer != '1'))
        ) {
            console.table(obj);
            return toast.error('Nhập đầy đủ thông tin VÒNG THI cho TheVoice');
        } 

        if (obj.timeStartRound1 > obj.timeEndRound1) {
            return toast.error('Thời gian bắt đầu cuộc thi phải trước thời gian kết thúc');
        }

        if (obj.idEvent == "TheVoice") {
            obj.timeStartRound1 = Date.parse(obj.timeStartRound1);
            obj.timeEndRound1 = Date.parse(obj.timeEndRound1);
            obj.timeEndRound2 = Date.parse(obj.timeEndRound2);
        } else if (obj.idEvent == "GAME" || obj.idEvent == "WebView") {
            let timeEndRound1 = obj.timeEndRound1;
            obj.timeStartRound1 = Date.parse(obj.timeStartRound1);
            obj.timeEndRound1 = obj.timeStartRound1;
            obj.timeEndRound2 = Date.parse(timeEndRound1);
        }

        obj.timeShowStart = Date.parse(obj.timeShowStart);
        obj.timeShowEnd = Date.parse(obj.timeShowEnd);
    
        let arrSticker = [];
        choiceStickerDeepAr && choiceStickerDeepAr.map((choiceSticker, index) => {
            deepARStickerModels && deepARStickerModels.map((sticker, index) => {
                if (choiceSticker == sticker._id) {
                    arrSticker.push(sticker);
                }
            })
        })

        obj.stickersDeepAr = arrSticker;
        obj.categoryId = +obj.categoryId;
        if (obj.canApplyExaminer === '0') {
            obj.canApplyExaminer = false;
        } else if (obj.canApplyExaminer === '1') {
            obj.canApplyExaminer = true;
        }
       
        obj.hashtags = choiceHashtag;

        const myJSON = JSON.stringify(obj);
        console.log(myJSON);

        this.setState({
            loading: true
        });
        axios.post(global.config.apiDomain + '/rest/theVoiceV2/addOrUpdateTheVoice', myJSON)
            .then(res => {
                this.setState({
                    loading: false
                });

                if (res.data.status == "OK") {
                    window.$("#modalUpdateCompetition").modal("hide");
                    this.props.resetList();
                    toast.success(res.data.message);
                } else {
                    toast.warning(res.data.message);
                }
            }, error => {
                this.setState({
                    loading: false
                });
                toast.error(error);
            })
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }
    uploadAnimationToS3(event) {
        let target = event.target;
        if (target.files[0].size > 10495760) {
            toast.error("Kích thước file phải dưới 10mb.");
            return;
        }

        this.setState({
            ...this.state,
            loading: true
        });

        let property = target.name;
        let competition = this.state.competition;
        let bucketName = "ikara-data/images/contests";
        let extension = target.files[0].name.split('.').pop().toLowerCase();
        let keyName = this.guid() + "." + extension;
        let contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";

        let fileReader = new FileReader();
        fileReader.onload = function () {
            let fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    this.setState({
                        loading: false
                    });
                    this.setState({
                        competition: {
                            ...competition,
                            [property]: 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName
                        }
                    });
                }.bind(this),
            });
        }.bind(this);
        fileReader.readAsArrayBuffer(target.files[0]);
    }
    uploadAnimationToS4(event) {
        let target = event.target;
        if (target.files[0].size > 10495760) {
            toast.error("Kích thước file phải dưới 10mb.");
            return;
        }

        this.setState({
            ...this.state,
            loading: true
        });

        let property = target.name;
        let competition = this.state.competition;
        let bucketName = "ikara-data/images/contests";
        let extension = target.files[0].name.split('.').pop().toLowerCase();
        let keyName = this.guid() + "." + extension;
        let contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";

        let fileReader = new FileReader();
        fileReader.onload = function () {
            let fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    this.setState({
                        loading: false
                    });
                    this.setState({
                        competition: {
                            ...competition,
                            [property]: 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName
                        }
                    });
                }.bind(this),
            });
        }.bind(this);
        fileReader.readAsArrayBuffer(target.files[0]);
    }
    uploadAnimationToS5(event) {
        let target = event.target;
        if (target.files[0].size > 10495760) {
            toast.error("Kích thước file phải dưới 10mb.");
            return;
        }

        this.setState({
            ...this.state,
            loading: true
        });

        let property = target.name;

        let competition = this.state.competition;
        let bucketName = "ikara-data/images/contests";
        let extension = target.files[0].name.split('.').pop().toLowerCase();
        let keyName = this.guid() + "." + extension;
        let contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";

        let fileReader = new FileReader();

        fileReader.onload = function () {
            let fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    this.setState({
                        loading: false
                    });
                    this.setState({
                        competition: {
                            ...competition,
                            [property]: 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName
                        }
                    });
                }.bind(this),
            });
        }.bind(this);
        fileReader.readAsArrayBuffer(target.files[0]);
    }

    showImgPreview(event) {
        const { cups, avas, roomEntryEffects, userDecorations, commentFrames, personalCards, frameDecoration, medalDecoration, realGifts, singEffects } = this.state;
        let tagId = event.target.value;
        let tagName = event.target.id;
        let type = tagName.slice(0, 3);
        let index = +tagName.substr(6, 1);
        let idAvatar = tagId;
        let items;

        switch (type) {
            case "ava":
                items = avas;
                break;
            case "roo":
                items = roomEntryEffects;
                break;
            case "prs":
                items = personalCards;
                break;
            case "cmt":
                items = commentFrames;
                break;
            case "udc":
                items = userDecorations;
                break;
            case "fra":
                items = frameDecoration;
                items.map((item) => {
                    if (item.name === tagId) {
                        idAvatar = item.id;
                    }
                })
                break;
            case "med":
                items = medalDecoration;
                items.map((item) => {
                    if (item.name === tagId) {
                        idAvatar = item.id;
                    }
                })
                break;
            case "rgf":
                items = realGifts;
                break;
            case "sin":
                items = singEffects;
                break;

            // cuối cùng là cups
            default: items = cups;
        }
        this.updateObjectToGiftTheVoiceRound2(items, idAvatar, type, index)

    }
    updateObjectToGiftTheVoiceRound2(listItem, tagId, type, index) {
        const { competition } = this.state;
        if (competition.giftTheVoiceRound2.length <= index) {
            return
        }

        let obj;
        for (let i = 0; i < listItem.length; i++) {
            const item = listItem[i];
            // huy hiệu + khung bài thu + cup + quà + quà thật
            if (item.id && item.id == tagId) {
                obj = item;
                break;
            }
            // shopItem
            if (item._id && item._id == tagId) {
                obj = item;
                break;
            }
        }
        if (obj === undefined) {
            return
        }

        switch (type) {
            case 'cup':
                competition.giftTheVoiceRound2[index - 1].cup = obj
                break;
            case 'gif':
                competition.giftTheVoiceRound2[index - 1].gift = obj
                break;
            case 'cmt':
                //cmt: comment frame
                competition.giftTheVoiceRound2[index - 1].commentFrame = obj;
                break;
            case 'prs':
                // prs: personal card
                competition.giftTheVoiceRound2[index - 1].personalCard = obj;
                break;
            case 'udc':
                //udc: user decoration
                competition.giftTheVoiceRound2[index - 1].userDecoration = obj;
                break;
            case 'roo':
                //roo: room entry effect
                competition.giftTheVoiceRound2[index - 1].roomEntryEffect = obj;
                break;
            case 'fra':
                //roo: room entry effect
                competition.giftTheVoiceRound2[index - 1].recordingFrame = obj;
                break;
            case 'med':
                //roo: room entry effect
                competition.giftTheVoiceRound2[index - 1].recordingMedal = obj;
                break;
            case 'ava':
                // ava: avatar frame
                competition.giftTheVoiceRound2[index - 1].avatarFrame = obj
                break;
            case 'sin':
                // sin: singer effect
                competition.giftTheVoiceRound2[index - 1].singEffects = obj
                break;
            case 'rgf':
                // rgf: realGift
                competition.giftTheVoiceRound2[index - 1].realGift = obj
                break;
        }
        this.setState({
            competition: competition
        })
    }

    render() {
        const model = bindModel(this);
        const { loading, competition, categories, frameDecoration, medalDecoration, cups, avas, userDecorations, personalCards, commentFrames, roomEntryEffects, realGifts, singEffects } = this.state;
        return (
            <div>
                <div className="modal fade" id="modalUpdateCompetition" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật Cuộc thi</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="setting-tab" data-toggle="tab" href="#setting" role="tab" aria-controls="setting" aria-selected="true">Cài đặt chính</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="rounds-tab" data-toggle="tab" href="#rounds" role="tab" aria-controls="rounds" aria-selected="false">Cài đặt Vòng thi</a>
                                    </li>
                                    {competition && (competition.idEvent == "TheVoice" || competition.idEvent == "GAME") ? 
                                        <>
                                            <li className="nav-item">
                                                <a className="nav-link" id="gift-tab" data-toggle="tab" href="#gift" role="tab" aria-controls="gift" aria-selected="false">Cài đặt quà</a>
                                            </li>
                                        </>
                                        : <></>
                                    }
                                </ul>

                                <div className="tab-content" id="myTabContent">
                                    {/* SETTING */}
                                    <div className="tab-pane fade show active" id="setting" role="tabpanel" aria-labelledby="setting-tab">
                                        <div>
                                            <p className="text-center">Chọn thể loại cuộc thi trước khi chọn các bước tiếp theo</p>
                                            <div id="idEvent" className="row mb-2">
                                                <div className="col-3">
                                                    <label>Thể loại Cuộc Thi</label>
                                                </div>
                                                <div className="form-check col-9">
                                                    <select
                                                        className="form-control"
                                                        type="text"
                                                        name="idEvent"
                                                        {...model('competition.idEvent')} 
                                                        >
                                                        <option value="">Chọn</option>
                                                        <option value="GAME">GAME</option>
                                                        <option value="TheVoice">TheVoice</option>
                                                        <option value="WebGame">WebGame</option>
                                                        <option value="WebView">WebView</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div id="language" className="row mb-2">
                                                <div className="col-3">
                                                    <label>Ngôn ngữ</label>
                                                </div>
                                                <div className="form-check col-9">
                                                    <select
                                                        className="form-control"
                                                        type="text"
                                                        name="language"
                                                        {...model('competition.language')} >
                                                        <option value="">Chọn</option>
                                                        <option value="vi">Vietnamese </option>
                                                        <option value="lo.okara">Laos</option>
                                                        <option value="mz.okara">Mozambique</option>
                                                        <option value="tl.okara">TimorLeste</option>
                                                        <option value="ht.okara">TimorLeste</option>
                                                        <option value="en.yokara">English</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div id="miniBackgroundUrl" className="row mb-2">
                                                <div className="col-3">
                                                    <label>Banner cuộc thi</label>
                                                </div>
                                                <div className="col-9">
                                                    <input
                                                        type="file"
                                                        className="custom-file-input"
                                                        name="miniBackgroundUrl"
                                                        onChange={this.uploadAnimationToS3}
                                                    />
                                                    <label className="custom-file-label ml-3 mr-3" htmlFor="miniBackgroundUrl">Choose file</label>
                                                </div>
                                            </div>

                                            {competition && (competition.idEvent == "TheVoice" || competition.idEvent == "GAME") ? 
                                                <>
                                                    <div id="imgUrl" className="row mb-2">
                                                        <div className="col-3">
                                                            <label>Hình chủ đề</label>
                                                        </div>
                                                        <div className="col-9">
                                                            <input
                                                                type="file"
                                                                className="custom-file-input"
                                                                name="imgUrl"
                                                                onChange={this.uploadAnimationToS4} />
                                                            <label className="custom-file-label ml-3 mr-3" htmlFor="imgUrl">Choose file</label>
                                                        </div>
                                                    </div>
                                                    <div id="backgroundDialogUrl" className="row mb-2">
                                                        <div className="col-3">
                                                            <label>Ảnh Dialog</label>
                                                        </div>
                                                        <div className="col-9">
                                                            <input
                                                                type="file"
                                                                className="custom-file-input"
                                                                name="backgroundDialogUrl"
                                                                onChange={this.uploadAnimationToS5}
                                                            />
                                                            <label className="custom-file-label ml-3 mr-3" htmlFor="backgroundDialogUrl">Choose file</label>
                                                        </div>
                                                    </div>
                                                </>
                                                : <></>
                                            }
                                         

                                            <div className="row mb-2">
                                                <div className="col-3">
                                                    <label></label>
                                                </div>
                                                <div className="col-3">
                                                    {(competition && competition.miniBackgroundUrl) ? (
                                                        <>
                                                            <label>Banner</label>
                                                            <img src={competition.miniBackgroundUrl} />
                                                        </>
                                                    ) : ""}
                                                </div>

                                                {competition && (competition.idEvent == "TheVoice" || competition.idEvent == "GAME") ? 
                                                    <>
                                                        <div className="col-3">
                                                            {(competition && competition.imgUrl) ? (
                                                                <>
                                                                    <label>Chủ đề</label>
                                                                    <img src={competition.imgUrl} />
                                                                </>
                                                            ) : ""}
                                                        </div>
                                                        <div className="col-3">
                                                            {(competition && competition.backgroundDialogUrl) ? (
                                                                <>
                                                                    <label>Ảnh Dialog</label>
                                                                    <img src={competition.backgroundDialogUrl} />
                                                                </>
                                                            ) : ""}
                                                        </div>
                                                    </>
                                                    : <>
                                                        <div className="col-3">
                                                        </div>
                                                        <div className="col-3">
                                                        </div>
                                                    </>
                                                }
                                               
                                            </div>

                                            {competition && (competition.idEvent == "TheVoice" || competition.idEvent == "GAME") ? 
                                                <>
                                                    <div className="row mb-2">
                                                        <div className="col-5">
                                                        </div>
                                                        <div className="col-7">
                                                            <label>Mã màu</label>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-3">
                                                            <label>Màu Cuộc thi</label>
                                                        </div>
                                                        <div className="form-check col-4">
                                                            <input
                                                                height={40}
                                                                className="form-control mb-2"
                                                                type="text"
                                                                name="backgroundCodeContest"
                                                                placeholder="Mã màu cuộc thi"
                                                                {...model('competition.backgroundCode.backgroundCodeContest')}
                                                            />
                                                        </div>
                                                        <div className="col-5">
                                                            {
                                                                (competition && competition.backgroundCode && competition.backgroundCode.backgroundCodeContest) ?
                                                                    (/^#(?:[0-9a-fA-F]{3,4}){1,2}$/g.test(competition.backgroundCode.backgroundCodeContest)) ?
                                                                        (<div style={{ marginTop: '1%', marginRight: '1%', marginLeft: '1%', marginBottom: '1%', height: '75%', width: '100%', backgroundColor: competition.backgroundCode.backgroundCodeContest }} />) :
                                                                        (<div><h5>Mã màu sai định dạng</h5></div>)
                                                                    : ""
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-3">
                                                            <label>Màu Tên cuộc thi</label>
                                                        </div>
                                                        <div className="form-check col-4">
                                                            <input
                                                                height={40}
                                                                className="form-control mb-2"
                                                                type="text"
                                                                name="backgroundCodeContestName"
                                                                placeholder="Mã màu tên cuộc thi"
                                                                {...model('competition.backgroundCode.backgroundCodeContestName')}
                                                            />
                                                        </div>
                                                        <div className="col-5">
                                                            {
                                                                (competition && competition.backgroundCode && competition.backgroundCode.backgroundCodeContestName) ?
                                                                    (/^#(?:[0-9a-fA-F]{3,4}){1,2}$/g.test(competition.backgroundCode.backgroundCodeContestName)) ?
                                                                        (<div style={{ marginTop: '1%', marginRight: '1%', marginLeft: '1%', marginBottom: '1%', height: '75%', width: '100%', backgroundColor: competition.backgroundCode.backgroundCodeContestName }} />) :
                                                                        (<div><h5>Mã màu sai định dạng</h5></div>)
                                                                    : ""
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-3">
                                                            <label>Màu Thể lệ</label>
                                                        </div>
                                                        <div className="form-check col-4">
                                                            <input
                                                                height={40}
                                                                className="form-control mb-2"
                                                                type="text"
                                                                name="backgroundCodeRule"
                                                                placeholder="Mã màu thể lệ"
                                                                {...model('competition.backgroundCode.backgroundCodeRule')}
                                                            />
                                                        </div>
                                                        <div className="col-5">
                                                            {
                                                                (competition && competition.backgroundCode && competition.backgroundCode.backgroundCodeRule) ?
                                                                    (/^#(?:[0-9a-fA-F]{3,4}){1,2}$/g.test(competition.backgroundCode.backgroundCodeRule)) ?
                                                                        (<div style={{ marginTop: '1%', marginRight: '1%', marginLeft: '1%', marginBottom: '1%', height: '75%', width: '100%', backgroundColor: competition.backgroundCode.backgroundCodeRule }} />) :
                                                                        (<div><h5>Mã màu sai định dạng</h5></div>)
                                                                    : ""
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-3">
                                                            <label>Màu Vòng 1</label>
                                                        </div>
                                                        <div className="form-check col-4">
                                                            <input
                                                                height={40}
                                                                className="form-control mb-2"
                                                                type="text"
                                                                name="backgroundCodeRound1"
                                                                placeholder="Mã màu vòng 1"
                                                                {...model('competition.backgroundCode.backgroundCodeRound1')}
                                                            />
                                                        </div>
                                                        <div className="col-5">
                                                            {
                                                                (competition && competition.backgroundCode && competition.backgroundCode.backgroundCodeRound1) ?
                                                                    (/^#(?:[0-9a-fA-F]{3,4}){1,2}$/g.test(competition.backgroundCode.backgroundCodeRound1)) ?
                                                                        (<div style={{ marginTop: '1%', marginRight: '1%', marginLeft: '1%', marginBottom: '1%', height: '75%', width: '100%', backgroundColor: competition.backgroundCode.backgroundCodeRound1 }} />) :
                                                                        (<div><h5>Mã màu sai định dạng</h5></div>)
                                                                    : ""
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-3">
                                                            <label>Màu BXH</label>
                                                        </div>
                                                        <div className="form-check col-4">
                                                            <input
                                                                height={40}
                                                                className="form-control mb-2"
                                                                type="text"
                                                                name="backgroundCodeRank"
                                                                placeholder="Mã màu BXH"
                                                                {...model('competition.backgroundCode.backgroundCodeRank')}
                                                            />
                                                        </div>
                                                        <div className="col-5">
                                                            {
                                                                (competition && competition.backgroundCode && competition.backgroundCode.backgroundCodeRank) ?
                                                                    (/^#(?:[0-9a-fA-F]{3,4}){1,2}$/g.test(competition.backgroundCode.backgroundCodeRank)) ?
                                                                        (<div style={{ marginTop: '1%', marginRight: '1%', marginLeft: '1%', marginBottom: '1%', height: '75%', width: '100%', backgroundColor: competition.backgroundCode.backgroundCodeRank }} />) :
                                                                        (<div><h5>Mã màu sai định dạng</h5></div>)
                                                                    : ""
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-3">
                                                            <label>Màu Button</label>
                                                        </div>
                                                        <div className="form-check col-4">
                                                            <input
                                                                height={40}
                                                                className="form-control mb-2"
                                                                type="text"
                                                                name="backgroundCodeButton"
                                                                placeholder="Mã màu button"
                                                                {...model('competition.backgroundCode.backgroundCodeButton')}
                                                            />
                                                        </div>
                                                        <div className="col-5">
                                                            {
                                                                (competition && competition.backgroundCode && competition.backgroundCode.backgroundCodeButton) ?
                                                                    (/^#(?:[0-9a-fA-F]{3,4}){1,2}$/g.test(competition.backgroundCode.backgroundCodeButton)) ?
                                                                        (<div style={{ marginTop: '1%', marginRight: '1%', marginLeft: '1%', marginBottom: '1%', height: '75%', width: '100%', backgroundColor: competition.backgroundCode.backgroundCodeButton }} />) :
                                                                        (<div><h5>Mã màu sai định dạng</h5></div>)
                                                                    : ""
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-3">
                                                            <label>Màu Footer</label>
                                                        </div>
                                                        <div className="form-check col-4">
                                                            <input
                                                                height={40}
                                                                className="form-control mb-2"
                                                                type="text"
                                                                name="backgroundCodeFooter"
                                                                placeholder="Mã màu footer"
                                                                {...model('competition.backgroundCode.backgroundCodeFooter')}
                                                            />
                                                        </div>
                                                        <div className="col-5">
                                                            {
                                                                (competition && competition.backgroundCode && competition.backgroundCode.backgroundCodeFooter) ?
                                                                    (/^#(?:[0-9a-fA-F]{3,4}){1,2}$/g.test(competition.backgroundCode.backgroundCodeFooter)) ?
                                                                        (<div style={{ marginTop: '1%', marginRight: '1%', marginLeft: '1%', marginBottom: '1%', height: '75%', width: '100%', backgroundColor: competition.backgroundCode.backgroundCodeFooter }} />) :
                                                                        (<div><h5>Mã màu sai định dạng</h5></div>)
                                                                    : ""
                                                            }
                                                        </div>
                                                    </div>
                                                    <div id="describe" className="row mb-2">
                                                        <div className="col-3">
                                                            <label>Mô tả Cuộc thi</label>
                                                        </div>
                                                        <div className="col-9">
                                                            <input
                                                                className="form-control mb-2"
                                                                type="text"
                                                                name="describe"
                                                                placeholder="Nhập mô tả..."
                                                                {...model('competition.describe')}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div id="slogan" className="row mb-2">
                                                        <div className="col-3">
                                                            <label>Slogan</label>
                                                        </div>
                                                        <div className="col-9">
                                                            <input
                                                                className="form-control mb-2"
                                                                type="text"
                                                                name="slogan"
                                                                placeholder="Nhập Slogan..."
                                                                {...model('competition.slogan')}
                                                            />
                                                        </div>
                                                    </div>
                                                    { competition && competition.idEvent == "TheVoice" &&
                                                        <div id="categoryId" className="row mb-2">
                                                            <div className="col-3">
                                                                <label>Chủ đề bài hát</label>
                                                            </div>
                                                            <div className="col-9">
                                                                <select
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="categoryId"
                                                                    {...model('competition.categoryId')}
                                                                >
                                                                    <option value=''>Chọn chủ đề</option>
                                                                    <option value='0' >SONG CA</option>
                                                                    <option value='1' >Song ca cùng Thần Tượng</option>
                                                                    {
                                                                        categories && categories.map((category) => (
                                                                            <option key={category.id} value={category.id} >{category.name}</option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div id="titleTime" className="row mb-2">
                                                        <div className="col-3">
                                                            <label>Thời gian diễn ra</label>
                                                        </div>
                                                        <div className="col-9">
                                                            <input
                                                                className="form-control mb-2"
                                                                type="text"
                                                                name="titleTime"
                                                                placeholder="Nhập thời gian..."
                                                                {...model('competition.theVoiceRuleFull.titleTime')}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div id="detailTime" className="row mb-2">
                                                        <div className="col-3">
                                                            <label>Mô tả Thời gian diễn ra</label>
                                                        </div>
                                                        <div className="col-9">
                                                            <textarea
                                                                className="form-control mb-2"
                                                                type="text"
                                                                name="detailTime"
                                                                placeholder="Nhập mô tả..."
                                                                {...model('competition.theVoiceRuleFull.detailTime')}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div id="titleRule" className="row mb-2">
                                                        <div className="col-3">
                                                            <label>Tiêu đề luật cuộc thi</label>
                                                        </div>
                                                        <div className="col-9">
                                                            <input
                                                                className="form-control mb-2"
                                                                type="text"
                                                                name="titleRule"
                                                                placeholder="Nhập tiêu đề..."
                                                                {...model('competition.theVoiceRuleFull.titleRule')}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div id="detailRule" className="row mb-2">
                                                        <div className="col-3">
                                                            <label>Mô tả luật cuộc thi</label>
                                                        </div>
                                                        <div className="col-9">
                                                            <textarea
                                                                className="form-control mb-2"
                                                                type="text"
                                                                name="titleRule"
                                                                placeholder="Nhập mô tả..."
                                                                {...model('competition.theVoiceRuleFull.detailRule')}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div id="titleOther" className="row mb-2">
                                                        <div className="col-3">
                                                            <label>Tiêu đề khác</label>
                                                        </div>
                                                        <div className="col-9">
                                                            <input
                                                                className="form-control mb-2"
                                                                type="text"
                                                                name="titleOther"
                                                                placeholder="Nhập tiêu đề..."
                                                                {...model('competition.theVoiceRuleFull.titleOther')}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div id="detailOther" className="row mb-2">
                                                        <div className="col-3">
                                                            <label>Mô tả khác</label>
                                                        </div>
                                                        <div className="col-9">
                                                            <textarea
                                                                className="form-control mb-2"
                                                                type="text"
                                                                name="detailOther"
                                                                placeholder="Nhập mô tả..."
                                                                {...model('competition.theVoiceRuleFull.detailOther')}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div id="numRewardRound2 " className="row mb-2">
                                                        <div className="col-3">
                                                            <label>Số lượng user trao giải</label>
                                                        </div>
                                                        <div className="col-9">
                                                            <input
                                                                className="form-control mb-2"
                                                                type="number"
                                                                name="numRewardRound2 "
                                                                placeholder="Nhập số lượng ..."
                                                                {...model('competition.numRewardRound2')}
                                                            />
                                                        </div>
                                                    </div>

                                                    { competition && competition.idEvent == "TheVoice" && 
                                                    <div>
                                                        <div id="typePermissionAccept" className="row mb-2">
                                                            <div className="col-3">
                                                                <label>Bài hát hợp lệ</label>
                                                            </div>
                                                            <div className="form-check col-9">
                                                                <select id='giftTop1Round1'
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="typePermissionAccept"
                                                                    {...model('competition.typePermissionAccept')} >
                                                                    <option value="">Chọn</option>
                                                                    <option value="PUBLIC">Công khai</option>
                                                                    <option value="PRIVATE">Riêng tư</option>
                                                                    <option value="ALL">Tất cả</option>
                                                                </select>
                                                                
                                                            </div>
                                                        </div>

                                                        <div id="typeRecordingAccept" className="row mb-2">
                                                            <div className="col-3">
                                                                <label>Hình thức hợp lệ</label>
                                                            </div>
                                                            <div className="form-check col-9">
                                                                <select id='giftTop1Round1'
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="typeRecordingAccept"
                                                                    {...model('competition.typeRecordingAccept')} >
                                                                    <option value="">Chọn</option>
                                                                    <option value="SOLO">Solo</option>
                                                                    <option value="DUET">Duet</option>
                                                                    <option value="ASK4DUET">Ask For Duet</option>
                                                                    <option value="ALL">All</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div id="typeRecordingMedia" className="row mb-2">
                                                            <div className="col-3">
                                                                <label>Bài dự thi</label>
                                                            </div>
                                                            <div className="form-check col-9">
                                                                <select id='typeRecordingAcceptSelected'
                                                                    className="form-control"
                                                                    name="typeRecordingMedia"
                                                                    {...model('competition.typeRecordingMedia')} >
                                                                    <option value="">Chọn</option>
                                                                    <option value="VIDEO">Hình</option>
                                                                    <option value="AUDIO">Tiếng</option>
                                                                    <option value="ALL">Cả 2</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    }

                                                    <div id="typeRecordingMedia" className="row mb-2">
                                                        <div className="col-3">
                                                            <label>Sticker DeepAr</label>
                                                        </div>
                                                        <div className="form-check col-9" id='choiceStickerDeepAr'>

                                                        </div>
                                                    </div>
                                                    <div id="deepAr" className="row mb-2">
                                                        <div className="col-3">
                                                            {/* <label>Sticker DeepAr</label> */}
                                                        </div>
                                                        <div className="form-check col-9">
                                                            <button className='btn btn-success'
                                                                onClick={this.handleShowChoice}>Thêm</button>
                                                        </div>
                                                    </div>
                                                    <div id="typeRecordingMedia" className="row mb-2">
                                                        <div className="col-3">
                                                            <label>Hashtag</label>
                                                        </div>
                                                        <div className="form-check col-9" id='choiceHashtag'>
                                                        </div>
                                                    </div>
                                                    <div id="deepAr" className="row mb-2">
                                                        <div className="col-3">
                                                            {/* <label>Sticker DeepAr</label> */}
                                                        </div>
                                                        <div className="form-check col-9">
                                                            <button className='btn btn-success'
                                                                onClick={this.handleShowHashtag}>Thêm</button>
                                                        </div>
                                                    </div>
                                                </>
                                                : competition && competition.idEvent == "WebView" ? <>
                                                    <div className="row mb-2">
                                                        <div className="col-3">
                                                            <label>Deep link App</label>
                                                        </div>
                                                        <div className="form-check col-9">
                                                            <input
                                                                height={40}
                                                                className="form-control mb-2"
                                                                type="text"
                                                                name="deepLink"
                                                                placeholder="Deep link"
                                                                {...model('competition.deepLink')}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-3">
                                                            <label>Web Chuẩn bị</label>
                                                        </div>
                                                        <div className="form-check col-9">
                                                            <input
                                                                height={40}
                                                                className="form-control mb-2"
                                                                type="text"
                                                                name="inProgressLink"
                                                                placeholder="In progress link"
                                                                {...model('competition.inProgressLink')}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-2">
                                                        <div className="col-3">
                                                            <label>Web Hoàn thành</label>
                                                        </div>
                                                        <div className="form-check col-9">
                                                            <input
                                                                height={40}
                                                                className="form-control mb-2"
                                                                type="text"
                                                                name="doneLink"
                                                                placeholder="Done link"
                                                                {...model('competition.doneLink')}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                                : <></>
                                            }
                                        
                                           
                                            <div id="name" className="row mb-2">
                                                <div className="col-3">
                                                    <label>Tên Cuộc thi</label>
                                                </div>
                                                <div className="col-9">
                                                    <input
                                                        className="form-control mb-2"
                                                        type="text"
                                                        name="name"
                                                        placeholder="Nhập Tên cuộc thi..."
                                                        {...model('competition.name')}
                                                    />
                                                </div>
                                            </div>
                                            
                                            <div id="timeShowStart" className="row mb-2">
                                                <div className="col-3">
                                                    <label>Hẹn giờ đăng</label>
                                                </div>
                                                <div className="col-9">
                                                    <input
                                                        className="form-control mb-2"
                                                        type="datetime-local"
                                                        name="timeShowStart"
                                                        {...model('competition.timeShowStart')}
                                                    />
                                                </div>
                                            </div>
                                            <div id="timeShowEnd" className="row mb-2">
                                                <div className="col-3">
                                                    <label>Hẹn giờ Kết thúc</label>
                                                </div>
                                                <div className="col-9">
                                                    <input
                                                        className="form-control mb-2"
                                                        type="datetime-local"
                                                        name="timeShowEnd"
                                                        {...model('competition.timeShowEnd')}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Vòng thi */}
                                    <div className="tab-pane fade" id="rounds" role="tabpanel" aria-labelledby="rounds-tab">
                                        <br /><br />

                                        <div id="round1" className='border border-primary'>
                                            <h4>Vòng 1</h4>
                                            <div id="timeStartRound1" className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-3">
                                                    <label>Thời gian bắt đầu</label>
                                                </div>
                                                <div className="col-7">
                                                    <input
                                                        className="form-control mb-2"
                                                        type="datetime-local"
                                                        name="timeStartRound1"
                                                        {...model('competition.timeStartRound1')}
                                                    />
                                                </div>
                                            </div>
                                            <div id="timeEndRound1" className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-3">
                                                    <label>Thời gian kết thúc</label>
                                                </div>
                                                <div className="col-7">
                                                    <input
                                                        className="form-control mb-2"
                                                        type="datetime-local"
                                                        name="timeEndRound1"
                                                        {...model('competition.timeEndRound1')}
                                                    />
                                                </div>
                                            </div>
                                            
                                            { competition && competition.idEvent == "TheVoice" &&
                                                <>
                                                <div id="limitRecordingRound1" className="row mb-2">
                                                    <div className="col-1">
                                                    </div>
                                                    <div className="col-3">
                                                        <label>Tổng bài dự thi tối đa</label>
                                                    </div>
                                                    <div className="col-7">
                                                        <input
                                                            className="form-control mb-2"
                                                            type="number"
                                                            name="limitRecordingRound1"
                                                            placeholder="Nhập Số bài..."
                                                            {...model('competition.limitRecordingRound1')}
                                                        />
                                                    </div>
                                                </div>
                                                <div id="maxNumMediaOneUse" className="row mb-2">
                                                    <div className="col-1">
                                                    </div>
                                                    <div className="col-3">
                                                        <label>Số bài dự thi của 1 user</label>
                                                    </div>
                                                    <div className="col-7">
                                                        <input
                                                            className="form-control mb-2"
                                                            type="number"
                                                            name="maxNumMediaOneUse"
                                                            placeholder="Nhập Số bài..."
                                                            {...model('competition.maxNumMediaOneUse')}
                                                        />
                                                    </div>
                                                </div>
                                                <div id="limitRecordingRound2" className="row mb-2">
                                                    <div className="col-1">
                                                    </div>
                                                    <div className="col-3">
                                                        <label>Số bài đc lấy vào vòng 2</label>
                                                    </div>
                                                    <div className="col-7">
                                                        <input
                                                            className="form-control mb-2"
                                                            type="number"
                                                            name="limitRecordingRound2"
                                                            placeholder="Nhập Số bài..."
                                                            {...model('competition.limitRecordingRound2')}
                                                        />
                                                    </div>
                                                </div>
                                                <br />
                                            
                                            </>}
                                            

                                        </div>

                                        <br /><br />
                                        { competition && competition.idEvent == "TheVoice" &&
                                            <div id="round2" className='border border-primary'>
                                                <h4>Vòng 2</h4>
                                                <div id="timeEndRound2" className="row mb-2">
                                                    <div className="col-1">
                                                    </div>
                                                    <div className="col-3">
                                                        <label>Thời gian kết thúc</label>
                                                    </div>
                                                    <div className="col-7">
                                                        <input
                                                            className="form-control mb-2"
                                                            type="datetime-local"
                                                            name="timeEndRound2"
                                                            {...model('competition.timeEndRound2')}
                                                        />
                                                    </div>
                                                </div>
                                                    <div>
                                                        <div id="minNumMark" className="row mb-2">
                                                            <div className="col-1">
                                                            </div>
                                                            <div className="col-3">
                                                                <label>Số giám khảo tối thiểu </label>
                                                            </div>
                                                            <div className="col-7">
                                                                <input
                                                                    className="form-control mb-2"
                                                                    type="number"
                                                                    name="minNumMark"
                                                                    placeholder="Nhập số giám khảo tối thiểu..."
                                                                    {...model('competition.minNumMark')}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div id="minLevelExaminer" className="row mb-2">
                                                            <div className="col-1">
                                                            </div>
                                                            <div className="col-3">
                                                                <label>Level của giám khảo đc vào chấm</label>
                                                            </div>
                                                            <div className="col-7">
                                                                <input
                                                                    className="form-control mb-2"
                                                                    type="number"
                                                                    name="minLevelExaminer"
                                                                    placeholder="Nhập Level giám khảo..."
                                                                    {...model('competition.minLevelExaminer')}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div id="maxMediaForOneExaminer" className="row mb-2">
                                                            <div className="col-1">
                                                            </div>
                                                            <div className="col-3">
                                                                <label>Mỗi giám khảo đc chấm bao nhiêu bài</label>
                                                            </div>
                                                            <div className="col-7">
                                                                <input
                                                                    className="form-control mb-2"
                                                                    type="number"
                                                                    name="maxMediaForOneExaminer"
                                                                    placeholder="Nhập số bài được chấm..."
                                                                    {...model('competition.maxMediaForOneExaminer')}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div id="maxExaminerForOneMedia" className="row mb-2">
                                                            <div className="col-1">
                                                            </div>
                                                            <div className="col-3">
                                                                <label>Mỗi bài đc bao nhiêu giám khảo vào chấm </label>
                                                            </div>
                                                            <div className="col-7">
                                                                <input
                                                                    className="form-control mb-2"
                                                                    type="number"
                                                                    name="maxExaminerForOneMedia"
                                                                    placeholder="Nhập Số giám khảo..."
                                                                    {...model('competition.maxExaminerForOneMedia')}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div id="canApplyExaminer" className="row mb-2">
                                                            <div className="col-1">
                                                            </div>
                                                            <div className="col-3">
                                                                <label>Cho phép tự ứng cử làm giám khảo</label>
                                                            </div>
                                                            <div className="col-7">
                                                                <select
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="canApplyExaminer"
                                                                    {...model('competition.canApplyExaminer')}
                                                                >
                                                                    <option value='' >Chọn</option>
                                                                    <option value='1' >CHO PHÉP</option>
                                                                    <option value='0' >KHÔNG</option>
                                                                </select>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                <br />
                                            </div>
                                        }
                                    </div>

                                    {/* QUÀ */}
                                    <div className="tab-pane fade" id="gift" role="tabpanel" aria-labelledby="gift-tab">
                                        <br /><br />
                                        <div id="giftsTop1" className='border border-primary'>
                                            <h4>Quà Top 1</h4>

                                            {/* CUP, Quà, Khung Bài thu, Huy hiệu Bài thu */}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    <label>CUP</label>
                                                </div>
                                                <div className="col-2">
                                                    <label>Quà</label>
                                                </div>
                                                {
                                                    competition && competition.idEvent == "TheVoice" ?
                                                    <div className="col-2">
                                                            <label>Khung Bài thu</label>
                                                    </div> : <div className="col-2"></div>


                                                }

                                                <div className="col-2">
                                                    {competition && competition.idEvent == "TheVoice" ? (
                                                        <label>Huy hiệu Bài thu</label>
                                                    ) : competition && competition.idEvent == "GAME" ? (
                                                        <label>Huy hiệu Game</label>
                                                    ) : null}
                                                </div>

                                                <div className="col-2">
                                                    <label>Hiệu ứng khi hát</label>
                                                </div>

                                            </div>
                                            {/* CHỌN ================= */}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    <input list='dcupTop1' id="cupTop1"
                                                        placeholder='Chọn Cup'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="dcupTop1"
                                                    >
                                                        <option value="">Chọn Cup</option>
                                                        {
                                                            cups && cups.map((value) => (
                                                                <option key={value.id} value={value.id} >
                                                                </option>
                                                            ))
                                                        }
                                                    </datalist>
                                                </div>
                                                <div className="col-2">
                                                    <input list='dgifTop1' id="gifTop1"
                                                        placeholder='Chọn Quà'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="dgifTop1"
                                                    >
                                                        <option value="">Chọn Quà</option>
                                                        {
                                                            cups && cups.map((value) => (
                                                                <option name={value.url} key={value.id} value={value.id} >
                                                                </option>
                                                            ))
                                                        }

                                                    </datalist>
                                                </div>
                                                {
                                                    competition && competition.idEvent == "TheVoice" ?
                                                    <div className="col-2">
                                                            <input list='dfraTop1' id="fraTop1"
                                                                placeholder='Chọn Khung trang trí bài thu'
                                                                onChange={this.showImgPreview} />
                                                            <datalist
                                                                id="dfraTop1"
                                                            >
                                                                <option value="">Chọn khung Top 1</option>
                                                                {
                                                                    frameDecoration && frameDecoration.map((value) => (
                                                                        <option key={value.id} value={value.name} >
                                                                        </option>
                                                                    ))
                                                                }
                                                            </datalist>
                                                        </div> : <div className="col-2"></div>

                                                }
                                                {competition && (competition.idEvent == "TheVoice" || competition.idEvent == "GAME") ? (
                                                    <div className="col-2">
                                                        <input list='dmedTop1' id="medTop1"
                                                            placeholder={competition.idEvent == "TheVoice" ? 'Chọn huy hiệu trang trí bài thu' : 'Chọn huy hiệu trang trí Game'}
                                                            onChange={this.showImgPreview} />
                                                        <datalist id="dmedTop1">
                                                            <option value="">Chọn huy hiệu</option>
                                                            {medalDecoration && medalDecoration.map((value) => (
                                                                <option key={value.id} value={value.name}></option>
                                                            ))}
                                                        </datalist>
                                                    </div>
                                                ) : (
                                                    <div className="col-2"></div>
                                                )}
                                                <div className="col-2">
                                                    <input list='dsinTop1' id="sinTop1"
                                                        placeholder='Chọn Hiệu ứng Hát'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="dsinTop1"
                                                    >
                                                        <option value="">Chọn Hiệu ứng hát</option>
                                                        {
                                                            singEffects && singEffects.map((value) => (
                                                                <option name={value.previewImg} key={value._id} value={value._id} >
                                                                </option>
                                                            ))
                                                        }
                                                    </datalist>
                                                </div>
                                            </div>
                                            {/* XEM TRƯỚC ================= */}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[0].cup &&
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[0].cup.url}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[0].cup = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("cupTop1").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                    }
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[0].gift &&
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[0].gift.url}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[0].gift = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("gifTop1").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                    }
                                                </div>
                                                {
                                                    (competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[0].recordingFrame && competition.idEvent == "TheVoice") ?
                                                    <div className="col-2">
                                                        <img width={50} height={50} src={competition.giftTheVoiceRound2[0].recordingFrame.thumbnailUrl}>
                                                        </img>
                                                        <button className='btn btn-sm btn-outline-danger'
                                                            onClick={() => {
                                                                let competitionBefore = competition;
                                                                competitionBefore.giftTheVoiceRound2[0].recordingFrame = null;
                                                                this.setState({
                                                                    competition: competitionBefore
                                                                })
                                                                document.getElementById("fraTop1").value = "";
                                                            }}
                                                        >x</button>
                                                    </div> : <div className="col-2"></div>

                                                }
                               
                                                {
                                                    (competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[0].recordingMedal && (competition.idEvent == "TheVoice" || competition.idEvent == "GAME")) ?
                                                    <div className="col-2">
                                                        <img width={50} height={50} src={competition.giftTheVoiceRound2[0].recordingMedal.thumbnailUrl}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[0].recordingMedal = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("medTop1").value = "";
                                                                    }}
                                                                >x</button>
                                                    </div> : <div className="col-2"></div>
                                                }
    
                                                <div className="col-2">
                                                    {
                                                        competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[0].singEffects &&
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[0].singEffects.resourceUrl}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[0].singEffects = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("sinTop1").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                            <br />
                                            {/* Avatar, Khung bình luận, Thẻ cá nhân, Trang trí cá nhân, Hiệu ứng vào phòng */}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    <label>Avatar</label>
                                                </div>
                                                <div className="col-2">
                                                    <label>Khung bình luận</label>
                                                </div>
                                                <div className="col-2">
                                                    <label>Thẻ cá nhân</label>
                                                </div>
                                                <div className="col-2">
                                                    <label>Trang trí cá nhân</label>
                                                </div>
                                                <div className="col-2">
                                                    <label>Hiệu ứng vào phòng</label>
                                                </div>
                                            </div>
                                            {/* CHỌN ================= */}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    <input list='davaTop1' id="avaTop1"
                                                        placeholder='Chọn Khung'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="davaTop1"
                                                    >
                                                        <option value="">Chọn Khung</option>
                                                        {
                                                            avas && avas.map((value) => (
                                                                <option name={value.previewImg} key={value._id} value={value._id} >
                                                                </option>
                                                            ))
                                                        }
                                                    </datalist>
                                                </div>
                                                <div className="col-2">
                                                    <input list='dcmtTop1' id="cmtTop1"
                                                        placeholder='Chọn hiệu ứng bình luận'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="dcmtTop1"
                                                    >
                                                        <option value="">Chọn hiệu ứng bình luận</option>
                                                        {
                                                            commentFrames && commentFrames.map((value) => (
                                                                <option name={value.previewImg} key={value._id} value={value._id} >
                                                                </option>
                                                            ))
                                                        }
                                                    </datalist>
                                                </div>
                                                <div className="col-2">
                                                    <input list='dprsTop1' id="prsTop1"
                                                        placeholder='Chọn thẻ cá nhân'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="dprsTop1"
                                                    >
                                                        <option value="">Chọn thẻ cá nhân</option>
                                                        {
                                                            personalCards && personalCards.map((value) => (
                                                                <option name={value.previewImg} key={value._id} value={value._id} >
                                                                </option>
                                                            ))
                                                        }
                                                    </datalist>
                                                </div>
                                                <div className="col-2">
                                                    <input list='dudcTop1' id="udcTop1"
                                                        placeholder='Chọn trang trí cá nhân'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="dudcTop1"
                                                    >
                                                        <option value="">Chọn trang trí cá nhân</option>
                                                        {
                                                            userDecorations && userDecorations.map((value) => (
                                                                <option name={value.url} key={value._id} value={value._id} >
                                                                </option>
                                                            ))
                                                        }

                                                    </datalist>
                                                </div>
                                                <div className="col-2">
                                                    <input list='drooTop1' id="rooTop1"
                                                        placeholder='Chọn Hiệu ứng vào phòng'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="drooTop1"
                                                    >
                                                        <option value="">Chọn Hiệu ứng vào phòng</option>
                                                        {
                                                            roomEntryEffects && roomEntryEffects.map((value) => (
                                                                <option key={value._id} value={value._id} >
                                                                </option>
                                                            ))
                                                        }
                                                    </datalist>
                                                </div>
                                            </div>
                                            {/* XEM TRƯỚC =================*/}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        (competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[0].avatarFrame) ? (
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[0].avatarFrame.previewImg}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[0].avatarFrame = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("avaTop1").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                        ) : ''
                                                    }
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        (competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[0].commentFrame) ? (
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[0].commentFrame.resourceUrl}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[0].commentFrame = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("cmtTop1").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                        ) : ''
                                                    }
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        (competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[0].personalCard) ? (
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[0].personalCard.resourceUrl}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[0].personalCard = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("prsTop1").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                        ) : ''
                                                    }
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        (competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[0].userDecoration) ? (
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[0].userDecoration.resourceUrl}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[0].userDecoration = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("udcTop1").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                        ) : ''
                                                    }
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        (competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[0].roomEntryEffect) ? (
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[0].roomEntryEffect.resourceUrl}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[0].roomEntryEffect = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("rooTop1").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                        ) : ''
                                                    }
                                                </div>
                                            </div>
                                            <br />

                                            {/* Quà vật lý */}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    <label>Quà vật lý</label>
                                                </div>

                                            </div>
                                            {/* CHỌN ================= */}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    <input list='drgfTop1' id="rgfTop1"
                                                        placeholder='Chọn Quà'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="drgfTop1"
                                                    >
                                                        <option value="">Chọn Quà</option>
                                                        {
                                                            realGifts && realGifts.map((value) => (
                                                                <option name={value.name} key={value.id} value={value.id} >
                                                                </option>
                                                            ))
                                                        }
                                                    </datalist>
                                                </div>

                                            </div>
                                            {/* XEM TRƯỚC =================*/}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        (competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[0].realGift) ? (
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[0].realGift.url}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[0].realGift = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("rgfTop1").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                        ) : ''
                                                    }
                                                </div>
                                            </div>
                                            <br />
                                        </div>

                                        <br /><br />
                                        <div id="giftsTop2" className='border border-primary'>
                                            <h4>Quà Top 2</h4>

                                            {/* CUP, Quà, Khung Bài thu, Huy hiệu Bài thu */}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    <label>CUP</label>
                                                </div>
                                                <div className="col-2">
                                                    <label>Quà</label>
                                                </div>
                                                {
                                                    competition && competition.idEvent == "TheVoice" ? 
                                                    <div className="col-2">
                                                            <label>Khung Bài thu</label>
                                                    </div> : <div className="col-2"></div>
                                                }
                                                {
                                                    competition && competition.idEvent == "TheVoice" ? 
                                                    <div className="col-2">
                                                            <label>Huy hiệu Bài thu</label>
                                                    </div> : <div className="col-2"></div>
                                                }
                                                <div className="col-2">
                                                    <label>Hiệu ứng khi hát</label>
                                                </div>
                                              
                                            </div>
                                            {/* CHỌN ================= */}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    <input list='dcupTop2' id="cupTop2"
                                                        placeholder='Chọn Cup'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="dcupTop2"
                                                    >
                                                        <option value="">Chọn Cup</option>
                                                        {
                                                            cups && cups.map((value) => (
                                                                <option key={value.id} value={value.id} >
                                                                </option>
                                                            ))
                                                        }
                                                    </datalist>
                                                </div>
                                                <div className="col-2">
                                                    <input list='dgifTop2' id="gifTop2"
                                                        placeholder='Chọn Quà'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="dgifTop2"
                                                    >
                                                        <option value="">Chọn Quà</option>
                                                        {
                                                            cups && cups.map((value) => (
                                                                <option name={value.url} key={value.id} value={value.id} >
                                                                </option>
                                                            ))
                                                        }

                                                    </datalist>
                                                </div>
                                                {
                                                    competition && competition.idEvent == "TheVoice" ?
                                                    <div className="col-2">
                                                            <input list='dfraTop2' id="fraTop2"
                                                                placeholder='Chọn Khung trang trí bài thu'
                                                                onChange={this.showImgPreview} />
                                                            <datalist
                                                                id="dfraTop2"
                                                            >
                                                                <option value="">Chọn khung</option>
                                                                {
                                                                    frameDecoration && frameDecoration.map((value) => (
                                                                        <option key={value.id} value={value.name} >
                                                                        </option>
                                                                    ))
                                                                }
                                                            </datalist>
                                                    </div> : <div className="col-2"></div>

                                                }
                                                { competition && competition.idEvent == "TheVoice" ?
                                                    <div className="col-2">
                                                        <input list='dmedTop2' id="medTop1"
                                                            placeholder='Chọn huy hiệu trang trí bài thu'
                                                            onChange={this.showImgPreview} />
                                                        <datalist
                                                            id="dmedTop2"
                                                        >
                                                            <option value="">Chọn huy hiệu</option>
                                                            {
                                                                medalDecoration && medalDecoration.map((value) => (
                                                                    <option key={value.id} value={value.name} >
                                                                    </option>
                                                                ))
                                                            }
                                                        </datalist>
                                                    </div> : <div className="col-2"></div>
                                                }
                                                <div className="col-2">
                                                    <input list='dsinTop2' id="sinTop2"
                                                        placeholder='Chọn Hiệu ứng Hát'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="dsinTop2"
                                                    >
                                                        <option value="">Chọn Hiệu ứng hát</option>
                                                        {
                                                            singEffects && singEffects.map((value) => (
                                                                <option name={value.previewImg} key={value._id} value={value._id} >
                                                                </option>
                                                            ))
                                                        }
                                                    </datalist>
                                                </div>
                                                
                                            </div>
                                            {/* XEM TRƯỚC ================= */}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        (competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[1].cup) ? (
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[1].cup.url}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[1].cup = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("cupTop2").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                        ) : ''
                                                    }
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        (competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[1].gift) ? (
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[1].gift.url}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[1].gift = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("gifTop2").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                        ) : ''
                                                    }
                                                </div>
                                                {
                                                    competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[1].recordingFrame && competition.idEvent == "TheVoice" ?
                                                    <div className="col-2">
                                                        <img width={50} height={50} src={competition.giftTheVoiceRound2[1].recordingFrame.thumbnailUrl}>
                                                        </img>
                                                        <button className='btn btn-sm btn-outline-danger'
                                                            onClick={() => {
                                                                let competitionBefore = competition;
                                                                competitionBefore.giftTheVoiceRound2[1].recordingFrame = null;
                                                                this.setState({
                                                                    competition: competitionBefore
                                                                })
                                                                document.getElementById("fraTop2").value = "";
                                                            }}
                                                        >x</button>
                                                    </div> : <div className="col-2"></div>
                                                }
                                                {
                                                    competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[1].recordingMedal && competition.idEvent == "TheVoice" ?
                                                    <div className="col-2">
                                                        <img width={50} height={50} src={competition.giftTheVoiceRound2[1].recordingMedal.url}>
                                                        </img>
                                                        <button className='btn btn-sm btn-outline-danger'
                                                            onClick={() => {
                                                                let competitionBefore = competition;
                                                                competitionBefore.giftTheVoiceRound2[1].recordingMedal = null;
                                                                this.setState({
                                                                    competition: competitionBefore
                                                                })
                                                                document.getElementById("medTop2").value = "";
                                                            }}
                                                        >x</button> 
                                                    </div> : <div className="col-2"></div>
                                                }
                                                {
                                                    competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[1].singEffects &&
                                                    <div className="col-2">
                                                        <img width={50} height={50} src={competition.giftTheVoiceRound2[1].singEffects.previewImg}>
                                                        </img>
                                                        <button className='btn btn-sm btn-outline-danger'
                                                            onClick={() => {
                                                                let competitionBefore = competition;
                                                                competitionBefore.giftTheVoiceRound2[1].singEffects = null;
                                                                this.setState({
                                                                    competition: competitionBefore
                                                                })
                                                                document.getElementById("sinTop2").value = "";
                                                            }}
                                                        >x</button>
                                                    </div>
                                                }
                                            </div>
                                            <br />
                                            {/* Avatar, Khung bình luận, Thẻ cá nhân, Trang trí cá nhân, Hiệu ứng vào phòng */}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    <label>Avatar</label>
                                                </div>
                                                <div className="col-2">
                                                    <label>Khung bình luận</label>
                                                </div>
                                                <div className="col-2">
                                                    <label>Thẻ cá nhân</label>
                                                </div>
                                                <div className="col-2">
                                                    <label>Trang trí cá nhân</label>
                                                </div>
                                                <div className="col-2">
                                                    <label>Hiệu ứng vào phòng</label>
                                                </div>
                                            </div>
                                            {/* CHỌN ================= */}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    <input list='davaTop2' id="avaTop2"
                                                        placeholder='Chọn Khung'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="davaTop2"
                                                    >
                                                        <option value="">Chọn Khung</option>
                                                        {
                                                            avas && avas.map((value) => (
                                                                <option name={value.previewImg} key={value._id} value={value._id} >
                                                                </option>
                                                            ))
                                                        }
                                                    </datalist>
                                                </div>
                                                <div className="col-2">
                                                    <input list='dcmtTop2' id="cmtTop2"
                                                        placeholder='Chọn hiệu ứng bình luận'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="dcmtTop2"
                                                    >
                                                        <option value="">Chọn hiệu ứng bình luận</option>
                                                        {
                                                            commentFrames && commentFrames.map((value) => (
                                                                <option name={value.previewImg} key={value._id} value={value._id} >
                                                                </option>
                                                            ))
                                                        }
                                                    </datalist>
                                                </div>
                                                <div className="col-2">
                                                    <input list='dprsTop2' id="prsTop2"
                                                        placeholder='Chọn thẻ cá nhân'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="dprsTop2"
                                                    >
                                                        <option value="">Chọn thẻ cá nhân</option>
                                                        {
                                                            personalCards && personalCards.map((value) => (
                                                                <option name={value.previewImg} key={value._id} value={value._id} >
                                                                </option>
                                                            ))
                                                        }
                                                    </datalist>
                                                </div>
                                                <div className="col-2">
                                                    <input list='dudcTop2' id="udcTop2"
                                                        placeholder='Chọn trang trí cá nhân'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="dudcTop2"
                                                    >
                                                        <option value="">Chọn trang trí cá nhân</option>
                                                        {
                                                            userDecorations && userDecorations.map((value) => (
                                                                <option name={value.url} key={value._id} value={value._id} >
                                                                </option>
                                                            ))
                                                        }

                                                    </datalist>
                                                </div>
                                                <div className="col-2">
                                                    <input list='drooTop2' id="rooTop2"
                                                        placeholder='Chọn Hiệu ứng vào phòng'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="drooTop2"
                                                    >
                                                        <option value="">Chọn Hiệu ứng vào phòng</option>
                                                        {
                                                            roomEntryEffects && roomEntryEffects.map((value) => (
                                                                <option key={value._id} value={value._id} >
                                                                </option>
                                                            ))
                                                        }
                                                    </datalist>
                                                </div>
                                            </div>
                                            {/* XEM TRƯỚC =================*/}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        (competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[1].avatarFrame) ? (
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[1].avatarFrame.previewImg}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[1].avatarFrame = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("avaTop2").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                        ) : ''
                                                    }
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        (competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[1].commentFrame) ? (
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[1].commentFrame.resourceUrl}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[1].commentFrame = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("cmtTop2").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                        ) : ''
                                                    }
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        (competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[1].personalCard) ? (
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[1].personalCard.resourceUrl}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[1].personalCard = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("prsTop2").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                        ) : ''
                                                    }
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        (competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[1].userDecoration) ? (
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[1].userDecoration.resourceUrl}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[1].userDecoration = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("udcTop2").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                        ) : ''
                                                    }
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        (competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[1].roomEntryEffect) ? (
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[1].roomEntryEffect.resourceUrl}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[1].roomEntryEffect = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("rooTop2").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                        ) : ''
                                                    }
                                                </div>
                                            </div>
                                            <br />

                                            {/* Quà vật lý */}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    <label>Quà vật lý</label>
                                                </div>

                                            </div>
                                            {/* CHỌN ================= */}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    <input list='drgfTop2' id="rgfTop2"
                                                        placeholder='Chọn Quà'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="drgfTop2"
                                                    >
                                                        <option value="">Chọn Quà</option>
                                                        {
                                                            realGifts && realGifts.map((value) => (
                                                                <option name={value.name} key={value.id} value={value.id} >
                                                                </option>
                                                            ))
                                                        }
                                                    </datalist>
                                                </div>

                                            </div>
                                            {/* XEM TRƯỚC =================*/}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        (competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[1].realGift) ? (
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[1].realGift.url}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[1].realGift = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("rgfTop2").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                        ) : ''
                                                    }
                                                </div>
                                            </div>
                                            <br />
                                        </div>

                                        <br /><br />
                                        <div id="giftsTop3" className='border border-primary'>
                                            <h4>Quà Top 3</h4>

                                            {/* CUP, Quà, Khung Bài thu, Huy hiệu Bài thu */}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    <label>CUP</label>
                                                </div>
                                                <div className="col-2">
                                                    <label>Quà</label>
                                                </div>
                                                {
                                                    competition && competition.idEvent == "TheVoice" ?
                                                    <div className="col-2">
                                                        <label>Khung Bài thu</label>
                                                    </div> : <div className="col-2"></div>
                                                }
                                                { competition && competition.idEvent == "TheVoice" ?
                                                    <div className="col-2">
                                                        <label>Huy hiệu Bài thu</label>
                                                    </div> : <div className="col-2"></div>
                                                }
                                                <div className="col-2">
                                                    <label>Hiệu ứng khi hát</label>
                                                </div>
                                         
                                            </div>
                                            {/* CHỌN ================= */}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    <input list='dcupTop3' id="cupTop3"
                                                        placeholder='Chọn Cup'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="dcupTop3"
                                                    >
                                                        <option value="">Chọn Cup</option>
                                                        {
                                                            cups && cups.map((value) => (
                                                                <option key={value.id} value={value.id} >
                                                                </option>
                                                            ))
                                                        }
                                                    </datalist>
                                                </div>
                                                <div className="col-2">
                                                    <input list='dgifTop3' id="gifTop3"
                                                        placeholder='Chọn Quà'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="dgifTop3"
                                                    >
                                                        <option value="">Chọn Quà</option>
                                                        {
                                                            cups && cups.map((value) => (
                                                                <option name={value.url} key={value.id} value={value.id} >
                                                                </option>
                                                            ))
                                                        }

                                                    </datalist>
                                                </div>
                                                {
                                                    competition && competition.idEvent == "TheVoice" ?
                                                    <div className="col-2">
                                                        <input list='dfraTop3' id="fraTop3"
                                                            placeholder='Chọn Khung trang trí bài thu'
                                                            onChange={this.showImgPreview} />
                                                        <datalist
                                                            id="dfraTop3"
                                                        >
                                                            <option value="">Chọn khung</option>
                                                            {
                                                                frameDecoration && frameDecoration.map((value) => (
                                                                    <option key={value.id} value={value.name} >
                                                                    </option>
                                                                ))
                                                            }
                                                        </datalist>
                                                    </div> : <div className="col-2"></div>

                                                }
                                                { competition && competition.idEvent == "TheVoice" ?
                                       
                                                        <div className="col-2">
                                                            <input list='dmedTop3' id="medTop3"
                                                                placeholder='Chọn huy hiệu trang trí bài thu'
                                                                onChange={this.showImgPreview} />
                                                            <datalist
                                                                id="dmedTop3"
                                                            >
                                                                <option value="">Chọn huy hiệu</option>
                                                                {
                                                                    medalDecoration && medalDecoration.map((value) => (
                                                                        <option key={value.id} value={value.name} >
                                                                        </option>
                                                                    ))
                                                                }
                                                            </datalist>
                                                        </div> : <div className="col-2"></div>
                                          
                                                }

                                                <div className="col-2">
                                                    <input list='dsinTop3' id="sinTop3"
                                                        placeholder='Chọn Hiệu ứng Hát'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="dsinTop3"
                                                    >
                                                        <option value="">Chọn Hiệu ứng hát</option>
                                                        {
                                                            singEffects && singEffects.map((value) => (
                                                                <option name={value.previewImg} key={value._id} value={value._id} >
                                                                </option>
                                                            ))
                                                        }
                                                    </datalist>
                                                </div>
                                            </div>
                                            {/* XEM TRƯỚC ================= */}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        (competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[2].cup) ? (
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[2].cup.url}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[2].cup = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("cupTop3").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                        ) : ''
                                                    }
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        (competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[2].gift) ? (
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[2].gift.url}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[2].gift = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("gifTop3").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                        ) : ''
                                                    }
                                                </div>
                                                {
                                                    competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[2].recordingFrame && competition.idEvent == "TheVoice" ?
                                                    <div className="col-2">
                                                        <img width={50} height={50} src={competition.giftTheVoiceRound2[2].recordingFrame.thumbnailUrl}>
                                                        </img>
                                                        <button className='btn btn-sm btn-outline-danger'
                                                            onClick={() => {
                                                                let competitionBefore = competition;
                                                                competitionBefore.giftTheVoiceRound2[2].recordingFrame = null;
                                                                this.setState({
                                                                    competition: competitionBefore
                                                                })
                                                                document.getElementById("fraTop3").value = "";
                                                            }}
                                                        >x</button>
                                                    </div> : <div className="col-2"></div>
                                                }
                                                {
                                                    competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[2].recordingMedal && competition.idEvent == "TheVoice" ?
                                                    <div className="col-2">
                                                        <img width={50} height={50} src={competition.giftTheVoiceRound2[2].recordingMedal.url}>
                                                        </img>
                                                        <button className='btn btn-sm btn-outline-danger'
                                                            onClick={() => {
                                                                let competitionBefore = competition;
                                                                competitionBefore.giftTheVoiceRound2[2].recordingMedal = null;
                                                                this.setState({
                                                                    competition: competitionBefore
                                                                })
                                                                document.getElementById("medTop3").value = "";
                                                            }}
                                                        >x</button>
                                                    </div> : <div className="col-2"></div>
                                                }
                                                {
                                                    competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[2].singEffects &&
                                                    <div className="col-2">
                                                        <img width={50} height={50} src={competition.giftTheVoiceRound2[2].singEffects.previewImg}>
                                                        </img>
                                                        <button className='btn btn-sm btn-outline-danger'
                                                            onClick={() => {
                                                                let competitionBefore = competition;
                                                                competitionBefore.giftTheVoiceRound2[2].singEffects = null;
                                                                this.setState({
                                                                    competition: competitionBefore
                                                                })
                                                                document.getElementById("sinTop3").value = "";
                                                            }}
                                                        >x</button>
                                                    </div>

                                                }
                                                
                                            </div>
                                            <br />
                                            {/* Avatar, Khung bình luận, Thẻ cá nhân, Trang trí cá nhân, Hiệu ứng vào phòng */}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    <label>Avatar</label>
                                                </div>
                                                <div className="col-2">
                                                    <label>Khung bình luận</label>
                                                </div>
                                                <div className="col-2">
                                                    <label>Thẻ cá nhân</label>
                                                </div>
                                                <div className="col-2">
                                                    <label>Trang trí cá nhân</label>
                                                </div>
                                                <div className="col-2">
                                                    <label>Hiệu ứng vào phòng</label>
                                                </div>
                                            </div>
                                            {/* CHỌN ================= */}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    <input list='davaTop3' id="avaTop3"
                                                        placeholder='Chọn Khung'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="davaTop3"
                                                    >
                                                        <option value="">Chọn Khung</option>
                                                        {
                                                            avas && avas.map((value) => (
                                                                <option name={value.previewImg} key={value._id} value={value._id} >
                                                                </option>
                                                            ))
                                                        }
                                                    </datalist>
                                                </div>
                                                <div className="col-2">
                                                    <input list='dcmtTop3' id="cmtTop3"
                                                        placeholder='Chọn hiệu ứng bình luận'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="dcmtTop2"
                                                    >
                                                        <option value="">Chọn hiệu ứng bình luận</option>
                                                        {
                                                            commentFrames && commentFrames.map((value) => (
                                                                <option name={value.previewImg} key={value._id} value={value._id} >
                                                                </option>
                                                            ))
                                                        }
                                                    </datalist>
                                                </div>
                                                <div className="col-2">
                                                    <input list='dprsTop3' id="prsTop3"
                                                        placeholder='Chọn thẻ cá nhân'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="dprsTop3"
                                                    >
                                                        <option value="">Chọn thẻ cá nhân</option>
                                                        {
                                                            personalCards && personalCards.map((value) => (
                                                                <option name={value.previewImg} key={value._id} value={value._id} >
                                                                </option>
                                                            ))
                                                        }
                                                    </datalist>
                                                </div>
                                                <div className="col-2">
                                                    <input list='dudcTop3' id="udcTop3"
                                                        placeholder='Chọn trang trí cá nhân'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="dudcTop3"
                                                    >
                                                        <option value="">Chọn trang trí cá nhân</option>
                                                        {
                                                            userDecorations && userDecorations.map((value) => (
                                                                <option name={value.url} key={value._id} value={value._id} >
                                                                </option>
                                                            ))
                                                        }

                                                    </datalist>
                                                </div>
                                                <div className="col-2">
                                                    <input list='drooTop3' id="rooTop3"
                                                        placeholder='Chọn Hiệu ứng vào phòng'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="drooTop3"
                                                    >
                                                        <option value="">Chọn Hiệu ứng vào phòng</option>
                                                        {
                                                            roomEntryEffects && roomEntryEffects.map((value) => (
                                                                <option key={value._id} value={value._id} >
                                                                </option>
                                                            ))
                                                        }
                                                    </datalist>
                                                </div>
                                            </div>
                                            {/* XEM TRƯỚC =================*/}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        (competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[2].avatarFrame) ? (
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[2].avatarFrame.previewImg}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[2].avatarFrame = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("avaTop3").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                        ) : ''
                                                    }
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        (competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[2].commentFrame) ? (
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[2].commentFrame.resourceUrl}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[2].commentFrame = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("cmtTop3").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                        ) : ''
                                                    }
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        (competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[2].personalCard) ? (
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[2].personalCard.resourceUrl}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[2].personalCard = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("prsTop3").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                        ) : ''
                                                    }
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        (competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[2].userDecoration) ? (
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[2].userDecoration.resourceUrl}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[2].userDecoration = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("udcTop3").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                        ) : ''
                                                    }
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        (competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[2].roomEntryEffect) ? (
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[2].roomEntryEffect.resourceUrl}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[2].roomEntryEffect = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("rooTop3").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                        ) : ''
                                                    }
                                                </div>
                                            </div>
                                            <br />

                                            {/* Quà vật lý */}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    <label>Quà vật lý</label>
                                                </div>

                                            </div>
                                            {/* CHỌN ================= */}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    <input list='drgfTop3' id="rgfTop3"
                                                        placeholder='Chọn Quà'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="drgfTop3"
                                                    >
                                                        <option value="">Chọn Quà</option>
                                                        {
                                                            realGifts && realGifts.map((value) => (
                                                                <option name={value.name} key={value.id} value={value.id} >
                                                                </option>
                                                            ))
                                                        }
                                                    </datalist>
                                                </div>

                                            </div>
                                            {/* XEM TRƯỚC =================*/}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        (competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[2].realGift) ? (
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[2].realGift.url}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[2].realGift = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("rgfTop3").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                        ) : ''
                                                    }
                                                </div>
                                            </div>
                                            <br />
                                        </div>

                                        <br /><br />
                                        <div id="giftsTop4" className='border border-primary'>
                                            <h4>Quà Khuyến khích</h4>

                                            {/* CUP, Quà, Avatar */}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    <label>CUP</label>
                                                </div>
                                                <div className="col-2">
                                                    <label>Quà</label>
                                                </div>
                                                <div className="col-2">
                                                    <label>Avatar</label>
                                                </div>
                                                <div className="col-2">
                                                    <label>Quà vật lý</label>
                                                </div>
                                            </div>
                                            {/* CHỌN ================= */}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    <input list='dcupTop4' id="cupTop4"
                                                        placeholder='Chọn Cup'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="dcupTop4"
                                                    >
                                                        <option value="">Chọn Cup</option>
                                                        {
                                                            cups && cups.map((value) => (
                                                                <option key={value.id} value={value.id} >
                                                                </option>
                                                            ))
                                                        }
                                                    </datalist>
                                                </div>
                                                <div className="col-2">
                                                    <input list='dgifTop4' id="gifTop4"
                                                        placeholder='Chọn Quà'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="dgifTop4"
                                                    >
                                                        <option value="">Chọn Quà</option>
                                                        {
                                                            cups && cups.map((value) => (
                                                                <option name={value.url} key={value.id} value={value.id} >
                                                                </option>
                                                            ))
                                                        }

                                                    </datalist>
                                                </div>
                                                <div className="col-2">
                                                    <input list='davaTop4' id="avaTop4"
                                                        placeholder='Chọn Khung'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="davaTop4"
                                                    >
                                                        <option value="">Chọn Khung</option>
                                                        {
                                                            avas && avas.map((value) => (
                                                                <option name={value.previewImg} key={value._id} value={value._id} >
                                                                </option>
                                                            ))
                                                        }
                                                    </datalist>
                                                </div>
                                                <div className="col-2">
                                                    <input list='drgfTop4' id="rgfTop4"
                                                        placeholder='Chọn Quà'
                                                        onChange={this.showImgPreview} />
                                                    <datalist
                                                        id="drgfTop4"
                                                    >
                                                        <option value="">Chọn Quà</option>
                                                        {
                                                            realGifts && realGifts.map((value) => (
                                                                <option name={value.name} key={value.id} value={value.id} >
                                                                </option>
                                                            ))
                                                        }
                                                    </datalist>
                                                </div>

                                            </div>
                                            {/* XEM TRƯỚC ================= */}
                                            <div className="row mb-2">
                                                <div className="col-1">
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        (competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[3].cup) ? (
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[3].cup.url}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[3].cup = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("cupTop4").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                        ) : ''
                                                    }
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        (competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[3].gift) ? (
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[3].gift.url}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[3].gift = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("gifTop4").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                        ) : ''
                                                    }
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        (competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[3].avatarFrame) ? (
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[3].avatarFrame.previewImg}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[3].avatarFrame = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("avaTop4").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                        ) : ''
                                                    }
                                                </div>
                                                <div className="col-2">
                                                    {
                                                        (competition && competition.giftTheVoiceRound2 && competition.giftTheVoiceRound2[3].realGift) ? (
                                                            <>
                                                                <img width={50} height={50} src={competition.giftTheVoiceRound2[3].realGift.url}>
                                                                </img>
                                                                <button className='btn btn-sm btn-outline-danger'
                                                                    onClick={() => {
                                                                        let competitionBefore = competition;
                                                                        competitionBefore.giftTheVoiceRound2[3].realGift = null;
                                                                        this.setState({
                                                                            competition: competitionBefore
                                                                        })
                                                                        document.getElementById("rgfTop4").value = "";
                                                                    }}
                                                                >x</button>
                                                            </>
                                                        ) : ''
                                                    }
                                                </div>
                                            </div>
                                            <br />


                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.saveCompetition}>Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}