import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import MyContext from '../../../common/MyContext';

const $ = window.$;

export default class ModalSellGift extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            noItem: 1
        }
        this.sellGift = this.sellGift.bind(this);
    }

    sellGift() {
        this.setState({
            loading: true
        })
        const { user } = this.context;

        const json = {
            userId : user.facebookId,
            domain: window.location.hostname,
            giftId: this.props.gift.id,
            noItem: this.state.noItem
        }

        axios.post(global.config.apiDomain + '/rest/sellgift', JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            
            this.setState({
                loading: false
            })
            if(res.data.status == "OK") {
                $('#modalSellGift').modal('hide');
                this.props.resetList();
                this.loadUser();
                toast.success(res.data.message);
            } else {
                toast.warning(res.data.message);
            }
        }, err => {
            this.setState({
                loading: false
            })
            toast.error("Bán quà tặng không thành công.");
        })


    }

    componentDidMount() {
        $("#modalSellGift").on("hidden.bs.modal", function () {
            this.state.noItem = 1;
        }.bind(this));
    }

    closeModal = () => {
        this.state.noItem = 1;
    }

    loadUser() {
        // const json = JSON.stringify({ domain: window.location.hostname});

		// axios.post(global.config.apiDomain + '/rest/loginuser', json, {
		// 	headers: {
		// 	  'Content-Type': 'application/json'
		// 	}
		//   }).then(res => {
		// 	const {user} = res.data;
        //     console.log(user);
		// 	this.context.updateUser(user); 
		//   })

        const { user } = this.context;
        if(this.props.gift.id == "BALLOON") {
            user.totalIcoin = user.totalIcoin + (Math.floor(this.props.gift.buyPrice * 0.3 * this.state.noItem));
        } else {
            user.totalIcoin = user.totalIcoin + (this.props.gift.sellPrice * this.state.noItem);
        }
        this.context.updateUser(user); 
    }

    render() {
        const { gift } = this.props;
        console.log(gift)
        return (
            <div className="modal fade" id="modalSellGift" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Bán quà tặng</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {gift != null ? (
                                <div
                                    className=" text-center">
                                    <img className="bg-secondary border rounded" src={gift.url} />
                                    <p className="text-center mb-1">{gift.name} &nbsp;x{gift.noItem}</p>
                                    <p className="text-center mb-1">
                                        {this.state.noItem <= 1 ? (
                                            <button
                                                disabled={true}
                                                className="btn-red disable"><i className="fa fa-minus"></i></button>
                                        ) : (
                                            <button
                                                onClick={() => { this.setState({ noItem: this.state.noItem - 1 }) }}
                                                className="btn-red"><i className="fa fa-minus"></i></button>
                                        )}

                                        <span className="ml-2 mr-2">{this.state.noItem}</span>
                                        {this.state.noItem >= gift.noItem ? (
                                            <button
                                            disabled={true}
                                            className="btn-red disable"><i className="fa fa-plus"></i></button>
                                        ) : (
                                            <button
                                            onClick={() => { this.setState({ noItem: this.state.noItem + 1 }) }}
                                            className="btn-red"><i className="fa fa-plus"></i></button>
                                        )}
                                    </p>
                                    <p>= {gift.id == "BALLOON" ? (
                                        Math.floor(gift.buyPrice * 0.3 * this.state.noItem)
                                    ) : (
                                        gift.sellPrice * this.state.noItem
                                    )} <img height="15px" width="15px" src={require('../../../assets/images/icoin.png')}></img></p>
                                </div>
                            ) : ""}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                            {this.state.loading ?
                                (
                                    <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Xác nhận...
                                    </button>
                                ) :
                                (
                                    <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.sellGift}>Xác nhận</button>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ModalSellGift.contextType = MyContext;