import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import SVGA from 'svgaplayerweb'
import bindModel from '../../../common/bindModel.js';
import { toast } from 'react-toastify';

export default class ModalUpdateMedal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            thisMedal: null,
            loading: false,
            register: true
        };

        this.handleCreate = this.handleCreate.bind(this);
        this.uploadAnimation = this.uploadAnimation.bind(this);
        this.guid = this.guid.bind(this);
        this.loadSVGA = this.loadSVGA.bind(this);
    }

    handleCreate(){
        const { thisMedal, register } =this.state;
        let url = global.config.apiDomain + "/rest/medal/createOrUpdateMedal"

        if(thisMedal== null || thisMedal.deleted == "-1" || thisMedal.type == "-1" || thisMedal.deleted == null || thisMedal.type == null 
        ||  thisMedal.resourceUrl == null || thisMedal.backgroundColor == null || thisMedal.order == null ||
        thisMedal.resourceUrl == "" || thisMedal.backgroundColor == "" || thisMedal.pinColor == null || thisMedal.pinColor == "" 
        || thisMedal.shadowColor == null || thisMedal.shadowColor == "" || thisMedal.frameColor == null || thisMedal.frameColor == ""){
            toast.warning("Điền đầy đủ thông tin")
            console.log(thisMedal)
            return
        }

        this.setState({
            loading: true
        })

        let deleted = thisMedal.deleted == "1" ? true :  false;
        thisMedal.deleted = deleted;
        thisMedal.order = Number(thisMedal.order);
        thisMedal.id ? thisMedal.id = thisMedal.id : thisMedal.id = Number(0);
        thisMedal.addTime =  new Date().getTime();
        axios.post(url,thisMedal)
        .then(res =>{
            if(res.data.status == "OK"){
                // this.props.handleUpdateState();
                toast.success(res.data.message);
                this.setState({
                    loading: false
                })
                window.$("#modalUpdateMedal").modal("hide");
            }else{
                toast.warning(res.data.message)
                this.setState({
                    loading: false
                })
            }
        })
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    loadSVGA(url,id) {
        var player = new SVGA.Player(`#${id}`);
        var parser = new SVGA.Parser(`#${id}`);
        parser.load(url, function (videoItem) {
            console.log(videoItem)
            player.setVideoItem(videoItem);
            player.startAnimation();
        })
    }
    
    uploadAnimation(event) {
        var target = event.target;
        const { thisMedal } = this.state;
        //10495760
        if (target.files[0].size > 10495760 ){
            toast.error("Kích thước file phải dưới 10mb.");
            return;
        }

        this.setState({
            loading: true
        });

        var bucketName = "ikara-data/images/medal";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";

        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    let animatedUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;

                    if(target.id && target.id.includes("ResourceUrl")){
                        thisMedal.resourceUrl = animatedUrl;  

                        if(animatedUrl.endsWith(".svga")){
                            $("#show-resourceUrl").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas1\"></div>");
                            this.loadSVGA(thisMedal.resourceUrl,"demoCanvas1");
                        }else if(animatedUrl.endsWith(".png")){
                            $("#show-resourceUrl").children().replaceWith(`<img src=${thisMedal.resourceUrl} width=\"150px" />`);
                        } else {
                            $("#show-resourceUrl").children().replaceWith(`<a href=${thisMedal.resourceUrl} width=\"150px">${thisMedal.resourceUrl}</a>`); 
                        }


                    }else{
                        thisMedal.thumbnailUrl = animatedUrl;
                        $("#show-thumbnailUrl").children().replaceWith(`<img src=${thisMedal.thumbnailUrl} width="150px" />`);
                    }

                    this.setState({
                        thisMedal: thisMedal,
                        loading: false
                    })

                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);

    }

    componentWillReceiveProps(props){
        const { medal } = props;

        if(medal == null){
            let json  = {
                thumbnailUrl: "",
                resourceUrl: ""
            }
            this.setState({
                thisMedal: json,
                loading:false
            })

            $("#show-resourceUrl").children().replaceWith(`<div width={500} height={500} id="canvas"></div>`);
            $("#show-thumbnailUrl").children().replaceWith(`<div width={500} height={500} id="canvas"></div>`);
        }

        if(medal != null){
            medal.deleted ? medal.deleted = "1" : medal.deleted = "0";
            this.setState({
                thisMedal: medal,
                register: false
            })

            if(medal.resourceUrl && medal.resourceUrl.endsWith(".svga")){
                $("#show-resourceUrl").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas1\"></div>");
                this.loadSVGA(medal.resourceUrl,"demoCanvas1");
            }

            if(medal.resourceUrl && medal.resourceUrl.endsWith(".png")){
                $("#show-resourceUrl").children().replaceWith(`<img src=${medal.resourceUrl} width=\"150px" />`);
            }

            if(medal.thumbnailUrl){
                $("#show-thumbnailUrl").children().replaceWith(`<img src=${medal.thumbnailUrl} width="150px" />`);
            }
        }
    }

    render(){
        const model = bindModel(this);
        const { thisMedal, register } = this.state;

        return (
            <div>
                <div className="modal fade" id="modalUpdateMedal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">{register ? "Cập nhật Huy Hiệu" : "Tạo Huy Hiệu"}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                    <div id="order" className="row mb-2">
                                        <div className="col-3">
                                            <label>Độ ưu tiên hiển thị</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                type="number"
                                                name="order"
                                                placeholder="Ưu tiên hiển thị thứ tự lớn hơn"
                                                {...model('thisMedal.order')} />
                                        </div>
                                    </div>
                                    <div id="name" className="row mb-2">
                                        <div className="col-3">
                                            <label>Tên Huy Hiệu</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                name="name"
                                                placeholder="Nhập tên huy hiệu"
                                                {...model('thisMedal.name')} />
                                        </div>
                                    </div>
                                     {/* màu nền */}
                                    <div id="bgColor" className="row mb-2">
                                        <div className="col-3">
                                            <label>Màu nền</label>
                                        </div>
                                        <div className="col-4">
                                            <input
                                                className="form-control mb-2"
                                                name="bgColor"
                                                placeholder="Nhập mã màu nền"
                                                {...model('thisMedal.backgroundColor')} />
                                        </div>
                                        <div className="col-5">
                                        {
                                            (this.props.medal || (thisMedal && thisMedal.backgroundColor)) ? 
                                                (/^#(?:[0-9a-fA-F]{3,4}){1,2}$/g.test(thisMedal.backgroundColor)) ? 
                                                    (<div style={{ marginTop: '1%', marginRight: '1%', marginLeft: '1%', marginBottom: '1%', height: '75%', width: '100%', backgroundColor: thisMedal.backgroundColor }} />) :
                                                (<div><h5>Mã màu sai định dạng</h5></div>)
                                            : ""
                                        }
                                        </div>
                                    </div>
                                    {/* màu Khung */}
                                    <div id="frameColor" className="row mb-2">
                                        <div className="col-3">
                                            <label>Màu khung</label>
                                        </div>
                                        <div className="col-4">
                                            <input
                                                className="form-control mb-2"
                                                name="frameColor"
                                                placeholder="Nhập mã màu khung"
                                                {...model('thisMedal.frameColor')} />
                                        </div>
                                        <div className="col-5">
                                        {
                                            (this.props.medal || (thisMedal && thisMedal.frameColor)) ? 
                                                (/^#(?:[0-9a-fA-F]{3,4}){1,2}$/g.test(thisMedal.frameColor)) ? 
                                                    (<div style={{ marginTop: '1%', marginRight: '1%', marginLeft: '1%', marginBottom: '1%', height: '75%', width: '100%', backgroundColor: thisMedal.frameColor }} />) :
                                                (<div><h5>Mã màu sai định dạng</h5></div>)
                                            : ""
                                        }
                                        </div>
                                    </div>
                                    {/* màu ghim */}
                                    <div id="pinColor" className="row mb-2">
                                        <div className="col-3">
                                            <label>Màu ghim</label>
                                        </div>
                                        <div className="col-4">
                                            <input
                                                className="form-control mb-2"
                                                name="pinColor"
                                                placeholder="Nhập mã màu ghim"
                                                {...model('thisMedal.pinColor')} />
                                        </div>
                                        <div className="col-5">
                                        {
                                            (this.props.medal || (thisMedal && thisMedal.pinColor)) ? 
                                                (/^#(?:[0-9a-fA-F]{3,4}){1,2}$/g.test(thisMedal.pinColor)) ? 
                                                    (<div style={{ marginTop: '1%', marginRight: '1%', marginLeft: '1%', marginBottom: '1%', height: '75%', width: '100%', backgroundColor: thisMedal.pinColor }} />) :
                                                (<div><h5>Mã màu sai định dạng</h5></div>)
                                            : ""
                                        }
                                        </div>
                                    </div>
                                    {/* màu đổ bóng */}
                                    <div id="shadowColor" className="row mb-2">
                                        <div className="col-3">
                                            <label>Màu đổ bóng</label>
                                        </div>
                                        <div className="col-4">
                                            <input
                                                className="form-control mb-2"
                                                name="shadowColor"
                                                placeholder="Nhập mã màu đổ bóng"
                                                {...model('thisMedal.shadowColor')} />
                                        </div>
                                        <div className="col-5">
                                        {
                                            (this.props.medal || (thisMedal && thisMedal.shadowColor)) ? 
                                                (/^#(?:[0-9a-fA-F]{3,4}){1,2}$/g.test(thisMedal.shadowColor)) ? 
                                                    (<div style={{ marginTop: '1%', marginRight: '1%', marginLeft: '1%', marginBottom: '1%', height: '75%', width: '100%', backgroundColor: thisMedal.shadowColor }} />) :
                                                (<div><h5>Mã màu sai định dạng</h5></div>)
                                            : ""
                                        }
                                        </div>
                                    </div>
                                    <div id="thumbnailUrl" className="row mb-2">
                                        <div className="col-3">
                                            <label>Ảnh Mô Tả</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="custom-file-input"
                                                name="thumbnailUrl"
                                                type='file'
                                                id="inputThumbnailUrl"
                                                placeholder="Chọn file"
                                                onChange={this.uploadAnimation}/>
                                                <label className="custom-file-label ml-3 mr-3" htmlFor="inputThumbnailUrl">Choose file</label>
                                        </div>
                                    </div>
                                    <div id="reviewThumbnailUrl" className="row mb-2">
                                    <div className="col-3">
                                        </div>
                                        <div id="show-thumbnailUrl" className="col-9" style={{ maxHeight: "300px" }}>
                                            <div width={500} height={500} id="canvas"></div>
                                        </div>
                                    </div>
                                    <div id="resourceUrl" className="row mb-2">
                                        <div className="col-3">
                                            <label>Hình Ảnh Huy Hiệu</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="custom-file-input"
                                                name="resourceUrl"
                                                type='file'
                                                id="inputResourceUrl"
                                                placeholder="Chọn file"
                                                onChange={this.uploadAnimation}/>
                                                <label className="custom-file-label ml-3 mr-3" htmlFor="inputResourceUrl">Choose file</label>
                                        </div>
                                    </div>
                                    <div id="reviewResourceUrl" className="row mb-2">
                                    <div className="col-3">
                                        </div>
                                        <div id="show-resourceUrl" className="col-9" style={{ maxHeight: "300px" }}>
                                            <div width={500} height={500} id="canvas"></div>
                                        </div>
                                    </div>
                                    <div id="type" className="row mb-2">
                                        <div className="col-3">
                                            <label>Loại Huy Hiệu</label>
                                        </div>
                                        <div className="col-9">
                                            <select name="type" id="type"
                                            {...model('thisMedal.type')}>
                                                <option value="-1">Chọn</option>
                                                <option value="ACHIEVEMENT">Thành Tựu</option>
                                                <option value="EVENT">Sự Kiện</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div id="deleted" className="row mb-2">
                                        <div className="col-3">
                                            <label>Trạng thái</label>
                                        </div>
                                        <div className="col-9">
                                            <select name="deleted" id="deleted"
                                             {...model('thisMedal.deleted')}>
                                                <option value="-1">Chọn</option>
                                                <option value="0">Kích hoạt</option>
                                                <option value="1">Vô hiệu</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-3">
                                            <label>Mô tả</label>
                                        </div>
                                        <div className="col-9">
                                            <textarea rows="4"
                                                className="form-control mb-2"
                                                placeholder="Nhập mô tả"
                                                {...model('thisMedal.description')} />
                                        </div>
                                    </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal" >Hủy</button>
                                {this.state.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red"
                                        onClick={this.handleCreate}
                                        >Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}