import React, { Component } from 'react';
import axios from 'axios';
import '../../../assets/css/newDevice.css';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import MidiPlayer from 'midi-player-js';
import * as Tone from 'tone';
import MIDISounds from 'midi-sounds-react';

export default class MidiRecordingDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            midiRecording: [],
            page: 0,
            statusButtonNextPage1: false,
            isLoading: false,
            isInitialLoading: true,
            error: null,
            countMidiRecording: 0
        };

        this.getMidiRecordingList = this.getMidiRecordingList.bind(this);
        this.handleMidi = this.handleMidi.bind(this);
        this.midiSoundsRef = React.createRef();
        this.player = new MidiPlayer.Player();
        this.synth = new Tone.PolySynth().toDestination();
        this.getMidiRecordingList_url = this.getMidiRecordingList_url.bind(this);
    }

    componentDidMount() {
        this.getMidiRecordingList();
    }

    getMidiRecordingList(page = 0) {
        const { isInitialLoading } = this.state;
        const { match } = this.props;
        const id = match.params.id;

        if (!isInitialLoading) {
            this.setState({
                isLoading: true
            });
        }

        let apiUrl = global.config.apiDomain + `/rest/midi-score/get-list-midi-recording/${this.state.page}?idSongKey=${id}`;
        axios.get(apiUrl, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            var midiRecording = res.data.models;
            console.log(midiRecording)
            let status = midiRecording.length > 0;
           
            
            this.setState((prevState) => ({
                midiRecording: prevState.midiRecording.concat(midiRecording),
                page: prevState.page + 1,
                statusButtonNextPage1: status,
                isLoading: false,
                isInitialLoading: false,
                error: null
            }));
            this.getMidiRecordingList_url();
        })
        .catch(error => {
            this.setState({
                isLoading: false,
                isInitialLoading: false
            });
            console.error("Error:", error);
        });
    }
    getMidiRecordingList_url(){
        console.log("vào đây1");
        
        var midiRecording1 = [...this.state.midiRecording]
        console.log(midiRecording1)
    midiRecording1.map(async (item) => {
        if(item.aiMixedMidiUrl == null || item.aiMixedMidiUrl ==""){
        try {
          const response = await fetch(`https://ai2.ikara.co/extractbeat/v1/mix-file-recording/${item._id}`);
          const result = await response.json();
          console.log(`Data for : `, result.mixedFileUrl);
          item.aiMixedMidiUrl = result.mixedFileUrl;
        } catch (error) {
          console.error(`Error fetching data for : `, error);
        }
    }
    console.log(midiRecording1)
    this.setState({
        midiRecording: midiRecording1
    });
      });
    }
    stopMidiPlayback = async () => {
        try {
            this.player.stop();
            this.synth.releaseAll();
        } catch (error) {
            console.error('Lỗi khi dừng phát lại hoặc giải phóng MIDI:', error.message);
        }
    }

    handleMidi = async (midiPath) => {
        console.log("handleMidi được gọi với đường dẫn:", midiPath);
        try {
            await this.stopMidiPlayback();
            const response = await fetch(midiPath);
            const arrayBuffer = await response.arrayBuffer();
            const midiArray = new Uint8Array(arrayBuffer);
            this.player.loadArrayBuffer(midiArray);

            this.player.on('midiEvent', (event) => {
                console.log("Sự kiện MIDI:", event);
                if (event.name === 'Note on') {
                    console.log("Note On Event:", event);
                    const { noteNumber, velocity } = event;
                    const noteFrequency = Tone.Frequency(noteNumber, 'midi').toFrequency();
                    this.synth.triggerAttackRelease(noteFrequency, '8n');
                }
            });

            this.player.play();
        } catch (error) {
            console.error("Error loading or playing MIDI file:", error);
        }
    }

    render() {
        const { midiRecording, statusButtonNextPage1, isLoading, isInitialLoading, page } = this.state;

        return (
            <>
                <div id="content">   
                    <div className="container-fluid">
                        <div className="container-fluid">
                            <h1 className="text-center">Danh sách chấm điểm bài thu</h1>
                        </div>
                        {isInitialLoading ? (
                            <div className="spinner-container">
                                <div className="spinner"></div>
                            </div>
                        ) : midiRecording.length > 0 ? (
                            <table className="table table-hover">
                                <thead>
                                    <tr className="text-center">
                                        <th className="align-middle" scope="col">Id</th>
                                        <th className="align-middle" scope="col">User id</th>
                                        <th className="align-middle" scope="col">Ngày phát hành</th>
                                        <th className="align-middle" scope="col">Tên bài hát</th>
                                        <th className="align-middle" scope="col">Link nhạc</th>
                                        <th className="align-middle" scope="col">File Midi</th>
                                        <th className="align-middle" scope="col">Phát</th>
                                        <th className="align-middle" scope="col">mixedFileUrl</th>
                                        <th className="align-middle" scope="col">Điểm</th>
                                        <th className="align-middle" scope="col">Điểm ảo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {midiRecording.map((item) => {
                                        let addTime2 = new Date(item.addTime2)
                                        return (
                                            <tr key={item._id}>
                                                <td className="align-middle text-center" scope="col">{item._id}</td>
                                                <td className="align-middle text-center" scope="col">{item.uidUser}</td>
                                                <td className="align-middle text-center" scope="col">{addTime2.toLocaleString()}</td>
                                                <td className="align-middle text-center" scope="col">{item.songName}</td>
                                                <td className="align-middle text-center" scope="col">
                                                    <a href={item.vocalUrl} target="_blank" rel="noopener noreferrer">Nghe nhạc</a>
                                                </td>
                                                <td className="align-middle text-center" scope="col">{item.midi}</td>
                                                <td className="align-middle text-center" scope="col">
                                                    <div className="d-flex justify-content-center">
                                                        {item.midi && 
                                                            <button 
                                                                className="btn btn-primary mr-2" 
                                                                onClick={() => {
                                                                    console.log("click button");
                                                                    this.handleMidi(item.midi)
                                                                }}
                                                            >
                                                                Play
                                                            </button>
                                                        }
                                                        {item.midi && 
                                                            <button 
                                                                className="btn btn-danger mr-2" 
                                                                onClick={() => {
                                                                    this.stopMidiPlayback()
                                                                }}
                                                            >
                                                                Stop
                                                            </button>
                                                        }
                                                    </div>
                                                </td>
                                                <td className="align-middle text-center" scope="col">
                                                    {item.aiMixedMidiUrl != null && item.aiMixedMidiUrl != "" ?
                                                <audio controls>
                                                <source src={item.aiMixedMidiUrl}/>
                                            </audio>    
                                                : <p>loading...</p>}
                                                </td>
                                                <td className="align-middle text-center" scope="col">{item.scoreMidi}</td>
                                                <td className="align-middle text-center" scope="col">{item.scoreMidiFake}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        ) : (
                            <div className="text-center">
                                <p>Không có dữ liệu.</p>
                            </div>
                        )}
                        {
                            statusButtonNextPage1 &&
                            (<div onClick={this.getMidiRecordingList} className="view_more w-100 text-center mb-lg-4">
                                <a className="d-none d-md-block">
                                    <span>{isLoading ? "ĐANG TẢI..." : "TẢI THÊM"}</span>
                                </a>
                            </div>)
                        }
                    </div>
                </div>
            </>
        );              
    }    
}
