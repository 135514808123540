import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import $ from 'jquery';
import { toast } from 'react-toastify';
import ModalCreate from './components/ModalCreate';
import ModalDelete from './components/ModalDelete';
import ModalUpdateSinger from './components/ModalUpdateSinger';


const titleList = ["#",
                  "Thời gian",
                  "Thể loại",
                  "Mô tả",
                  "Thumbnail",
                  "Video",
                  "Fan Page",
                  "Order",
                  "Cập Nhật",
                  "Xoá",
                  "Bỏ Ghim"
                ]
export default class UserPostPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      postList: null,
      user: null,
      pin: true
    }

    this.submit = this.submit.bind(this);
    this.getUserPost = this.getUserPost.bind(this);
    this.updateModel = this.updateModel.bind(this);
    this.getPinPosts = this.getPinPosts.bind(this);
    this.pin = this.pin.bind(this);
    this.unpin = this.unpin.bind(this);
  }

  componentDidMount() {
    this.getPinPosts();
  }

  getUserPost(uid) {
    axios.get(global.config.apiDomain + '/rest/userPost/' + uid)
      .then(res => {
        if (res.data.status == "OK") {
          console.log(res.data);
          toast.success(res.data.message);
          this.setState({
            postList: res.data.list,
            user: res.data.user,
            model: null,
            pin: false
          })
        } else {
          toast.error(res.data.message);
        }

      });
  }

  getPinPosts() {
    axios.get(global.config.apiDomain + '/rest/userPost/pin/list')
      .then(res => {
        this.setState({
          postList: res.data.list,
          model: null,
          pin: true
        })

      });
  }

  async pin(recordingId) {
    console.log("pin")
    let {user, pin} = this.state;
    let order = prompt("Nhập vị trí muốn ghim");
    let isConfirm = true;
    try {
      // Gửi yêu cầu đầu tiên và chờ kết quả
      let response = await axios.post(global.config.apiDomain + '/rest/userPost/ispin' + "?order=" + order);

      if (response.data.status == "OK") {
        isConfirm = window.confirm("Vị trí này đã có bài ghim. Bạn có muốn ghi đè không ?");
      }

      if (!isConfirm) return;

      // Gửi yêu cầu thứ hai và chờ kết quả
      response = await axios.post(global.config.apiDomain + '/rest/userPost/pin/' + recordingId + "?order=" + order);

      if (response.data.status == "OK") {
        toast.success(response.data.message)

        if(pin) {
          this.getPinPosts();
        } else {
          this.getUserPost(user.uid)
        }
      } else {
        toast.error(response.data.message)
      }
    } catch (error) {
      // Xử lý lỗi nếu có
      console.error("Đã xảy ra lỗi", error);
    }
  }

  async unpin(recordingId) {
    let {user, pin} = this.state;
    let isConfirm = window.confirm("Bạn chắc chắn muốn hủy ghim ?");
    if (!isConfirm) return;
    try {
      // Gửi yêu cầu đầu tiên và chờ kết quả
      let response = await axios.post(global.config.apiDomain + '/rest/userPost/unpin/' + recordingId);

      if (response.data.status == "OK") {
        toast.success(response.data.message)

        if(pin) {
          this.getPinPosts();
        } else {
          this.getUserPost(user.uid)
        }
      } else {
        toast.error(response.data.message)
      }

    } catch (error) {
      // Xử lý lỗi nếu có
      console.error("Đã xảy ra lỗi", error);
    }
  }

  submit() {
    let uid = $('#uid').val().trim();
    if (!uid) {
      return toast.warning("Nhập ID User");
    }

    this.getUserPost(uid);
  }

  updateModel(event) {
    const { user } = this.state;
    if (user == null) return;
    let index = event.target.name;

    let model = null;
    if (index === "-1") {
      model = {
        recordingId: 0,
        facebookId: user.facebookId,
        message: '',
        postType: '',
        thumbnailImageUrl: '',
        subVideos: [],
        postLink: ''
      }
    } else {
      model = this.state.postList[index];
    }

    this.setState({
      model: model
    })
  }

  render() {
    const { postList, user, model, pin } = this.state;
    return (
      <>
        <div id="content">
          <ModalCreate
            model={model}
            resetList={this.getUserPost}
          ></ModalCreate>
          <ModalDelete
            model={model}
            resetList={this.getUserPost}
          ></ModalDelete>
          <ModalUpdateSinger
            user={user}
          ></ModalUpdateSinger>
          <div className="container-fluid">

            <h1 className="text-center">Quản lý Bài đăng Ca sĩ</h1>

            <div className="d-flex justify-content-center mb-3">
              <input
                type="text"
                className="form-control col-6"
                placeholder="Nhập ID"
                id="uid"
              />
              <button
                style={{ width: "120px" }}
                className="btn btn-red"
                onClick={this.submit}
              >Xác nhận</button>
              <button
                style={{ width: "120px" }}
                className="btn btn-red ml-2"
                onClick={this.getPinPosts}
              >Reset</button>
            </div>

            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Bài đăng</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Ca sĩ</a>
              </li>
              {/* <li className="nav-item">
                <Link to="/admin/game-manager/create-game-level" className="btn btn-red mt-1 mr-1" >Bài Đăng</Link>
              </li>
              <li className="nav-item">
                <Link to="/admin/game-manager/create-game-type" className="btn btn-red mt-1" >Ca sĩ</Link>
              </li> */}
            </ul>
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div className=''>
                  <div className='row d-flex justify-content-between mt-2'>
                    {
                      user ? <button
                        style={{width: 100}}
                        className="btn btn-info ml-3 mb-1"
                        data-toggle="modal"
                        data-target="#modalCreate"
                        name="-1"
                        onClick={this.updateModel}
                      >+ Tạo Mới
                      </button> : <div style={{width: 100}}></div>
                    }

                    <div><h3 className="">Danh sách Bài đăng {pin ? "(Ghim)" : "(Ca sĩ)"}</h3></div>

                    <div style={{width: 100}}></div>
                  </div>

                  <table className="table table-hover">
                    <thead>
                      <tr>
                        {titleList.map((title) => 
                          <th scope="col">{title}</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {postList && postList.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{new Date(item.recordingTime).toLocaleString()}</td>
                            <td>{item.postType == 'IMAGE' ? <p className="text-primary">{item.postType}</p> : item.postType == 'VIDEO' ? <p className="text-info">{item.postType}</p> : item.postType == 'PLAYLIST' ? <p className="text-success">{item.postType}</p> : <p className="text-warning">{item.postType}</p>}</td>
                            <td>{item.message}</td>
                            <td>
                              <img src={item.thumbnailImageUrl}></img>
                            </td>
                            <td>
                              {item.subVideos ? item.subVideos.map((subVideo, i) => (<p key={i}>{subVideo}</p>)) : ''}
                            </td>
                            <td>
                              {item.fanPage ? item.fanPage : ""}
                            </td>
                            <td>{item.order != null ? item.order : ""}</td>
                            <td className="align-middle text-center" scope="col">
                              <button className='btn btn-success ml-2 mr-2'
                                data-toggle="modal"
                                data-target="#modalCreate"
                                onClick={this.updateModel}
                                name={index}
                              >
                                SỬA
                              </button>
                            </td>
                            <td className="align-middle text-center" scope="col">
                              {
                                item.status != 5 ? (
                                  <button
                                    className="btn btn-danger"
                                    onClick={this.updateModel}
                                    name={index}
                                    data-toggle="modal"
                                    data-target="#modalDelete"
                                  >
                                    XÓA
                                  </button>
                                ) : ''
                              }
                            </td>

                            <td className="align-middle text-center" scope="col">
                              {
                                item.order != null ? (
                                  <button
                                    style={{width: 100}}
                                    className="btn btn-danger"
                                    onClick={() => this.unpin(item.recordingId)}
                                    name={index}
                                  >
                                    Bỏ ghim
                                  </button>
                                ) : (
                                  <button
                                    style={{width: 100}}
                                    className="btn btn-danger"
                                    onClick={() => this.pin(item.recordingId)}
                                    name={index}
                                  >
                                    Ghim
                                  </button>
                                )
                              }
                            </td>

                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                {
                  user &&
                  <>
                    <div className='row justify-content-center'>
                      
                      <img width={200} src={user.profileImageLink}></img>

                    </div>
                    <br></br>
                    <div className='row justify-content-center'>
                      <div className=''>
                        <h5><span className='text-success'>{user.name}</span></h5>
                        <h6>ID: <span className='text-success'>{user.facebookId}</span></h6>
                        <br></br>
                        <button
                          className="btn btn-info mb-1 col-7"
                          data-toggle="modal"
                          data-target="#modalUpdateSinger"
                          name="-1"
                          onClick={this.updateModel}
                        >Cập nhật
                        </button>
                      </div>
                    </div>
                  </>
                }
              </div>
              <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">...</div>
            </div>
          </div>
        </div>
      </>
    );
  }
}