import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import bindModel from '../../common/bindModel.js';

import ModalUpdateFilter from './components/ModalUpdateFilter';
import ModalHideFilter from './components/ModalHideFilter';
import ModalDeleteFilter from './components/ModalDeleteFilter';

class DeepARFilterPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: "COLOR",
            filterList: [],
            filterModel: null,
        };

        this.changeType = this.changeType.bind(this);
        this.getAllFilters = this.getAllFilters.bind(this);
        this.createOrUpdateFilter = this.createOrUpdateFilter.bind(this);
        this.deleteOrActiveFilter = this.deleteOrActiveFilter.bind(this);
    }

    componentDidMount() {
        this.getAllFilters(this.state.type);
    }

    getAllFilters(type) {
        axios.post(global.config.apiDomain + '/rest/deepARFilter/getAllFilterByType/' + type)
            .then(res => {
                if (res.data.status === 'OK') {
                    console.log('/rest/deepARFilter/getAllFilterByType');
                    console.log(res);
                    this.setState({
                        filterList: res.data.filterList
                    })
                }
            })
    }

    createOrUpdateFilter(event) {
        let index = event.target.name;
        let filterModel = null;
        if (index === "-1") {
            filterModel = {
                type: this.state.type,
                _id: '',
                name: '',
                thumbnail: '',
                resourceUrl: '',
                order: 0,
                minVipLevel: 0,
                minLevel: 0,
                extraProperties: []
            }
        } else {
            filterModel = this.state.filterList[index];
        }

        this.setState({
            filterModel: filterModel
        })

    }

    deleteOrActiveFilter(event) {
        let index = event.target.name;
        let filterModel = this.state.filterList[index];

        this.setState({
            filterModel: filterModel
        })
    }

    changeType(event) {
        let type = event.target.value;
        this.setState({
            type: type
        })
        this.getAllFilters(type);
    }

    render() {
        const { filterList, filterModel, type } = this.state;
        // const model = bindModel(this);

        return (
            <>
                <ModalUpdateFilter
                    filterModel={filterModel}
                    resetList={this.getAllFilters}
                // resetList={this.getAllFilters((filterModel && filterModel.type != '')? filterModel.type: 'COLOR')}
                >
                </ModalUpdateFilter>
                <ModalHideFilter
                    filterModel={filterModel}
                    resetList={this.getAllFilters}
                >
                </ModalHideFilter>
                <ModalDeleteFilter
                    filterModel={filterModel}
                    resetList={this.getAllFilters}
                >
                </ModalDeleteFilter>
                <div id="content">
                    <div className="container">
                        <div className="row mb-2">
                            <div className="col-4">
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <span className="btn btn-success">Loại Filter</span>
                                    </div>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="type"
                                        onChange={this.changeType}
                                    >
                                        <option value='COLOR' >Bộ lọc Màu</option>
                                        <option value='BEAUTY'>Bộ lọc Trang điểm</option>
                                    </select>
                                </div>
                            </div>
                        </div>


                        {(type == 'COLOR') ? (<h1 className="text-center">Quản lí Bộ lọc Màu</h1>) : (<h1 className="text-center">Quản lí Bộ lọc Trang điểm</h1>)}

                        <button
                            className="btn btn-red mb-1"
                            data-toggle="modal"
                            data-target="#modalUpdateFilter"
                            name="-1"
                            onClick={this.createOrUpdateFilter}
                        >+ Tạo Mới Bộ lọc
                        </button>
                        <table className="table table-hover">
                            <thead>
                                <tr className="text-center">
                                    <th className="align-middle" scope="col">Thứ tự</th>
                                    <th className="align-middle" scope="col">ID</th>
                                    <th className="align-middle" scope="col">Tên</th>
                                    {(type == 'COLOR') ? (
                                        <th className="align-middle" scope="col">Bộ lọc</th>
                                    ) : ""}
                                    <th className="align-middle" scope="col">Minh họa</th>
                                    <th className="align-middle" scope="col">Level</th>
                                    <th className="align-middle" scope="col">Level Vip</th>
                                    {(type == 'BEAUTY') ? (
                                        <>
                                            <th className="align-middle" scope="col">Min Value</th>
                                            <th className="align-middle" scope="col">Max Value</th>
                                            <th className="align-middle" scope="col">Extra</th>
                                        </>
                                    ) : ""}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filterList && filterList.map((item, index) => {
                                        return (
                                            <tr>
                                                <td className='align-middle text-center'>{item.enable ? item.order : <del>{item.order}</del>}</td>
                                                <td className='align-middle text-center'>{item.enable ? <strong>{item._id}</strong> : <del>{item._id}</del>}</td>
                                                <td className='align-middle'>{item.enable ? item.name : <del>{item.name}</del>}</td>
                                                {item.type == 'COLOR' ?
                                                    (
                                                        <td align="center">
                                                            <img
                                                                src={item.resourceUrl}
                                                                style={{ width: '160px' }}
                                                                alt={item.name}
                                                            />
                                                        </td>
                                                    ) : ""
                                                }

                                                <td align="center">
                                                    <img
                                                        src={item.thumbnail}
                                                        style={{ width: '160px' }}
                                                        alt={item.name}
                                                    />
                                                </td>
                                                <td className='align-middle text-center'>{item.enable ? item.minLevel : <del>{item.minLevel}</del>}</td>
                                                <td className='align-middle text-center'>{item.enable ? item.minVipLevel : <del>{item.minVipLevel}</del>}</td>
                                                {item.type == 'BEAUTY' ?
                                                    (<>
                                                        <td className='align-middle text-center'>{item.enable ? item.minValue : <del>{item.minValue}</del>}</td>
                                                        <td className='align-middle text-center'>{item.enable ? item.maxValue : <del>{item.maxValue}</del>}</td>
                                                        <td className="align-middle text-center" scope="col">
                                                            {item.extraProperties ? item.extraProperties.map(property => {
                                                                const regexColor = /^#(?:[0-9a-fA-F]{3}){1,2}$/g;
                                                                if (regexColor.test('#' + property)) {
                                                                    return <p style={{ height: '18px', width: '70px', backgroundColor: '#' + property }}></p>
                                                                } else {
                                                                    return <a href={property} style={{ height: '18px', width: '70px' }}>{property.substring(property.lastIndexOf('/') + 1, property.length)}</a>
                                                                }
                                                            }) : ''}
                                                        </td>
                                                    </>) : ""
                                                }
                                                <td className='align-middle'>
                                                    {
                                                        !item.enable ? (
                                                            <>
                                                                <button
                                                                    className="btn btn-info"
                                                                    onClick={this.deleteOrActiveFilter}
                                                                    name={index}
                                                                    data-toggle="modal"
                                                                    data-target="#modalHideFilter"
                                                                >
                                                                    HIỆN
                                                                </button>&emsp;
                                                                <button
                                                                    className="btn btn-danger"
                                                                    onClick={this.deleteOrActiveFilter}
                                                                    name={index}
                                                                    data-toggle="modal"
                                                                    data-target="#modalDeleteFilter"
                                                                >
                                                                    XÓA LUÔN
                                                                </button>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <button
                                                                    className="btn btn-success"
                                                                    onClick={this.createOrUpdateFilter}
                                                                    name={index}
                                                                    data-toggle="modal"
                                                                    data-target="#modalUpdateFilter"
                                                                >
                                                                    Sửa
                                                                </button>&emsp;
                                                                <button
                                                                    className="btn btn-warning"
                                                                    onClick={this.deleteOrActiveFilter}
                                                                    name={index}
                                                                    data-toggle="modal"
                                                                    data-target="#modalHideFilter"
                                                                >
                                                                    ẨN
                                                                </button>
                                                            </>
                                                        )
                                                    }

                                                </td>
                                            </tr>
                                        )
                                    })

                                }

                            </tbody>
                        </table>
                    </div>
                </div>

            </>
        );
    }
}

export default DeepARFilterPage
