import ModalShowSVGA from './components/ModalShowSVGA';
import ModalShowGiftAnimation from './components/ModalShowGiftAnimation';
import React, { Component } from 'react';
import axios from 'axios';
import lodash from 'lodash';
import { Parser, Player } from 'svga'
import { Link } from "react-router-dom";
import '../../assets/css/giftmanager.css';

import $ from 'jquery';
import { toast } from 'react-toastify';

export default class DeletedGiftPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            gifts: [],
            giftsTemp: [],
            gift: null,
            giftShow: null,
            nameSearch: "",
            idSearch: "",
            buyPriceSort: "",
            sellPriceSort: "",
            orderSort: "",
            status: "",
            show: "",
            type: "",
            tag: ""
        }

        this.getGifts = this.getGifts.bind(this);
        this.restoreGift = this.restoreGift.bind(this);
    }

    getGifts() {
        axios.post(global.config.apiDomain + '/rest/gifts/getDeleted')
            .then(res => {
                this.setState({
                    gifts: res.data,
                    giftsTemp: res.data
                });
            });
    }

    restoreGift(deleteGift){
        if(window.confirm("Bạn có chắc chắn muốn khôi phục")){
            let json = {
                userId: "",
                giftId: deleteGift,
                value: 0
            }
            axios.post(global.config.apiDomain + '/rest/gifts/restoreGift', json)
            .then(res => {
                if("OK" == res.data.status){
                    toast.success(res.data.message)
                    this.getGifts();
                }else{
                    toast.warning(res.data.message)
                }
            });
        }
    }

    componentDidMount(){
        this.getGifts();
    }

    render(){
        const { gifts } = this.state;

        return (
            <>
            <ModalShowGiftAnimation gift={this.state.giftShow}></ModalShowGiftAnimation>
            <ModalShowSVGA gift={this.state.giftShow}></ModalShowSVGA>
            <div id="content">
                <div className="container-fluid">
                    <h1 className="text-center mb-2 mt-2">Quản Lí Quà Đã Xóa</h1>
                    <br />
                    <div>
                        <Link className="btn btn-primary ml-2" to="/admin/gift-manager">
                            Danh sách quà hiện tại
                        </Link>
                    </div>
                    <table className="table table-hover">
                            <thead>
                                <tr className="align-middle">
                                    <th className="align-middle" scope="col">ID</th>
                                    <th className="align-middle" scope="col">Name</th>
                                    <th className="align-middle" scope="col">Image</th>
                                    <th className="align-middle" scope="col">Img Animation</th>
                                    <th className="align-middle" scope="col">Buy price
                                        <button
                                            className="btn btn-outline-secondary ml-1"
                                            onClick={this.orderByBuyPrice}>
                                            <i className="fas fa-sort" />
                                        </button>
                                    </th>
                                    <th className="align-middle" scope="col">Sell price
                                        <button
                                            className="btn btn-outline-secondary ml-1"
                                            onClick={this.orderBySellPrice}>
                                            <i className="fas fa-sort" />
                                        </button>
                                    </th>
                                    <th className="align-middle" scope="col">Order
                                        <button
                                            className="btn btn-outline-secondary ml-1"
                                            onClick={this.orderByOrder}>
                                            <i className="fas fa-sort" />
                                        </button>
                                    </th>
                                    {/* <th className="align-middle" scope="col">Counter</th> */}
                                    <th className="align-middle" scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {gifts.map((gift, index) => {

                                    return (
                                        <tr key={gift.id}>
                                            <th scope="row">{gift.id}</th>
                                            <td>{gift.name}</td>
                                            <td> <img className="gift-image" src={gift.url}></img>  </td>
                                            <td>


                                                {gift.animatedUrl == null ? (
                                                    <span>NO IMAGE</span>
                                                ) : (

                                                    gift.animatedUrl.endsWith("svga") ? (
                                                        <button
                                                            style={{ width: "80px" }}
                                                            className="btn btn-outline-primary"
                                                            onClick={() => {
                                                                this.setState({
                                                                    giftShow: gift
                                                                })
                                                            }}
                                                            data-toggle="modal"
                                                            data-target="#modalShowGift"
                                                        >SVGA</button>
                                                    ) : (

                                                        gift.animatedUrl.endsWith("png") ? (
                                                            <button
                                                                style={{ width: "80px" }}
                                                                className="btn btn-outline-primary"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        giftShow: gift
                                                                    })
                                                                }}
                                                                data-toggle="modal"
                                                                data-target="#modalShowGiftAnimation"
                                                            >
                                                                PNG

                                                            </button>
                                                        ) : (
                                                            <button
                                                                style={{ width: "80px" }}
                                                                className="btn btn-outline-primary"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        giftShow: gift
                                                                    })
                                                                }}
                                                                data-toggle="modal"
                                                                data-target="#modalShowGiftAnimation"
                                                            >
                                                                WEBP

                                                            </button>
                                                        )

                                                    )

                                                )}

                                            </td>
                                            <td > {gift.buyPrice} </td>
                                            <td> {gift.sellPrice} </td>
                                            <td> {gift.order} </td>
                                            {/* <td> {gift.counter} </td> */}
                                            <td>
                                                <button
                                                    className="btn btn-red ml-sm-2"
                                                    
                                                    onClick={() => { this.restoreGift(gift.id) }}
                                                >Khôi phục</button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                    </table>
                </div>
            </div>
            </>
        )
    }
}