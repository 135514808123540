import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import bindModel from '../../../../../common/bindModel.js';
import ShowGameAnswers from './ShowGameAnswers.js';
import DetailQuestion from './DetailQuestion.js';

export default class ShowGameQuestions extends Component {
    constructor(props) {
        super(props);

        let newGameQuestion = props.newGameQuestion

        this.state = {
            gameQuestions: props.gameQuestions,
            newGameQuestion: newGameQuestion
        }

    }

    componentWillReceiveProps(props) {
        let newGameQuestion = props.newGameQuestion;

        this.setState({
            gameQuestions: props.gameQuestions,
            newGameQuestion: newGameQuestion
        })
    }

    render() {
        const model = bindModel(this);
        const {gameQuestions, newGameQuestion} = this.state;
        const {iGameType} = this.props;
        return (
            <>
                {gameQuestions != null &&
                    gameQuestions.map((gameQuestion, i) => {
                        return (
                            <div key={i}>
                                <div className="row justify-content-center mt-2">
                                    <div className="col-4 col-lg-2 my-auto">
                                        <label className="m-0 text-success"> &nbsp;&nbsp;&nbsp; Câu hỏi {i + 1} :</label>
                                    </div>
                                    <div className="col-6 col-lg-4">
                                    <textarea
                                        readOnly={true}
                                        className="form-control"
                                        type="text"
                                        name="gameTypesAdded.gameQuestions.title"
                                        {...model('gameQuestions['+ i +'].content')}
                                    />
                                    </div>
                                    <div className="col-2 col-lg-1">
                                        <button
                                            style={{width: "100px"}}
                                            onClick={() => this.props.selectGameQuestion(gameQuestion, i, iGameType)} 
                                            data-toggle="modal" 
                                            data-target="#DetailQuestion"
                                            className='btn btn-outline-primary' >
                                            Xem <i className="fa fa-eye" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )

                    })
                }
                <div className="row justify-content-center mt-2">
                    {/* <div className="col-4 col-lg-2 my-auto">
                        <label className="m-0 text-success"> &nbsp;&nbsp;&nbsp; Thêm câu hỏi:</label>
                    </div>
                    <div className="col-6 col-lg-4">
                        <input
                            className="form-control"
                            type="text"
                            name=""
                            placeholder='Nhập câu hỏi'
                            {...model('newGameQuestion.content')}
                        />
                    </div> */}
                    <div className="">
                        <button
                        // style={{width: "100px"}}
                        onClick={() => this.props.selectGameQuestion(newGameQuestion, null, iGameType)} 
                        data-toggle="modal" 
                        data-target="#DetailQuestion"
                        className='btn btn-primary'>
                            Thêm câu hỏi <i className="fa fa-plus" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </>
        )
    }

}