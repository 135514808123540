import React, {Component} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';
import bindModel from '../../../common/bindModel.js';

const $ = window.$;
export default class ModalAddRelatedUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            targetUID: "",
            loading: false
        }

        this.addRelatedUser = this.addRelatedUser.bind(this);
    }

    addRelatedUser(event) {
        if(event.key == 'Enter' || event.target.tagName == "BUTTON") {
            this.setState({
                loading: true
            });

            var json = {
                facebookId: this.props.id,
                targetUID: this.state.targetUID
            }

            axios.post(global.config.apiDomain + '/rest/admin/addRelatedUser', JSON.stringify(json), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                this.setState({
                    loading: false
                });

                if(res.data.status == "OK") {
                    $("#modalAddRelatedUser").modal("hide");
                    toast.success(res.data.message);
                } else {
                    toast.warning(res.data.message); 
                }
            }, error => {
                this.setState({
                    loading: false
                });
                toast.error("ERROR"); 
            })
        }
    }

    render() {
        const model = bindModel(this);

        return (
            <div>
                <div className="modal fade" id="modalAddRelatedUser" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Update realted</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <input
                                    onKeyUp={this.addRelatedUser} 
                                    type="number" 
                                    placeholder="Nhập ID"
                                    className="form-control"
                                    {...model('targetUID')}/>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ? 
                                (
                                <button style={{width: "120px"}} className="btn btn-red" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Xác nhận...
                                </button>
                                ) :
                                (
                                <button 
                                    style={{width: "120px"}} 
                                    type="button" 
                                    className="btn btn-red"
                                    onClick={this.addRelatedUser}>Xác nhận</button>    
                                )}                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}