import React, { Component } from 'react';
import axios from 'axios';
import RealGiftCreateOrUpdate from './components/RealGiftCreateOrUpdate';
import RealGiftDelete from './components/RealGiftDelete';

export default class RealGiftPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            models: [],
            model: null,
        };
        this.getAllRealGift = this.getAllRealGift.bind(this);
        this.createOrUpdateRealGift = this.createOrUpdateRealGift.bind(this);
        this.realGiftDelete = this.realGiftDelete.bind(this);
    }

    componentWillMount() {
        this.getAllRealGift();
    }

    getAllRealGift() {
        let url = global.config.apiDomain + "/rest/realGift/getAllRealGift"
        axios.post(url)
            .then(res => {
                this.setState({
                    models: res.data.models
                })
            })
    }
    createOrUpdateRealGift(event) {
        let index = event.target.name;
        let model = null;

        if (index === "-1") {
            model = {
                id: '',
                description: '',
            }
        } else {
            model = this.state.models[index];
        }
        this.setState({
            model: model
        })

    }
    realGiftDelete(event) {
        let index = event.target.name;
        console.log(index);
        let model = this.state.models[index];
        this.setState({
            model: model
        })
    }

    render() {
        const { models, model } = this.state;


        return (
            <>
                <RealGiftCreateOrUpdate
                    model={model}
                    resetList={this.getAllRealGift}
                ></RealGiftCreateOrUpdate>
                <RealGiftDelete
                    model={model}
                    resetList={this.getAllRealGift}
                ></RealGiftDelete>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí quà vật lí</h1>
                        <button
                            className="btn btn-info mb-1"
                            data-toggle="modal"
                            data-target="#modalCreate"
                            name="-1"
                            onClick={this.createOrUpdateRealGift}
                        >Tạo mới quà
                        </button>
                        <table className="table table-hover">
                            <thead>
                                <tr className="text-center">
                                    <th className="align-middle" scope="col">ID</th>
                                    <th className="align-middle" scope="col">Tên quà</th>
                                    <th className="align-middle" scope="col">Ngày tạo</th>
                                    <th className="align-middle" scope="col">Ảnh</th>
                                    <th className="align-middle" scope="col">Mô tả quà</th>
                                    <th className="align-middle" scope="col">Tuỳ chỉnh</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    models && models.map((item, index) => {
                                        return (
                                            <tr>
                                                <td className="align-middle text-center" scope="col">{item.id}</td>
                                                <td className="align-middle text-center" scope="col">{item.name}</td>
                                                <td className="align-middle text-center" scope="col">{new Date(item.addTime).toLocaleString()}</td>
                                                <td className="align-middle text-center" scope="col"> <img src={item.url} style={{ maxWidth: '100px', maxHeight: '100px' }} ></img></td>
                                                <td className="align-middle text-center" scope="col">{item.description}</td>
                                                <td className="align-middle text-center" scope="col">

                                                    <button
                                                        className="btn btn-success"
                                                        onClick={this.createOrUpdateRealGift}
                                                        name={index}
                                                        data-toggle="modal"
                                                        data-target="#modalCreate"
                                                    >
                                                        Sửa
                                                    </button>&emsp;
                                                    <button
                                                        className="btn btn-danger"
                                                        onClick={this.realGiftDelete}
                                                        name={index}
                                                        data-toggle="modal"
                                                        data-target="#modalDelete"
                                                    >
                                                        xoá
                                                    </button>

                                                </td>
                                            </tr>
                                        )
                                    })

                                }

                            </tbody>
                        </table>
                    </div>
                </div>

            </>
        );
    }
}