import React, { Component } from 'react';
import Firebase from '../../../components/Firebase.js';
import Moment from 'react-moment'
import $ from 'jquery';
import lodash from 'lodash'

export default class ShowMessage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            facebookId: "",
            toFacebookId: "",
            avatarToFacebookId: "",
            messages: [],
            messageDetails: [],
            loaded: false
        }

        this.ikaraNode = Firebase.database().ref("ikara");
        this.functions = Firebase.functions();
        this.auth = Firebase.auth();
    }


    accessToken() {
        const { account } = this.props;
        this.auth.signInWithCustomToken(account.firebaseToken)
            .then(res => {
                this.getMessage();
            });
    }

    componentDidMount() {

    }

    getMessageDetail() {
        const { facebookId, toFacebookId } = this.state;

        var roomId = "";
        if (facebookId > toFacebookId) {
            roomId = toFacebookId + "-" + facebookId;
        } else {
            roomId = facebookId + "-" + toFacebookId;
        }
        this.ikaraNode
            .child("privateChats")
            .child(roomId)
            // .orderByChild("dateTime")
            // .limitToLast(100000)
            // .endAt("999999999999999")
            .limitToLast(1000)
            .get()
            .then(snapshot => {
                var chats = [];
                snapshot.forEach(value => {
                    var chatItem = value.val();
                    chatItem.key = value.key;
                    chats.push(chatItem);
                })

                this.setState({
                    messageDetails: chats,
                    loaded: true
                });

                $('.chat-box').animate({ scrollTop: "1000000px" }, 'slow');

            });
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo);
      }

    getMessage() {
        const { account } = this.props;

        this.ikaraNode
            .child("users")
            .child(account.id)
            .child("privateChats")
            .on('value', snapshot => {
                var messages = [];
                snapshot.forEach(value => {
                    var message = value.val();
                    message.fromUserId = value.key;

                    if(message.dateTime == null) {
                        message.dateTime = new Date(946659600000);
                    }
                    messages.push(message);
                });

                messages = lodash.orderBy(messages, "dateTime", 'desc');

                this.setState({
                    messages: messages
                });


                if (messages != null && messages.length > 0) {
                    this.state.toFacebookId = messages[0].fromUserId;
                    this.state.avatarToFacebookId = messages[0].userProfile;

                    this.getMessageDetail()
                }
            });

    }

    handleToFacebookId(message) {
        this.state.toFacebookId = message.fromUserId;
        this.state.avatarToFacebookId = message.userProfile;
        this.getMessageDetail();
    }

    componentDidUpdate() {
        const { account } = this.props;
        if (account !== null && account.id !== this.state.facebookId) {
            this.state.facebookId = account.id;
            this.accessToken();
        }
    }


    render() {
        const { toFacebookId, avatarToFacebookId, messages, messageDetails, loaded } = this.state;
        return (

            <div className="container py-5 px-4">
                <div 
                    className="row rounded-lg overflow-hidden shadow"
                    // style={{
                    //     height: "600px",
                    // }}
                    >
                    <div className="col-lg-5 mb-5 mb-lg-0 px-0">
                        <div className="bg-white">
                            <div className="bg-gray px-4 py-2 bg-light">
                                <p className="h5 mb-0 py-1">Recent</p>
                            </div>
                            <div 
                            className="messages-box"
                            style={{
                                maxHeight: "60vh",
                                overflowY: "auto",
                            }}
                            >
                                <div className="list-group rounded-0">
                                    {loaded ? (

                                        messages.map((message, index) => {
                                            var lastMessage = message.lastMessage;
                                            
                                            if(lastMessage instanceof Object) {
                                                lastMessage = JSON.stringify(lastMessage)
                                            }
                                            return (
                                                <a href='#' key={index} onClick={() => this.handleToFacebookId(message)} className={message.fromUserId === toFacebookId 
                                                            ? "list-group-item list-group-item-action rounded-0 active text-white" 
                                                            : "list-group-item list-group-item-action list-group-item-light rounded-0" }>
                                                    <div className="media"><img src={message.userProfile} alt="user" width={50} className="rounded-circle" />
                                                        <div className="media-body ml-4">
                                                            <div className="d-flex align-items-center justify-content-between mb-1">
                                                                <h6 className="mb-0">{message.userName}</h6>
                                                                <small className="small font-weight-bold">
                                                                    <Moment
                                                                        fromNow
                                                                        tz="Asia/Ho_Chi_Minh"
                                                                        locale="vi"
                                                                    >{message.dateTime}
                                                                    </Moment>
                                                                </small>
                                                            </div>
                                                            <p className="font-italic mb-0 text-small">{lastMessage != null && lastMessage.length > 35 
                                                                                                        ? lastMessage.substring(0,35) + "..." 
                                                                                                        : lastMessage }</p>
                                                        </div>
                                                    </div>
                                                </a>
                                            )
                                        })

                                    ) : <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Chat Box*/} 
                    <div className="col-lg-7 px-0">
                        <div 
                        className="px-4 py-5 chat-box bg-white"
                        style={{
                            height: "60vh",
                            overflowY: "auto",
                        }}
                        >
                            {loaded ? (
                                messageDetails.map((messageDetail, index) => {
                                    var message = messageDetail.originalMessage != null ? messageDetail.originalMessage : messageDetail.message;
                                    if(message instanceof Object) {
                                        message = JSON.stringify(message);
                                    }

                                    if(messageDetail.key === "properties") {
                                        return <></>;
                                    }
                                    if (messageDetail.userId === toFacebookId ) {
                                        // Sender 
                                        return (
                                            <div key={index} className="media w-50 mb-3"><img src={avatarToFacebookId} alt="user" width={50} className="rounded-circle" />
                                                <div className="media-body ml-3">
                                                    <div style={{wordBreak: "break-all"}} className="bg-light rounded py-2 px-3 mb-2">
                                                        <p className="text-small mb-0 text-muted">{message}</p>
                                                    </div>
                                                    <p className="small text-muted">
                                                        <Moment
                                                            withTitle
                                                            format="MM/DD">
                                                            {messageDetail.dateTime}
                                                        </Moment>&nbsp;|&nbsp;
                                                        <Moment
                                                            withTitle
                                                            format="hh:mm"
                                                        >{messageDetail.dateTime}
                                                        </Moment></p>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        // Reiciver
                                        return (
                                            <div key={index} className="media w-50 ml-auto mb-3">
                                                <div className="media-body">
                                                    <div style={{wordBreak: "break-all"}} className="bg-primary rounded py-2 px-3 mb-2">
                                                        <p className="text-small mb-0 text-white">{message}</p>
                                                    </div>
                                                    <p className="small text-muted">
                                                        <Moment
                                                            withTitle
                                                            format="MM/DD"
                                                        >{messageDetail.dateTime}
                                                        </Moment>&nbsp;|&nbsp;
                                                        <Moment
                                                            withTitle
                                                            format="hh:mm"
                                                        >{messageDetail.dateTime}
                                                        </Moment></p>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            ) : <></>}

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}