import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import SVGA from 'svgaplayerweb';
import $ from 'jquery';

export default class ModalSetDecoration extends Component {
    constructor(props) {
        super(props);

        this.state = {
            decorations: []
        }

        this.getDecorationItemType = this.getDecorationItemType.bind(this);
        this.setData = this.setData.bind(this);
    }

    componentWillMount() {
        this.getDecorationItemType();
    }

    getDecorationItemType() {
        let decorations = [];
        axios.post(global.config.apiDomain + "/rest/decorationItemsType/getAllItems")
            .then(res => {
                for (let i = 0; i < res.data.decorationItemTypes.length; i++) {
                    if (res.data.decorationItemTypes[i].type === "MEDAL" || res.data.decorationItemTypes[i].type === "FRAME") {
                        decorations.push(res.data.decorationItemTypes[i]);
                    }
                }
                this.setState({
                    decorations: decorations,
                })
            })
    }

    setData(event) {
        let { model, property, decorType } = this.props;
        let index = event.target.name;
        let decoration = this.state.decorations[index];
        if (decoration.type != decorType) {
            toast.error("Chọn sai item");
            return
        }

        let decorationItemsType = [];
        let element = null;
        if (decorType == 'MEDAL') {
            try {
                element = model[property].decorationItemsType[0]
            } catch (e) {

            }
            decorationItemsType = [element, decoration.id]
        } else {
            try {
                element = model[property].decorationItemsType[1]
            } catch (e) {

            }
            decorationItemsType = [decoration.id, element]
        }

        model[property] = {
            ...model[property],
            decorationItemsType: decorationItemsType
        }

        this.props.update(model);
        window.$("#modalSetDecoration").modal("hide");
    }

    render() {
        const { decorations } = this.state;
        return (
            <div>
                <div className="modal fade" id="modalSetDecoration" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Chọn Trang trí bài thu</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <table className="table table-hover">
                                <thead>
                                    <tr className="align-middle">
                                        <th className="align-middle" scope="col">ID</th>
                                        <th className="align-middle" scope="col">Name</th>
                                        <th className="align-middle" scope="col">Image</th>
                                        <th className="align-middle" scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {decorations && decorations.map((item, index) => (
                                        <tr key={item.id}>
                                            <th scope="row">{item.id}</th>
                                            <td>{item.name}</td>
                                            <td><span id={item.id}><img className="gift-image" src={item.resourceUrl}></img></span></td>
                                            <td>
                                                <button
                                                    name={index}
                                                    onClick={this.setData}
                                                    className="btn btn-warning"
                                                >Chọn</button>
                                            </td>
                                        </tr>
                                    )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}