import React, { Component, useEffect } from "react";
import axios from "axios";
import lodash from "lodash";
import { toast } from "react-toastify";
import ModalAdminPrivilegeInfo from "./ModalAdminPrivilegeInfo";

export default class SetPrivilegeAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listAdmin: [],
      listRole: [],
      admin: null,
      uid: null,
      level: null,
      update: false,
      levelUpdate: null,
      manager: null,
    };

    this.getAdminList = this.getAdminList.bind(this);
    this.getListRole = this.getListRole.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.hideElement = this.hideElement.bind(this);
    this.showElement = this.showElement.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
  }

  componentWillMount() {
    this.getAdminList();
    this.getListRole();

    const { user } = this.context;
    if (user != null) {
      this.setState({
        manager: user,
      });
    }
  }

  handleCreate(json) {
    if (json != null && json.role == null) {
      toast.warning("Vui Lòng chọn role ");
      return;
    }
    

    axios
      .post(
        global.config.apiDomain+"/rest/privilegeAdmin/create",
        json
      )
      .then((res) => {
        if (res.data.status == "FAILED") {
          toast.warning(res.data.message);
        } else {
          toast.success(res.data.message);
        }
        this.setState({
          uid: null,
          level: null,
        });
      })
      .catch((err) => {
        toast.warning("Thêm thất bại ");
      });
  }

  handleUpdate(json) {
    if (
      window.confirm("Bạn có chắc là chỉnh sửa vai trò của tài khoản này ?")
    ) {
      if (json != null && json.role == null) {
        toast.warning("Vui Lòng chọn role ");
        return;
      }

      axios
        .post(global.config.apiDomain + "/rest/privilegeAdmin/update", json)
        .then((res) => {
          if (res.data.status == "FAILED") {
            toast.warning(res.data.message);
          } else {
            toast.success(res.data.message);
          }
          let eleUpdate = document.getElementById(`update${json.uid}`);
          let eleConfirm = document.getElementById(`confirm${json.uid}`);
          this.showElement(eleUpdate);
          this.hideElement(eleConfirm);
          this.setState({
            uid: null,
            level: null,
          });
        })
        .catch((err) => {
          toast.warning("Chỉnh sửa tài khoản thất bại !");
        });
    }
  }

  hideElement(element) {
    element.setAttribute("hidden", true);
  }

  showElement(element) {
    element.removeAttribute("hidden");
  }

  handleDelete(json) {
    if (window.confirm("Bạn có chắc là xóa vai trò của tài khoản này ?")) {

      axios
        .post(global.config.apiDomain + "/rest/privilegeAdmin/delete", json)
        .then((res) => {
          if (res.data.status == "FAILED") {
            toast.warning(res.data.message);
          } else {
            toast.success(res.data.message);
          }
          this.setState({
            uid: null,
            level: null,
          });
        })
        .catch((err) => {
          toast.warning("Xóa admin thất bại !");
        });
    }
  }

  getAdminList() {
    let url = global.config.apiDomain + "/rest/privilegeAdmin";

    axios.post(url).then((res) => {
      let data = res.data;
      this.setState({
        listAdmin: data,
      });
    });
  }

  getListRole() {
    let data = [
      {
        level: "TESTER",
        role: "TESTER",
        privilege:
          "Không tính vào tổng số iCoin thống kê, không tính vào thống kê doanh thu…",
      },
      {
        level:"CUSTOMER-SUPPORTER" ,
        role: "CUSTOMER-SUPPORTER",
        privilege:
          "Được sử dụng hết tất cả các quyền quản lý trong Web trừ cộng trừ iCoin, tiền…",
      },
      {
        level: "ICOIN-MANAGER",
        role: "ICOIN-MANAGER",
        privilege: "Được quyền cộng trừ iCoin",
      },
      { level: "MANAGER", 
        role: "MANAGER", 
        privilege: "Được xem tin nhắn user" 
      },
      { 
        level: "SINGER", 
        role: "SINGER", 
        privilege: "Ca sĩ" 
      },
      { 
        level: "SELLER", 
        role: "SELLER", 
        privilege: "Xem các User được mời trong tính năng Referral" 
      },
      { 
        level: "BOT", 
        role: "BOT", 
        privilege: "Set up bot cho comment, like bài thu" 
      },
      { 
        level: "IDOL", 
        role: "IDOL", 
        privilege: "Song ca cùng thần tượng" 
      },
      { 
        level: "COLLABORATOR", 
        role: "COLLABORATOR", 
        privilege: "Cộng tác viên, hiện tại chưa set quyền" 
      },
    ];
    this.setState({
      listRole: data,
    });
  }

  render() {
    const {
      listAdmin,
      admin,
      uid,
      level,
      listRole,
      update,
      levelUpdate,
      manager,
    } = this.state;

    const { user } = this.context;
    console.log("user", user)
    return (
      <>
        <ModalAdminPrivilegeInfo listRole={listRole}></ModalAdminPrivilegeInfo>
        <div id="content">
          <div className="container-fluid">
            <h1 className="text-center">Quản lí Admin</h1>
            <hr />
            <div className="row mb-2">
              <div className="col-7">
                <input
                  type="text"
                  className="mr-2 ml-2"
                  value={uid}
                  onChange={(e) => {
                    this.setState({ uid: e.target.value });
                  }}
                  placeholder="nhập UID"
                />
                <select
                  type="text"
                  className="form-select mr-2 ml-2 "
                  aria-label="Default select example"
                  onChange={(e) => {
                    console.log(e.target.value);
                    this.setState({ level: e.target.value });
                  }}
                >
                  <option value="-1">Chọn vai trò</option>
                  {listRole.map((role, index) => {
                    return <option value={role.level}>{role.role}</option>;
                  })}
                </select>
                <button
                  className="btn btn-info btn-sm"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Bạn có chắc là thêm tài khoản này vào danh sách admin ?"
                      )
                    ) {
                      let json = {
                        uid: uid,
                        role: level,
                      };
                      this.handleCreate(json);
                    }
                  }}
                >
                  Thêm
                </button>
              </div>
              <div className="col-4">
                <button
                  className="btn btn-primary btn-sm float-right"
                  data-toggle="modal"
                  data-target="#ModalAdminPrivilegeInfo"
                >
                  Admin Level Info
                </button>
              </div>
            </div>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">STT</th>
                  <th scope="col">UID</th>
                  <th scope="col">Facebook Id</th>
                  <th scope="col">FullName</th>
                  <th scope="col">Language</th>
                  <th scope="col">Platform</th>
                  <th scope="col">Role</th>
                  <th scope="col">Tùy chỉnh</th>
                </tr>
              </thead>
              <tbody>
                {listAdmin.map((admin, index) => {
                  return (
                    <tr key={index}>
                      <td>{index}</td>
                      <td>{admin.uid}</td>
                      <td>{admin.facebookId}</td>
                      <td>{admin.fullName}</td>
                      <td>{admin.language}</td>
                      <td>{admin.platform}</td>
                      <td id={`update${admin.uid}`}>
                        <span>{admin.role}</span>
                        <button
                          className="btn btn-info ml-1 mr-1 float-right"
                          onClick={() => {
                            let eleUpdate = document.getElementById(
                              `update${admin.uid}`
                            );
                            let eleConfirm = document.getElementById(
                              `confirm${admin.uid}`
                            );
                            this.hideElement(eleUpdate);
                            this.showElement(eleConfirm);
                          }}
                        >
                          Sửa
                        </button>
                      </td>
                      <td id={`confirm${admin.uid}`} hidden={true}>
                        <select
                          type="text"
                          className="form-select mr-2 ml-2 "
                          aria-label="Default select example"
                          onChange={(e) => {
                            this.setState({ level: e.target.value });
                          }}
                        >
                          <option value="-1">Chọn vai trò</option>
                          {listRole.map((role, index) => {
                            return (
                              <option value={role.level}>{role.role}</option>
                            );
                          })}
                        </select>
                        <button
                          className="btn btn-info  ml-1 mr-1 float-right"
                          onClick={() => {
                            let json = {
                              uid: admin.uid,
                              role: level,
                            };

                            this.handleUpdate(json);
                          }}
                        >
                          Xác Nhận
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn btn-danger"
                          onClick={() => {
                            let json = {};

                            json = {
                              uid: admin.uid,
                              role: admin.role,
                            };

                            this.handleDelete(json);
                          }}
                        >
                          Xóa
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}
