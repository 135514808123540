import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import SVGA from 'svgaplayerweb'
import { toast } from 'react-toastify';


export default class ModalUserMedal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            medals: [],
            medal: null,
            allMedal:[],
            reRender: true,
            medalChoice: null,
            userId: null
        };
        this.getAllMedal = this.getAllMedal.bind(this);
        this.handleDeleteMedal = this.handleDeleteMedal.bind(this);
        this.handleSendMedalForUser = this.handleSendMedalForUser.bind(this);
        this.loadSVGA = this.loadSVGA.bind(this);
        this.getAllMedalInApp = this.getAllMedalInApp.bind(this);
        this.choiceMedal = this.choiceMedal.bind(this);
        this.handleResendItem = this.handleResendItem.bind(this);
    }

    getAllMedal(account){
        let url = global.config.apiDomain + "/rest/medal/getMedalOfUser";
        let json = {
            userId: account.id
        }
        axios.post(url,json)
        .then(res =>{
            this.setState({
                medals: res.data.medals,
                reRender: false
            })
        })
    }

    handleUseMedalForUser(medal){
        
        if(!window.confirm("bạn có chắc ?")){
            return;
        }

        const { account } = this.props;
        let url = global.config.apiDomain + "/rest/medal/useOrUnUseMedalOfUser"
        this.setState({
            loading : true
        })

        let json = {
            userId : account.id,
            medalId: medal.id,
            useMedalId: medal.id
        }

        axios.post(url,json)
        .then(res =>{
            if(res.data.status == "OK"){
                toast.success(res.data.message)
                if(res.data.medals && res.data.medals.length > 0){
                    setTimeout(()=>{
                        this.setState({
                            medals: res.data.medals,
                            loading: false
                        })
                    },2000)
                }else{
                    this.setState({
                        loading: false
                    })
                }
            }else{
                toast.success(res.data.message)
                this.setState({
                    loading: false
                })
            }
        })
    }

    handleSendMedalForUser(){
        const { account } = this.props;
        const { medalChoice } = this.state;

        if(!window.confirm("bạn có chắc ?")){
            return;
        }
        let url = global.config.apiDomain + "/rest/medal/sendMedalForUser"
        this.setState({
            loading : true
        })

        let json = {
            userId : account.id,
            medalId: medalChoice,
            useMedalId: medalChoice
        }

        axios.post(url,json)
        .then(res =>{
            if(res.data.status == "OK"){
                toast.success(res.data.message)
                if(res.data.medals && res.data.medals.length > 0){
                    setTimeout(()=>{
                        this.setState({
                            medals: res.data.medals,
                            loading: false
                        })
                    },2000)
                }else{
                    this.setState({
                        loading: false
                    })
                }
            }else{
                toast.success(res.data.message)
                this.setState({
                    loading: false
                })
            }
        })
    }

    getAllMedalInApp(){
        let url = global.config.apiDomain + "/rest/medal/getAll"
        axios.post(url)
        .then(res =>{
            this.setState({
                allMedal:res.data.medals
            })
        })
    }

    handleResendItem(item){
        const { account } = this.props;

        if(!window.confirm("bạn có chắc ?")){
            return;
        }
        let url = global.config.apiDomain + "/rest/medal/resendItem"
        this.setState({
            loading : true
        })

        if(account){
            let json = {
                useMedalId: item.id,
                userId: account.id
            }
    
            axios.post(url,json)
            .then(res =>{
                if(res.data.status == "OK"){
                    toast.success(res.data.message)
                    if(res.data.medals && res.data.medals.length > 0){
                        setTimeout(()=>{
                            this.setState({
                                medals: res.data.medals,
                                loading: false
                            })
                        },2000)
                    }else{
                        this.setState({
                            loading: false
                        })
                    }
                }else{
                    toast.success(res.data.message)
                    this.setState({
                        loading: false
                    })
                }
            })
        }else{
            toast.success("Không tìm thấy tài khoản")
        }
        
    }

    handleDeleteMedal(medal){
        let url = global.config.apiDomain + "/rest/medal/deleteMedalOfUser"
        this.setState({
            loading : true
        })

        axios.post(url,medal)
        .then(res =>{
            if(res.data.status == "OK"){
                toast.success(res.data.message)
                if(res.data.medals && res.data.medals.length > 0){
                    setTimeout(()=>{
                        this.setState({
                            medals: res.data.medals,
                            loading: false
                        })
                    },2000)
                }else{
                    this.setState({
                        loading: false
                    })
                }
            }else{
                toast.success(res.data.message)
                this.setState({
                    loading: false
                })
            }
        })
    }

    choiceMedal(event){
        let id = event.target.value;
        this.setState({
            medalChoice: Number(id)
        })
    }

    loadSVGA(url, id) {

        var player = new SVGA.Player(`#${id}`);
        var parser = new SVGA.Parser(`#${id}`); 
        parser.load(url, function (videoItem) {
            player.setVideoItem(videoItem);
            player.startAnimation();
        })
    }

    componentDidUpdate(prevProps, nextProps){
        const { account } = this.props;
        const { medals, reRender } = this.state;

        if(reRender){
            if (account != null) {
                if(this.state.medals != null && this.state.medals.length < 1) {
                    this.getAllMedal(account);
                    this.getAllMedalInApp();
                }
            }
        }

        medals && medals.map((medals, index) => {
            if (medals.resourceUrl != null && medals.resourceUrl.endsWith(".svga")) {
                $("#show-resourceUrl").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas1\"></div>");
                this.loadSVGA(medals.resourceUrl,"demoCanvas1");
            } 
        })

        if(account != null && prevProps != null && prevProps.account != null && account.id !== prevProps.account.id){
            this.setState({
                reRender : true
            })
        }
    }

    render(){
        const { medals, medal, allMedal } = this.state;

        return (
            <>
                <div className='mt-2 mb-2 row'>
                    <div  className='mt-2 mb-2 col-2'>
                        <select name="addMedal" id="addMedal" onChange={this.choiceMedal}>
                            <option value="-1">Chọn</option>
                            {allMedal && allMedal.map((medal,index)=>{
                                return (<option value={medal.id} key={index}>{medal.name}</option>)
                            })}
                        </select>
                    </div>
                    <div className='mt-2 mb-2 col-4'>
                        <button className='mr-4 ml-4 btn btn-success'
                        onClick={this.handleSendMedalForUser}
                        >Thêm vật phẩm vào túi</button>
                    </div>
                </div>
                <table className="table table-hover">
                            <thead>
                            <tr className="">
                                <th className="align-middle" scope="col">#</th>
                                <th className="align-middle" scope="col">id</th>
                                <th className="align-middle" scope="col">Độ ưu tiên</th>
                                <th className="align-middle" scope="col">Name</th>
                                <th className="align-middle" scope="col">Mô tả</th>
                                <th className="align-middle text-center" scope="col">Ảnh huy hiệu</th>    
                                <th className="align-middle text-center" scope="col" style={{width: '200px'}}>Ảnh mô tả</th>  
                                <th className="align-middle text-center" scope="col">Type</th>
                                <th className="align-middle text-center" scope="col">Mã màu</th>                                                
                                <th className="align-middle" scope="col">Trạng thái</th>
                                <th className="align-middle" scope="col" colSpan={2}>Tùy chỉnh</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                                {medals && medals.map( (item,index)=>{
                                    return (
                                        <tr>
                                            <td className="align-middle" scope="col">{index +1}</td>
                                            <td className="align-middle" scope="col">{item.id}</td>
                                            <td className="align-middle" scope="col">{item.order}</td>
                                            <td className="align-middle" scope="col">{item.name}</td>
                                            <td className="align-middle" scope="col">{item.description}</td>
                                            <td className="align-middle" scope="col">
                                                <div id="show-resourceUrl" style={{ maxHeight: "50px", maxWidth: "50px", align: 'center' }}>
                                                    <div width={50} height={50} id="canvas"></div>
                                                    <img src={item.resourceUrl} alt=""  width={50} height={50} />
                                                </div> 
                                                   
                                            </td>
                                            <td className="align-middle" scope="col"> <img src={item.resourceUrl} alt=""  width={50} height={50} /></td>
                                            <td className="align-middle" scope="col">{item.type}</td>
                                            <td className="align-middle" scope="col">{item.colorCode}</td>
                                            <td className="align-middle" scope="col">
                                                {item.deleted ? 
                                                <button className='btn btn-danger' disabled>Đã xóa</button> : 
                                                    item.isUse ? <button className='btn btn-warning' 
                                                                    onClick={()=>{
                                                                        this.handleUseMedalForUser(item)
                                                                    }}>Gỡ</button> : 
                                                                <button className='btn btn-success'
                                                                    onClick={()=>{
                                                                        this.handleUseMedalForUser(item)
                                                                 }}>Đeo</button>}
                                            </td>
                                            <td className="align-middle" scope="col">
                                                {item.deleted ? 
                                                <button className='btn btn-danger'
                                                    onClick={()=>{
                                                        this.handleResendItem(item)
                                                    }}
                                                    >Trao lại
                                                </button> : 
                                                <button className='btn btn-danger'
                                                    onClick={()=>{
                                                        if(window.confirm("Bạn có chắc là muốn xóa ?")){
                                                            this.handleDeleteMedal(item)
                                                        }
                                                    }}
                                                    >Xóa
                                                </button>}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                </table>

            </>
        )
    }
}