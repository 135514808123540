import React, { Component } from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import $ from 'jquery';
import ModalCreate from './components/ModalCreate';
import ModalChangeStatus from './components/ModalChangeStatus';
const FeatureRequestPage = () => {

    const [models, setModels] = useState([])
    const [model, setModel] = useState(null)
    const [newStatus, setNewStatus] = useState('OPEN')
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         models: [],
    //         model: null,
    //         newStatus: 'OPEN',
    //     };

    //     this.getAllFeatureRequest = this.getAllFeatureRequest.bind(this);
    //     this.createOrUpdate = this.createOrUpdate.bind(this);
    //     this.changeStatus = this.changeStatus.bind(this);
    // }
    useEffect(() => {
        getAllFeatureRequest();
    }, [])
    const getAllFeatureRequest = () => {
        axios.post(global.config.apiDomain + "/rest/admin/feature-request/getAllFeatureRequest")
            .then(res => {
                if (res.data.status == "OK") {
                    console.log('getAllFeatureRequest');
                    console.log(res.data.models);
                    setModels(res.data.models)
                }
            })

    }



    const createOrUpdate = (event) => {
        let index = event.target.name;
        let model = null;
        if (index === "-1") {
            model = {
                id: 0,
                name: '',
                description: '',
                addTime: new Date().toLocaleString("sv", { timeZone: "Asia/Ho_Chi_Minh" }).replace(" ", "T"),
                owner: {},
                status: 'OPEN',
                voteCount: 0
            }
        } else {
            model = models[index];
            model.addTime = new Date(model.addTime).toLocaleString("sv", { timeZone: "Asia/Ho_Chi_Minh" }).replace(" ", "T")

            if (model.releaseDate) model.releaseDate = new Date(model.releaseDate).toLocaleString("sv", { timeZone: "Asia/Ho_Chi_Minh" }).replace(" ", "T")

        }

        setModel(model)
    }

    const changeStatus = (event) => {
        let newStatus = event.target.value;
        let index = event.target.name;
        let model = models[index];
        setNewStatus(newStatus)
        setModel(model)
        window.$('#modalChangeStatus').modal("show");
    }


    return (
        <>
            <ModalCreate
                model={model}
                resetList={() => getAllFeatureRequest()}
            ></ModalCreate>
            <ModalChangeStatus
                model={model}
                newStatus={newStatus}
                resetList={() => getAllFeatureRequest()}
            ></ModalChangeStatus>

            <div id="content">
                <div className="container-fluid">
                    <h1 className="text-center">Quản lí Feature Request</h1>
                    <button
                        className="btn btn-info mb-1"
                        data-toggle="modal"
                        data-target="#modalCreate"
                        name="-1"
                        onClick={(event) => createOrUpdate(event)}
                    >+ Tạo Mới
                    </button>
                    <table className="table table-hover">
                        <thead>
                            <tr className="text-center">
                                <th className="align-middle" scope="col" rowspan="2">ID</th>
                                <th className="align-middle" scope="col" rowspan="2">Thời gian</th>
                                <th className="align-middle" scope="col" rowspan="2">Tên</th>
                                <th className="align-middle" scope="col" rowspan="2">Mô tả</th>
                                <th className="align-middle" scope="col" rowspan="2">Số vote</th>
                                <th className="text-center" scope="col" colspan="3">Owner</th>
                                <th className="align-middle" scope="col" rowspan="2">Sửa</th>
                                <th className="align-middle" scope="col" rowspan="2">Trạng thái</th>
                                <th className="align-middle" scope="col" rowspan="2">Release</th>
                            </tr>
                            <tr className="text-center">
                                <th className="text-center" scope="col">Ảnh</th>
                                <th className="text-center" scope="col">Tên</th>
                                <th className="text-center" scope="col">UID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                models && models.map((model, index) => {
                                    return (
                                        <tr>
                                            <td className="align-middle text-center" scope="col"><strong>{model.id}</strong></td>
                                            <td className="align-middle text-center" scope="col">{new Date(model.addTime).toLocaleString()}</td>
                                            <td className="align-middle text-center" scope="col">{model.name}</td>
                                            <td className="align-middle text-center" scope="col">{model.description}</td>
                                            <td className="align-middle text-center" scope="col">{model.voteCount}</td>
                                            <td className="align-middle" scope="col">
                                                <img src={model.owner.profileImageLink} height={100} width={100} />
                                            </td>
                                            <td className="align-middle text-center" scope="col">{model.owner.name}</td>
                                            <td className="align-middle text-center" scope="col">{model.owner.uid}</td>
                                            <td className="align-middle text-center" scope="col">
                                                <button className='btn btn-success ml-2 mr-2'
                                                    data-toggle="modal"
                                                    data-target="#modalCreate"
                                                    onClick={(event) => createOrUpdate(event)}
                                                    name={index}
                                                >
                                                    Sửa
                                                    {/* <i class="fa-regular fa-pen-to-square"></i> */}
                                                </button>
                                            </td>
                                            <td className="align-middle text-center" scope="col">
                                                <select
                                                    value={model.status}
                                                    onChange={(event) => changeStatus(event)}
                                                    name={index}
                                                >
                                                    <option value='PENDING'>PENDING</option>
                                                    <option value='OPEN'>OPEN</option>
                                                    <option value='INPROGRESS'>INPROGRESS</option>
                                                    <option value='DONE'>DONE</option>
                                                    <option value='DELETED'>DELETED</option>
                                                </select>
                                            </td>
                                            <td className="align-middle text-center" scope="col">{model.releaseDate && new Date(model.releaseDate).toLocaleString()}</td>
                                        </tr>
                                    )
                                })

                            }

                        </tbody>
                    </table>
                </div>
            </div>

        </>
    );
}

export default FeatureRequestPage;