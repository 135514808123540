import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
class ErrorPage extends Component {
    render() {
        const { t } = this.props;
        return (
            <div id="content">
                <Helmet>
					<title>Error</title>
                    <meta name="robots" content="noindex,nofollow" />
				</Helmet>
                <div className="page-error">
                    <div className="container text-center">
                        <img src={require('../assets/images/404.png')} alt="Hát karaoke vui nhộn" />
                        {/* <h2 className="heading">{t("error_page.title", "Error 500")}</h2> */}
                        <h2 className="heading">Error 500</h2>
                        <Link to="/" className="btn-yellow">
                            <i className="fa fa-angle-left" /> {t("not_found_page.back_home", "Quay về trang chủ")}
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(ErrorPage)