import React, { Component } from 'react';
import Categories from './components/Categories/Categories';
import Category from './components/Category/Category';

class YoutubeSong extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCategories: {
                show: true,
                categoryId: ''
            }
        };
    }

    updateShowCategory = value => {
        this.setState({
            showCategories: value
        })
    }
    
    render() {
        const { showCategories } = this.state;

        return (
            <>
                { 
                    showCategories.show ? 
                        <Categories 
                            updateShowCategory = { this.updateShowCategory }
                        /> 
                        : <Category 
                            updateShowCategory = { this.updateShowCategory }
                            showCategories = { this.state.showCategories } 
                        /> 
                }
            </>
        );
    }
}

export default YoutubeSong
