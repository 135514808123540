import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import MyContext from '../../common/MyContext'
import { Helmet } from 'react-helmet';
import { setCookie } from '../../common/cookie.js';
import { detectApp, detectMob } from '../../common/utils.js';

const $ = window.$;

class LoginPage extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
        let code = new URLSearchParams(this.props.location.search).get("code") || new URLSearchParams(this.props.location.search).get("authorization_code");
        let token = new URLSearchParams(this.props.location.search).get("token");

        const { history } = this.props;

        if (detectApp("lo.okara") && code != null) {
            this.laoidSuccess(code);
        } else if(detectApp("ikarayokara") && code != null) {
			this.zaloSuccess(code);
		} else if(detectApp("ht.okara") && token != null) {
			this.natcomidSuccess(token);
		} else {
            $('#loginDialog').off('hide.bs.modal');
            $('#loginDialog').on('hide.bs.modal', function (e) {
                if (window.location.pathname == "/login") {
                    if (history.action == "PUSH") {
                        history.goBack();
                    } else {
                        history.push('/');
                    }
                }
            })

            $("#loginDialog").modal("show");
        }
    }



    zaloSuccess = (code) => {
        // const json = JSON.stringify({ domain: window.location.hostname, accessToken: data.tokenDetail.accessToken });
        const json = JSON.stringify({ domain: window.location.hostname, accessToken: code });

        axios.post(global.config.apiDomain + '/rest/loginuser/loginZalo', json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            console.log("login")
            const { user } = res.data;
            if (user != null) {
                const { history } = this.props;
                
                window.localStorage.setItem("login", true);
				window.localStorage.setItem("userId", user.facebookId);
				window.localStorage.setItem("profileImageLink", user.profileImageLink);
				window.localStorage.setItem("name", user.name);

				setCookie("login", "true", 1);
				setCookie("userId", user.facebookId, 1);
				setCookie("profileImageLink", user.profileImageLink, 1);
				setCookie("name", user.name, 1);

                if (history) {
                    if (history.action == "PUSH") {
                        history.goBack();
                    } else {
                        history.push('/');
                    }
                    this.context.updateUser(user);
                }
            }
            $("#loginDialog").modal("hide");
        })
    }

    uniidSuccess = (code) => {
        const json = JSON.stringify({ domain: window.location.hostname, accessToken: code });

        axios.post(global.config.apiDomain + '/rest/loginuser/loginLaoID', json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            console.log("login")
            const { user } = res.data;
            if (user != null) {
                const { history } = this.props;

                window.localStorage.setItem("login", true);
				window.localStorage.setItem("userId", user.facebookId);
				window.localStorage.setItem("profileImageLink", user.profileImageLink);
				window.localStorage.setItem("name", user.name);

				setCookie("login", "true", 1);
				setCookie("userId", user.facebookId, 1);
				setCookie("profileImageLink", user.profileImageLink, 1);
				setCookie("name", user.name, 1);

                if (history) {
                    if (history.action == "PUSH") {
                        history.goBack();
                    } else {
                        history.push('/');
                    }
                    this.context.updateUser(user);
                }
            }
            $("#loginDialog").modal("hide");
        })
    }

    laoidSuccess = (code) => {
        const json = JSON.stringify({ domain: window.location.hostname, accessToken: code });

        axios.post(global.config.apiDomain + '/rest/loginuser/loginLaoID', json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            console.log("login")
            const { user } = res.data;
            if (user != null) {
                if (user.phoneNumber == null) {
                    this.context.updateUserTemp(user);
                    $("#modalPhoneVetification").modal("show");
                    return;
                }
                const { history } = this.props;

                window.localStorage.setItem("login", true);
				window.localStorage.setItem("userId", user.facebookId);
				window.localStorage.setItem("profileImageLink", user.profileImageLink);
				window.localStorage.setItem("name", user.name);

				setCookie("login", "true", 1);
				setCookie("userId", user.facebookId, 1);
				setCookie("profileImageLink", user.profileImageLink, 1);
				setCookie("name", user.name, 1);

                if (history) {
                    if (history.action == "PUSH") {
                        history.goBack();
                    } else {
                        history.push('/');
                    }
                    this.context.updateUser(user);
                }
            }
            $("#loginDialog").modal("hide");
        })
    }


    natcomidSuccess = (token) => {
        const json = JSON.stringify({ domain: window.location.hostname, accessToken: token });

        axios.post(global.config.apiDomain + '/rest/loginuser/loginNatcomID', json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            console.log("login")
            const { user } = res.data;
            if (user != null) {
                const { history } = this.props;

                window.localStorage.setItem("login", true);
				window.localStorage.setItem("userId", user.facebookId);
				window.localStorage.setItem("profileImageLink", user.profileImageLink);
				window.localStorage.setItem("name", user.name);

				setCookie("login", "true", 1);
				setCookie("userId", user.facebookId, 1);
				setCookie("profileImageLink", user.profileImageLink, 1);
				setCookie("name", user.name, 1);

                if (history) {
                    if (history.action == "PUSH") {
                        history.goBack();
                    } else {
                        history.push('/');
                    }
                    this.context.updateUser(user);
                }
            }
            $("#loginDialog").modal("hide");
        })
    }

    openPhoneDialog() {
        $("#loginDialog").modal("hide");
        $("#modalPhoneVetification").modal("show");
    
      }

    render() {
        return (
        
            <div id="content">
                <Helmet>
					<title>Đăng nhập</title>
				</Helmet>
                <div></div>
            </div>
        );
    }
}

LoginPage.contextType = MyContext;
export default withRouter(LoginPage);


