import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import bindModel from '../../../../common/bindModel.js';
import axios from 'axios';

export default class CreateAndUpdateGameTypePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            gameType: {},
            loading: false
        }

        this.uploadImage = this.uploadImage.bind(this);
        this.guid = this.guid.bind(this);
        this.saveGameType = this.saveGameType.bind(this);
        this.getGameType = this.getGameType.bind(this);
    }

    componentDidMount() {
        let gameTypeId = this.props.match.params.gameTypeId;
        if(gameTypeId != null) {
            this.getGameType(gameTypeId);
        } else {
            this.setState({
                gameType : {}
            });
        }

        // this.setState({
        //     gameType : {
        //         activeImage: "https://s3.us-west-002.backblazeb2.com/ikara-data/images/banners/e6976e9a-cf9f-5d68-46a0-36434c0c7177.png",
        //         disabledImage: "https://s3.us-west-002.backblazeb2.com/ikara-data/images/banners/e6976e9a-cf9f-5d68-46a0-36434c0c7177.png",
        //         name: "test_name",
        //         title: "test_title",
        //         description: "test_description"
        //     }
        // })
    }

    uploadImage(event) {
        var target = event.target;
        console.log(target.id);
        if (target.files[0].size > 10495760 ){
            toast.error("Kích thước file phải dưới 10mb.");
            return;
        }

        this.setState({
            loading: true
        });

        var id = target.id;
        var gameType = this.state.gameType;
        var bucketName = "ikara-data/images/game";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/jpeg";

        if (extension == "png"){
            contentType = "image/png";
        } else if(extension == "jpg" || extension == "jpeg") {
            contentType = "image/jpeg";
        } else {
            toast.warn("Hình ảnh không đúng định dạng (png|jpg|jpeg)");
            
            this.setState({
                loading: false
            });
            return;
        }

        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data);
                    this.setState({
                        loading: false
                    });

                    if(id == "thumbnail-active") {
                        gameType.thumbnailActive = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                        // this.state.gift.url = 'https://s3.us-west-002.backblazeb2.com/' + bucketName + '/' + keyName;
                    } else {
                        gameType.thumbnailDisable = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                        // this.state.gift.url = 'https://s3.us-west-002.backblazeb2.com/' + bucketName + '/' + keyName;
                    }

                    this.setState({
                        gameType: gameType
                    });
                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    
    getGameType(gameTypeId) {
        
        axios.post(global.config.apiDomain + '/rest/game/type/detail', JSON.stringify(gameTypeId), {
          headers : {
            'Content-Type': 'application/json'
          }
        }).then((res) => {
          const gameType = res.data;
    
          this.setState({
            gameType: gameType
          });
        })
    }

    saveGameType() {
        this.setState({
            loading: true
        })
        const {gameType} = this.state;
        
        axios.post(global.config.apiDomain + '/rest/game/type/save', JSON.stringify(gameType), {
          headers : {
            'Content-Type': 'application/json'
          }
        }).then((res) => {
            const {status, message, gameType} = res.data;
    
            if(status == "OK") {
                toast.success(message);

                this.setState({
                    gameType: gameType
                })
                // this.props.history.push("/admin/game-manager")
            } else {
                toast.error(message);
            }

            this.setState({
                loading: false
            })
        })
    }

    render() {
        const model = bindModel(this);
        let {gameType, loading} = this.state;
        // gameType = {
        //     activeImage: "https://s3.us-west-002.backblazeb2.com/ikara-data/images/banners/e6976e9a-cf9f-5d68-46a0-36434c0c7177.png"
        // }
        // console.log(gameType.activeImage);
        return (
            <div id="content">
                <div className="d-flex justify-content-between">
                <Link style={{ width: "100px" }} to="/admin/game-manager" className="btn btn-secondary my-auto"><i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp; Trở về</Link>
                    <h1>Tạo thể loại game</h1>
                    <div></div>
                </div>
                <div className="mt-3">
                    <div className="row justify-content-center mt-2">
                        <div className="col-4 col-lg-2 my-auto">
                            <label className="m-0">Tên thể loại:</label>
                        </div>
                        <div className="col-8 col-lg-5">
                            <input
                                className="form-control"
                                type="text"
                                name="gameType.name"
                                {...model('gameType.name')}
                            />
                        </div>
                    </div>
                    <div className="row justify-content-center mt-2">
                        <div className="col-4 col-lg-2 my-auto">
                            <label className="m-0">Tiêu đề:</label>
                        </div>
                        <div className="col-8 col-lg-5">
                            <input
                                className="form-control"
                                type="text"
                                name="gameType.title" 
                                {...model('gameType.title')}
                            />
                        </div>
                    </div>
                    <div className="row justify-content-center mt-2">
                        <div className="col-4 col-lg-2 my-auto">
                            <label className="m-0">Nội dung:</label>
                        </div>
                        <div className="col-8 col-lg-5">
                            <input
                                className="form-control"
                                type="text"
                                name="gameType.description" 
                                {...model('gameType.description')}
                            />
                        </div>
                    </div>
                    <div className="row justify-content-center mt-2">
                        <div className="col-4 col-lg-2 my-auto">
                            <label className="m-0">Type:</label>
                        </div>
                        <div className="col-8 col-lg-5">
                            <input
                                className="form-control"
                                type="text"
                                name="gameType.type" 
                                {...model('gameType.type')}
                            />
                        </div>
                    </div>

                    <div className="row justify-content-center mt-2">
                        <div className="col-4 col-lg-2 my-auto">
                            <label className="m-0">Active image:</label>
                        </div>
                        <div className="col-8 col-lg-5">
                            <input
                                type="file"
                                className="custom-file-input"
                                id="thumbnail-active"
                                onChange={this.uploadImage}
                            />
                            <label className="custom-file-label ml-3 mr-3" htmlFor="thumbnail-active">Choose file</label>
                        </div>
                    </div>

                    {gameType != null && gameType.thumbnailActive != null &&
                        <div className="row justify-content-center mt-2">
                            <div className="col-4 col-lg-2 my-auto">
                            </div>
                                <div id="show-animation" className="col-8 col-lg-5" style={{ maxHeight: "300px" }}>
                                    <img src={gameType.thumbnailActive} />
                                </div>
                        </div>
                    }

                    <div className="row justify-content-center mt-2">
                        <div className="col-4 col-lg-2 my-auto">
                            <label className="m-0">Disabled image:</label>
                        </div>
                        <div className="col-8 col-lg-5">
                        <input
                                type="file"
                                className="custom-file-input"
                                id="thumbnail-disable"
                                onChange={this.uploadImage}
                            />
                            <label className="custom-file-label ml-3 mr-3" htmlFor="thumbnail-disable">Choose file</label>
                        </div>
                    </div>

                    {gameType != null && gameType.thumbnailDisable != null &&
                        <div className="row justify-content-center mt-2">
                            <div className="col-4 col-lg-2 my-auto">
                            </div>
                                <div id="show-animation" className="col-8 col-lg-5" style={{ maxHeight: "300px" }}>
                                    <img src={gameType.thumbnailDisable} />
                                </div>
                        </div>
                    }

                    <div className="row justify-content-center my-3">
                        {loading ?
                        (
                            <button 
                                style={{ width: "120px" }} 
                                className="btn btn-primary" 
                                type="button" disabled
                            >
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                {gameType.id ? (<>Lưu <i className="fa fa-refresh" aria-hidden="true"></i></>) : <>Tạo <i className="fa fa-plus" aria-hidden="true"></i></>}
                            </button>
                        ) :
                        (
                            <button 
                                style={{ width: "120px" }} 
                                type="button" 
                                className="btn btn-primary" 
                                onClick={this.saveGameType}
                            >
                                {gameType.id ? (<>Lưu <i className="fa fa-refresh" aria-hidden="true"></i></>) : <>Tạo <i className="fa fa-plus" aria-hidden="true"></i></>}
                            </button>
                        )}
                    </div>
                </div>

            </div>
        );
    }
}