import React, { Component } from 'react';
import { Link } from "react-router-dom";
import '../assets/css/footer.css'
import { useTranslation } from 'react-i18next';
import { detectApp, getVersion } from '../common/utils.js';

export default function Footer() {
	const { t, i18n } = useTranslation();

	const background = { "backgroundColor": "rgb(245, 140, 31)" }

	const hostName = window.location.hostname;

	return (
		<footer id="footer" className={(hostName.includes("wap.okara") || hostName.includes("mz.ikara.co") || hostName.includes(".okara.co")) ? "d-none" : ""}> {/* //myanmar */}
			<div className="container">
				<div className="row copyright">
					<div className="col-12">
						<h2 className="logo2"><Link
							to="/"
							title={getVersion()}
							style={{ background: "url(" + require("../assets/images/" + t('footer.logo', "yokara-logo-footer.png")) + ") no-repeat 0 0 transparent", backgroundSize: "contain" }
							}>
							{/* <img src={require('../assets/images/ikara-logo-footer.png')} /> */}
						</Link></h2>
						<div className="menu-bottom d-sm-block d-none">
							<ul>
								<li><Link to="/">{t("footer.home_page", "Trang chủ")}</Link></li>
								<li><Link to="/term">{t("footer.term", "Điểu khoản dịch vụ")}</Link></li>
								{!detectApp("mz.okara") && (<li><Link to="/privacy-policy">{t("footer.privacy_policy", "Chính sách bảo mật")}</Link></li>)}
								
							</ul>
						</div>
					</div>
					<div className="col-md-8 col-xl-8 item">
						<h4>{t("footer.description", "IKARA - Ứng dụng hát karaoke hàng đầu Việt Nam")}</h4>
						<p>{t("footer.company", "Công Ty IKARA Việt Nam")}</p>
						<p>{t("footer.copyright", "Copyright © 2013 IKARA")}</p>
					</div>
					<div className="col-md-4 col-xl-4 item">
						<h4>{t("footer.contact", "Liên hệ")}</h4>
						<p>{t("footer.hotline", "Hotline")}: {t("footer.hotline_value", "1900686868")}</p>
						{/* <p>{t("address.1")}: {t("address.2")}</p> */}
						{!detectApp("mz.okara") && (<p>{t("footer.email", "Email")}: {t("footer.email_value", "support@ikara.co")}</p>)}

					</div>
				</div>
			</div>
		</footer>
	);

}

