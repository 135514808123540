import React, {Component} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';

const $ = window.$;
export default class ModalEditName extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            name: ""
        }

        this.editName = this.editName.bind(this);
    }


    editName() {
        this.setState({
            loading: true
        })

        let json = {
            name : this.state.name,
            userId: this.props.account.id,
            language: this.props.account.language
        }

        axios.post(global.config.apiDomain + '/rest/admin/editName', JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            
            this.setState({
                loading: false
            })
            if(res.data.status == "OK") {
                $('#modalEditName').modal('hide');
                this.props.resetAccount();
                toast.success(res.data.message);
            } else {
                toast.warning(res.data.message);
            }
        }, err => {
            this.setState({
                loading: false
            })
            toast.error("Chỉnh sửa tên thất bại");
        })

    }


    render() {
        const { name } = this.state;

        return (
            <div className="modal fade" id="modalEditName" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog  modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Update Name</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {this.props.account ? 
                    (
                        <input className="form-control" type="text" defaultValue={this.props.account.name} onChange={(event) => this.setState({name: event.target.value})} />
                    ) : (
                        ""
                    )
                    }
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                    <button style={{width: "120px"}} type="button" className="btn btn-red" onClick={this.editName} disabled={name.trim() === ''}>Xác nhận</button>
                  </div>
                </div>
              </div>
            </div>
          );
    }
}