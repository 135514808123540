import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';

import bindModel from '../../../common/bindModel.js';
import $ from 'jquery';
import AWS from 'aws-sdk';

export default class ModalUpdatePromotion extends Component {

    constructor(props) {
        super(props);

        this.state = {
            promotion: null,
            types: [],
            loading: false
        }

        this.onHandleChangeTypes = this.onHandleChangeTypes.bind(this);
        this.savePromotion = this.savePromotion.bind(this);
        this.uploadThumbnailForWasabi = this.uploadThumbnailForWasabi.bind(this);

        this.updateStartDate = this.updateStartDate.bind(this);
        this.updateEndDate = this.updateEndDate.bind(this);
        this.updateStartDateBanner = this.updateStartDateBanner.bind(this);
    }

    convertDate(value) {
        let arr = value.split("/");
        let date = arr[2] + "-" + arr[1] + "-" + arr[0];

        return date;
    }

    convertDateTime(value) {
        let arr = value.split(",");
        let arrDate = arr[1].trim().split("/");
        arrDate[1] = arrDate[1].length == 1 ? "0" + arrDate[1] : arrDate[1];
        arrDate[0] = arrDate[0].length == 1 ? "0" + arrDate[0] : arrDate[0];
        let date = arrDate[2] + "-" + arrDate[1] + "-" + arrDate[0];
        return date + "T" + arr[0].substring(0,5);

    }

    componentWillReceiveProps(props) {
        // let promotion = props.promotion;
        let promotion = JSON.parse(JSON.stringify(props.promotion));
        // if (promotion != null) {
        //     promotion.banner.startDate = this.convertDate(new Date(promotion.banner.startDate).toLocaleDateString()) ;
        //     promotion.banner.endDate = this.convertDate(new Date(promotion.banner.endDate).toLocaleDateString());
        //     // promotion.startDate = this.convertDate(new Date(promotion.startDate).toLocaleDateString());
        //     promotion.startDate = this.convertDateTime(new Date(promotion.startDate).toLocaleString());
        //     promotion.endDate = this.convertDateTime(new Date(promotion.endDate).toLocaleString());
        // }
        // console.log(props.promotion)

        this.setState({
            promotion: promotion
        })
    }

    onHandleChangeTypes(event) {
        var target = event.target;
        var checked = target.checked;

        var promotion = this.state.promotion;
        if (checked) {
            promotion.types = promotion.types.concat(target.value)
            this.setState({
                promotion: promotion
            })
        } else {
            var item = promotion.types.findIndex(item => item == target.value)
            promotion.types.splice(item, 1)
            this.setState({
                promotion: promotion
            })
        }

        console.log(this.state.promotion);
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }


    uploadThumbnailForWasabi(event) {

        this.setState({
            loading: true
        });

        // var credentials = new AWS.Credentials("0IOD12IF62UYZ22355HV", "H1hKICheXJrZCwJlIu6BeBV21laYErelMgArrMsu");
        // var ep = new AWS.Endpoint("s3.wasabisys.com");
        // var s3 = new AWS.S3({
        //     endpoint: ep,
        //     credentials: credentials,
        //     region: 'us-east-1'
        // });

        var promotion = this.state.promotion;
        var target = event.target;
        var bucketName = "ikara-data/images/promotions";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/jpeg";
        if (extension == "png") contentType = "image/png";

        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data)
                    this.setState({
                        loading: false
                    });

                    promotion.banner.thumbnail = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        promotion: promotion
                    })

                }.bind(this),
            });
        }.bind(this);

        // fileReader.onload = function () {
        //     var data = fileReader.result; // data <-- in this var you have the file data in Base64 format
        //     var params = {
        //         ACL: 'public-read',
        //         ContentType: contentType,
        //         Bucket: bucketName,
        //         Key: keyName,
        //         Body: data
        //     };
        //     s3.putObject(params, function (err, data) {
        //         if (err) {
        //             alert("Upload Failed " + err);
        //         } else {
        //             console.log("OK");
        //             promotion.banner.thumbnail = 'https://s3.wasabisys.com/' + bucketName + '/' + keyName;
        //             this.setState({
        //                 promotion: promotion
        //             })
        //         }
        //     }.bind(this));
        // }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);

    }


    savePromotion() {
        this.setState({
            loading: true
        });

        // this.state.promotion.startDate += ":00";
        // this.state.promotion.endDate += ":00";
        
        axios.post(global.config.apiDomain + '/rest/promotion/save', JSON.stringify(this.state.promotion), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                loading: false
            });
            if (res.data.status == "OK") {
                window.$("#modalUpdatePromotion").modal("hide");
                toast.success(res.data.message);
                this.props.resetList();
            } else {
                toast.warning(res.data.message);
            }
        }, error => {
            toast.error('Cập nhật thất bại');
        })
    }

    updateStartDate(props) {
        let {promotion} = this.state;
        let currentTime = props.value
        if(promotion) {
            promotion.startDate = currentTime;
            this.setState({
                promotion: promotion
            });
        }
        
    }

    updateEndDate(props) {
        let {promotion} = this.state;
        let currentTime = props.value
        if(promotion) {
            promotion.endDate = currentTime;
            this.setState({
                promotion: promotion
            });
        }
        
    }

    updateStartDateBanner(props) {
        let {promotion} = this.state;
        let currentTime = props.value
        if(promotion) {
            promotion.banner.startDate = currentTime;
            this.setState({
                promotion: promotion
            });
        }
        
    }

    render() {
        const model = bindModel(this);
        const { promotion } = this.state;

        return (
            <div>
                <div className="modal fade" id="modalUpdatePromotion" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật khuyến mãi</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {promotion ? (<div className="modal-body">
                                <div id="id" className="row mb-2">
                                    <div className="col-3">
                                        <label>ID</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="startDate"
                                            readOnly={true}
                                            {...model('promotion.id')} />
                                    </div>
                                </div>
                                <div id="thumbnail" className="row mb-2">
                                    <div className="col-3">
                                        <label>Thumbnail</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            id="inputGroupFile03"
                                            onChange={this.uploadThumbnailForWasabi} />
                                        <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile03">Choose file</label>
                                    </div>
                                </div>
                                <div id="startDate" className="row mb-2">
                                    <div className="col-3">
                                        <label>Ngày bắt đầu (banner)</label>
                                    </div>
                                    <div className="col-9">
                                        {/* <input
                                            className="form-control mb-2"
                                            type="date"
                                            name="startDate"
                                            {...model('promotion.banner.startDate')} /> */}

                                        <DateTimePickerComponent 
                                            change= {this.updateStartDateBanner}
                                            value = {new Date(promotion.banner.startDate)}
                                        >    
                                        </DateTimePickerComponent>
                                    </div>
                                </div>
                                {promotion.banner.thumbnail ? (
                                    <div id="thumbnail" className="row mb-2">
                                        <div className="col-3">

                                        </div>
                                        <div className="col-9">
                                            <img src={promotion.banner.thumbnail} />

                                        </div>
                                    </div>
                                ) : ""}
                                <div id="title" className="row mb-2">
                                    <div className="col-3">
                                        <label>Tiêu đề</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="title"
                                            {...model('promotion.title')} />
                                    </div>
                                </div>
                                <div id="id" className="row mb-2">
                                    <div className="col-3">
                                        <label>Mô tả</label>
                                    </div>
                                    <div className="col-9">
                                        <textarea
                                            className="form-control mb-2"
                                            type="text"
                                            name="startDate"
                                            rows={5}
                                            {...model('promotion.description')} /> 
                                    </div>
                                </div>

                                <div id="startDate" className="row mb-2">
                                    <div className="col-3">
                                        <label>Ngày bắt đầu</label>
                                    </div>
                                    <div className="col-9">
                                        {/* <input
                                            className="form-control mb-2"
                                            type="datetime-local"
                                            name="startDate"
                                            {...model('promotion.startDate')} /> */}
                                        <DateTimePickerComponent 
                                            change= {this.updateStartDate}
                                            value = {new Date(promotion.startDate)}
                                        >    
                                        </DateTimePickerComponent>
                                    </div>
                                </div>
                                <div id="endDate" className="row mb-2">
                                    <div className="col-3">
                                        <label>Ngày kết thúc</label>
                                    </div>
                                    <div className="col-9">
                                        {/* <input
                                            className="form-control"
                                            type="datetime-local"
                                            name="endDate"
                                            {...model('promotion.endDate')} /> */}
                                        <DateTimePickerComponent 
                                            change= {this.updateEndDate}
                                            value = {new Date(promotion.endDate)}
                                        >    
                                        </DateTimePickerComponent>
                                    </div>
                                </div>
                                <div id="backgroundColor" className="row mb-2">
                                    <div className="col-3">
                                        <label>Màu nền</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control"
                                            type="color"
                                            name="backgroundColor"
                                            {...model('promotion.banner.backgroundColor')} />
                                    </div>
                                </div>
                                <div id="link" className="row mb-2">
                                    <div className="col-3">
                                        <label>Link</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="url"
                                            {...model('promotion.banner.url')} />
                                    </div>
                                </div>
                                <div id="promotionPlus" className="row mb-2">
                                    <div className="col-3">
                                        <label>Khuyến mãi (%)</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="number"
                                            name="promotionPlus"
                                            {...model('promotion.promotionPlus')} />
                                    </div>
                                </div>
                                <div id="link" className="row">
                                    <div className="col-3">
                                        <label>Phương thức</label>
                                    </div>
                                    <div className="col-9">
                                        <div><input type="checkbox" checked={promotion.types.includes("VIETCOMBANK")} value="VIETCOMBANK" onChange={this.onHandleChangeTypes} />  Vietcombank</div>
                                        <div><input type="checkbox" checked={promotion.types.includes("MOMO")} value="MOMO" onChange={this.onHandleChangeTypes} /> Momo</div>
                                        <div><input type="checkbox" checked={promotion.types.includes("CARD")} value="CARD" onChange={this.onHandleChangeTypes} /> Thẻ cào</div>
                                        <div><input type="checkbox" checked={promotion.types.includes("BANK")} value="BANK" onChange={this.onHandleChangeTypes} /> VNPAY</div>
                                        <div><input type="checkbox" checked={promotion.types.includes("GOOGLEINAPP")} value="GOOGLEINAPP" onChange={this.onHandleChangeTypes} /> APPLE INAPP</div>
                                        <div><input type="checkbox" checked={promotion.types.includes("APPLEINAPP")} value="APPLEINAPP" onChange={this.onHandleChangeTypes} /> GOOGLE INAPP</div>
                                        <div><input type="checkbox" checked={promotion.types.includes("PAYPAL")} value="PAYPAL" onChange={this.onHandleChangeTypes} /> PAYPAL</div>
                                    </div>
                                </div>
                                <div>
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">Package</th>
                                                <th scope="col">Platform</th>
                                                <th scope="col">Min version</th>
                                                <th scope="col">Max version</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {promotion.banner.minMaxVersions.map((version, index) => {
                                                return (
                                                    <tr key={version.id}>
                                                        <td>{version.packageName}</td>
                                                        <td>{version.platform}</td>
                                                        <td><input className="form-control" value={version.minVersion} {...model('promotion.banner.minMaxVersions[' + index + '].minVersion')} /></td>
                                                        <td><input className="form-control" value={version.maxVersion} {...model('promotion.banner.minMaxVersions[' + index + '].maxVersion')} /></td>
                                                    </tr>)
                                            })}

                                        </tbody>
                                    </table>
                                </div>
                            </div>) : ''}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.savePromotion}>Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}