import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import bindModel from '../../../../../common/bindModel.js';
import axios from 'axios';

export default class ShowGameAnswers extends Component {
    constructor(props) {
        super(props);
        
        let newGameAnswer = {
            content: "",
            description: "",
            isRight: false
        }

        this.state = {
            gameQuestion: props.gameQuestion,
            newGameAnswer: newGameAnswer
        }

        this.removeGameAnswer = this.removeGameAnswer.bind(this);
        this.addGameAnswer = this.addGameAnswer.bind(this);
        this.selectAnswer = this.selectAnswer.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            gameQuestion: props.gameQuestion
        })
    } 

    removeGameAnswer(index) {
        let {gameQuestion} = this.state;
        gameQuestion.gameAnswers.splice(index, 1);
        this.setState({
            gameQuestion: gameQuestion
        });

    }

    addGameAnswer() {
        let {gameQuestion, newGameAnswer} = this.state;

        if(gameQuestion.gameAnswers.length < 4) {
            let newGameAnwserCopy = JSON.parse(JSON.stringify(newGameAnswer));
            gameQuestion.gameAnswers.push(newGameAnwserCopy);
            newGameAnswer = {
                content: "",
                description: "",
                isRight: false
            }
            this.setState({
                gameQuestion: gameQuestion,
                newGameAnswer: newGameAnswer
            });
        } else {
            toast.warn("Không được thêm quá 4 câu trả lời");
        }
    }

    selectAnswer(event) {
        let {gameQuestion} = this.state;
        let gameAnswers = gameQuestion.gameAnswers;
        let index = event.target.value;

        for(let i = 0; i < gameAnswers.length; i++) {
            if(i == index) {
                gameAnswers[i].isRight = true;
            } else {
                gameAnswers[i].isRight = false;
            }
        }

        gameQuestion.answerId = index;

        this.setState({
            gameQuestion: gameQuestion
        })
    }

    render() {
        const model = bindModel(this);
        const {gameQuestion} = this.state;
        return (
            <>
                {gameQuestion.gameAnswers != null &&
                    gameQuestion.gameAnswers.map((gameAnswer, i) => {
                        return (
                            <div key={i}>
                                <div className="row mb-2">
                                    <div className="col-3 my-auto">
                                        <label className="m-0 text-danger">Câu trả lời {i + 1}:</label>
                                    </div>
                                    <div className="col-6">
                                    <textarea
                                        className="form-control"
                                        type="text"
                                        name=""
                                        {...model('gameQuestion.gameAnswers['+ i +'].content')}
                                    />
                                    </div>
                                    <div className="col-3">
                                        <button style={{width: "100px"}} className='btn btn-danger' onClick={() => this.removeGameAnswer(i)} >Xóa  <i className="fa fa-trash" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-3 my-auto">
                                        <label className="m-0 text-danger">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mô tả:</label>
                                    </div>
                                    <div className="col-6">
                                    <textarea
                                        className="form-control"
                                        type="text"
                                        name=""
                                        {...model('gameQuestion.gameAnswers['+ i +'].description')}
                                    />
                                    </div>
                                    <div className="col-3"></div>
                                </div>
                            </div>
                        )

                    })
                }
                
                <div className="row mb-2 justify-content-center">
                    {/* <div className="col-3 my-auto">
                        <label className="my-auto">Thêm câu trả lời:</label>
                    </div>
                    <div className="col-6">
                        <input
                            className="form-control"
                            type="text"
                            name=""
                            placeholder='Nhập câu trả lời'
                            {...model('newGameAnswer.content')}
                        />
                    </div> */}
                    <div className="col-3">
                        <button className='btn btn-primary' onClick={this.addGameAnswer}>Thêm câu trả lời <i className="fa fa-plus" aria-hidden="true"></i></button>
                    </div>
                </div>

                <div className="row mb-2">
                    <div className="col-3">
                        <label className="my-auto">Chọn câu trả lời đúng:</label>
                    </div>
                    <div className="col-6">
                        <select
                            className="form-control"
                            type="text"
                            value={gameQuestion.answerId}
                            onChange={this.selectAnswer}
                        >
                            <option value="">Chọn câu trả lời đúng</option>
                            {
                                gameQuestion.gameAnswers.map((element, i) => {
                                    return <option key={i} value={i}>{element.content}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-3">

                    </div>
                </div>
            </>
        )
    }

}