import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';

import ModalUpdateVipPrivilege from './components/ModalUpdateVipPrivilege';
import ModalDeleteVipPrivilege from './components/ModalDeleteVipPrivilege';

class VipPrivilege extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vipPrivileges: [],
            vipPrivilege: null,
        };

        this.getVipPrivileges = this.getVipPrivileges.bind(this);
        this.addNewVipPrivilege = this.addNewVipPrivilege.bind(this);
        this.updateVipPrivilege = this.updateVipPrivilege.bind(this);
        this.deleteOrActiveVipPrivilege = this.deleteOrActiveVipPrivilege.bind(this);

    }

    componentDidMount() {
        this.getVipPrivileges();
    }

    getVipPrivileges() {
        axios.post(global.config.apiDomain + '/rest/privileges/getAllVipPrivileges')
        .then(res => {
            if (res.data.status === 'OK') {
                this.setState({
                    vipPrivileges: res.data.privileges
                })
            } 
        })
    }

    addNewVipPrivilege() {
        let vipPrivilege = {
            idTab: '',
            name: '',
            description: '',
            detail: '',
            icon: '',
            levelRequirement: 0,
            image: '',
            isActive: '',
            index: 0
        };

        this.setState({
            vipPrivilege : vipPrivilege,
        })
    }

    updateVipPrivilege(event) {
        let index= event.target.name;
        let vipPrivilege = this.state.vipPrivileges[index];
        if (vipPrivilege.isActive) {
            vipPrivilege.isActive = '1';
        } else {
            vipPrivilege.isActive = '0';
        }

        this.setState({
            vipPrivilege : vipPrivilege,
        })
    }

    deleteOrActiveVipPrivilege(event) {
        let index= event.target.name;
        let vipPrivilege = this.state.vipPrivileges[index];

        this.setState({
            vipPrivilege : vipPrivilege,
        })
    }
   
    async getAndModifySVG(url) {
        const response = await fetch(url);
        const str = await response.text();
        return (new window.DOMParser()).parseFromString(str, "text/xml");
    }

    async setIconList(index) {
        let text = await this.getAndModifySVG(this.state.vipPrivileges[index].icon);
        $("#icon" + index).html(function() {
            return text.documentElement;
        });
    }

    render() {
        const {vipPrivileges, vipPrivilege} = this.state;
        return (
            <>
                <ModalUpdateVipPrivilege
                    vipPrivilege={vipPrivilege}
                    resetList={this.getVipPrivileges}
                >                
                </ModalUpdateVipPrivilege>
                <ModalDeleteVipPrivilege
                    vipPrivilege={vipPrivilege}
                    resetList={this.getVipPrivileges}
                >                
                </ModalDeleteVipPrivilege>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí Đặc quyền Vip</h1>
                        <button 
                            className="btn btn-red mb-1" 
                            data-toggle="modal" 
                            data-target="#modalUpdateVipPrivilege"
                            onClick={this.addNewVipPrivilege}
                            >+ Tạo Mới Đặc quyền
                        </button>
                        <table className="table table-hover">
                            <thead>
                            <tr className="">
                                <th className="align-middle" scope="col">#</th>
                                <th className="align-middle" scope="col">idTab</th>
                                <th className="align-middle" scope="col">Level yêu cầu</th>
                                <th className="align-middle" scope="col">Tên</th>
                                <th className="align-middle text-center" scope="col">Icon</th>    
                                <th className="align-middle text-center" scope="col" style={{width: '200px'}}>Ảnh</th>  
                                <th className="align-middle text-center" scope="col">Mô tả</th>
                                <th className="align-middle text-center" scope="col">Mô tả Chi tiết</th>                                                
                                <th className="align-middle" scope="col">Index</th>
                                <th className="align-middle" scope="col">Trạng thái</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>                            {
                                vipPrivileges && vipPrivileges.map((item,index) => {
                                    this.setIconList(index);
                                    return (
                                        <tr>
                                            <td className='align-middle'>{index +1}</td>
                                            <td className='align-middle'><strong>{item.idTab}</strong></td>
                                            <td className='align-middle'>{item.levelRequirement}</td>
                                            <td className='align-middle'>{item.name}</td>
                                            <td className='align-middle'>
                                                <span id={'icon' + index}></span>
                                            </td>
                                            <td>
                                                {item.image && item.image.endsWith('.mp4')?
                                                 <video width="200" height="250" controls="controls">
                                                     <source src={item.image} type="video/mp4" />
                                                </video> :
                                                <img 
                                                src={item.image} 
                                                style={{width: '200px'}}
                                                className="img-item" 
                                                alt={item.name}
                                            />}
                                            </td>
                                            <td className='align-middle'>{item.description}</td>
                                            <td className='align-middle'>{item.detail}</td>
                                            <td className='align-middle'>{item.index}</td>
                                            <td className='align-middle'>{item.isActive ? <span className='text-success'>Đang kích hoạt</span> : <span className='text-danger'>Đã Ẩn</span>}</td>
                                            <td className='align-middle'>
                                                {
                                                    !item.isActive ? (
                                                        <>
                                                            <button 
                                                                className="btn btn-info"
                                                                onClick={this.deleteOrActiveVipPrivilege}
                                                                name={index} 
                                                                data-toggle="modal" 
                                                                data-target="#modalDeleteVipPrivilege"
                                                            >
                                                                Kích hoạt
                                                            </button>
                                                        </>
                                                    
                                                    ) : (
                                                        <>
                                                            <button 
                                                                className="btn btn-success"
                                                                onClick={this.updateVipPrivilege}
                                                                name={index} 
                                                                data-toggle="modal" 
                                                                data-target="#modalUpdateVipPrivilege"
                                                            >
                                                                Sửa
                                                            </button>&emsp;
                                                            <button 
                                                                className="btn btn-danger"
                                                                onClick={this.deleteOrActiveVipPrivilege}
                                                                name={index} 
                                                                data-toggle="modal" 
                                                                data-target="#modalDeleteVipPrivilege"
                                                            >
                                                                Hủy
                                                            </button>
                                                        </>
                                                    )
                                                }
                                        
                                            </td>
                                        </tr>
                                    )
                                }
                                    
                                )
                            }

                            </tbody>
                        </table>
                    </div>
                </div>

            </>
        );
    }
}

export default VipPrivilege
