import React, { Component } from 'react';
import axios from 'axios';
import { withTranslation } from 'react-i18next';

import UserItemInUsersPage from './components/UserItemInUsersPage';
import { Helmet } from 'react-helmet';
class TopUsersPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentPage: 0,
      users: [],
      statusButtonLoadPage: false,
      loading: false
    }

    this.loadMoreUsers = this.loadMoreUsers.bind(this);
  }

  componentDidMount() {
    this.getUsers(this.state.currentPage);
  }

  getUsers(pageIndex) {
    let json = {
      domain: window.location.hostname,
      currentPage: pageIndex
    }

    axios.post(global.config.apiDomain + '/rest/users', JSON.stringify(json), {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
      const { users } = res.data;
      let status = true;

      if (users.length < 18) {
        status = false;
      }
      this.setState({
        users: this.state.users.concat(users),
        currentPage: pageIndex + 1,
        statusButtonLoadPage: status,
        loading: false
      });
    })
  }

  loadMoreUsers() {
    const { loading } = this.state;
    if (!loading) {
      this.setState({
        loading: true
      });
      this.getUsers(this.state.currentPage);
    }
  }


  render() {
    const { users, statusButtonLoadPage, loading } = this.state;

    const { t } = this.props;

    return (
      <div id="content">
        <Helmet>
					<title>{t("top_users_page.top_users", "Danh ca")}</title>
				</Helmet>
        <div className="singer-page">
          <div className="container">
            <h1 className="heading_one">{t("top_users_page.top_users", "Danh ca")}</h1>
            <div className="wrap-list-singer">
              <div className="row list-singer">
                {users.map((element, index) => (
                  <UserItemInUsersPage key={index} user={element}></UserItemInUsersPage>
                ))}
              </div>
            </div>
            {
              statusButtonLoadPage ?
                (<div onClick={this.loadMoreUsers} className="view_more w-100 text-center mb-lg-4">
                  <a className="d-none d-md-block">
                    <span>{loading ? t("top_users_page.loading", "ĐANG TẢI") + "..." : t("top_users_page.load_more", "TẢI THÊM")}</span>
                  </a>
                  <a className="view_more_mobile d-md-none">
                    <i className="fa fa-angle-down" aria-hidden="true" />
                  </a>
                </div>) :
                ('')
            }

          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(TopUsersPage)


