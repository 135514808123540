import React, { Component } from 'react';
import axios from 'axios';
import OwnerInRecordingPage from './components/OwnerInRecordingPage.js'
import RecordingInfoInRecordingPage from './components/RecordingInfoInRecordingPage.js'
import VideoPlayerInRecordingPage from './components/VideoPlayerInRecordingPage.js'
import LikeCommentGiftInRecordingPage from './components/LikeCommentGiftInRecordingPage.js'
import TopRecordingsInRecordingPage from './components/TopRecordingsInRecordingPage.js';
import ToastDownloadApp from './components/ToastDownloadApp.js';
import ToastDownloadAppForYokara from './components/ToastDownloadAppForYokara.js';
import { Helmet } from 'react-helmet';
import { useTranslation, withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Base64 } from 'js-base64'
import { detectApp } from '../../common/utils.js';

class RecordingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      recording: null,
      giftList: [],
      isComponentUpdate: true,
      loadRecording: false,
      link: "https://play.google.com/store/apps/details?id=co.ikara.v2",
      changeLink: true,
    };

    this.resetViewCounter = this.resetViewCounter.bind(this);
    this.getRecording = this.getRecording.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    var recordingIdRegex = /^.*-(\d+)$/;
    var match = recordingIdRegex.exec(nextProps.match.params.recordingId);
    var recordingId = match[1];
    if (this.state.recording != null) {

      if (this.state.recording._id != recordingId) {
        this.state.isComponentUpdate = true;
        this.state.loadRecording = false;
      }
    }
    this.getRecording(nextProps);

  }

  newGetKey(data, password, password2) {
    var listOfNumbers = "" + password2;
    for (var i = 0; i < data.length; i++) {
      if (data.charAt(i) >= '0' && data.charAt(i) <= '9') {
        listOfNumbers = [listOfNumbers.slice(0, 0), data.charAt(i), listOfNumbers.slice(0)].join('');
      }
    }

    var originalKey = null;
    if (listOfNumbers.length > 15)
      originalKey = listOfNumbers.substring(0, 15);
    else
      originalKey = listOfNumbers;
    return Number(originalKey) + Number(password);
  }

  checkLink() {
    let json = {
      url: this.state.link
    };
    console.log("check url: ", this.state.link);
    const jsonBase64 = Base64.encode(JSON.stringify(json));
    var pass = this.newGetKey(jsonBase64, 7123358024, 3564);
    var keys = jsonBase64 + "-" + pass;
    var url = global.config.apiDomain + "/web.v5.getUrlStatus?parameters=" + keys;
    axios.post(url, json, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
      if (res.data.status == "OK") {
        this.setState({
          changeLink: true,
        })
      } else {
        this.setState({
          changeLink: false,
        })
      }
      console.log(res);
    }, err => {
      toast.error("Đã có lỗi xảy ra, vui lòng thử lại!");
    });
  }


  componentDidMount() {
    this.getRecording(this.props);
  }

  shouldComponentUpdate() {
    return this.state.isComponentUpdate;
  }


  getRecording(props) {
    const { t } = props;
    var recordingIdRegex = /^.*-(\d+)$/;
    var match = recordingIdRegex.exec(props.match.params.recordingId);
    var recordingId = null;
    if (match) {
      recordingId = match[1];
      const json = JSON.stringify({ domain: window.location.hostname, recordingId: recordingId });

      axios.post(global.config.apiDomain + '/rest/recordingpage', json, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        const { error, recording, gifts } = res.data;
        this.setState(
          {
            recording: recording,
            giftList: gifts,
            error: error,
            loadRecording: true
          });
      })
    } else {
      this.setState({
        error: t("recording_page.invalid_path")
      });
    }
  }

  resetViewCounter(viewCounter) {
    let { recording } = this.state;
    recording.viewCounter = recording.viewCounter + 1;
    this.setState({
      recording: recording
    });
  }


  render() {

    const { error, recording, giftList, loadRecording } = this.state;
    const { t } = this.props;

    if (recording != null && loadRecording) {
      this.setState({
        isComponentUpdate: false
      })
    }

    if (error != null) {
      return (
        <div id="content">
          <div className="page-error">
            <div className="container text-center">
              <img src={require('../../assets/images/404.png')} />
              <h2 className="heading">{t("recording_page.error_recording", "Lỗi bài thu")}</h2>
              <p>{error}</p>
              <a href="/" className="btn-yellow">
                <i className="fa fa-angle-left" /> {t("recording_page.back_home", "Quay về trang chủ")}
              </a>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <>
          <div id="content">
            <Helmet>
              <title>{recording != null && recording.song.songName}</title>
            </Helmet>
            <div className="record-detail-page">
              <div className="container">
                {!detectApp("mi") && !detectApp("en.yokara") && (
                  <ToastDownloadApp></ToastDownloadApp>
                )}
                <div className="row">
                  {detectApp("en.yokara") && (
                    <ToastDownloadAppForYokara></ToastDownloadAppForYokara>
                  )}
                  <div className="col-md-8">
                    <div className="item-record pl-xl-4"
                      playsInline={true}>
                      <VideoPlayerInRecordingPage resetViewCounter={this.resetViewCounter} recording={recording}></VideoPlayerInRecordingPage>
                    </div>
                    <div className="info-record pl-xl-4">
                      <div className="middle">
                        <OwnerInRecordingPage recording={recording}></OwnerInRecordingPage>
                        <RecordingInfoInRecordingPage recording={recording}></RecordingInfoInRecordingPage>
                      </div>
                      <hr />
                      <div className="download-app" style={{ marginBottom: '200px', display: 'none' }}>
                        <h2 className="title">Tải app ngay để thu âm</h2>
                        <ul className="list">
                          <li>
                            <a href="https://itunes.apple.com/app/id711994026">
                              <img src={require('../../assets/images/app-store-download.png')} alt="Download app store" />
                            </a>
                          </li>
                          <li>
                            <a href={this.state.changeLink ? "https://play.google.com/store/apps/details?id=net.ukara" :
                              "https://data4.ikara.co/data/minio/ikara-data/android-apk/yokara_10.19.9.apk"}>
                              <img src={require('../../assets/images/google-play-download.png')} alt="Download google play" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="info-record pl-xl-4">
                      {recording ? (
                        <LikeCommentGiftInRecordingPage giftList={giftList} recording={recording} noCommentPerRequest={5}></LikeCommentGiftInRecordingPage>
                      ) : <></>}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <TopRecordingsInRecordingPage></TopRecordingsInRecordingPage>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}
// export default RecordingPage
export default withTranslation()(RecordingPage)
