import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';

export default class ModalTheVoiceUserExamine extends Component {
    constructor(props) {
        super(props);

        this.state = {
            examines : null,
            examineModal : null
        }
        this.theVoiceUserExamine = this.theVoiceUserExamine.bind(this);
    }
    componentWillReceiveProps(props) {
        this.setState({
            examineModal: props.examineModal
        })
        this.theVoiceUserExamine(props.examineModal)
    }

    theVoiceUserExamine (examineModal) {
        let url = global.config.apiDomain + "/rest/theVoiceV2/theVoiceUserExamine"
        axios.post(url, examineModal)
            .then(res => {
                this.setState({
                    examines : res.data.listExaminerMark
                })
            })
    }

    render() {
        const { examines, examineModal } = this.state;

        return (
            <div>
                <div className="modal fade" id="modalSurveyStatistics" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="exampleModalCenterTitle">Danh sách chấm điểm</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div id="modal-body">
                        
                                <table className="table table-hover">
                                    <thead>
                                        <tr className="text-center">
                                            <th className="align-middle" scope="col">STT</th>
                                            <th className="align-middle" scope="col">UID</th>
                                            <th className="align-middle" scope="col">Tên</th>
                                            <th className="align-middle" scope="col">Số Điểm</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {examines && examines.map((item, index) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td className="align-middle text-center" scope="col">{index+1}</td>
                                                        <td className="align-middle text-center" scope="col">{item.uid}</td>
                                                        <td className="align-middle text-center" scope="col">{item.name}</td>
                                                        <td className="align-middle text-center" scope="col">{item.score}</td>
                                                    </tr>
                                                </>
                                            )
                                            })}
                                    </tbody>
                                </table>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}