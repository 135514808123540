import React, { Component } from 'react';
import MyContext from '../../../common/MyContext.js';
import bindModel from '../../../common/bindModel.js';
import axios from 'axios';
import {toast} from 'react-toastify';
import ModalConfirmBuyiCoinForMobileCard from './ModalConfirmBuyiCoinForMobileCard';

const $ = window.$;


export default class ModalBuyIconForMobileCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            request: {
                userId: props.id,
                domain: window.location.hostname,
                cardId: "VIETTEL",
                pin: "",
                serial: "",
                amount: 0,
            },
            loading: false,
            confirm: false
        }

        this.rechargeCard = this.rechargeCard.bind(this); 
        this.closeModalConfirm = this.closeModalConfirm.bind(this);
        this.back = this.back.bind(this);
    }

    componentDidMount() {
        $("#modalBuyIconForMobileCard").on("hidden.bs.modal", function () {
            if(!this.state.confirm) {
                this.setState({
                    request: {
                        userId: this.props.id,
                        domain: window.location.hostname,
                        cardId: "VIETTEL",
                        pin: "",
                        serial: "",
                        amount: 0,
                    }
                });
            }  
        }.bind(this));
    }

    closeModalConfirm() {
        this.setState({
            request: {
                userId: this.props.id,
                domain: window.location.hostname,
                cardId: "VIETTEL",
                pin: "",
                serial: "",
                amount: 0,
            },
            confirm: false
        });
        $("#modalConfirmBuyCard").modal("hide");
    }

    back() {
        this.state.confirm = false;
        $("#modalConfirmBuyCard").modal("hide");
        $("#modalBuyIconForMobileCard").modal("show");
    }

    rechargeCard() {
        this.setState({
            loading: true
        });

        axios.post(global.config.apiDomain + '/rest/cardPayment', JSON.stringify(this.state.request), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                loading: false,
                confirm: false
            });

            if(res.data.status == "OK") {
                this.setState({
                    request: {
                        userId: this.props.id,
                        domain: window.location.hostname,
                        cardId: "VIETTEL",
                        pin: "",
                        serial: "",
                        amount: 0,
                    }
                });
                $("#modalConfirmBuyCard").modal("hide");
                toast.success(res.data.message);
            } else {
                $("#modalConfirmBuyCard").modal("hide");
                $("#modalBuyIconForMobileCard").modal("show");
                toast.warning(res.data.message);
            }
        }, error => {
            this.setState({
                loading: false,
                confirm: false
            });
            toast.error("Thanh toán thất bại");
        });
    }

    render() {
        const model = bindModel(this);
        const { user } = this.context;
        var id = "XXXXXX";
        if (user != null) {
            id = user.uid;
        }
        return (
            <>
            <ModalConfirmBuyiCoinForMobileCard 
                loading={this.state.loading} 
                submit={this.rechargeCard} 
                card={this.state.request}
                back={this.back}
                close={this.closeModalConfirm}
                ></ModalConfirmBuyiCoinForMobileCard>
            <div>
                <div className="modal fade" id="modalBuyIconForMobileCard" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Mobile card</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <h5 className="text-danger">Quy đổi: 1.000 VNĐ = 11 iCoin.
                                    Nếu có thể bạn nên nạp qua tài khoản ngân hàng hoặc Ví MOMO thì mỗi 1000đ sẽ đổi được 14 iCoin</h5>
                                <h5 className="font-weight-bold text-danger">Lưu ý: chọn đúng mệnh giá và nhà mạng</h5>
                                
                                <div className="row p-2">
                                    <label className="col-3 col-form-label">Nhà mạng: </label>
                                    <select
                                        className="form-control col-8"
                                        {...model('request.cardId')}>
                                        <option value="VIETTEL">VIETTEL</option>
                                        <option value="MOBIFONE">MOBIFONE</option>
                                        <option value="VINAPHONE">VINAPHONE</option>
                                    </select>
                                </div>
                                <div className="row p-2">
                                    <label className="col-3 col-form-label">Mệnh giá: </label>
                                    <select
                                        className="form-control col-8"
                                        {...model('request.amount')}>
                                        <option value={0}>Chọn mệnh giá thẻ cào</option>
                                        <option value={10000}>10.000đ</option>
                                        <option value={20000}>20.000đ</option>
                                        <option value={30000}>30.000đ</option>
                                        <option value={50000}>50.000đ</option>
                                        <option value={100000}>100.000đ</option>
                                        <option value={200000}>200.000đ</option>
                                        <option value={300000}>300.000đ</option>
                                        <option value={500000}>500.000đ</option>
                                        <option value={1000000}>1.000.000đ</option>
                                    </select>
                                </div>
                                <div className="row p-2">
                                    <label className="col-3 col-form-label">Mã thẻ cào: </label>
                                    <input
                                        type="text"
                                        className="form-control col-8"
                                        placeholder="Nhập mã thẻ cào"
                                        {...model('request.pin')} />
                                </div>
                                <div className="row p-2">
                                    <label className="col-3 col-form-label">Số Serial: </label>
                                    <input
                                        type="text"
                                        className="form-control col-8"
                                        placeholder="Nhập số serial"
                                        {...model('request.serial')} />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button
                                            data-dismiss="modal"
                                            data-toggle="modal" 
                                            data-target="#modalConfirmBuyCard"
                                            style={{ width: "120px" }}
                                            type="button"
                                            className="btn btn-red"
                                            onClick={() => {this.state.confirm = true}}
                                            >Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }
}

ModalBuyIconForMobileCard.contextType = MyContext;