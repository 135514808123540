import React, { Component } from 'react';
import Moment from 'react-moment';

import "../css/chatforadminpage.css";

export default class MessageItem extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        const { message, user, targetUser, handleTargetUser } = this.props;
        
        if (!message || !targetUser) return null;        
        
        var lastMessage = message.lastMessage;

        if (lastMessage instanceof Object) {
            lastMessage = JSON.stringify(lastMessage)
        }
        
        return (
            <>
                <button onClick={() => handleTargetUser(message)} 
                    className={message.fromUserId == targetUser.fromUserId
                        ? "border border-light list-group-item list-group-item-action rounded-0 active text-white"
                        : "border border-light list-group-item list-group-item-action list-group-item-light rounded-0"}
                        id={message.lastUserId !="105912617350916906040" ? "lastUserId":""}
                        >
                    <div className="media"><img width={50} className="message-item-img rounded-circle" src={message.userProfile} alt="user"/>
                        <div className="media-body ml-4">
                            <div className="d-flex align-items-center justify-content-between mb-1">
                                <h6 className="mb-0">{message.userName}</h6>
                                <small className="small font-weight-bold">
                                    {message.dateTime != null && <Moment
                                        fromNow
                                        tz="Asia/Ho_Chi_Minh"
                                        locale="vi"
                                    >{message.dateTime}
                                    </Moment>}
                                </small>
                                <a
                                    href={`${global.config.apiDomain}/admin/account-manager?id=${message.fromUserId}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn btn-link"
                                    style={{ color: 'black' }}
                                >
                                    <i className="fa fa-user" />
                                </a>
                            </div>
                            <p className="font-italic mb-0 text-small">{(lastMessage && lastMessage.length > 35)
                                ? lastMessage.substring(0, 35) + "..."
                                : lastMessage}</p>
                        </div>
                    </div>
                </button>
            </>
        )
    }
}