import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import axios from 'axios';
import { toast } from 'react-toastify';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

export default function ModalIcoin({ showModalIcoin, uid }) {

    const [showModalIcoinSet, setShowModalIcoin] = useState(showModalIcoin);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [chartData, setChartData] = useState(null);
    const [partner, setPartner] = useState(null);

    useEffect(() => {
        setShowModalIcoin(showModalIcoin);
    }, [showModalIcoin]);

    useEffect(() => {
        if (showModalIcoin && uid) {
            handleSubmitIcoin();
        }
    }, [showModalIcoin, uid]);

    const handleSubmitIcoin = () => {
        console.log('handleSubmitIcoin');

        let fromDateDefault = new Date();
        fromDateDefault.setDate(1);
        fromDateDefault.setHours(10);

        let toDateDefault = new Date();
        
        const fromTimestamp = fromDate? new Date(fromDate).getTime(): fromDateDefault.getTime();
        const toTimestamp = toDate? new Date(toDate).getTime(): toDateDefault.getTime();

        if (fromTimestamp > toTimestamp) {
            toast.error("Ngày kết thúc phải lớn hơn hoặc bằng ngày bắt đầu!");
            return;
        }

        setIsLoading(true);

        axios.post(
                global.config.apiDomain + '/rest/partner-duration/web/get-partner-icoin-list',
                { fromDate: fromTimestamp, toDate: toTimestamp, uid: uid },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then(res => {
                const data = res.data.data || null;
                setPartner(data);
                setIsLoading(false);

                const dates = [];
                const icoins = [];

                if (data && data.list) {
                    Object.entries(data.list).forEach(([key, value]) => {
                        
                        dates.push(convertISODate(key));
                        icoins.push(value);
                    });

                    setChartData({
                        labels: dates,
                        datasets: [
                            {
                                label: 'Icoin quà',
                                data: icoins,
                                fill: false,
                                borderColor: '#FFFF00',
                                pointBackgroundColor: '#FF0000',
                                tension: 0.3
                            }
                        ]
                    });
                }
            })
            .catch(error => {
                console.error('Error fetching commissions:', error);
                setIsLoading(false);
            });
    };

    const resetFormIcoin = () => {
        setFromDate('');
        setToDate('');
        setIsLoading(false);
        setPartner(null);
        setChartData(null);
        setShowModalIcoin(!showModalIcoinSet);
    };

    const convertISODate = (isoDate) => {
        const date = new Date(isoDate);
        
        // Lấy ngày, tháng và năm
        const day = date.getDate(); // Lấy ngày (UTC)
        const month = date.getMonth() + 1; // Lấy tháng (UTC, bắt đầu từ 0)
        const year = date.getFullYear(); // Lấy năm (UTC)
        
        // Định dạng thành chuỗi dd/mm/yyyy
        const formattedDate = `${day.toString().padStart(2, "0")}/${month
          .toString()
          .padStart(2, "0")}/${year}`;
        
        return formattedDate;
    }

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        onClick: (event, elements) => {
            if (!elements.length) return; // No element clicked

            const index = elements[0].index; // Get the index of the clicked point
            const label = chartData.labels[index]; // Get the label for that index
            const value = chartData.datasets[0].data[index]; // Get the value

            console.log('uid: ' + uid);
            console.log('index: ' + index);
            console.log('label: ' + label);
            console.log('value: ' + value);
        
            // Example: Navigate to another page or pass data to another component
            // history.push(`/admin/partner/icoin-detail/${uid}`, { label });
            // navigate(`/admin/partner/icoin-detail/${uid}`, { state: { label } }); 

            const newUrl = `/admin/icoin-detail/${uid}?label=${label}`;
            window.open(newUrl, "_blank");
        },
      };


    return (
        <div>
            <div className="modal fade" id="modalIcoin" tabIndex="-1" role="dialog" aria-labelledby="modalCompareLabel" aria-hidden="true">
                <div className="modal-dialog" role="document" style={{ maxWidth: '95%' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="resultModalLabel">Biểu đồ lượng icoin</h5>
                            <button type="button" className="close" data-dismiss="modal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group d-flex" style={{ gap: '10px' }}>
                                <div style={{ flex: 1 }}>
                                    <label>From Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="fromDate"
                                        value={fromDate}
                                        onChange={e => setFromDate(e.target.value)}
                                    />
                                </div>
                                <div style={{ flex: 1 }}>
                                    <label>To Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="toDate"
                                        value={toDate}
                                        onChange={e => setToDate(e.target.value)}
                                    />
                                </div>
                            </div>

                            <button className="btn btn-primary" onClick={handleSubmitIcoin}>
                                Hiển thị
                            </button>
                            {isLoading ? (
                                <p>Loading...</p>
                            ) : chartData ? (
                                <div style={{ width: '100%', height: '360px' }}>
                                    <Line data={chartData} options={chartOptions} />
                                    
                                </div>
                            ) : (
                                <p>Không có dữ liệu</p>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-secondary ml-2 mr-2" data-dismiss="modal">
                                Hủy
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}