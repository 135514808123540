import React, { Component } from 'react';
import bindModel from '../../../common/bindModel.js';
import axios from 'axios';
import { toast } from 'react-toastify';
import SVGA from 'svgaplayerweb'
import $ from 'jquery';
import getImageSize from 'image-size-from-url';
import { Base64 } from 'js-base64';

import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';

export default class ModalUpdateSingEffect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            singEffect: {
                sourceType: '',
                name: '',
                expiry: 0,
                colorCode: '',
                resourceUrl: '',
                thumbnailUrl: '',
                price: 0,
                dateStart: new Date().toLocaleString("sv", { timeZone: "Asia/Ho_Chi_Minh" }).replace(" ", "T"),
                dateEnd: new Date().toLocaleString("sv", { timeZone: "Asia/Ho_Chi_Minh" }).replace(" ", "T"),
                order: 0
            }, loading: false
        }
        this.saveSingEffect = this.saveSingEffect.bind(this);
        this.uploadAnimationToS4 = this.uploadAnimationToS4.bind(this);
        this.uploadAnimationToS5 = this.uploadAnimationToS5.bind(this);
    }

    componentWillReceiveProps(props) {
        let { singEffect } = props;
        if (singEffect != null) {
            singEffect.dateStart = new Date(singEffect.dateStart).toLocaleString("sv", { timeZone: "Asia/Ho_Chi_Minh" }).replace(" ", "T");
            singEffect.dateEnd = new Date(singEffect.dateEnd).toLocaleString("sv", { timeZone: "Asia/Ho_Chi_Minh" }).replace(" ", "T");
            if (singEffect._id == null) {
                this.setState({
                    singEffect: singEffect,
                    register: true
                });
                $("#show-animation_resourceUrl").children().replaceWith("<img style=\"height: 0px !important\"  />");
                $("#show-animation_thumbnailUrl").children().replaceWith("<img style=\"height: 0px !important\"  />");

                $("#show-animation").children().replaceWith("<img style=\"height: 0px !important\"  />");
            } else {
                this.setState({
                    singEffect: singEffect,
                    register: false
                });

                if (singEffect.resourceUrl != null && singEffect.resourceUrl.endsWith("svga")) {
                    $("#show-animation_resourceUrl").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas4\"></div>");
                    this.loadSVGA4(singEffect.resourceUrl,"demoCanvas4");
                } else if (singEffect.resourceUrl != null) {
                    $("#show-animation_resourceUrl").children().replaceWith("<img style=\"height: 300px !important\" src=\"" + singEffect.resourceUrl + "\"  />");
                }
                
                if (singEffect.thumbnailUrl != null && singEffect.thumbnailUrl.endsWith("svga")) {
                    $("#show-animation_thumbnailUrl").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas5\"></div>");
                    this.loadSVGA4(singEffect.thumbnailUrl,"demoCanvas5");
                } else if (singEffect.thumbnailUrl != null) {
                    $("#show-animation_thumbnailUrl").children().replaceWith("<img style=\"height: 300px !important\" src=\"" + singEffect.thumbnailUrl + "\"  />");
                }

                if (singEffect.previewImg != null && singEffect.previewImg.endsWith("svga")) {
                    $("#show-animation").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas2\"></div>");
                    this.loadSVGA2(singEffect.previewImg,"demoCanvas2");
                } else if (singEffect.previewImg != null) {
                    $("#show-animation").children().replaceWith("<img style=\"height: 300px !important\" src=\"" + singEffect.previewImg + "\"  />");
                }
            }
        }
    }

    loadSVGA4(url,id) {
        var player = new SVGA.Player(`#${id}`);
        var parser = new SVGA.Parser(`#${id}`);
        parser.load(url, function (videoItem) {
            console.log(videoItem)
            player.setVideoItem(videoItem);
            player.startAnimation();
        })
    }
    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    uploadAnimationToS4(event) {
        var target = event.target;
        //10495760
        if (target.files[0].size > 1049576) {
            toast.error("Kích thước file phải dưới 1mb.");
            return;
        }

        this.setState({
            loading: true
        });


        var singEffect = this.state.singEffect;
        var bucketName = "ikara-data/images/singEffects";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";

        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data);
                    this.setState({
                        loading: false
                    });

                    singEffect.resourceUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.state.singEffect.resourceUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        singEffect: singEffect
                    });


                    if (singEffect.resourceUrl != null && singEffect.resourceUrl.endsWith("svga")) {
                        $("#show-animation_resourceUrl").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas4\"></div>");
                        this.loadSVGA4(singEffect.resourceUrl,"demoCanvas4");
                    } else if (singEffect.resourceUrl != null) {
                        $("#show-animation_resourceUrl").children().replaceWith("<img style=\"height: 300px !important\" src=\"" + singEffect.resourceUrl + "\"  />");
                    }
                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);

    }
    uploadAnimationToS5(event) {
        var target = event.target;
        //10495760
        if (target.files[0].size > 1049576) {
            toast.error("Kích thước file phải dưới 1mb.");
            return;
        }

        this.setState({
            loading: true
        });


        var singEffect = this.state.singEffect;
        var bucketName = "ikara-data/images/singEffects";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";

        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data);
                    this.setState({
                        loading: false
                    });

                    singEffect.thumbnailUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.state.singEffect.thumbnailUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        singEffect: singEffect
                    });


                    if (singEffect.thumbnailUrl != null && singEffect.thumbnailUrl.endsWith("svga")) {
                        $("#show-animation_thumbnailUrl").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas5\"></div>");
                        this.loadSVGA4(singEffect.thumbnailUrl,"demoCanvas5");
                    } else if (singEffect.thumbnailUrl != null) {
                        $("#show-animation_thumbnailUrl").children().replaceWith("<img style=\"height: 300px !important\" src=\"" + singEffect.thumbnailUrl + "\"  />");
                    }
                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);

    }

    saveSingEffect() {
        let singEffect = { ...this.state.singEffect };
        if (singEffect == null ||
            singEffect.sourceType == null ||
            singEffect.name == null ||
            singEffect.expiry == null ||
            singEffect.resourceUrl == null ||
            singEffect.price == null ||
            singEffect.dateStart == null ||
            singEffect.dateEnd == null ||
            singEffect.order == null
        ) {
            toast.error("Vui lòng điền đầy đủ thông tin.");
            return;
        }

        singEffect.dateStart = Date.parse(singEffect.dateStart);
        singEffect.dateEnd = Date.parse(singEffect.dateEnd);

        if (singEffect.vipRequire == null) singEffect.vipRequire = 0;
        if (singEffect.lvRequire == null) singEffect.lvRequire = 0;
        singEffect.autoRenew = true;
        singEffect.vipDiscount = 0.9;
        singEffect.type = "SingEffect";
        this.setState({
            loading: true
        });

        axios.post(global.config.apiDomain + '/rest/shop-items/createOrUpdateShopItem', singEffect)
            .then(res => {
                this.setState({
                    loading: false
                });

                if (res.data.status == "OK") {
                    window.$("#modalUpdateSingEffect").modal("hide");
                    this.props.resetList(true);
                    toast.success(res.data.message);
                } else {
                    toast.warning(res.data.message);
                }
            }, error => {
                this.setState({
                    loading: false
                });
                toast.error(error);
            })
    }

    render() {
        const model = bindModel(this);
        const { singEffect } = this.state;

        return (
            <div>
                <div className="modal fade" id="modalUpdateSingEffect" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật Item Shop</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {true ? (
                                <div className="modal-body">
                                    <div id="order" className="row mb-2">
                                        <div className="col-3">
                                            <label>Độ ưu tiên hiển thị</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                type="number"
                                                name="order"
                                                placeholder="Ưu tiên hiển thị thứ tự lớn hơn"
                                                {...model('singEffect.order')} />
                                        </div>
                                    </div>
                                    <div id="sourceType" className="row mb-2">
                                        <div className="col-3">
                                            <label>Thể loại Vật phẩm</label>
                                        </div>
                                        <div className="col-9">
                                            <select
                                                className="form-control"
                                                type="text"
                                                name="sourceType"
                                                {...model('singEffect.sourceType')} >
                                                <option value="">Chọn</option>
                                                <option value="NORMAL">Bán trong Cửa hàng</option>
                                                <option value="SYSTEM">Hệ thống</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div id="_id" className="row mb-2">
                                        <div className="col-3">
                                            <label>ID Vật phẩm</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                type="text"
                                                name="platform"
                                                readOnly={true}
                                                placeholder="ID sẽ được tạo tự động"
                                                {...model('singEffect._id')} />
                                        </div>
                                    </div>
                                    <div id="name" className="row mb-2">
                                        <div className="col-3">
                                            <label>Tên vật phẩm</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                type="text"
                                                name="platform"
                                                placeholder="Nhập tên VP"
                                                {...model('singEffect.name')} />
                                        </div>
                                    </div>
                                    {/* <div id="description" className="row mb-2">
                                        <div className="col-3">
                                            <label>Mô tả vật phẩm</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                type="text"
                                                name="title"
                                                placeholder="Nhập mô tả của vật phẩm"
                                                {...model('singEffect.description')} />
                                        </div>
                                    </div> */}
                                    <div id="expiry" className="row mb-2">
                                        <div className="col-3">
                                            <label>Hạn sử dụng</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="singEffect.buyPrice"
                                                defaultValue='7'
                                                placeholder="Nhập số ngày hết hạn"
                                                {...model('singEffect.expiry')} />
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-3">
                                        </div>
                                        <div id="show-animation" className="col-9" style={{ maxHeight: "300px" }}>
                                            <div width={500} height={500} id="canvas"></div>
                                        </div>
                                    </div>
                                    <div id="resourceUrl" className="row mb-2">
                                        <div className="col-3">
                                            <label>Hình hiển thị</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id="inputGroupFile04"
                                                onChange={this.uploadAnimationToS4}
                                            />
                                            <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile04">Choose file</label>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-3">
                                        </div>
                                        <div id="show-animation_resourceUrl" className="col-9" style={{ maxHeight: "300px" }}>
                                            <div width={500} height={500} id="canvas"></div>
                                        </div>
                                    </div>
                                    <div id="thumbnailUrl" className="row mb-2">
                                        <div className="col-3">
                                            <label>Hình mô tả</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id="inputGroupFile05"
                                                onChange={this.uploadAnimationToS5}
                                            />
                                            <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile05">Choose file</label>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-3">
                                        </div>
                                        <div id="show-animation_thumbnailUrl" className="col-9" style={{ maxHeight: "300px" }}>
                                            <div width={500} height={500} id="canvas"></div>
                                        </div>
                                    </div>
                                    <div id="price" className="row mb-2">
                                        <div className="col-3">
                                            <label>Giá vật phẩm</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                type="number"
                                                name="title"
                                                placeholder="Nhập giá của vật phẩm"
                                                {...model('singEffect.price')} />
                                        </div>
                                    </div>
                                    <div id="dateStart" className="row mb-2">
                                        <div className="col-3">
                                            <label>Ngày mở bán</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control"
                                                type="date"
                                                name="singEffect.dateStart"
                                                placeholder="Chọn ngày mở bán..."
                                                {...model('singEffect.dateStart')} />
                                            {/* <DateTimePickerComponent
                                                change={this.updateStartDate}
                                                value={singEffect ? new Date(singEffect.dateStart) : new Date()}
                                            >
                                            </DateTimePickerComponent> */}
                                        </div>
                                    </div>
                                    <div id="dateEnd" className="row mb-2">
                                    <div className="col-3">
                                    <label>Ngày kết thúc</label>
                                </div>
                                <div className="col-9">
                                    <select
                                        className="form-control"
                                        name="singEffect.dateEnd"
                                        onChange={(e) => {
                                            const selectedDate = e.target.value;
                                            console.log("Selected date:", selectedDate);
                                            let dateEndValue;
                                            switch (selectedDate) {
                                                case "infinity":
                                                    dateEndValue = "9999-12-31";
                                                    break;
                                                default:
                                                    dateEndValue = selectedDate;
                                                    break;
                                            }
                                            this.setState(prevState => ({
                                                singEffect: {
                                                    ...prevState.singEffect,
                                                    dateEnd: dateEndValue
                                                }
                                            }));
                                        }}
                                        value={singEffect.dateEnd === "9999-12-31" ? "9999-12-31" : ""}>
                                        <option value="">Chọn ngày kết thúc...</option>
                                        <option value="9999-12-31">Vô hạn</option>
                                    </select>

                                    {singEffect.dateEnd === "9999-12-31" ? (
                                        <p>Vô hạn</p>
                                    ) : (
                                        <input
                                            className="form-control mt-2"
                                            type="date"
                                            value={singEffect.dateEnd}
                                            onChange={(e) => {
                                                const selectedDate = new Date(e.target.value);
                                                console.log("Selected specific date:", selectedDate.toISOString().slice(0, 10));
                                                this.setState(prevState => ({
                                                    singEffect: {
                                                        ...prevState.singEffect,
                                                        dateEnd: selectedDate.toISOString().slice(0, 10)
                                                    }
                                                }));
                                            }}
                                        />
                                    )}
                                </div>
                                </div>
                                    <div id="lvRequire" className="row mb-2">
                                        <div className="col-3">
                                            <label>LV yêu cầu</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                type="number"
                                                name="title"
                                                placeholder="Nhập lv có thể mua"
                                                {...model('singEffect.lvRequire')} />
                                        </div>
                                    </div>
                                    <div id="vipRequire" className="row mb-2">
                                        <div className="col-3">
                                            <label>Vip yêu cầu</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                type="number"
                                                name="title"
                                                placeholder="Nhập cấp vip được mua"
                                                {...model('singEffect.vipRequire')} />
                                        </div>
                                    </div>
                                    <div id="idAllowed" className="row mb-2">
                                        <div className="col-3">
                                            <label>ID được hiển thị</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                type="text"
                                                name="title"
                                                placeholder="Nhập id được hiển thị"
                                                {...model('singEffect.idAllowed')} />
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-3">
                                            <label>Mô tả</label>
                                        </div>
                                        <div className="col-9">
                                            <textarea rows="4"
                                                className="form-control mb-2"
                                                placeholder="Nhập mô tả"
                                                {...model('gift.description')} />
                                        </div>
                                    </div>
                                </div>
                            ) : ''}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal" >Hủy</button>
                                {this.state.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.saveSingEffect}>Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}