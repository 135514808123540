import React, { Component } from 'react';
import bindModel from '../../../common/bindModel.js';
import axios from 'axios';
import { toast } from 'react-toastify';
import SVGA from 'svgaplayerweb'
import $ from 'jquery';

export default class ModalCreateOrUpdateDecorationItemType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            decorationItem: {
                name: '',
                expiry: 0,
                colorCode: '',
                // colorCode2 : '',
                // colorCode3 : '',
                // colorCode4 : '',
                // colorCode5 : '',
                // topRightUrl : '',
                // bottomRightUrl : '',
                // bottomLeftUrl : '',
                // topLeftUrl : '',
                resourceUrl: "",
                type: "",
                thumbnailUrl: "",
                id: 0,
            },
            loading: false
        }
        this.saveDecorationItemType = this.saveDecorationItemType.bind(this);
        this.uploadAnimationToS4 = this.uploadAnimationToS4.bind(this);
        this.uploadAnimationToS3 = this.uploadAnimationToS3.bind(this);
        this.uploadAnimationToS2 = this.uploadAnimationToS2.bind(this);
        this.uploadFileJson = this.uploadFileJson.bind(this);
    }

    componentWillReceiveProps(props) {

        const { decorationItemType } = props;

        if (decorationItemType != null) {
            this.setState({
                decorationItem: decorationItemType
            })
        }
    }


    uploadFileJson(event) {
        var target = event.target;
        const namejson = target.files[0].name;
        const targetFIle = target.files[0];
        const fileReader1 = new FileReader();
        fileReader1.readAsDataURL(target.files[0]);

        var decorationItemType = this.state.decorationItem;

        fileReader1.onload = (fe) => {
            //   const image = new JSON();
            //   image.src = fe.currentTarget.result;
            //   image.onload = function(ie){

            this.setState({
                loading: true
            });

            var iconUrl = "";
            var frameUrl = "";
            var bucketName = "ikara-data/images/iconAnimationInApp";
            // var extension = target.files[0].name.split('.').pop().toLowerCase();
            var extension = namejson.split('.').pop().toLowerCase();
            var keyName = this.guid() + "." + extension;
            var contentType = "application/json";
            // if (extension == "png") contentType = "image/png";

            var fileReader = new FileReader();

            fileReader.onload = function () {
                var fd = new FormData();
                fd.append('file', targetFIle);
                fd.append('bucketName', bucketName);
                fd.append('objectKey', keyName);
                fd.append('contentType', contentType)
                $.ajax({
                    url: global.config.apiDomain + '/web.UploadFile',
                    data: fd,
                    processData: false,
                    contentType: false,
                    type: 'POST',
                    success: async function (data) {
                        console.log(data)
                        this.setState({
                            loading: false
                        });

                        iconUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                        decorationItemType.resourceUrl = iconUrl;
                        this.state.decorationItem.resourceUrl = iconUrl;

                        this.setState({
                            decorationItemType: decorationItemType
                        })

                        $("#sweetHeartUrlJson").html(`<a href=${iconUrl} target="blank">${iconUrl}</a>`)

                        // if(target.id == 'inputFileIconAfterClick6') {
                        //     this.setState({
                        //         inputFileIconAfterClick6: iconUrl
                        //     });
                        // }
                    }.bind(this),
                    error: function (request, status, error) {
                        toast.error("Đang xảy ra lỗi, xin hãy thử lại nha!");
                    }.bind(this),
                });

            }.bind(this);
            fileReader.readAsArrayBuffer(targetFIle);
            // }.bind(this);
        }
    }

    componentWillUnmount() {
        const { decorationItem } = this.state;
        if (decorationItem != null) {
            this.setState({
                decorationItem: {
                    name: '',
                    expiry: 0,
                    colorCode: '',
                    resourceUrl: '',
                    type: "",
                    thumbnailUrl: "",
                    id: "",
                },
                loading: false
            })
            if ($("#show-animation_resourceUrl") != null && $("#show-animation_resourceUrl").children() != null) {
                $("#show-animation_resourceUrl").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas4\"></div>");
            }
            if ($("#show-animation_thumbnailUrl") != null && $("#show-animation_thumbnailUrl").children() != null) {
                $("#show-animation_thumbnailUrl").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas4\"></div>");
            }
        }
    }


    loadSVGA4(url) {
        var player = new SVGA.Player('#demoCanvas4');
        var parser = new SVGA.Parser('#demoCanvas4');
        parser.load(url, function (videoItem) {
            console.log(videoItem)
            player.setVideoItem(videoItem);
            player.startAnimation();
        })
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    uploadAnimationToS4(event) {
        var target = event.target;
        let id = event.target.id;
        let showId = `#show-animation_${id}Url`;
        console.log(showId)
        //10495760
        if (target.files[0].size > 1049576) {
            toast.error("Kích thước file phải dưới 1mb.");
            return;
        }

        this.setState({
            loading: true
        });

        console.log(this.state.decorationItem)


        var decorationItemType = this.state.decorationItem;
        var bucketName = "ikara-data/images/decorationItemType";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";

        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data);
                    this.setState({
                        loading: false
                    });
                    console.log("$(`${showId}`)", $(`${showId}`))

                    switch (id) {
                        case "topRight":
                            decorationItemType.topRightUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                            this.state.decorationItem.topRightUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                            this.setState({
                                decorationItemType: decorationItemType
                            });

                            console.log(decorationItemType.topRightUrl)


                            if (decorationItemType.topRightUrl != null && decorationItemType.topRightUrl.endsWith("svga")) {
                                $("#show-animation_topRightUrl").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas4\"></div>");
                                this.loadSVGA4(decorationItemType.topRightUrl);
                            } else if (decorationItemType.topRightUrl != null) {
                                $(`${showId}`).children().replaceWith("<img style=\"height: 300px !important\" src=\"" + decorationItemType.topRightUrl + "\"  />");
                            }
                            break;
                        case "topLeft":
                            decorationItemType.topLeftUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                            this.state.decorationItem.topLeftUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                            this.setState({
                                decorationItemType: decorationItemType
                            });
                            console.log(decorationItemType.topLeftUrl)

                            if (decorationItemType.topLeftUrl != null && decorationItemType.topLeftUrl.endsWith("svga")) {
                                $("#show-animation_topLeftUrl").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas4\"></div>");
                                this.loadSVGA4(decorationItemType.topLeftUrl);
                            } else if (decorationItemType.topLeftUrl != null) {
                                $("#show-animation_topLeftUrl").children().replaceWith("<img style=\"height: 300px !important\" src=\"" + decorationItemType.topLeftUrl + "\"  />");
                            }
                            break;
                        case "bottomRight":
                            decorationItemType.bottomRightUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                            this.state.decorationItem.bottomRightUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                            this.setState({
                                decorationItemType: decorationItemType
                            });

                            console.log(decorationItemType.bottomRightUrl)
                            if (decorationItemType.bottomRightUrl != null && decorationItemType.bottomRightUrl.endsWith("svga")) {
                                $("#show-animation_bottomRightUrl").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas4\"></div>");
                                this.loadSVGA4(decorationItemType.bottomRightUrl);
                            } else if (decorationItemType.bottomRightUrl != null) {
                                $("#show-animation_bottomRightUrl").children().replaceWith("<img style=\"height: 300px !important\" src=\"" + decorationItemType.bottomRightUrl + "\"  />");
                            }
                            break;
                        case "bottomLeft":
                            decorationItemType.bottomLeftUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                            this.state.decorationItem.bottomLeftUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                            this.setState({
                                decorationItemType: decorationItemType
                            });
                            console.log(decorationItemType.bottomLeftUrl)

                            if (decorationItemType.bottomLeftUrl != null && decorationItemType.bottomLeftUrl.endsWith("svga")) {
                                $("#show-animation_bottomLeftUrl").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas4\"></div>");
                                this.loadSVGA4(decorationItemType.bottomLeftUrl);
                            } else if (decorationItemType.bottomLeftUrl != null) {
                                $("#show-animation_bottomLeftUrl").children().replaceWith("<img style=\"height: 300px !important\" src=\"" + decorationItemType.bottomLeftUrl + "\"  />");
                            }
                            break;

                        default:
                            console.log("default")
                            break;
                    }

                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);

    }

    uploadAnimationToS3(event) {
        var target = event.target;
        //10495760
        if (target.files[0].size > 1049576) {
            toast.error("Kích thước file phải dưới 1mb.");
            return;
        }

        this.setState({
            loading: true
        });

        var decorationItemType = this.state.decorationItem;
        var bucketName = "ikara-data/images/decorationItemType";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";

        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    this.setState({
                        loading: false
                    });


                    decorationItemType.thumbnailUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.state.decorationItem.thumbnailUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        decorationItemType: decorationItemType
                    });

                    console.log("decorationItemType.thumbnailUrl", decorationItemType.thumbnailUrl)

                    if (decorationItemType.thumbnailUrl != null && decorationItemType.thumbnailUrl.endsWith("svga")) {
                        $("#show-animation_thumbnailUrl").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas4\"></div>");
                        this.loadSVGA4(decorationItemType.thumbnailUrl);
                    } else if (decorationItemType.thumbnailUrl != null) {
                        $("#show-animation_thumbnailUrl").children().replaceWith("<img style=\"height: 300px !important\" src=\"" + decorationItemType.thumbnailUrl + "\"  />");
                    }
                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);

    }

    uploadAnimationToS2(event) {
        var target = event.target;
        //10495760
        if (target.files[0].size > 1049576) {
            toast.error("Kích thước file phải dưới 1mb.");
            return;
        }

        if (target.files[0] != null && target.files[0].name != null) {
            let doThis = target.files[0].name.endsWith(".json");
            if (doThis) {
                this.uploadFileJson(event)
                return
            }
        }

        this.setState({
            loading: true
        });

        var decorationItemType = this.state.decorationItem;
        var bucketName = "ikara-data/images/decorationItemType";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";

        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    this.setState({
                        loading: false
                    });


                    decorationItemType.resourceUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.state.decorationItem.resourceUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        decorationItemType: decorationItemType
                    });

                    console.log("decorationItemType.resourceUrl", decorationItemType.resourceUrl)

                    if (decorationItemType.resourceUrl != null && decorationItemType.resourceUrl.endsWith("svga")) {
                        $("#show-animation_resourceUrl").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas4\"></div>");
                        this.loadSVGA4(decorationItemType.resourceUrl);
                    } else if (decorationItemType.resourceUrl != null) {
                        $("#show-animation_resourceUrl").children().replaceWith("<img style=\"height: 300px !important\" src=\"" + decorationItemType.resourceUrl + "\"  />");
                    }
                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);

    }

    saveDecorationItemType() {
        const { decorationItem } = this.state;
        console.log(decorationItem)

        if (decorationItem.type == "" || decorationItem.type == null) {
            toast.warning("Chưa chọn loại trang trí");
            return;
        }
        if (decorationItem.name == "" || decorationItem.name == null) {
            toast.warning("Chưa nhập tên trang trí");
            return;
        }
        if (decorationItem.expiry == 0 || decorationItem.name == null) {
            toast.warning("Thời gian hết hạn phải lớn hơn 0");
            return;
        }

        if (decorationItem.type == "MEDAL" && decorationItem.thumbnailUrl == null || decorationItem.type == "MEDAL" && decorationItem.thumbnailUrl == "") {
            toast.warning("Huy hiệu phải có hình ảnh mô tả");
            return;
        }
        if (decorationItem.type == "MEDAL" && decorationItem.colorCode == "" || decorationItem.type == "MEDAL" && decorationItem.colorCode == null) {
            toast.warning("Huy hiệu phải có mã màu chữ");
            return;
        }
        if (decorationItem.type == "MEDAL" && decorationItem.colorCode != "" && decorationItem.type == "MEDAL" && decorationItem.colorCode != null) {
            let checkColor = (/^#(?:[0-9a-fA-F]{3,4}){1,2}$/g.test(decorationItem.colorCode));
            if (!checkColor) {
                toast.warning("Mã màu không hợp lệ");
                return;
            }
        }
        if (decorationItem.type == "COUPLE" && decorationItem.thumbnailUrl == null || decorationItem.type == "MEDAL" && decorationItem.thumbnailUrl == "") {
            toast.warning("Huy hiệu phải có hình ảnh mô tả");
            return;
        }

        if (decorationItem.type == "MEDAL") {
            if (decorationItem.order == null || decorationItem.order == 0 || decorationItem.description == null || decorationItem.description == "") {
                toast.warning("Huy hiệu phải có độ ưu tiên và mô tả");
                return;
            }
        }
        // if(decorationItem.topLeftUrl == null || decorationItem.topLeftUrl == "" || 
        // decorationItem.topRightUrl == null || decorationItem.topRightUrl == "" || 
        // decorationItem.bottomRightUrl == null || decorationItem.bottomRightUrl == ""||
        // decorationItem.bottomLeftUrl == null || decorationItem.bottomLeftUrl == ""){
        //     if(decorationItem.type == "FRAME"){
        //         toast.warning("Khung phải có đầy đủ hình hiển thị ở 4 góc");  
        //     return;
        //     }
        // }

        let json = {
            name: decorationItem.name,
            colorCode: decorationItem.colorCode,
            order: decorationItem.order,
            description: decorationItem.description,
            // colorCode2: decorationItem.colorCode2,
            // colorCode3: decorationItem.colorCode3,
            // colorCode4: decorationItem.colorCode4,
            // colorCode5: decorationItem.colorCode5,
            // topLeftUrl: decorationItem.topLeftUrl,
            // topRightUrl: decorationItem.topRightUrl,
            // bottomLeftUrl: decorationItem.bottomLeftUrl,
            // bottomRightUrl: decorationItem.bottomRightUrl,
            resourceUrl: decorationItem.resourceUrl,
            expiry: decorationItem.expiry,
            thumbnailUrl: decorationItem.thumbnailUrl,
            type: decorationItem.type,
            id: decorationItem.id,
            addTime: null
        }

        let url = global.config.apiDomain + "/rest/decorationItemsType/createOrUpdate";

        axios.post(url, json)
            .then(res => {
                if (res.data.status == "OK") {
                    window.$("#modalCreateOrUpdateDecorationItemType").modal("hide");
                    toast.success(res.data.message);
                    this.setState({
                        decorationItem: {
                            name: '',
                            expiry: 0,
                            colorCode: '',
                            order: 0,
                            description: '',
                            // colorCode2 : '',
                            // colorCode3 : '',
                            // colorCode4 : '',
                            // colorCode5 : '',
                            // topRightUrl : '',
                            // bottomRightUrl : '',
                            // bottomLeftUrl : '',
                            // topLeftUrl : '',
                            resourceUrl: "",
                            type: "",
                            thumbnailUrl: "",
                            id: 0,
                        }
                    })
                } else {
                    toast.warning(res.data.message);
                }
            })
    }

    render() {
        const model = bindModel(this);
        const { decorationItem } = this.state;

        return (
            <div>
                <div className="modal fade" id="modalCreateOrUpdateDecorationItemType" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật Trang trí bài thu</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {true ? (
                                <div className="modal-body">
                                    <div id="order" className="row mb-2">
                                        <div className="col-3">
                                            <label>ID</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                type="number"
                                                name="order"
                                                disabled={true}
                                                placeholder="ID được tự động tạo"
                                                {...model('decorationItem.id')} />
                                        </div>
                                    </div>
                                    <div id="sourceType" className="row mb-2">
                                        <div className="col-3">
                                            <label>Thể loại Vật phẩm</label>
                                        </div>
                                        <div className="col-9">
                                            <select
                                                className="form-control"
                                                type="text"
                                                name="sourceType"
                                                {...model('decorationItem.type')} >
                                                <option value="">Chọn</option>
                                                <option value="FRAME">Khung bài thu</option>
                                                {/* <option value="MEDAL">Huy hiệu bài thu</option> */}
                                                <option value="COUPLE">Cặp đôi</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div id="name" className="row mb-2">
                                        <div className="col-3">
                                            <label>Tên vật phẩm</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                type="text"
                                                name="platform"
                                                placeholder="Nhập tên VP"
                                                {...model('decorationItem.name')} />
                                        </div>
                                    </div>
                                    <div id="order" className="row mb-2">
                                        <div className="col-3">
                                            <label>Độ ưu tiên hiển thị</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="decorationItem.order"
                                                defaultValue='7'
                                                placeholder="Nhập số ngày hết hạn"
                                                {...model('decorationItem.order')} />
                                        </div>
                                    </div>
                                    <div id="expiry" className="row mb-2">
                                        <div className="col-3">
                                            <label>Hạn sử dụng</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control"
                                                type="number"
                                                name="decorationItem.buyPrice"
                                                defaultValue='7'
                                                placeholder="Nhập số ngày hết hạn"
                                                {...model('decorationItem.expiry')} />
                                        </div>
                                    </div>
                                    <div id="resourceUrl" className="row mb-2">
                                        <div className="col-3">
                                            <label>Hình vật phẩm</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id="inputGroupFile02"
                                                onChange={this.uploadAnimationToS2}
                                            />
                                            <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile02">Choose file</label>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-3">
                                        </div>
                                        <div className="col-9" id="sweetHeartUrlJson">
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-3">
                                        </div>
                                        <div id="show-animation_resourceUrl" className="col-9" style={{ maxHeight: "300px" }}>
                                            <div width={500} height={500} id="canvas"></div>
                                        </div>
                                    </div>
                                    <div id="thumbnailUrl" className="row mb-2">
                                        <div className="col-3">
                                            <label>Hình mô tả</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id="inputGroupFile04"
                                                onChange={this.uploadAnimationToS3}
                                            />
                                            <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile04">Choose file</label>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-3">
                                        </div>
                                        <div id="show-animation_thumbnailUrl" className="col-9" style={{ maxHeight: "300px" }}>
                                            <div width={500} height={500} id="canvas"></div>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-3">
                                            <label>Mô tả</label>
                                        </div>
                                        <div className="col-9">
                                            <textarea rows="4"
                                                className="form-control mb-2"
                                                placeholder="Nhập mô tả"
                                                {...model('decorationItem.description')} />
                                        </div>
                                    </div>
                                </div>
                            ) : ''}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal" >Hủy</button>
                                {this.state.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.saveDecorationItemType}>Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}