import React, { Component } from 'react';
import bindModel from '../../../common/bindModel.js';
import axios from 'axios';
import { toast } from 'react-toastify';
import SVGA from 'svgaplayerweb'
import $ from 'jquery';

export default class ModalUpdateSurvey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            surveyModal: null,
            screens: []
        }
        this.getAllScreen = this.getAllScreen.bind(this);
        this.createOrUpdateSurvey = this.createOrUpdateSurvey.bind(this);
        this.addAnswer = this.addAnswer.bind(this);
    }

    getAllScreen() {
        let url = global.config.apiDomain + "/rest/screens/getAllScreen"
        axios.post(url)
            .then(res => {
                this.setState({
                    screens: res.data.screenModels
                })
            })
    }

    addAnswer() {
        $("#answers").append(`
            <div class="row">
                <div class='col-10'>
                    <input
                        class="form-control mb-2 inputChoice"
                        type="text"
                        name="order"
                        placeholder="Nhập câu trả lời"
                    />
                </div>
                <div class='col-2'>
                    <button class='btn btn-danger btnDelete' 
                        onclick="this.parentElement.parentElement.innerHTML = ''"}>Xóa
                    </button>
                </div>
            </div>
        `)
    }

    createOrUpdateSurvey() {
        const { surveyModal } = this.state;
        let allChoices = $(".inputChoice");
        let arr = [];
        allChoices && allChoices.map((index, element) => {
            if (element.value) arr.push(element.value)
        })
        surveyModal.answers = arr

        let obj = surveyModal;
        if (!obj.question) {
            toast.warning("Vui lòng nhập câu hỏi")
            return
        }
        if (!obj.screenId) {
            toast.warning("Vui lòng chọn màn hình khảo sát")
            return
        }
        if (!obj.appearNextScreen) {
            toast.warning("Vui lòng chọn màn hình xuất hiện")
            return
        }
        if (!obj.showTime) {
            toast.warning("Vui lòng nhập thời gian hiển thị")
            return
        }
        if (!obj.endTime) {
            toast.warning("Vui lòng nhập thời gian kết thúc")
            return
        }
        obj.type = "VOTE";
        if (obj.type == "VOTE" && surveyModal.answers && surveyModal.answers.length > 0) {
            toast.warning("Khảo sát đánh giá không cần nhập câu hỏi")
            return
        }
        obj.showTime = Date.parse(obj.showTime);
        obj.endTime = Date.parse(obj.endTime);
        if (obj.appearNextScreen == 'true') {
            obj.appearNextScreen = true;
        } else {
            obj.appearNextScreen = false;
        }

        let url = global.config.apiDomain + "/rest/survey/createOrUpdateSurveyNPS"
        axios.post(url, obj)
            .then(res => {
                if (res.data.status == "OK") {
                    this.props.resetList();
                    toast.success("Xử lý thành công")
                    window.$("#modalUpdateSurvey").modal("hide");
                } else {
                    toast.error(res.data.message)
                }
            })
    }

    componentWillMount() {
        this.getAllScreen();
    }

    componentWillReceiveProps(props) {
        let surveyModal = props.surveyModal;
        this.setState({
            surveyModal: surveyModal,
        })

        $('#answers').html('');
        surveyModal && surveyModal.answers && surveyModal.answers.map((element, index) => {
            $("#answers").append(`
                <div class="row">
                    <div class='col-10'>
                        <input
                            class="form-control mb-2 inputChoice"
                            type="text"
                            placeholder="Lựa chọn"
                            value="${element}"
                        />
                    </div>
                    <div class='col-2'>
                        <button class='btn btn-danger btnDelete' 
                            onclick="this.parentElement.parentElement.innerHTML = ''"}
                        >Xóa</button>
                    </div>
                </div>
            `)
        })
    }

    render() {
        const model = bindModel(this);
        const { surveyModal, screens } = this.state;
        return (
            <div>
                <div className="modal fade" id="modalUpdateSurvey" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật ý kiến người dùng</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div id="id" className="row mb-2">
                                    <div className="col-3">
                                        <label>ID</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="order"
                                            readOnly
                                            {...model('surveyModal.id')} />
                                    </div>
                                </div>
                                <div id="question" className="row mb-2">
                                    <div className="col-3">
                                        <label>Câu hỏi khảo sát</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="question"
                                            placeholder="Câu hỏi khảo sát"
                                            {...model('surveyModal.question')} />
                                    </div>
                                </div>
                                <div id="screenId" className="row mb-2">
                                    <div className="col-3">
                                        <label>Màn hình Khảo sát</label>
                                    </div>
                                    <div className="col-9">
                                        <select
                                            className="form-control"
                                            type="text"
                                            name="screenId"
                                            {...model('surveyModal.screenId')}
                                        >
                                            <option value="">Chọn màn hình</option>
                                            {screens && screens.map((element, index) => {
                                                return element.deleted ? "" : <option key={index} value={element.id}>{element.screenName}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div id="question" className="row mb-2">
                                    <div className="col-3">
                                        <label>Màn hình Xuất hiện</label>
                                    </div>
                                    <div className="col-9">
                                        <select
                                            className="form-control"
                                            type="text"
                                            name="appearNextScreen"
                                            {...model('surveyModal.appearNextScreen')}
                                        >
                                            <option value="">Chọn màn hình</option>
                                            <option value="true">Màn hình TIẾP THEO</option>
                                            <option value="false">Màn hình HIỆN TẠI</option>
                                        </select>
                                    </div>
                                </div>
                                <div id="showTime" className="row mb-2">
                                    <div className="col-3">
                                        <label>Thời gian xuất hiện</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="datetime-local"
                                            name="showTime"
                                            {...model('surveyModal.showTime')}
                                        />
                                    </div>
                                </div>
                                <div id="endTime" className="row mb-2">
                                    <div className="col-3">
                                        <label>Thời gian kết thúc</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="datetime-local"
                                            name="endTime"
                                            {...model('surveyModal.endTime')}
                                        />
                                    </div>
                                </div>
                                <div id="levelRequire" className="row mb-2">
                                    <div className="col-3">
                                        <label>Level hiển thị</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="number"
                                            {...model('surveyModal.levelRequire')} />
                                    </div>
                                </div>
                                <div id="vipRequire" className="row mb-2">
                                    <div className="col-3">
                                        <label>Level VIP hiển thị</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="number"
                                            name="order"
                                            placeholder="Level VIP hiển thị"
                                            {...model('surveyModal.vipRequire')} />
                                    </div>
                                </div>
                                <div id="limitAnswers" className="row mb-2">
                                    <div className="col-3">
                                        <label>Giới hạn lượt trả lời</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="number"
                                            {...model('surveyModal.limitAnswers')} />
                                    </div>
                                </div>
                            </div>
                            <div className='modal-footer'>
                                <div id="cancel" className="row mb-2">
                                    <button className='btn btn-secondary ml-2 mr-2' data-dismiss="modal" aria-label="Close">Hủy</button>
                                    <button className='btn btn-success ml-2 mr-2' onClick={this.createOrUpdateSurvey}>Xác Nhận</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}