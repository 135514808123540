import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify'
import ModalTheVoiceUserExamine from './components/ModalTheVoiceUserExamine';

export default class TheVoiceExamnerPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            theVoiceExamine : [],
           examineModal : null,
           maxExaminerForOneMedia: ""
        }
        this.submit = this.submit.bind(this);
        this.getTheVoiceExamine = this.getTheVoiceExamine.bind(this);
        this.theVoiceUserExamine = this.theVoiceUserExamine.bind(this);

      }
    
      getTheVoiceExamine(theVoiceId) {
        axios.post(global.config.apiDomain + '/rest/theVoiceV2/getTheVoiceExamine/' + theVoiceId)
          .then(res => {
            console.log(res);
            if (res.data.status == "OK") {
              toast.success(res.data.message);
              this.setState({
                theVoiceExamine : res.data.theVoiceExamine,
                maxExaminerForOneMedia: res.data.maxExaminerForOneMedia,
              })
              
            } else {
              toast.error(res.data.message);
            }
    
          });
          
      }

      theVoiceUserExamine(event) {
        let index = event.target.name;
        let theVoiceUserExamine = this.state.theVoiceExamine[index];
        this.setState({
            examineModal: theVoiceUserExamine
        })
    }
      submit() {
        let theVoiceId = $('#theVoiceId').val().trim();
        if (!theVoiceId || !Number.isInteger(+theVoiceId)) {
          return toast.warning("theVoiceId phải là số");
        }
    
        this.getTheVoiceExamine(theVoiceId);
      }

      render() {
        const { theVoiceExamine, examineModal} = this.state;
        return (
            <>
                { <ModalTheVoiceUserExamine examineModal = {examineModal}></ModalTheVoiceUserExamine> }
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Thống kê lượt chấm của cuộc thi</h1>
                        <div className="d-flex justify-content-center mb-3">
                            <input
                                type="text"
                                className="form-control col-6"
                                placeholder="Nhập theVoiceId"
                                id="theVoiceId"
                            />
                            <button
                                style={{ width: "120px" }}
                                className="btn btn-red"
                                onClick={this.submit}
                            >Xác nhận</button>
                        
                       </div>
                        <div className="d-flex justify-content-end mb-3" style={{ marginRight:"80px"}}>
                        <p style={{ fontSize :"16px",margin: "6px",marginBottom:"0px"}}>Mỗi bài đc bao nhiêu giám khảo vào chấm :</p>
                           <input
                               type="text"
                                className="form-control col-1"
                                placeholder=""
                               value={this.state.maxExaminerForOneMedia} 
                               readOnly
                           />
                        </div>
                        <table className="table table-hover">
                            <thead>
                                <tr className="text-center">
                                    <th className="align-middle" scope="col">Tên Cuộc Thi</th>
                                    <th className="align-middle" scope="col">Tên User</th>
                                    <th className="align-middle" scope="col">Link bài thu</th>
                                    <th className="align-middle" scope="col">Số giám khảo</th>
                                    <th className="align-middle" scope="col">Kết quả</th>
                                </tr>
                            </thead>
                            <tbody>
                                {theVoiceExamine && theVoiceExamine.map((item, index) => {
                                    return (
                                        <>
                                            <tr>
                                                <td className="align-middle text-center" scope="col">{item.theVoiceName}</td>
                                                <td className="align-middle text-center" scope="col">{item.name}</td>  
                                                 <td className="align-middle text-center" scope="col">
                                                    <a href={item.onlineRecordingUrl}>{item.namesSong}</a>
                                                </td>                                                            
                                                <td className="align-middle text-center" scope="col">{item.examineNo}</td>
                                                <td className="align-middle text-center" scope="col">
                                                    <button className='btn btn-info'
                                                        data-toggle="modal"
                                                        data-target="#modalSurveyStatistics"
                                                        name={index}
                                                        onClick={this.theVoiceUserExamine}
                                                    >Giám Khảo</button>
                                                </td>
                                                
                                            </tr>
                                        </>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}
