import React, {Component} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';
import bindModel from '../../../common/bindModel.js';

const $ = window.$;

export default class ModalAddVip extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            expiredDate: new Date()
        }

        this.addVip = this.addVip.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.app == "IKARA") {
            if(nextProps.account.ikaraExpiredDate != null) {
                this.setState({
                    expiredDate : new Date(nextProps.account.ikaraExpiredDate)
                });
            } else {
                this.setState({
                    expiredDate : new Date()
                });
            }
        } else {
            if(nextProps.account.yokaraExpiredDate != null) {
                this.setState({
                    expiredDate : new Date(nextProps.account.yokaraExpiredDate)
                });
            } else {
                this.setState({
                    expiredDate : new Date()
                });
            }
        }
    }

    addVip(event) {
        if(event.key == 'Enter' || event.target.tagName == "BUTTON") {
            this.setState({
                loading: true
            });

            var json = {
                id: this.props.account.id,
                expiredDate: this.state.expiredDate,
                app: this.props.app
            }

            axios.post(global.config.apiDomain + '/rest/admin/addVip', JSON.stringify(json), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                this.setState({
                    loading: false
                });

                if(res.data.status == "OK") {
                    $("#modalAddVip").modal("hide");
                    toast.success(res.data.message);
                    this.props.resetAccount(); 
                } else {
                    toast.warning(res.data.message); 
                }
            }, error => {
                this.setState({
                    loading: false
                });
                toast.error("ERROR"); 
            })
        }
    }

    render() {
        const model = bindModel(this);
        const {id} = this.state;

        return (
            <div>
                <div className="modal fade" id="modalAddVip" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Add VIP</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <input
                                    onKeyUp={this.addVip} 
                                    onChange={(event) => {this.setState({expiredDate: event.target.value})}}
                                    type="date" 
                                    placeholder="Nhập icoin"
                                    className="form-control"
                                    value={new Date(this.state.expiredDate).toISOString().substr(0,10)}
                                    />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ? 
                                (
                                <button style={{width: "120px"}} className="btn btn-red" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Xác nhận...
                                </button>
                                ) :
                                (
                                <button 
                                    style={{width: "120px"}} 
                                    type="button" 
                                    className="btn btn-red"
                                    onClick={this.addVip}>Xác nhận</button>    
                                )}                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}