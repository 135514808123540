import React, { Component } from 'react';
import axios from 'axios';
import './../../assets/css/newDevice.css';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

export default class TopBeatPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            originalRecordings: [],
            recordings: [],
            page: 0,
            statusButtonLoadPage: false,
            isLoading: false,
            isInitialLoading: true,
            type: 2,
            years: '',
            selectedDate: '',
            error: null
        };

        this.getAccountList = this.getAccountList.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleYearsChange = this.handleYearsChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSelectDate = this.handleSelectDate.bind(this);
    }

    componentDidMount() {
        this.getAccountList();
    }

    getAccountList() {
        const { isInitialLoading, type, selectedDate, years } = this.state;

        if (!isInitialLoading) {
            this.setState({
                isLoading: true
            });
        }
        let apiUrl = global.config.apiDomain + `/rest/recording/list-monthly-view-counter/${this.state.page}?type=${type}&dateTime=${selectedDate}&year=${years}`;
        axios.post(apiUrl, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            let recordings = res.data.models;
            let status = true;
            if (recordings.length < 30) {
                status = false;
            }

            this.setState((prevState) => ({
                recordings: prevState.recordings.concat(recordings),
                page: prevState.page + 1,
                statusButtonLoadPage: status,
                isLoading: false,
                isInitialLoading: false,
                error: null
            }));
        })
        .catch(error => {
            this.setState({
                isLoading: false,
                isInitialLoading: false
            });
            console.error("Error:", error);
        });
    }

    handleTypeChange(event) {
        const selectedType = parseInt(event.target.value);
        this.setState({
            type: selectedType,
            recordings: [],
            page: 0,
            isInitialLoading: true,
            statusButtonLoadPage: false,
            years: '',
            selectedDate: '',
            error: null
        }, () => {
            this.getAccountList();
        });
    }

    handleYearsChange(event) {
        this.setState({ years: event.target.value, error: null });
    }

    handleSearch() {
        const { years } = this.state;
        if (!/^(0[1-9]|1[0-2])\/\d{4}$/.test(years)) {
            this.setState({ error: "Định dạng phải là MM/yyyy, ví dụ: 01/2023" });
            return;
        }
    
        this.setState({ page: 0, recordings: [], isInitialLoading: true, statusButtonLoadPage: false, error: null }, () => {
            this.getAccountList();
        });
    }

    handleDateChange(event) {
        const selectedDate = event.target.value;
        const today = new Date();
        const selectedDay = new Date(selectedDate).getDay();

        const twoDaysAgo = new Date(today);
        twoDaysAgo.setDate(today.getDate() - 2);
        if (this.state.type === 1) {
            if (selectedDay !== 0) {
                this.setState({ error: "Chỉ được chọn ngày Chủ nhật." });
                return;
            } else if (new Date(selectedDate) >= today) {
                this.setState({ error: "Chỉ được chọn ngày trước ngày hôm nay." });
                return;
            }
        }
        if (this.state.type === 0) {
            if (new Date(selectedDate) >= twoDaysAgo) {
                this.setState({ error: "Chỉ được chọn ngày trước 2 ngày so với ngày hôm nay." });
                return;
            }
        }
        this.setState({ selectedDate, error: null });
    }

    handleSelectDate() {
        const { selectedDate, error } = this.state;
        if (!error && selectedDate) {
            this.setState({ recordings: [], page: 0, isInitialLoading: true, statusButtonLoadPage: false, error: null }, () => {
                this.getAccountList();
            });
        }
    }

    render() {
        const { recordings, statusButtonLoadPage, isLoading, isInitialLoading, years, type, error, selectedDate} = this.state;

        const today = new Date();
        const twoDaysAgo = new Date(today);
        twoDaysAgo.setDate(today.getDate() - 2);

        const maxDateForTopNgay = twoDaysAgo.toISOString().split('T')[0];

        const sixDaysAgo = new Date(today);
        sixDaysAgo.setDate(today.getDate() - 6);
        const maxDateForTopNgay1 = sixDaysAgo.toISOString().split('T')[0];
    
        return (
            <>
                <div id="content">   
                    <div className="container-fluid">
                        <div className="container-fluid">
                            <h1 className="text-center">Top beat chờ Song ca</h1>
                        </div>
                        <div className="search-container">
                            <select onChange={this.handleTypeChange} style={{ width: '100px' }}>
                                <option value="2">Top Tháng</option>
                                <option value="1">Top Tuần</option>
                                <option value="0">Top Ngày</option>
                            </select>
                            {type === 2 ? (
                                <>
                                    <input
                                        style={{ width: '140px' }}
                                        type="text"
                                        value={years}
                                        onChange={this.handleYearsChange}
                                        placeholder="Nhập tháng/năm"
                                        pattern="(0[1-9]|1[0-2])\/[0-9]{4}"
                                        title="Định dạng phải là MM/yyyy, ví dụ: 01/2023"
                                        required
                                    />
                                    <button style={{ minWidth: '90px' }} onClick={this.handleSearch} className="btn btn-primary">Chọn Tháng</button>
                                </>
                            ) : (
                                <>
                                    <input
                                        style={{ width: '140px' }}
                                        type="date"
                                        value={selectedDate}
                                        onChange={this.handleDateChange}
                                        max={type === 0 ? maxDateForTopNgay : type === 1 ? maxDateForTopNgay1 : undefined}
                                    />
                                    <button style={{ minWidth: '90px' }} onClick={this.handleSelectDate}>Chọn Ngày</button>
                                    {error && <p style={{ color: 'red' }}>{error}</p>}
                                </>
                            )}
                        </div>
                        {isInitialLoading ? (
                            <div className="spinner-container">
                                <div className="spinner"></div>
                            </div>
                        ) : recordings.length > 0 ? (
                            <table className="table table-hover">
                                <thead>
                                    <tr className="text-center">
                                        <th className="align-middle" scope="col">Xếp hạng</th>
                                        <th className="align-middle" scope="col">Tên bài hát</th>
                                        <th className="align-middle" scope="col">Lượt view</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {recordings.map((item, index) => {
                                        return (
                                            <tr key={item.uid}>
                                                <td className="align-middle text-center" scope="col">{index + 1}</td>
                                                <td className="align-middle text-center" scope="col">{item.songName}</td>
                                                <td className="align-middle text-center" scope="col">{item.viewCounter}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        ) : (
                            <div className="text-center">
                                <p>Không có dữ liệu.</p>
                            </div>
                        )}
                        {
                            statusButtonLoadPage &&
                            (<div onClick={this.getAccountList} className="view_more w-100 text-center mb-lg-4">
                                <a className="d-none d-md-block">
                                    <span>{isLoading ? "ĐANG TẢI..." : "TẢI THÊM"}</span>
                                </a>
                            </div>)
                        }
                    </div>
                </div>
            </>
        );              
    }    
}
