import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import ModalUpdateBanner from './components/ModalUpdateBanner';
import ModalDeleteBanner from './components/ModalDeleteBanner';
export default class BannerSellMicPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bannersFull: [],
            banner: null,
        };

        this.getListBannersFull = this.getListBannersFull.bind(this);
        this.addNewbanner = this.addNewbanner.bind(this);
        this.updatebanner = this.updatebanner.bind(this);
        this.deleteOrActivebanner = this.deleteOrActivebanner.bind(this);

    }

    componentDidMount() {
        this.getListBannersFull();
    }

    getListBannersFull() {
        axios.post(global.config.apiDomain + '/rest/bannerSellMicRestful/getAllBannerSellMic')
            .then(res => {
                if (res.data.status === 'OK') {
                    this.setState({
                        bannersFull: res.data.bannerSellMicModel
                    })
                }
            })
    }

    addNewbanner() {
        let banner = {
            id: '',
            thumbnail: '',
            url: '',

            deleted: ''
        };

        this.setState({
            banner: banner,
        })
    }

    updatebanner(event) {
        let index = event.target.name;
        let bannersF = this.state.bannersFull[index];

        this.setState({
            banner: bannersF,
        })
    }

    deleteOrActivebanner(event) {
        let index = event.target.name;
        let bannerF = this.state.bannersFull[index];
        this.setState({
            banner: bannerF,
        })
    }

    render() {
        const { bannersFull, banner } = this.state;
        return (
            <>
                <ModalUpdateBanner
                    banner={banner}
                    resetList={this.getListBannersFull}
                ></ModalUpdateBanner>
                 <ModalDeleteBanner
                    banner={banner}
                    resetList={this.getListBannersFull}
                 ></ModalDeleteBanner>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí Banner bán hàng</h1>
                        <button
                            className="btn btn-red mb-1"
                            data-toggle="modal"
                            data-target="#modalUpdateBanner"
                            onClick={this.addNewbanner}
                        >+ Tạo Mới Banner
                        </button>
                        <table className="table table-hover">
                            <thead>
                                <tr className="">
                                    <th className="align-middle" scope="col">#</th>
                                    <th className="align-middle" scope="col">Thumbnail</th>
                                    <th className="align-middle" scope="col">URL</th>
                                    <th className="align-middle" scope="col">Độ ưu tiên </th>
                                   
                                    <th className="align-middle" scope="col">Trạng thái</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>                            {
                                bannersFull && bannersFull.map((item, index) => {
                                    return (
                                        <tr>
                                            <td className='align-center' style={{ verticalAlign: "top !important" }}>{index + 1}</td>
                                            <td className='align-center' style={{ wordWrap: "break-word", maxWidth: 250 }}><img width={250} src={item.thumbnail} /></td>
                                            <td className='align-center' style={{ wordWrap: "break-word", maxWidth: 250 }}>{item.url}</td>
                                            <td className='align-center' style={{ verticalAlign: "top !important" }}>{item.order}</td>
                                            <td className='align-center'>

                                                <button
                                                    className="btn btn-success"
                                                    onClick={this.updatebanner}
                                                    name={index}
                                                    data-toggle="modal"
                                                    data-target="#modalUpdateBanner"
                                                >
                                                    Sửa
                                                </button>&emsp;
                                                <button
                                                    className="btn btn-danger"
                                                    onClick={this.deleteOrActivebanner}
                                                    name={index}
                                                    data-toggle="modal"
                                                    data-target="#modalDeleteBanner"
                                                >
                                                    Xoá
                                                </button>

                                            </td>
                                        </tr>
                                    )
                                }

                                )
                            }

                            </tbody>
                        </table>
                    </div>
                </div>

            </>
        );
    }
}