import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';

import bindModel from '../../common/bindModel.js';
import SVGA from 'svgaplayerweb'
import { update } from 'lodash';
const AWS = window.AWS;
export default class ModalCreate extends Component {
    constructor(props) {
        super(props);
        this.fileInputRef = React.createRef();
        this.state = {
            recordings: [],
            Gifts: [],
            recording: {
                id: 0,
                thumbnail: '',
                addTime: '',
                status: 2,
                mixedVideoUrl: '',
                recordingType: '',
                performanceType: '',
                userId: '',
                message: '',
                songId: '',
                beatName: '',
                beatUrl: '',
                beatId: '',
                numberOfRecording:0,

            },
            loading: false,
            update: 0,
            song: false,
            user: null,
            url: '',
            gift: '',
            status: 1,
            type: '',
            Types: [],
        }
        // this.GiftImg=this.GiftImg.bind(this)
        this.saveVipPrivilege = this.saveVipPrivilege.bind(this);
        // this.listGift = this.listGift.bind(this);
        this.uploadAnimationToS5 = this.uploadAnimationToS5.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
    }

    componentWillReceiveProps(props) {
        $("#iconPreview").children("svg").remove();

        let recording = props.recording;

        let update = props.update
        let user = props.user
        $("#show-animation_thumbnailUrl").children().remove();
        console.log(user)
        console.log(recording)
        this.setState({
            recording: recording,
            update: update,
            user: user,
            song: false,
            loading: false
        })


    }
    componentWillUnmount() {
        this.setState({
            loading: false
        });
    }

    selectDate(e) {
        let value = e.target.value;
        let obj = { ...this.state.recording }
        obj.addTime = value
        this.setState({
            recording: obj
        })
    }
    handleSong(e) {
        let value = e.target.value;
        let obj = { ...this.state.recording }
        obj.songId = value
        this.setState({
            recording: obj
        })
    }
    handleBeat(e) {
        let value = e.target.value;
        let obj = { ...this.state.recording }
        obj.beatName = value
        this.setState({
            recording: obj
        })
    }
    selectStatus(e) {
        let value = e.target.value;
        let obj = { ...this.state.recording }
        obj.message = value
        this.setState({
            recording: obj
        })
    }
     isNumeric(value) {
        return !isNaN(parseFloat(value)) && isFinite(value);
    }
    handleLink(e) {
        let value = e.target.value;
        let obj = { ...this.state.recording }
        obj.mixedVideoUrl = value
        this.setState({
            recording: obj
        })
        if(!this.isNumeric(value)){
            this.setState({
                song :true
            })
        }
        
    }
    handleRecordingType(e) {
        let value = e.target.value;
        let obj = { ...this.state.recording }
        obj.recordingType = value
        this.setState({
            recording: obj
        })
    }
    beat(e) {
        let value = e.target.value;
        let obj = { ...this.state.recording }
        obj.beatName = value
        this.setState({
            recording: obj
        })
    }

    uploadFile_Song(event) {
        var target = event.target;

        this.setState({
            loading: true
        });

        var id = target.id;
        var obj = { ...this.state.recording }
        var bucketName = "ikara-data";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = "recordings/vip/video/" + this.state.user + "." + extension;
        var contentType = "video/mp4";
        console.log("trang thái load : " + this.state.loading);
        console.log("định dạng video " + extension)

        if (extension != "mp4" || extension != "mp3") {
            toast.error("Vui lòng chọn đúng loại bài thu")
            this.setState({
                loading: false
            });
            return;
        }


        var self = this;
        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data);
                    obj.beatUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;


                    this.setState({
                        recording: obj,
                        loading: false,
                        song: true
                    });
                }.bind(this), error: function (jqXHR, exception) {
                    // if (id == "vipVocalUrl") {
                    //     document.getElementById("vipVocalUrl").value = "";
                    // } else if (id == "vipMixedVideoUrl") {
                    //     document.getElementById("vipMixedVideoUrl").value = "";
                    // }

                    toast.error(exception);
                    this.setState({
                        loading: false
                    });
                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);
    }



    // guid() {
    //     function s4() {
    //         return Math.floor((1 + Math.random()) * 0x10000)
    //             .toString(16)
    //             .substring(1);
    //     }
    //     return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    // }
    uploadFile(event) {
        var target = event.target;

        this.setState({
            loading: true
        });

        var id = target.id;
        var obj = { ...this.state.recording }
        var bucketName = "ikara-data";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = "recordings/or" + this.state.user + "." + extension;
        var contentType = "audio/mp3";
        console.log("trang thái load : " + this.state.loading);
        console.log("định dạng video " + extension)
        if (obj.recordingType == null) {
            toast.error("Vui lòng chọn loại bài thu trước khi upload")
            return;
        }
        else {
            if (extension == "mp3" && obj.recordingType == "AUDIO") {
                keyName = "recordings/or/mp3" + this.guid() + "." + extension;
                contentType = "audio/mp3";
                // obj.recordingType = "AUDIO"
            }
            else if (extension == "mp4" && obj.recordingType == "VIDEO") {
                keyName = "recordings/or/mp4" + this.guid() + "." + extension;
                contentType = "video/mp4";
                // obj.recordingType = "VIDEO"
            }
            else {
                toast.error("Vui lòng chọn đúng loại bài thu")
                this.setState({
                    loading: false
                });
                return;
            }

        }
        const AWS = window.AWS;
        var credentials = new AWS.Credentials("writeonlyuser", "awJBPnZwvVpGYmp5MDQsw3Ry7z5EsyAE");
        var ep = new AWS.Endpoint("https://data4.ikara.co:9000");
        var s3 = new AWS.S3({
            endpoint: ep,
            credentials: credentials,
            region: 'us-east-1',
            s3ForcePathStyle: true,
            httpOptions: {
                timeout: 0 
            }
        });

        var self = this;
        var fileReader = new FileReader();

        fileReader.onload = function () {
            var data = fileReader.result; // data <-- in this var you have the file data in Base64 format
            var params = {
                ACL: 'public-read',
                ContentType: contentType,
                Bucket: bucketName,
                Key: keyName,
                Body: data
            };
            var upload = new AWS.S3.ManagedUpload({
                params: params,
                service: s3
            });
            upload.send((err, data) => {
                if (err) {
                    console.error('Upload Failed:', err);
                    alert("Upload Failed " + err);
                    this.setState({ loading: false });
                } else {
                    console.log('Upload Success:', data);
                    obj.mixedVideoUrl =  'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;;
                    this.setState({
                        recording: obj,
                        song: true,
                        loading: false
                    });
                }
            });
        }.bind(this);
        // var fileReader = new FileReader();

        // fileReader.onload = function () {
        //     var fd = new FormData();
        //     fd.append('file', target.files[0]);
        //     fd.append('bucketName', bucketName);
        //     fd.append('objectKey', keyName);
        //     fd.append('contentType', contentType)
        //     $.ajax({
        //         url: global.config.apiDomain + '/web.UploadFile',
        //         data: fd,
        //         processData: false,
        //         contentType: false,
        //         type: 'POST',
        //         success: function (data) {
        //             console.log(data);
        //             obj.mixedVideoUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;


        //             this.setState({
        //                 recording: obj,
        //                 loading: false,
        //                 song: true
        //             });
        //         }.bind(this), error: function (jqXHR, exception) {

        //             toast.error(exception);
        //             this.setState({
        //                 loading: false
        //             });
        //         }.bind(this),
        //     });
        // }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }
    loadSVGA4(url) {
        var player = new SVGA.Player('#demoCanvas4');
        var parser = new SVGA.Parser('#demoCanvas4');
        parser.load(url, function (videoItem) {
            console.log(videoItem)
            player.setVideoItem(videoItem);
            player.startAnimation();
        })
    }
    uploadAnimationToS5(event) {
        var target = event.target;
        //10495760
        if (target.files[0].size > 1049576) {
            toast.error("Kích thước file phải dưới 1mb.");
            return;
        }

        this.setState({
            loading: true
        });


        var obj = { ...this.state.recording };
        var bucketName = "ikara-data/recordings/images";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        console.log("định dạng ảnh " + extension)
        if (extension != "png" && extension != "jpg") {
            this.setState({
                loading: false
            });
            toast.error('Vui lòng nhập đúng định dạng ảnh ')
            return;
        }
        var contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";

        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data);
                    this.setState({
                        loading: false
                    });

                    obj.thumbnail = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    // this.state.gift.thumbnailUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        recording: obj
                    });
                    console.log(obj)
                    if (obj.thumbnail.endsWith("svga")) {
                        $("#show-animation_thumbnailUrl").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas4\"></div>");
                        this.loadSVGA4(obj.thumbnail);
                    } else if (obj.thumbnail != null) {
                        $("#show-animation_thumbnailUrl").children().replaceWith("<img style=\"height: 300px !important\" src=\"" + obj.thumbnail + "\"  />");
                    }
                }.bind(this), error: function (jqXHR, exception) {
                    toast.error(exception);
                    this.setState({
                        loading: false
                    });
                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);

    }
    saveVipPrivilege = () => {

        console.log(this.state.recording)

        console.log(this.state.update)
        let obj = { ...this.state.recording }
        obj.userId = this.state.user
        const currentDate = new Date();
        const selectedDateObject = new Date(obj.addTime);
        if (selectedDateObject > currentDate) {
            toast.error("Ngày phải nhỏ hơn hoặc bằng ngaỳ hiện tại ");
            return;
        }
        if (obj.thumbnail == null || obj.thumbnail == ''
        ) {
            var json = {
                id: obj.userId
            }

            axios.post(global.config.apiDomain + '/rest/admin/getAccount', JSON.stringify(json), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                obj.thumbnail = res.data && res.data.avatar ? res.data.avatar : null;
                console.log()
                if (res.data.status == "OK") {
                    console.log("ảnh api :" + res.data.avatar)
                    obj.thumbnail = res.data.avatar
                    console.log("ảnh" + obj.thumbnail)
                    console.log(res.data)
                } else {
                    toast.error(res.data.message);
                }
            }, err => {
                this.setState({
                    loading: false,
                });
                toast.error("ERR");
            });

        }
        this.setState({
            loading: true
        });
        if (this.state.update == 1) {
            console.log("updatr")
            axios.post(global.config.apiDomain + '/rest/idol-recording/update',
                obj
            )
                .then(res => {


                    if (res.data.status == "OK") {
                        this.setState({
                            loading: false,
                            update: 0,
                        });
                        if (this.fileInputRef.current) {
                            this.fileInputRef.current.value = '';
                        }
                        window.$("#modalUpdateVipPrivilege").modal("hide");
                        this.props.resetList();
                        toast.success(res.data.message);
                        console.log("thành công")
                    } else {
                        toast.warning(res.data.message);
                        console.log("thất bại")
                    }
                }, error => {
                    this.setState({
                        loading: false
                    });
                    toast.error(error);
                })
        }
        else {
            console.log("đối tượng ");
            console.log(obj);
            if (obj == null ||
                obj.addTime == null ||
                obj.mixedVideoUrl == null ||
                obj.status == null ||
                obj.performanceType == null ||
                obj.userId == null ||
                obj.thumbnail == null ||
                obj.message == null
            ) {
                this.setState({
                    loading: false,
                });
                toast.error("Vui lòng điền đầy đủ thông tin.");
                return;
            }

            console.log("trước khi gọi api")
            console.log(obj)
            axios.post(global.config.apiDomain + '/rest/idol-recording/create',
                obj
            )
                .then(res => {
                    this.setState({
                        loading: false
                    });
                    if (res.data.status == "OK") {
                        this.setState({
                            loading: false
                        });
                        if (this.fileInputRef.current) {
                            this.fileInputRef.current.value = '';
                        }
                        window.$("#modalUpdateVipPrivilege").modal("hide");
                        this.props.resetList();
                        toast.success(res.data.message);
                    } else {
                        toast.warning(res.data.message);
                    }
                }, error => {
                    this.setState({
                        loading: false
                    });
                    toast.error(error);
                })
        }
        // let obj = { ...this.state.vipPrivilege };
        // let MyGift = [...this.state.Gifts]
        // console.log([...this.state.Gifts])
        // console.log({ ...this.state.vipPrivilege })
        // this.setState({
        //     loading: true
        // });
        // if (this.state.update == 1) {
        //     console.log("updatr")
        //     axios.post(global.config.apiDomain + '/rest/eventLevelPrivilege/update', {
        //         levelRequirement: obj.levelRequirement,
        //         arrayGiftID: [...this.state.Gifts],
        //         types:[...this.state.Types],
        //         description: obj.description,
        //         thumbnai:obj.thumbnai,
        //         thumbnaiCard:obj.thumbnaiCard,
        //     })
        //         .then(res => {
        //             this.setState({
        //                 loading: false,
        //                 update: 0,
        //             });

        //             if (res.data.status == "OK") {
        //                 window.$("#modalUpdateVipPrivilege").modal("hide");
        //                 this.props.resetList();
        //                 toast.success(res.data.message);
        //                 console.log("thành công")
        //             } else {
        //                 toast.warning(res.data.message);
        //                 console.log("thất bại")
        //             }
        //         }, error => {
        //             this.setState({
        //                 loading: false
        //             });
        //             toast.error(error);
        //         })
        // }
        // else {
        //     if (obj == null ||
        //         MyGift == null ||
        //         obj.levelRequirement == null
        //     ) {
        //         toast.error("Vui lòng điền đầy đủ thông tin.");
        //         return;
        //     }
        //     this.setState({
        //         loading: false,
        //     });
        //     axios.post(global.config.apiDomain + '/rest/eventLevelPrivilege/create', {
        //         levelRequirement: obj.levelRequirement,
        //         arrayGiftID: [...this.state.Gifts],
        //         types:[...this.state.Types],
        //         thumbnai:obj.thumbnai,
        //         thumbnaiCard:obj.thumbnaiCard,
        //         description: obj.description,
        //     })
        //         .then(res => {
        //             if (res.data.status == "OK") {
        //                 window.$("#modalUpdateVipPrivilege").modal("hide");
        //                 this.props.resetList();
        //                 toast.success(res.data.message);
        //             } else {
        //                 toast.warning(res.data.message);
        //             }
        //         }, error => {
        //             this.setState({
        //                 loading: false
        //             });
        //             toast.error(error);
        //         })
        // }
    }



    render() {
        const model = bindModel(this);
        const { loading, vipPrivilege, Gifts, update, type, Types, recording, status, song } = this.state;

        return (
            <div>
                <div className="modal fade" id="modalUpdateVipPrivilege" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật bài thu</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                    <div id="levelRequirement" className="row mb-2">
                                        <div className="col-3">
                                            <label>Ngày Đăng</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                type="date"
                                                name="addTime"
                                                placeholder="Chọn ngày"
                                                onChange={(e) => this.selectDate(e)}
                                                {...model('recording.addTime')}
                                            />
                                        </div>
                                    </div>
                                    {update ==1 ?
                                    <div id="levelRequirement" className="row mb-2">
                                        <div className="col-3">
                                            <label>Song Name</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="form-control mb-2"
                                                type="text"
                                                name="beatName"
                                                placeholder="Thay đổi tên beat"
                                                onChange={(e) => this.beat(e)}
                                                {...model('recording.beatName')}
                                            />
                                        </div>
                                    </div>
    :''}
                         
                                <div id="thumbnailUrl" className="row mb-2">
                                    <div className="col-3">
                                        <label>ID bài thu</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            type="text"
                                            className="form-control mb-2"
                                            // className="custom-file-input"
                                            {...model('recording.mixedVideoUrl')}
                                            id="inputGroupFile05"
                                            placeholder="Nhâp ID bài thu"
                                            onChange={(e) => this.handleLink(e)}
                                        />
                                    </div>
                                </div>
                                <div id="thumbnailUrl" className="row mb-2">
                                    <div className="col-3">
                                        <label>Message</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            type="text"
                                            className="form-control mb-2"
                                            // className="custom-file-input"
                                            {...model('recording.message')}
                                            id="inputGroupFile05"
                                            placeholder="Nhập message"
                                            onChange={(e) => this.selectStatus(e)}
                                        />
                                    </div>
                                </div>
                                {song && update==0 ?
                                    <>
                                        <div id="thumbnailUrl" className="row mb-2">
                                            <div className="col-3">
                                                <label>BeatName</label>
                                            </div>
                                            <div className="col-9">
                                                <input
                                                    type="text"
                                                    className="form-control mb-2"
                                                    // className="custom-file-input"
                                                    // {...model('recording.songId')}
                                                    id="inputGroupFile05"
                                                    placeholder="Nhâp Message"
                                                    onChange={(e) => this.handleBeat(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-2  mt-2">
                                            <div className="col-3">
                                                <label>beatUrl</label>
                                            </div>
                                            <div className="col-9">
                                                <input
                                                    ref={this.fileInputRef}
                                                    type="file"
                                                    // className="custom-file-input"
                                                    id="inputGroupFile05"
                                                    placeholder="Chọn File"
                                                    onChange={this.uploadFile}
                                                />
                                            </div>
                                        </div>
                                        <div id="thumbnailUrl" className="row mb-2">
                                            <div className="col-3">
                                                <label>Song ID</label>
                                            </div>
                                            <div className="col-9">
                                                <input
                                                    type="text"
                                                    className="form-control mb-2"
                                                    // className="custom-file-input"
                                                    {...model('recording.songId')}
                                                    id="inputGroupFile05"
                                                    placeholder="Nhâp Message"
                                                    onChange={(e) => this.handleSong(e)}
                                                />
                                            </div>
                                        </div>
                                    </> : ""}
                                <div id="levelRequirement" className="row mb-2">
                                    <div className="col-3">
                                        <select onChange={(e) => this.handleRecordingType(e)}  {...model('recording.recordingType')} >
                                            <option value="">--Chọn Recording Type--</option>
                                            <option value="VIDEO">VIDEO</option>
                                            <option value="AUDIO">AUDIO</option>
                                            {/* Add more options as needed */}
                                        </select>
                                    </div>
                                    <div className="col-9">
                                        <select value={type} onChange={(e) => this.selectType(e)} {...model('recording.performanceType')}>
                                            <option value="">--performanceType--</option>
                                            <option value="SOLO">SOLO</option>
                                            <option value="ASK4DUET">ASK4DUET</option>
                                            <option value="DUET">DUET</option>
                                            {/* Add more options as needed */}
                                        </select>

                                    </div>
                                    <div id="thumbnailUrl" className="row mb-2 ml-2 mt-2">
                                        <div className="col-6">
                                            <label>Hình mô tả</label>
                                        </div>
                                        <div className="col-3">
                                            <input
                                                type="file"
                                                // className="custom-file-input"
                                                placeholder="Chọn File"
                                                onChange={this.uploadAnimationToS5}
                                            />

                                        </div>

                                    </div>
                                    {/* <input className="col" style={{ outline: 'none', border: 'none' }} {...model('recording.thumbnail')} /> */}
                                    <div className="row mb-2">
                                        <div className="col-3">
                                        </div>
                                        <div id="show-animation_thumbnailUrl" className="col-9" style={{ maxHeight: "300px" }}>
                                            <div width={500} height={500} id="canvas"></div>
                                        </div>
                                    </div>
                                    {recording && recording.thumbnail ?
                                        <img width={200} height={200} src={recording.thumbnail}></img> : ''}

                                </div>
                                <div className="row mb-2  mt-2">
                                    <div className="col-3">
                                        <label>Video Or Audio</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            ref={this.fileInputRef}
                                            type="file"
                                            // className="custom-file-input"
                                            id="inputGroupFile05"
                                            placeholder="Chọn File"
                                            onChange={this.uploadFile}
                                        />
                                    </div>
                                </div>
                            </div>



                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.saveVipPrivilege}>Xác nhận</button>
                                    )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        );
    }
}
// //    "postbuild":  "rm -r /Users/quhuy/ikaragae/src/main/webapp/react-source && mkdir /Users/quhuy/ikaragae/src/main/webapp/react-source && mv -v build/* /Users/quhuy/ikaragae/src/main/webapp/react-source",
