import React, { Component } from 'react';
import axios from 'axios';
import './../../assets/css/newDevice.css';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
export default class TopVideoRecordingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videoRecordings: [],
            cursor: null,
            statusButtonLoadPage: false,
            isLoading: false,
            isInitialLoading: true,
            error: null,
            uid: ''
        };
        this.getAccountList = this.getAccountList.bind(this);
        this.toggleIsHidden = this.toggleIsHidden.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleUidChange = this.handleUidChange.bind(this);
        this.reset = this.reset.bind(this);
    }
    componentDidMount() {
        this.getAccountList();
    }
    getAccountList() {
        const { isInitialLoading, cursor } = this.state;
        let getCursor = '';
        if (cursor) {
            getCursor = `?cursor=` + encodeURIComponent(cursor);
        }
        if (!isInitialLoading) {
            this.setState({
                isLoading: true
            });
        }
        let apiUrl = global.config.apiDomain + `/rest/recording/get-list-mv-recommend` + getCursor;
        axios.get(apiUrl, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            let videoRecordings = res.data.recordings;
            let status = true;
            if (videoRecordings.length < 1 || (res.data.cursor && res.data.cursor === "END")) {
                status = false;
            }
            this.setState((prevState) => ({
                videoRecordings: prevState.videoRecordings.concat(videoRecordings),
                cursor: res.data.cursor || prevState.cursor,
                statusButtonLoadPage: status,
                isLoading: false,
                isInitialLoading: false,
                error: null
            }));
        })
        .catch(error => {
            this.setState({
                isLoading: false,
                isInitialLoading: false
            });
            console.error("Error:", error);
        });
    }

    getAccountByUid(uid) {
        axios.post(global.config.apiDomain + '/rest/recording/find-mv-recommend-by-id', {
            recordingId: uid
        })
        .then(res => {
            let videoRecordings = res.data.recordings;
            let status = false;
            this.setState((prevState) => ({
                videoRecordings: prevState.videoRecordings.concat(videoRecordings),
                statusButtonLoadPage: status,
                isLoading: false,
                isInitialLoading: false,
                error: null
            }));
        })
        .catch(error => {
            this.setState({ recordings: null, error: "Không tìm thấy tài khoản với UID này." });
            console.error("Error fetching account:", error);
        });
    }

    toggleIsHidden(itemId, currentIsHidden) {
        let newIsHidden = !currentIsHidden;
        let isHiddenValue = newIsHidden ? 1 : 0;
    
        const json = {
            recordingId: itemId,
            isHiddenValue: isHiddenValue
        }

        axios.post(global.config.apiDomain + `/rest/recording/update-mv-recommend`, json,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
        .then(res => {
            if (res.data.status === 'SUCCESS') {
                newIsHidden ? toast.warn(`Đã ẩn MV ${itemId} thành công`) : toast.success(`Đã hiện MV ${itemId} thành công`);
                this.setState(prevState => ({
                    videoRecordings: prevState.videoRecordings.map(item =>
                        item._id === itemId ? { ...item, isHidden: newIsHidden } : item
                    )
                }));
            } else {
                toast.error(`Cập nhật MV ${itemId} thất bại`);
            }
        })
        .catch(error => {
            console.error("Error updating IsHidden:", error);
            toast.error("Failed to update IsHidden");
        });
    }

    handleUidChange(event) {
        this.setState({ uid: event.target.value});
    }

    handleSearch() {
        const {uid } = this.state;
        if (uid) {
            this.setState({ 
                cursor: null, 
                videoRecordings: [], 
                isInitialLoading: true, 
                statusButtonLoadPage: false, 
                error: null
            }, () => {
                this.getAccountByUid(uid);
            });
        } else {
            this.setState({ error: "Vui lòng nhập UID." });
        }
    }

    reset() {
        this.setState({
            videoRecordings: [],
            cursor: null,
            statusButtonLoadPage: false,
            isLoading: false,
            isInitialLoading: true,
            error: null,
            uid: ''
        });
        this.getAccountList();
    }
    
    render() {
        const { videoRecordings, statusButtonLoadPage, isLoading, isInitialLoading, uid } = this.state;
        return (
            <>
                <div id="content">
                    <div className="container-fluid">
                        <div className="container-fluid">
                            <h1 className="text-center">Danh sách MV đề xuất</h1>
                        </div>
                        <div className="search-container">
                            <input style={{width: '190px'}}
                                type="number"
                                value={uid}
                                onChange={this.handleUidChange}
                                onKeyDown={(e) => { if (e.key === 'Enter') this.handleSearch(); }}
                                placeholder="Nhập uid"
                            />
                            <button onClick={this.handleSearch} className="btn btn-primary" style={{maxWidth: '120px', minWidth: '90px'}}>Tìm kiếm</button>
                            <button
                                className="btn btn-outline-danger"
                                type="button"
                                id="button-addon2"
                                onClick={this.reset}>
                                <i className="fas fa-sync"></i>
                            </button>
                        </div>
                        {isInitialLoading ? (
                            <div className="spinner-container">
                                <div className="spinner"></div>
                            </div>
                        ) : videoRecordings.length > 0 ? (
                            <table className="table table-hover">
                                <thead>
                                    <tr className="text-center">
                                        <th className="align-middle" scope="col">STT</th>
                                        <th className="align-middle" scope="col">Id Bài thu</th>
                                        <th className="align-middle" scope="col">Tên bài hát</th>
                                        <th className="align-middle" scope="col">Chủ bài hát</th>
                                        <th className="align-middle" scope="col">Thumbnail</th>
                                        <th className="align-middle" scope="col">Ẩn MV</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {videoRecordings.map((item, index) => {
                                        return (
                                            <tr key={item._id}>
                                                <td className="align-middle text-center" scope="col">{index + 1}</td>
                                                <td className="align-middle text-center" scope="col">{item._id}</td>
                                                <td className="align-middle text-center" scope="col">{item.song.songName}</td>
                                                <td className="align-middle text-center" scope="col">{item.owner.name}</td>
                                                <td className="align-middle text-center" scope="col">
                                                    <img src={item.thumbnailImageUrl} alt="Thumbnail" width="100" height="100" />
                                                </td>
                                                <td className="align-middle text-center" scope="col">
                                                    <label className="switch">
                                                        <input
                                                            type="checkbox"
                                                            checked={item.isHidden}
                                                            onChange={() => this.toggleIsHidden(item._id, item.isHidden)}
                                                        />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        ) : (
                            <div className="text-center">
                                <p>Không có dữ liệu.</p>
                            </div>
                        )}
                        {
                            statusButtonLoadPage &&
                            (<div onClick={this.getAccountList} className="view_more w-100 text-center mb-lg-4">
                                <a className="d-none d-md-block">
                                    <span>{isLoading ? "ĐANG TẢI..." : "TẢI THÊM"}</span>
                                </a>
                            </div>)
                        }
                    </div>
                </div>
            </>
        );
    }
}