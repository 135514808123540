import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const ModalDeleteLevelPrivilege = ({ levelPrivilege: propLevelPrivilege, resetList }) => {
    const [levelPrivilege, setLevelPrivilege] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLevelPrivilege(propLevelPrivilege);
    }, [propLevelPrivilege]);

    const deleteOrActiveLevelPrivilege = () => {
        let obj = { ...levelPrivilege };

        setLoading(true);

        axios.post(global.config.apiDomain + '/rest/levelPrivilege/deleteOrActive', obj)
            .then(res => {
                setLoading(false);

                if (res.data.status === "OK") {
                    window.$("#modalDeleteLevelPrivilege").modal("hide");
                    toast.success(res.data.message);
                    resetList();
                } else {
                    toast.warning(res.data.message);
                }
            })
            .catch(error => {
                setLoading(false);
                toast.error("An error occurred");
            });
    };

    return (
        <div>
            <div className="modal fade" id="modalDeleteLevelPrivilege" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            {levelPrivilege && levelPrivilege.isActive ? 
                                <h5 className="modal-title" id="exampleModalLabel">Ẩn Đặc quyền</h5> :
                                <h5 className="modal-title" id="exampleModalLabel">Kích hoạt lại</h5>
                            }
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        {levelPrivilege && levelPrivilege.name && (levelPrivilege.isActive ?
                            <div className="modal-body">
                                <h3 className="text-center text-danger">Bạn chắc chắn muốn Ẩn Đặc quyền: <span className="font-weight-bold">{levelPrivilege.name}</span> ?</h3>
                            </div> :
                            <div className="modal-body">
                                <h3 className="text-center text-info">Bạn muốn Kích hoạt lại: <span className="font-weight-bold">{levelPrivilege.name}</span> ?</h3>
                            </div>
                        )}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                            {loading ? 
                                <button style={{width: "120px"}} className="btn btn-red" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Xác nhận...
                                </button> :
                                <button
                                    style={{width: "120px"}}
                                    type="button"
                                    className="btn btn-red"
                                    onClick={deleteOrActiveLevelPrivilege}
                                >
                                    Xác nhận
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalDeleteLevelPrivilege;
