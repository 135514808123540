import React, { Component } from 'react';
import bindModel from '../../../common/bindModel.js';
import axios from 'axios';
import { toast } from 'react-toastify';
import SVGA from 'svgaplayerweb';
import { Parser, Player } from 'svga';
import lodash from 'lodash';
import $ from 'jquery';
import getImageSize from 'image-size-from-url';


import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';

export default class ModalUpdateDecoration extends Component {

    constructor(props) {
        super(props);

        this.state = {
            decorationItemsType: [],
            decorationItem: null
        }

        // this.saveGift = this.saveGift.bind(this);
        this.uploadThumbnailToS3 = this.uploadThumbnailToS3.bind(this);
        this.uploadAnimationToS3 = this.uploadAnimationToS3.bind(this);

        this.updateStartDate = this.updateStartDate.bind(this);
        this.updateEndDate = this.updateEndDate.bind(this);




// ////
        this.getGifts = this.getGifts.bind(this);
        this.setDataInUrl = this.setDataInUrl.bind(this);
    }

    componentWillMount() {
        this.getGifts();
    }

    componentDidUpdate(){
        const { decorationItemsType } = this.state;
        decorationItemsType.map((decorationItemType, index) => {
            let idResource = decorationItemType.id;
            console.log(idResource)
            if (decorationItemType.resourceUrl != null && decorationItemType.resourceUrl != "" && decorationItemType.resourceUrl.endsWith("svga")) {
                // $("#" + idResource).children().replaceWith("<div style=\" height: 40px !important\" id=\" " + canvasIdResource + "\"></div>");
                this.loadSVGA4(decorationItemsType.resourceUrl, idResource);
            } else if (decorationItemType.resourceUrl != null) {
                $("#" + idResource).replaceWith("<img style=\"height: 50px; width: 50px; align: center; \" src=\"" + decorationItemType.resourceUrl + "\"  />");
            }
        })
    }

    async loadSVGA(id, url) {
        const parser = new Parser()
        const svga = await parser.load(url)

        const player = new Player(document.getElementById(id))
        await player.mount(svga)

        player.onStart = () => console.log('onStart')

        player.start()
    }

    getGifts() {
        let { decorationTop1 } = this.props;
        let type= "";
        if(decorationTop1 == "frameTop1"){
            type = "FRAME"
        }else{
            type = "MEDAL"
        }
        // let json = {
        //     "id": 5730499856039936,
        //     "name": "halolo",
        //     "type": "FRAME",
        //     "resourceUrl": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/decorationItemType/22e041fc-2d49-6f03-dde8-1a575a102fc0.png",
        //     "thumbnailUrl": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/decorationItemType/e9285dfb-88fa-630a-f618-11b4cfd87a9f.png",
        //     "expiry": 10,
        //     "addTime": 1684902296687
        //   };
        //   let arr = [json]
        let url = global.config.apiDomain + "/rest/decorationItemsType/getAllItems";

        axios.post(url)
        .then(res =>{
            let decorations = [];
            for(let i =0; i < res.data.decorationItemTypes.length ; i++){
                if( res.data.decorationItemTypes[i].type == type ){
                    decorations.push(res.data.decorationItemTypes[i]);
                }
            }
            this.setState({
                // decorationItemsType: arr
                decorationItemsType: decorations
            })
        })
    }


    async loadSVGA(id, url) {
        const parser = new Parser()
        const svga = await parser.load(url)

        const player = new Player(document.getElementById(id))
        await player.mount(svga)

        player.onStart = () => console.log('onStart')

        player.start()
    }

    loadSVGA4(url, canvasId) {
        var parser = new SVGA.Parser('#' + canvasId);
        parser.load(url, function (videoItem) {
            var player = new SVGA.Player('#' + canvasId);
            console.log("videoItem")
            console.log(canvasId)
            console.log(player)
            console.log(videoItem)
            player.setVideoItem(videoItem);
            // player.setFrame(0, 0, 750, 750);
            player.setContentMode("AspectFill")
            player.startAnimation();
        })
    }


    componentWillReceiveProps(props) {
        let { decorationTop1 } = props;
        // console.log("decorationTop1",decorationTop1)
        if (decorationTop1 != null) {
            this.setState({
                decorationItem : decorationTop1
            });
        }
    }

    loadSVGA2(url) {
        var player = new SVGA.Player('#demoCanvas2');
        var parser = new SVGA.Parser('#demoCanvas2');
        parser.load(url, function (videoItem) {
            console.log(videoItem)
            player.setVideoItem(videoItem);
            player.startAnimation();
        })
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    uploadAnimationToS3(event) {
        var target = event.target;
        //10495760
        if (target.files[0].size > 10495760 ){
            toast.error("Kích thước file phải dưới 10mb.");
            return;
        }

        this.setState({
            loading: true
        });

        var gift = this.state.gift;
        var bucketName = "ikara-data/images/cup";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";

        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data);
                    this.setState({
                        loading: false
                    });

                    gift.animatedUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.state.gift.animatedUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        gift: gift
                    });

                    $("#show-animation").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas\"></div>");
                    this.loadSVGA2(gift.animatedUrl);
                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);

    }

    uploadThumbnailToS3(event) {
        var target = event.target;
        if (target.files[0].size > 512999){
            toast.error("Kích thước file phải dưới 500kb.");
            return;
        }

        const fileReader1 = new FileReader();
        fileReader1.readAsDataURL(target.files[0]);

        fileReader1.onload =  (fe) => {
          const image = new Image();
          image.src = fe.currentTarget.result;
          image.onload = function(ie){
            let width = 0, height = 0;
            width = this.naturalWidth;
            height = this.naturalHeight;
            if (width != height || height > 256){
                toast.error("Kích thước ảnh tối đa 256px và Width = Height");
                return;
            }

            this.setState({
                            loading: true
                        });
                
                        var gift = this.state.gift;
                        var bucketName = "ikara-data/images/cup";
                        var extension = target.files[0].name.split('.').pop().toLowerCase();
                        var keyName = this.guid() + "." + extension;
                        var contentType = "image/jpeg";
                        if (extension == "png") contentType = "image/png";
                
                        var fileReader = new FileReader();
                
                        fileReader.onload = function () {
                            var fd = new FormData();
                            fd.append('file', target.files[0]);
                            fd.append('bucketName', bucketName);
                            fd.append('objectKey', keyName);
                            fd.append('contentType', contentType)
                            $.ajax({
                                url: global.config.apiDomain + '/web.UploadFile',
                                data: fd,
                                processData: false,
                                contentType: false,
                                type: 'POST',
                                success: function (data) {
                                    console.log(data)
                                    this.setState({
                                        loading: false
                                    });
                
                                    gift.url = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                                    this.setState({
                                        gift: gift
                                    });
                
                                }.bind(this),
                            });
                
                        }.bind(this);
                        fileReader.readAsArrayBuffer(target.files[0]);
            }.bind(this);
        }
    }

    updateStartDate(props) {
        
    }

    updateEndDate(props) {
          
    }
    setDataInUrl(id, url, keyCup){
        this.props.resetList(id, url, keyCup);
    }

    render() {

        const { decorationItemsType } = this.state;

        return (
            <div>
                <div className="modal fade" id="modalUpdateDecoration" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Chọn Trang trí bài thu</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <table className="table table-hover">
                                    <thead>
                                        <tr className="align-middle">
                                            <th className="align-middle" scope="col">ID</th>
                                            <th className="align-middle" scope="col">Name</th>
                                            <th className="align-middle" scope="col">Image</th>
                                            <th className="align-middle" scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {decorationItemsType.map((item, index)=>{
                                            return (
                                                <tr key={item.id}>
                                                    <th scope="row">{item.id}</th>
                                                    <td>{item.name}</td>
                                                    <td><span id={item.id}><img className="gift-image" src={item.resourceUrl}></img></span></td>
                                                    <td>
                                                        <button
                                                            onClick={() => {
                                                                this.setState({
                                                                    decorationItem: item
                                                                })
                                                                this.setDataInUrl(item, item.resourceUrl, this.state.decorationItem)
                                                            }}
                                                            className="btn btn-red"
                                                            data-toggle="modal"
                                                            data-target="#modalUpdateDecoration"
                                                        >Chọn</button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                            </table>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red">Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}