import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import bindModel from '../../../common/bindModel.js';


const $ = window.$;

export default class ModalHistoryScore extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false, 
            receiverFacebookId: "",
            cursor: null,
            scoreLogs: [],
            fromDate: null,
            toDate: null,
            statusButtonLoadPage: false,
            loading: false
        }

        this.getFirstHistory = this.getFirstHistory.bind(this);
        this.loadMoreHistory = this.loadMoreHistory.bind(this);
        this.reset = this.reset.bind(this);

    }

    loadMoreHistory() {
        const { loading } = this.state;
        if (!loading) {
            this.setState({
                loading: true,
                statusButtonLoadPage:false
            });

            let fromDate = null;
            let toDate = null;
            try {
                fromDate = Date.parse(this.state.fromDate);
            } catch (error) {
                fromDate = null;
            }

            try {
                toDate = Date.parse(this.state.toDate);
            } catch (error) {
                toDate = null;
            }

            let json = {
                id: this.state.receiverFacebookId,
                cursor: this.state.cursor
            };

            axios.post(global.config.apiDomain + '/rest/admin/scoreLog', JSON.stringify(json), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                console.log("data");
                console.log(res);
                let status = true;

                if (res.data.logScores.length < 20) {
                    status = false;
                }

                this.setState({
                    scoreLogs: this.state.scoreLogs.concat(res.data.logScores),
                    cursor: res.data.cursor,
                    statusButtonLoadPage: status,
                    loading: false
                });
            })
        }
    }

    getFirstHistory(account) {
        if (account != null) {
            let json = {
                id: this.state.receiverFacebookId,
                cursor: null
            };
            axios.post(global.config.apiDomain + '/rest/admin/scoreLog', JSON.stringify(json), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                console.log(res);
                let status = true;
                if (res.data.logScores.length < 20) {
                    status = false;
                }
                this.setState({
                    isLoaded: true,
                    scoreLogs: res.data.logScores,
                    cursor: res.data.cursor,
                    statusButtonLoadPage: status,
                    loading: false
                });
            })
        }
    }

    componentDidUpdate() {
        if (!this.state.isLoaded) {
            const { account } = this.props;
            if (account != null) {
                if (this.state.scoreLogs.length == 0) {
                    this.getFirstHistory(account);
                }
                if (this.state.receiverFacebookId == "") {
                    this.setState({
                        receiverFacebookId: account.id,
                    });
                }
            }
        }
    }

    reset() {
        this.setState({
            fromDate: "",
            toDate: "",
            cursor: null
        });
    }


    render() {
        const model = bindModel(this);
        const { scoreLogs, statusButtonLoadPage, loading } = this.state;

        return (
            <div>

                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th className="align-middle text-center" scope="col" rowspan="2">Ngày nhận</th>
                            <th className="text-center" scope="col" colspan="3">Người tặng</th>
                            <th className="text-center" scope="col" colspan="3">Gia tộc</th>
                            <th className="text-center" scope="col" colspan="5">Quà</th>
                            <th className="text-center" scope="col" rowspan="2">Chi tiết</th>
                            <th className="align-middle text-center" scope="col" colspan="2">Điểm kinh nghiệm</th>
                        </tr>
                        <tr>
                            <th className="text-center" scope="col">UID</th>
                            <th className="text-center" scope="col">Tên</th>
                            <th className="text-center" scope="col">Avatar</th>
                            <th className="text-center" scope="col">UID</th>
                            <th className="text-center" scope="col">Tên</th>
                            <th className="text-center" scope="col">Thumbnail</th>
                            <th className="text-center" scope="col">ID</th>
                            <th className="text-center" scope="col">Tên</th>
                            <th className="text-center" scope="col">Ảnh</th>
                            <th className="text-center" scope="col">Giá mua</th>
                            <th className="text-center" scope="col">Giá bán</th>
                            <th className="text-center" scope="col">Điểm trước đó</th>
                            <th className="text-center" scope="col">Điểm hiện tại</th>
                        </tr>
                    </thead>
                    <tbody>
                        {scoreLogs ? (
                            scoreLogs.map((record, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{new Date(record.addTime).toLocaleString()}</td>
                                        <td>{record.fromUserUid}</td>
                                        <td>{record.fromUserName}</td>
                                        <td><img src={record.fromUserAvatar} alt="" style={{width: "100px"}} /></td>
                                        <td>{record.fromFamilyUid}</td>
                                        <td>{record.fromFamilyName}</td>
                                        <td>{record.fromFamilyAvatar}</td>
                                        <td>{record.giftId}</td>
                                        <td>{record.giftName}</td>
                                        <td><img src={record.giftImage} alt="" style={{width: "100px"}} /></td>
                                        <td>{record.buyPrice}</td>
                                        <td>{record.sellPrice}</td>
                                        <td>{record.atClassAndPosition}</td>
                                        <td>{record.oldScore}</td>
                                        <td>{record.newScore}</td>
                                    </tr>
                                )
                            })
                        ) : <></>}

                    </tbody>
                </table>

                {
                    statusButtonLoadPage ?
                        (<div onClick={this.loadMoreHistory} className="view_more w-100 text-center mb-lg-4">
                            <a className="d-none d-md-block">
                                <span>{loading ? "ĐANG TẢI ..." : "TẢI THÊM"}</span>
                            </a>
                            <a className="view_more_mobile d-md-none">
                                <i className="fa fa-angle-down" aria-hidden="true" />
                            </a>
                        </div>) :
                        ('')
                }
                {/* <div className='text-center'><button onClick={this.loadMoreHistory} className="btn-red">Xem thêm</button></div> */}
            </div>
        )
    }
}