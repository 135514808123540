import React, { Component } from 'react';

export default class ModalShowDeviceDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            platform: null,
            versionSdk: null,
            model: null,
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            model: nextProps.model,
            platform: nextProps.platform,
            versionSdk: nextProps.versionSdk
        })
    }

    render() {

        const { versionSdk, model, platform } = this.state;

        return (
            <div>
                <div className="modal fade" id="modalShowDeviceDetail" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Chi tiết Device</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {/* {platform && */}
                                    
                                        <div>
                                            {/* <h3>Owner</h3> */}
                                            <ol>
                                                <li>Điện thoại: {model}</li>
                                                <li>Hệ điều hành: {platform}</li>
                                                <li>Phiên bản: {versionSdk}</li>
                                            </ol>
                                        </div>
                                    
                                    {/* } */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}