import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import SVGA from 'svgaplayerweb'
import $ from 'jquery';
import bindModel from '../../../common/bindModel';

export default class ModalCreate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            model: null,
            loading: false,
        }

        this.createOrUpdate = this.createOrUpdate.bind(this);
        this.uploadAnimationToS3 = this.uploadAnimationToS3.bind(this);
        this.guid = this.guid.bind(this);
    }


    createOrUpdate() {
        const { model } = this.state;
        if (
            !model.name ||
            !model.buyPrice ||
            !model.thumbnailUrl ||
            !model.resourceUrl ||
            !model.category ||
            (model.expiry <= 0 && model.expiry != -1) ||
            !model.order ||
            !model.sourceType
        ) {
            toast.warning("Nhập đầy đủ thông tin");
            return
        }

        this.setState({
            loading: true
        });

        axios.post(global.config.apiDomain + "/rest/item3D/createOrUpdate", JSON.stringify(model), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                this.setState({
                    loading: false
                });
                if (res.data && res.data.status == "OK") {
                    window.$("#modalCreate").modal("hide");
                    toast.success(res.data.message);
                    this.props.resetList(model.category);
                } else {
                    toast.success(res.data.message)
                }
            })

    }

    componentWillReceiveProps(props) {
        const { model } = props;
        if (model == null) return
        this.setState({
            model: model,
        })

    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    uploadAnimationToS3(event) {
        var target = event.target;
        const { model } = this.state;
        //10495760
        if (target.files[0].size > 10495760) {
            toast.error("Kích thước file phải dưới 10mb.");
            return;
        }

        this.setState({
            loading: true
        });

        var bucketName = "ikara-data/images/item3D";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = target.files[0].name;
        var contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";

        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    let animatedUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;

                    if (target.id && target.id.includes("ResourceUrl")) {
                        model.resourceUrl = animatedUrl;
                        $("#show-resourceUrl").html(`<h4>${model.resourceUrl.substring(model.resourceUrl.lastIndexOf("/"), model.resourceUrl.length)}</h4>`);
                    } else {
                        model.thumbnailUrl = animatedUrl;
                        $("#show-thumbnailUrl").children().replaceWith(`<img src=${model.thumbnailUrl} width="150px" />`);
                    }

                    this.setState({
                        model: model,
                        loading: false
                    })

                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);

    }

    render() {
        const bind = bindModel(this);
        const { model } = this.state;
        const { categories, subCategories } = this.props;
        return (
            <div>
                <div className="modal fade" id="modalCreate" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">{model && model.resourceUrl ? "Cập nhật vật phẩm" : "Thêm mới vật phẩm"}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div id="id" className="row mb-2">
                                    <div className="col-3">
                                        <label>ID</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="id"
                                            readOnly={true}
                                            {...bind('model.id')}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label>Danh mục</label>
                                    </div>

                                    <div className="col-9">
                                        <select
                                            className="form-control"
                                            type="text"
                                            name="category"
                                            {...bind('model.category')}
                                        >
                                            {categories.map(e => {
                                                return <option value={e.type}>{e.name}</option>
                                            })}

                                        </select>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label>Danh mục phụ</label>
                                    </div>

                                    <div className="col-9">
                                        <select
                                            className="form-control"
                                            type="text"
                                            name="category"
                                            {...bind('model.subCategory')}
                                        >
                                            <option value=''>Không</option>
                                            {subCategories.map(e => {
                                                return <option value={e.type}>{e.name}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label>Loại</label>
                                    </div>
                                    <div className="col-9">
                                        <select
                                            className="form-control"
                                            type="text"
                                            {...bind('model.sourceType')}
                                        >
                                            <option value=''>Chọn</option>
                                            <option value='NORMAL'>NORMAL</option>
                                            <option value='SYSTEM'>SYSTEM</option>
                                        </select>
                                    </div>
                                </div>
                                <div id="name" className="row mb-2">
                                    <div className="col-3">
                                        <label>Giới tính</label>
                                    </div>
                                    <div className="col-9">
                                        <select
                                            className="form-control"
                                            type="text"
                                            {...bind('model.gender')}
                                        >
                                            <option value='ALL'>Cả 2</option>
                                            <option value='MALE'>Nam</option>
                                            <option value='FEMALE'>Nữ</option>
                                        </select>
                                    </div>
                                </div>
                                <div id="name" className="row mb-2">
                                    <div className="col-3">
                                        <label>Tag</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="tag"
                                            placeholder="Nhập tag"
                                            {...bind('model.tag')} />
                                    </div>
                                </div>
                                <div id="name" className="row mb-2">
                                    <div className="col-3">
                                        <label>Tên vật phẩm</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="name"
                                            placeholder="Nhập tên"
                                            {...bind('model.name')} />
                                    </div>
                                </div>
                                <div id="buyPrice" className="row mb-2">
                                    <div className="col-3">
                                        <label>Giá bán</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="number"
                                            name="buyPrice"
                                            placeholder="Nhập Giá bán"
                                            {...bind('model.buyPrice')} />
                                    </div>
                                </div>
                                <div id="resourceUrl" className="row mb-2">
                                    <div className="col-3">
                                        <label>Hình vật phẩm</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="custom-file-input"
                                            type="file"
                                            id="inputResourceUrl"
                                            placeholder="Chọn hình vật phẩm"
                                            onChange={this.uploadAnimationToS3} />
                                        <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile03">Choose file</label>
                                    </div>
                                </div>
                                <div id="reviewResourceUrl" className="row mb-2">
                                    <div className="col-3">
                                    </div>
                                    <div id="show-resourceUrl" className="col-9">

                                    </div>
                                </div>
                                <div id="thumbnailUrl" className="row mb-2">
                                    <div className="col-3">
                                        <label>Hình mô tả</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="custom-file-input"
                                            type="file"
                                            id="inputThumbnailUrl"
                                            placeholder="Chọn hình mô tả"
                                            onChange={this.uploadAnimationToS3} />
                                        <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile03">Choose file</label>
                                    </div>
                                </div>
                                {model && model.thumbnailUrl ?
                                    <div id="reviewThumbnailUrl" className="row mb-2">
                                        <div className="col-3">
                                        </div>
                                        <div id="show-thumbnailUrl" className="col-9" style={{ maxHeight: "300px" }}>
                                            <img src={model.thumbnailUrl} width="200px" />
                                        </div>
                                    </div> : ''}

                                <div id="expiry" className="row mb-2">
                                    <div className="col-3">
                                        <label>Thời hạn</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="number"
                                            name="expiry"
                                            placeholder="Nhập Thời hạn"
                                            {...bind('model.expiry')} />
                                    </div>
                                </div>
                                <div id="id" className="row mb-2">
                                    <div className="col-3">
                                        <label>Level yêu cầu</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="number"
                                            name="minLevel"
                                            placeholder="Nhập level yêu cầu"
                                            {...bind('model.minLevel')} />
                                    </div>
                                </div>
                                <div id="id" className="row mb-2">
                                    <div className="col-3">
                                        <label>Level VIP yêu cầu</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="number"
                                            name="minVipLevel"
                                            placeholder="Nhập level VIP yêu cầu"
                                            {...bind('model.minVipLevel')} />
                                    </div>
                                </div>
                                <div id="id" className="row mb-2">
                                    <div className="col-3">
                                        <label>Độ ưu tiên</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="number"
                                            name="order"
                                            placeholder="Nhập độ ưu tiên"
                                            {...bind('model.order')} />
                                    </div>
                                </div>


                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red"
                                            onClick={this.createOrUpdate}
                                        >Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}