import React, { Component } from 'react';
import MyContext from '../../../common/MyContext.js';
import CopyToClipboard from 'react-copy-to-clipboard';

export default class ModalBuyIconForMomo extends Component {
    render() {
        const {user} = this.context;
        var id = "XXXXXX";
        if(user != null) {
            id = user.uid;
        }
        return (
            <div>
                <div className="modal fade" id="modalBuyIconForMomo" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Momo</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body bg-light">
                                <div className="border rounded p-2 bg-white">
                                    <span className="font-weight-bold">Bưới 1/6: Chọn "chuyển tiền"</span>
                                    <p className="m-0">Tại màn hình chính của ví momo, bạn chọn chuyển tiền</p>
                                </div>
                                <div className="text-center p-2">
                                    <img src={require("../../../assets/images/momo01.png")} />
                                </div>

                                <div className="border rounded p-2 bg-white">
                                    <span className="font-weight-bold">Bưới 2/6: Chọn chuyển tiền "Đến Ví MoMo"</span>
                                    {/* <p className="m-0">Tại màn hình chính của ví momo, bạn chọn chuyển tiền</p> */}
                                </div>
                                <div className="text-center p-2">
                                    <img src={require("../../../assets/images/momo02.png")} />
                                </div>

                                <div className="border rounded p-2 bg-white">
                                    <span className="font-weight-bold">Bưới 3/6: Nhập số điện thoại người nhận</span>
                                    <p className="m-0">Vui lòng nhập số điện thoại <span className="text-danger">0888268286</span>
                                    <CopyToClipboard
                                        text="0888268286">
                                        <button className="btn btn-secondary ml-2"><i className="fa fa-copy"></i></button>
                                    </CopyToClipboard></p>
                                </div>
                                <div className="text-center p-2">
                                    <img src={require("../../../assets/images/momo03.png")} />
                                </div>

                                <div className="border rounded p-2 bg-white">
                                    <span className="font-weight-bold">Bưới 4/6: Nhập số tiền và lời nhắn</span>
                                    <p className="m-0">Ở ô số tiền bạn hãy nhập số tiền mà bạn muốn chuyển.
                                    Mỗi 1000đ bạn chuyển sẽ nhận được 14 iCoin.</p>
                                    <p className="m-0">Ở ô lời nhắn bạn hãy nhập <strong>ID {id}</strong>
                                    <CopyToClipboard
                                        text={"ID " + id}>
                                        <button className="btn btn-secondary ml-2"><i className="fa fa-copy"></i></button>
                                    </CopyToClipboard>.
                                    Trong đó <strong>{id}</strong> là <strong>ID</strong> tài khoản của bạn trên ứng dụng iKara.
                                    Sau đó nhấn nút "Chuyển tiền"</p>
                                </div>
                                <div className="text-center p-2">
                                    <img src={require("../../../assets/images/momo04.png")} />
                                </div>

                                <div className="border rounded p-2 bg-white">
                                    <span className="font-weight-bold">Bưới 5/6: Chọn "Xác nhận"</span>
                                    <p className="m-0">Vui lòng kiểm tra lại đúng số tiền bạn muốn nạp iCoin 
                                    và số điện thoại người nhận là <span className="text-danger">0888268286</span> trước khi bấm "Xác nhận"</p>
                                </div>
                                <div className="text-center p-2">
                                    <img src={require("../../../assets/images/momo05.png")} />
                                </div>

                                <div className="border rounded p-2 bg-white">
                                    <span className="font-weight-bold">Giao dịch thành công</span>
                                    <p className="m-0">Vui lòng chờ trong 1 phút để nhận iCoin vào tài khoản.
                                    Nếu cần hỗ trợ thêm về giao dịch hãy liên hệ đến &nbsp;
                                    <a href="https://www.facebook.com/H%E1%BB%97-tr%E1%BB%A3-thanh-to%C3%A1n-Ikara-Yokara-105079051501582">IKARA YOKARA</a></p>
                                </div>
                                <div className="text-center p-2">
                                    <img src={require("../../../assets/images/momo06.png")} />
                                </div>

                            </div>
                            {/* <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                <button type="button" className="btn btn-red">Save changes</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ModalBuyIconForMomo.contextType = MyContext;