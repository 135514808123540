import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import LivestreamCreate from './components/LivestreamCreate';
import LivestreamDelete from './components/LivestreamDelete';
import { toast } from 'react-toastify';

export default class LivestreamPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            models: [],
            model: null,
        };
        this.getAllLivestream = this.getAllLivestream.bind(this);
        this.createOrUpdateLivestream = this.createOrUpdateLivestream.bind(this);

        this.deleteLivestream = this.deleteLivestream.bind(this);
    }
    componentWillMount() {
        this.getAllLivestream();
    }
    getAllLivestream() {
        let url = global.config.apiDomain + "/rest/livestream/getAllLivestream"
        axios.post(url)
            .then(res => {
                this.setState({
                    models: res.data.models
                })
            })
    }
    createOrUpdateLivestream(event) {
        let index = event.target.name;

        let model = null;
        if (index === "-1") {
            model = {
                uid: '',
                message: '',
                deepLink: '',
                timeShow: new Date().toLocaleString("sv", { timeZone: "Asia/Ho_Chi_Minh" }).replace(" ", "T"),
                timeStart: new Date().toLocaleString("sv", { timeZone: "Asia/Ho_Chi_Minh" }).replace(" ", "T"),
                timeEnd: new Date().toLocaleString("sv", { timeZone: "Asia/Ho_Chi_Minh" }).replace(" ", "T"),
                thumbnail: ''
            }
        } else {
            model = this.state.models[index];
            model.timeShow = new Date(model.timeShow).toLocaleString("sv", { timeZone: "Asia/Ho_Chi_Minh" }).replace(" ", "T")
            model.timeStart = new Date(model.timeStart).toLocaleString("sv", { timeZone: "Asia/Ho_Chi_Minh" }).replace(" ", "T")
            model.timeEnd = new Date(model.timeEnd).toLocaleString("sv", { timeZone: "Asia/Ho_Chi_Minh" }).replace(" ", "T")
        }
        this.setState({
            model: model
        })

    }
    deleteLivestream(event) {
        let index = event.target.name;
        let model = this.state.models[index];

        this.setState({
            model: model
        })
    }

    render() {
        const { models, model } = this.state;

        return (
            <>
                <LivestreamCreate
                    model={model}
                    resetList={this.getAllLivestream}
                ></LivestreamCreate>
                <LivestreamDelete
                    model={model}
                    resetList={this.getAllLivestream}
                ></LivestreamDelete>
                <div id="content">
                    <div className="container-fluid">

                        <h1 className="text-center">Quản lí Livestream</h1>
                        <button
                            className="btn btn-info mb-1"
                            data-toggle="modal"
                            data-target="#modalCreate"
                            name="-1"
                            onClick={this.createOrUpdateLivestream}
                        >Tạo mới Livestream
                        </button>
                        <table className="table table-hover">
                            <thead>
                                <tr className="text-center">
                                    <th className="align-middle" scope="col">ID</th>
                                    <th className="align-middle" scope="col">UID</th>
                                    <th className="align-middle" scope="col">Name</th>
                                    <th className="align-middle" scope="col">Nội dung</th>
                                    <th className="align-middle" scope="col">Thumbnail</th>
                                    <th className="align-middle" scope="col">Thời gian đếm ngược</th>
                                    <th className="align-middle" scope="col">Thời gian bắt đầu</th>
                                    <th className="align-middle" scope="col">Thời gian kết thúc</th>
                                    <th className="align-middle" scope="col">DeepLink</th>
                                    <th className="align-middle" scope="col" colSpan={2}>Tùy chỉnh</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    models && models.map((item, index) => {
                                        return (
                                            <tr>
                                                <td className="align-middle text-center" scope="col">{item.id}</td>
                                                <td className="align-middle text-center" scope="col">{item.uid}</td>
                                                <td className="align-middle text-center" scope="col">{item.name}</td>
                                                <td className="align-middle text-center" scope="col">{item.message}</td>
                                                <td>
                                                    <img src={item.thumbnail}></img>
                                                </td>
                                                <td className="align-middle text-center" scope="col">{new Date(item.timeShow).toLocaleString()}</td>
                                                <td className="align-middle text-center" scope="col">{new Date(item.timeStart).toLocaleString()}</td>
                                                <td className="align-middle text-center" scope="col">{new Date(item.timeEnd).toLocaleString()}</td>
                                                <td className="align-middle text-center" scope="col">{item.deepLink}</td>
                                                <td className="align-middle text-center" scope="col">
                                                    <button className='btn btn-success ml-2 mr-2'
                                                        data-toggle="modal"
                                                        data-target="#modalCreate"
                                                        onClick={this.createOrUpdateLivestream}
                                                        name={index}
                                                    >
                                                        SỬA
                                                    </button>

                                                    <button
                                                        className="btn btn-danger"
                                                        onClick={this.deleteLivestream}
                                                        name={index}
                                                        data-toggle="modal"
                                                        data-target="#modalDelete"
                                                    >
                                                        XÓA
                                                    </button>

                                                </td>
                                            </tr>
                                        )
                                    })

                                }

                            </tbody>
                        </table>
                    </div>
                </div>

            </>
        );
    }
}

