import React, { Component } from 'react';
import axios from 'axios';
import { Parser, Player } from 'svga'
import SVGA from 'svgaplayerweb'
import ModalUpdateBirthdayGift from './components/ModalUpdateBirthdayGift';
import { toast } from 'react-toastify';

export default class BirthdayGiftManager extends Component {
    constructor(props) {
        super(props);

        this.state = {
            birthdayGifts:[],
            birthdayGift: null
        }

        this.handleDeleteItem = this.handleDeleteItem.bind(this);
        this.handleRenewItem = this.handleRenewItem.bind(this);
    }

    componentDidMount() {
        axios.post(global.config.apiDomain + "/rest/birthday/getGift")
        .then(res =>{
            this.setState({
                birthdayGifts: res.data.birthdayGiftForUsers
            })
        })
    }

    handleDeleteItem(item){     
        item.deleted = true; 
       
        if(window.confirm("are you sure")){
            axios.post(global.config.apiDomain + "/rest/birthday/createOrUpdate", item)
        .then(res =>{
            toast.success(res.data.message)
        })
        }
    }

    handleRenewItem(item){     
        item.deleted = false; 
       
        if(window.confirm("are you sure")){
            axios.post(global.config.apiDomain + "/rest/birthday/createOrUpdate", item)
        .then(res =>{
            toast.success(res.data.message)
        })
        }
    }


    async loadSVGA(id, url) {
        const parser = new Parser()
        const svga = await parser.load(url)

        const player = new Player(document.getElementById(id))
        await player.mount(svga)

        player.onStart = () => console.log('onStart')

        player.start()
    }

    loadSVGA4(url, canvasId) {
        var parser = new SVGA.Parser('#' + canvasId);
        parser.load(url, function (videoItem) {
            var player = new SVGA.Player('#' + canvasId);
            player.setVideoItem(videoItem);
            // player.setFrame(0, 0, 750, 750);
            player.setContentMode("AspectFill")
            player.startAnimation();
        })
    }

    render(){
        const { birthdayGifts, birthdayGift } = this.state;
        return (
            <>
            <ModalUpdateBirthdayGift birthdayGift={birthdayGift}></ModalUpdateBirthdayGift>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí Quà tặng sinh nhật</h1>
                        <button className='btn btn-success mb-2'
                        data-toggle="modal"
                        data-target="#modalUpdateBirthdayGift"
                        onClick={()=>{
                            this.setState({
                                birthdayGift : null
                            })
                        }}
                        >Thêm Mới</button>
                        <table className="table table-hover">
                            <thead>
                                <tr className="align-middle">
                                    <th className="align-middle" scope="col">ID</th>
                                    <th className="align-middle" scope="col">Loại tài khoản</th>
                                    <th className="align-middle" scope="col">Mô tả</th>
                                    <th className="align-middle" scope="col">Trạng thái</th>
                                    <th className="align-middle" scope="col" colSpan={2}>Tùy chỉnh</th>
                                </tr>
                            </thead>
                            <tbody>
                                {birthdayGifts && birthdayGifts.map((item,index)=>{
                                    return (
                                    <tr>
                                        <td className="align-middle" scope="col">{item.id}</td>
                                        <td className="align-middle" scope="col">{item.typeAccount}</td>
                                        <td className="align-middle" scope="col">{item.description}</td>
                                        <td className="align-middle" scope="col">{item.deleted ? <button className='btn btn-danger'>Đã xóa</button>
                                        : <button className='btn btn-primary'>Đang sử dụng</button>}</td>
                                        <td className="align-middle" scope="col">
                                            <button className='btn btn-success' 
                                                    data-toggle="modal"
                                                    data-target="#modalUpdateBirthdayGift"
                                            onClick={()=>{
                                                this.setState({
                                                    birthdayGift : item
                                                })
                                            }}>Chỉnh sửa</button>
                                        </td>
                                        <td className="align-middle" scope="col">
                                        {item.deleted ? <button className='btn btn-primary'
                                        onClick={()=>{
                                            this.handleRenewItem(item)
                                        }}
                                        >Khôi phục</button>
                                        : <button className='btn btn-danger' 
                                        onClick={()=>{
                                            this.handleDeleteItem(item)
                                        }}>Xóa</button>}
                                        </td>
                                    </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}