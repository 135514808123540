import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';

export default class ModalSurveyStatistics extends Component {
    constructor(props) {
        super(props);

        this.state = {
            statistics: null,
            surveyModal: null,
        }
        this.getSurveyStatistics = this.getSurveyStatistics.bind(this);
    }

    componentWillReceiveProps(props) {
        console.log('ModalSurveyStatistics   ====== componentWillReceiveProps');
        console.log(props.surveyModal);
        this.setState({
            surveyModal: props.surveyModal
        })
        this.getSurveyStatistics(props.surveyModal)
    }

    // componentDidUpdate() {
    //     if (!this.state.statistics && this.state.surveyModal) {
    //         console.log('ModalSurveyStatistics   ====== componentDidUpdate');
            
    //     }
    // }

    getSurveyStatistics(surveyModal) {
        // let statistics = { "statisticsMap": { "Test1": 1, "11111111111": 0, "222222222": 0, "others": 2, "Test2": 3 }, "otherAnswers": ["Khác lắm luôn", "KHÁC"], "totalAnswers": 6 };
        // this.setState({
        //     statistics: statistics
        // })
        let url = global.config.apiDomain + "/rest/survey/getSurveyStatistics"
        axios.post(url, surveyModal)
            .then(res => {
                console.log('getSurveyStatistics');
                console.log(res);
                this.setState({
                    statistics: res.data
                })
            })
    }

    render() {
        const { statistics, surveyModal } = this.state;
        return (
            <div>
                <div className="modal fade" id="modalSurveyStatistics" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title" id="exampleModalCenterTitle">Danh sách trả lời</h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div id="modal-body">
                                <h4 className='mt-1'>Câu hỏi: <span>{surveyModal && surveyModal.question ? surveyModal.question : ""}</span> </h4>
                                <hr />
                                <table className="table table-hover">
                                    <thead>
                                        <tr className="text-center">
                                            <th className="align-middle" scope="col">STT</th>
                                            <th className="align-middle" scope="col">Câu trả lời</th>
                                            <th className="align-middle" scope="col">Số lượng VOTE</th>
                                            <th className="align-middle" scope="col">Phần trăm</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {statistics && Object.keys(statistics.statisticsMap).map((key, index) => {
                                            return (
                                                <>
                                                    <tr key={index}>
                                                        <td className="align-middle text-center" scope="col">{index + 1}</td>
                                                        <td className="align-middle text-center" scope="col">{key}</td>
                                                        <td className="align-middle text-center" scope="col">{statistics.statisticsMap[key]}</td>
                                                        <td className="align-middle text-center" scope="col">{Math.round(statistics.statisticsMap[key] * 100 / statistics.totalAnswers * 100) / 100 + '%'}</td>
                                                    </tr>
                                                </>
                                            )
                                        })
                                        }

                                    </tbody>
                                </table>
                                <hr />

                                <h5>Chi tiết các câu trả lời khác của người dùng</h5>
                                <table className="table table-hover">
                                    <thead>
                                        <tr className="text-center">
                                            <th className="align-middle" scope="col">STT</th>
                                            <th className="align-middle" scope="col">Câu trả lời</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {statistics && statistics.otherAnswers.map((value, index) => {
                                            return (
                                                <tr>
                                                    <td className="align-middle text-center" scope="col">{index + 1}</td>
                                                    <td className="align-middle text-center" scope="col">{value}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}