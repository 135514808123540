import React, {Component} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';
import $ from 'jquery';

import bindModel from '../../../../common/bindModel';

// const $ = window.$;

export default class ModalUpdateCategory extends Component {
    constructor(props) {
        super(props)

        this.state = {
            category: null,
            loading: false
        }

        this.uploadAnimationToS3 = this.uploadAnimationToS3.bind(this);
        this.saveCategory = this.saveCategory.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            category : props.category
        })
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    uploadAnimationToS3(event) {
        var target = event.target;
        if (target.files[0].size > 10495760 ){
            toast.error("Kích thước file phải dưới 10mb.");
            return;
        }

        this.setState({
            ...this.state,
            loading: true
        });


        var category = this.state.category;
        var bucketName = "ikara-data/images/categogyTheme";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";

        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    this.setState({
                        loading: false
                    });

                    category.previewImg = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.state.category.previewImg = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        category: category
                    });

                    if (category.previewImg != null && category.previewImg.endsWith("svga")) {
                        $("#show-animation").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas2\"></div>");
                        this.loadSVGA2(category.previewImg);
                    } else if (category.previewImg != null) {
                        $("#show-animation").children().replaceWith("<img style=\"height: 300px !important\" src=\"" + category.previewImg + "\"  />");
                    }
                }.bind(this),
            });
        }.bind(this);
        fileReader.readAsArrayBuffer(target.files[0]);
    }

    saveCategory() {
        if (
            this.state.category.name === '' || 
            this.state.category.description === '' || 
            this.state.category.previewImg === '' || 
            this.state.category.rank === '' 
        ) {
            return toast.error('Nhập đầy đủ thông tin');
        }

        this.setState({
            loading: true
        });

        axios.post(global.config.apiDomain + '/rest/categories/createOrUpdate', this.state.category).then(res => {
            this.setState({
                loading: true
            });

            window.$("#modalUpdateCategory").modal("hide");
            toast.success(res.data.message);
            this.props.resetCategories();
        })

    }

    render() {
        const model = bindModel(this);
        const {category} = this.state;

        return (
            <div>
                <div className="modal fade" id="modalUpdateCategory" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Tạo Chủ đề Beat Mới</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {category ? (<div className="modal-body">
                                <div id="name" className="row mb-2">
                                    <div className="col-3">
                                        <label>Tên Chủ đề</label>
                                    </div>
                                    <div className="col-9">
                                        <input 
                                            className="form-control mb-2" 
                                            type="text" 
                                            name="name"
                                            // readOnly={category.id == null ? false : true}
                                            placeholder="Nhập tên..."
                                            {...model('category.name')}
                                        />
                                    </div>
                                </div>
                                <div id="description" className="row mb-2">
                                    <div className="col-3">
                                        <label>Mô tả</label>
                                    </div>
                                    <div className="col-9">
                                        <textarea 
                                            className="form-control mb-2" 
                                            type="text" 
                                            name="description"
                                            // readOnly={category.id == null ? false : true}
                                            placeholder="Nhập mô tả..."
                                            {...model('category.description')}
                                        />
                                    </div>
                                </div>
                                <div id="avatar" className="row mb-2">
                                    <div className="col-3">
                                        <label>Ảnh đại diện</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            id="inputGroupFile03"
                                            onChange={this.uploadAnimationToS3} />
                                        <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile03">Choose file</label>
                                    </div>
                                </div>

                                {category.previewImg ? (
                                    <div id="preview" className="row mb-2">
                                        <div className="col-3">
                                            <label></label>
                                        </div>
                                        <div className="col-9">
                                            <img src={category.previewImg} />
                                        </div>
                                    </div>
                                ) : ""}

                                <div id="rank" className="row mb-2">
                                    <div className="col-3">
                                        <label>Đánh giá độ HOT hiển thị</label>
                                    </div>
                                    <div className="col-9">
                                        <input 
                                            className="form-control mb-2" 
                                            type="number" 
                                            name="rank"
                                            // readOnly={category.id == null ? false : true}
                                            placeholder="Nhập số thứ tự..."
                                            {...model('category.rank')}
                                        />
                                        <label className='text-right'>Chủ đề có số thứ tự lớn hơn hiển thị trước</label>
                                    </div>
                                </div>
                            </div>) : ''}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ? 
                                (
                                <button style={{width: "120px"}} className="btn btn-red" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Xác nhận...
                                </button>
                                ) :
                                (
                                <button style={{width: "120px"}} type="button" className="btn btn-red" onClick={this.saveCategory}>Xác nhận</button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}