import { options } from 'numeral';
import React, { Component } from 'react';
import Firebase from '../../components/Firebase.js';

export default class MixRecordingPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            server: "data3",
            logs: [],
            cursor: null,
            lastPage: false,
            listen:  null
        }

        this.ikaraNode = Firebase.database().ref("ikara");
        this.getLogs = this.getLogs.bind(this);
        this.listenNodeLogFirebase = this.listenNodeLogFirebase.bind(this);
    }

    componentWillMount() {
        this.getLogs();
        // this.listenNodeLogFirebase();
    }

    listenNodeLogFirebase() {
        this.state.listen = this.ikaraNode
            .child("console")
            .child("MixRecordingsApplication")
            .child(this.state.server)
            .limitToLast(1);


            this.state.listen.on("child_added", snapshot => {
                let logs = [];

                let log = snapshot.val();
                log.key = snapshot.key;
                console.log(snapshot.val());

                if(!this.state.logs.some(o => o.key == log.key)) {
                    logs.unshift(log);
                }
                // logs.unshift(log);
                this.setState({
                    logs: logs.concat(this.state.logs)
                })

            })
    }


    getLogs() {
        let { cursor, lastPage } = this.state;
        let results;

        if (cursor == null) {
            results = this.ikaraNode
                .child("console")
                .child("MixRecordingsApplication")
                .child(this.state.server)
                .orderByKey()
                .limitToLast(100)
                .get();
        } else {
            results = this.ikaraNode
                .child("console")
                .child("MixRecordingsApplication")
                .child(this.state.server)
                .orderByKey()
                .startAt(cursor)
                .limitToLast(100)
                .get();
        }

        results
            .then(snapshot => {
                const {logs} = this.state;
                snapshot.forEach(value => {
                    var log = value.val();
                    log.key = value.key;
                    cursor = log.key;

                    if(!this.state.logs.some(o => o.key == log.key)) {
                        logs.unshift(log);
                    }
                    
                });
                if (this.state.cursor != null) {
                    logs.splice(0, 1);
                }

                this.setState({
                    logs: logs,
                    cursor: cursor
                });
                this.listenNodeLogFirebase()
            });;
    }

    render() {
        return (
            <div id="content">
                <div className="container">
                    <h2 className="text-center">Mix recording console</h2>
                    <div className="row justify-content-center m-2">
                        {/* <label className="col-form-label mr-2">Chọn server</label> */}
                        <select
                            className="col-6 form-control"
                            value={this.state.server}
                            onChange={(event) => {
                                this.state.server = event.target.value;
                                this.state.cursor = null;
                                this.getLogs();
                                this.state.listen.off();
                                this.listenNodeLogFirebase();
                            }}
                        >
                            <option value="data1">Server 1</option>
                            <option value="data2">Server 2</option>
                            <option value="data3">Server 3</option>
                        </select>
                        <button 
                            className="ml-1 btn btn-outline-secondary"
                            onClick={() => {
                                this.setState({
                                    logs: []
                                })
                            }}
                            >Clear</button>
                    </div>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Content</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.logs.map(log => {
                                return (
                                    <tr key={log.key}>
                                        <td style={{"wordBreak": "break-all"}}>{log.text}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>

                </div>
            </div>
        );

    }
}