import React, { Component } from 'react';
import axios from 'axios';
import BotFollow from './Components/BotFollow';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';

export default class BOTFollowing extends Component {
    constructor(props) {
        super(props);
        const { id } = this.props.match.params;

        this.state = {
            botlist: [],
            bot: null,
            loading: false,
            botId : id
        }
        this.getBOTFollowings = this.getBOTFollowings.bind(this);
    }

    getBOTFollowings() {
        console.log("1111");
        
            axios.post(global.config.apiDomain + "/rest/bot-manager/get-list-bot-following", {
                userId: this.state.botId
            })
                .then(res => {
                    if (res.data.status == "OK") {
                        this.setState({
                            botlist: res.data.botlist
    
                        })
                        console.log(res.data)
                    }
                })
        }
        unfollow =(event) => {
            var un = event.target.name
            console.log(un);
            
            console.log("2222");
                axios.post(global.config.apiDomain + "/rest/bot-manager/bot-unfollow", {
                    botId: this.state.botId,
                    userId : un
                })
                    .then(res => {
                        if (res.data.status == "OK") {
                            console.log(res.data.message
                            );
                            
                       this.getBOTFollowings();
                        }
                    })
            }

    // componentDidUpdate() {
    //     const { botlist, bot } = this.state;
    //     botlist.map((bot, index) => {
    //         // console.log(screen)
    //     })

    // }

    componentDidMount() {
        this.getBOTFollowings();
    }

    render() {
        const { botlist, botId } = this.state;
        return (
            <>
            <BotFollow
                    bot={botId}
                    resetList={this.getBOTFollowings}
                >
                </BotFollow>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí BOT Following</h1>
                        <button
                            className="btn btn-red mb-1"
                            data-toggle="modal"
                            data-target="#modalUpdateVipPrivilege"
                            // onClick={this.addNewRecording}
                        >+ ADD Follow
                        </button>
                        <table className="table table-hover">
                            <thead>
                                <tr className="text-center">
                                    <th className="align-middle" scope="col">STT</th>
                                    <th className="align-middle" scope="col">UID</th>
                                    <th className="align-middle" scope="col">Name</th>
                                    <th className="align-middle" scope="col">Avatar</th>
                                    <th className="align-middle" scope="col">Hành Động</th>


                                </tr>
                            </thead>
                            <tbody>
                                {botlist && botlist.map((item, index) => {
                                    return (
                                        <tr>
                                            <td className="align-middle text-center" scope="col">{index + 1}</td>
                                            <td className="align-middle text-center" scope="col">{item.uid}</td>
                                            <td className="align-middle text-center" scope="col">{item.name}</td>
                                            <td className="align-middle text-center" scope="col">
                                                <img src={item.avatar} alt="" style={{ width: "100px" }} />
                                            </td>
                                            <td className="align-middle text-center" scope="col">
                                               <button 
                                               onClick={this.unfollow}
                                               name ={item.id} 
                                               >Bỏ Theo Dõi </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}

