import React, {Component} from 'react';
import ModalDeleteNotify from './components/ModalDeleteNotify';
import ModalUpdateNotify from './components/ModalUpdateNotify';
import axios from 'axios';
export default class NotifyManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notifies: [],
            notify: null,
        }

        this.getNewNotify = this.getNewNotify.bind(this);
        this.updateNotify = this.updateNotify.bind(this);
        this.getNotifies = this.getNotifies.bind(this);
    }

    componentDidMount() {
        this.getNotifies();
    }

    getNotifies() {
        axios.post("https://www.ikara.co/rest/notification").then(res => {
            this.setState({
                notifies: res.data
            });
            console.log(res.data)
        })
    }

    getNewNotify() {
        axios.post("https://www.ikara.co/rest/notification/new").then(res => {
            let notify = res.data;
            this.setState({
                notify: notify
            });

        })
    }

    updateNotify(notify) {
        this.setState({
            notify: notify
        })
    }

    render() {
        const { notifies, notify } = this.state;
        return (
            <>
                <ModalUpdateNotify notify={notify} resetList={this.getNotifies}></ModalUpdateNotify>
                <ModalDeleteNotify notify={notify} resetList={this.getNotifies}></ModalDeleteNotify>
                <div id="content">

                    <div className="container-fluid">
                        <h2 className="text-center">Quản lí thông báo</h2>
                        {/* <button
                            className="btn btn-red mb-1"
                            data-toggle="modal"
                            data-target="#modalUpdateNotify"
                            onClick={this.getNewNotify}
                        >+ Tạo thông báo
                        </button> */}
        
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th width={200} scope="col">Tiêu đề</th>
                                    <th width={400} scope="col">Nội dung</th>
                                    <th width={300} scope="col">URL</th>
                                    <th scope="col">Thời gian</th>
                                    <th scope="col">App</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {notifies.map((notify, index) => {
                                    return (
                                        <tr key={notify.id}>
                                            <th scope="row">{notify.title.length > 40 ? notify.title.substring(0, 40) + "..." : notify.title}</th>
                                            <td>{notify.message.length > 50 ? notify.message.substring(0, 50) + "..." : notify.message}</td>
                                            <td>{notify.link.length > 40 ? notify.link.substring(0, 40) + "..." : notify.link} </td>
                                            <td>{new Date(notify.timer).toDateString()}</td>
                                            <td>{notify.app}</td>
                                            <td>
                                                <button
                                                    onClick={() => {this.updateNotify(notify) }}
                                                    className="btn btn-red"
                                                    data-toggle="modal"
                                                    data-target="#modalUpdateNotify"

                                                >Sửa</button>
                                                <button
                                                    className="btn btn-red ml-sm-2"
                                                    data-toggle="modal"
                                                    data-target="#modalDeleteNotify"
                                                    onClick={() => { this.updateNotify(notify) }}
                                                >Xóa</button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    }
}
    