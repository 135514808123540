import React, {Component} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';

export default class ModalDeleteShopItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            gift: null,
            loading: false
        }

        this.deleteShopItem = this.deleteShopItem.bind(this);
    }

    componentWillReceiveProps(props) {
        this.setState({
            gift : props.gift
        })
    }

    deleteShopItem() {
        console.log('deleteShopItem');
        this.setState({
            loading: true
        });

        let modal = {...this.state.gift}

        axios.post(global.config.apiDomain + '/rest/shop-items/deleteShopItem', modal).then(res => {
            this.setState({
                loading: false
            });

            window.$("#modalDeleteShopItem").modal("hide");
            if (res.data == true)
                toast.success("Xóa item thành công"); 
            else
                toast.error("Xóa item thành công"); 
            this.props.resetList();
        })
    }

    render() {

        const {gift} = this.state;

        return (
            <div>
                <div className="modal fade" id="modalDeleteShopItem" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Xóa Item</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {gift ? (
                                <div className="modal-body">
                                <h3 className="text-center text-danger">Bạn chắc chắn muốn xóa Item: <span className="font-weight-bold">{gift.name}</span> ?</h3>
                            </div>
                            ) : ""}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ? 
                                (
                                <button style={{width: "120px"}} className="btn btn-red" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Xác nhận...
                                </button>
                                ) :
                                (
                                <button 
                                    style={{width: "120px"}} 
                                    type="button" 
                                    className="btn btn-red"
                                    onClick={this.deleteShopItem}>Xóa</button>    
                                )}                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}