import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';
import Swal from 'sweetalert2';
import bindModel from '../../common/bindModel.js';

export default class Update extends Component {
    constructor(props) {
        super(props);
        this.state = {
            specialGift:{},
            loading : false,
        }
        // this.GiftImg=this.GiftImg.bind(this)
        this.saveVipPrivilege = this.saveVipPrivilege.bind(this);
    }

    componentWillReceiveProps(props) {
        var specialGift = {}
        if (props.specialGift) {
            specialGift = props.specialGift;
            specialGift.startDay = this.formatDate(specialGift.startDay)
            specialGift.endDay = this.formatDate(specialGift.endDay)
            console.log(specialGift);
            this.setState({
                specialGift:specialGift
            })
        }
        


    }
     formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
    saveVipPrivilege = () => {
        let obj = { ...this.state.specialGift };
        this.setState({
            loading: true
        });
            if (obj == null 
            ) {
                toast.error("Vui lòng điền đầy đủ thông tin.");
                this.setState({
                    loading: false,
                });
                return;
            }
            if (obj.price <= obj.priceAfterDiscount 
            ) {
                toast.error("Giá sau khi giảm phải nhỏ hơn giá gốc");
                this.setState({
                    loading: false,
                });
                return;
            }
            const end = new Date(obj.endDay);
            const current = new Date();
            const start = new Date(obj.startDay);
            if (start >= end) {
                toast.error("Ngày bắt đầu phải hơn ngày kết thúc ");
                this.setState({
                    loading: false,
                });
                return;
            }
            if(end < current  && obj.id == 0){
                toast.error("Ngày kết thúc phải lớn hơn ngày hiện tại ");
                this.setState({
                    loading: false,
                });
                return;
            }
            else {
                console.log(obj.id);
                if(obj.id == 0){
                    axios.post(global.config.apiDomain + '/rest/SpecialGiftDiscount/create', obj
                    )
                        .then(res => {
                            if (res.data.status == "OK") {
                                window.$("#update").modal("hide");
                                this.setState({
                                    loading: false
                                });
                                this.props.resetList();
                                toast.success(res.data.message);
                            } else {
                                toast.warning(res.data.message);
                            }
                        }, error => {
                            this.setState({
                                loading: false
                            });
                            window.$("#update").modal("hide");
                            toast.error(error);
                        })
                }
                else{
                    if (end < current) {
                        Swal.fire({
                            title: 'Xác nhận',
                            text: 'Bạn có chắc chắn muốn huỷ giảm giá ?',
                            icon: 'question',
                            showCancelButton: true,
                            confirmButtonText: 'OK',
                            cancelButtonText: 'Hủy bỏ',
                            allowOutsideClick: false,
                            preCancel: (code) =>{
                                this.setState({
                                    loading: false,
                                });
                                return;
                           
                            },
                            preConfirm: (code) =>{
                                axios.post(global.config.apiDomain + '/rest/SpecialGiftDiscount/update', obj
                                )
                                    .then(res => {
                                        if (res.data.status == "OK") {
                                            window.$("#update").modal("hide");
                                            this.setState({
                                                loading: false
                                            });
                                            this.props.resetList();
                                            toast.success(res.data.message);
                                        } else {
                                            toast.warning(res.data.message);
                                        }
                                    }, error => {
                                        this.setState({
                                            loading: false
                                        });
                                        window.$("#update").modal("hide");
                                        toast.error(error);
                                    })
                            }
                    })
                        
                    }
                    else{
                        axios.post(global.config.apiDomain + '/rest/SpecialGiftDiscount/update', obj
                        )
                            .then(res => {
                                if (res.data.status == "OK") {
                                    window.$("#update").modal("hide");
                                    this.setState({
                                        loading: false
                                    });
                                    this.props.resetList();
                                    toast.success(res.data.message);
                                } else {
                                    toast.warning(res.data.message);
                                }
                            }, error => {
                                this.setState({
                                    loading: false
                                });
                                window.$("#update").modal("hide");
                                toast.error(error);
                            })
                    }
                    
                }
            }
           
        
    }

    render() {
        const { loading, vipPrivilege, Gifts, update ,type,Types} = this.state;
        const model = bindModel(this);
        return (
            <div>
                <div className="modal fade" id="update" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật giảm giá</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div id="levelRequirement" className="row mb-2">
                                    <div className="col-3">
                                        <label>Giá</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="number"
                                            name="levelRequirement"
                                            placeholder="Nhập giá gốc của thẻ"
                                            {...model('specialGift.price')}
                                        />
                                    </div>
                                </div>
                                <div id="levelRequirement" className="row mb-2">
                                    <div className="col-3">
                                        <label>Discount</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="number"
                                            name="levelRequirement"
                                            placeholder="Nhập phần trăm giảm giá"
                                            {...model('specialGift.discount')}
                                        />
                                    </div>
                                </div>
                                <div id="levelRequirement" className="row mb-2">
                                    <div className="col-3">
                                        <label>Giá sau khi giảm</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="number"
                                            name=""
                                            placeholder="Nhập giá sau khi giảm"
                                            {...model('specialGift.priceAfterDiscount')}
                                        />
                                    </div>
                                </div>
                                <div id="levelRequirement" className="row mb-2">
                                    <div className="col-3">
                                        <label>ngày bắt đầu</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="date"
                                            name="levelRequirement"
                                            placeholder="Chọn ngày bắt đầu"
                                           
                                            {...model('specialGift.startDay')}
                                        />
                                    </div>
                                </div><div id="levelRequirement" className="row mb-2">
                                    <div className="col-3">
                                        <label>Ngày Kết thúc</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="date"
                                            name="levelRequirement"
                                            placeholder="Chọn Ngày kết thúc"
                                            {...model('specialGift.endDay')}
                                        />
                                    </div>
                                </div>



                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.saveVipPrivilege}>Xác nhận</button>
                                    )}
                            </div>
                        </div>

                    </div>
            </div>
            </div>
</div>
        );
    }
}
//    "postbuild":  "rm -r /Users/quhuy/ikaragae/src/main/webapp/react-source && mkdir /Users/quhuy/ikaragae/src/main/webapp/react-source && mv -v build/* /Users/quhuy/ikaragae/src/main/webapp/react-source",
