import React, { Component } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import SellerId from './components/SellerId';

export default class ManagerSellerPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            originalSellers: [],
            sellers: [],
            searchField: '',
            inputValue: '' 
        };

        this.getAllSeller = this.getAllSeller.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    getAllSeller() {
        axios.get(global.config.apiDomain + '/rest/seller/getAllSeller')
            .then(res => {
                this.setState({
                    originalSellers: res.data.models,
                    sellers: res.data.models
                });
            })
    }

    componentDidMount() {
        this.getAllSeller();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        this.setState({
            inputValue: value
        });
    }

    handleSearch() {
        const { searchField, inputValue, originalSellers } = this.state;
        
        if (!searchField) {
            this.setState({
                sellers: originalSellers
            });
            return;
        }
        
        if (!inputValue) {
            return;
        }
    
        const filteredSellers = originalSellers.filter(seller => {
            return seller[searchField].toLowerCase().includes(inputValue.toLowerCase());
                      
        });
    
        this.setState({
            sellers: filteredSellers
        });
    }
    

    handleSelectChange(event) {
        const { value } = event.target;
        this.setState({
            searchField: value,
            inputValue: ''
        });
    }

    render() {
        const { sellers, searchField, inputValue } = this.state;

        return (
            <>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lý seller</h1>
                        <div>
                            <select value={searchField} onChange={this.handleSelectChange} style={{ height: '30px' }}>
                                <option value="">All</option>
                                <option value="id">ID</option>
                                <option value="name">Tên</option>
                            </select>
                            {['id', 'name'].includes(searchField) ?
                                <input type="text" value={inputValue} onChange={this.handleInputChange} /> :
                                null
                            }
                            <button onClick={this.handleSearch}>Tìm kiếm</button>
                        </div>
                        <table className="table table-hover">
                            <thead>
                                <tr className="text-center">
                                    <th className="align-middle" scope="col">ID seller</th>
                                    <th className="align-middle" scope="col">Tên seller</th>
                                    <th className="align-middle" scope="col">Tổng hoa hồng nhận được</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    sellers && sellers.map((item) => {
                                        return (
                                            <tr>
                                                <td className="align-middle text-center" scope="col">{item.id}</td>
                                                <td className="align-middle text-center" scope="col">{item.name}</td>
                                                <td className="align-middle text-center" scope="col">{item.totalBonus}</td>
                                                <button className='btn btn-success ml-2 mr-2' data-toggle="modal" data-target="#modalCreate"
                                                    onClick={() => { window.location.href = `/admin/seller-manager/sellerId/${item.id}` }}>Xem chi tiết</button>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}
