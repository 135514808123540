import React, { Component } from 'react';
import { detectApp, detectMob } from '../common/utils.js';
import { withTranslation } from 'react-i18next';
import { setCookie } from '../common/cookie.js';
import MyContext from '../common/MyContext';
import { toast } from 'react-toastify';
import AWS from 'aws-sdk';
import $ from 'jquery';
import { Link, Route } from "react-router-dom";

class SingButton extends Component {
    constructor(props) {
        super(props)

    }

    renderSingButton() {
        const { user } = this.context;
        if (detectApp("my.okara")) {
            if ((user != null)) {
                if (user.vipOkara) {
                    return detectMob() == "Android" ? (
                        <div className='text-center p-2'>
                            <a href='https://play.google.com/store/apps/details?id=la.unitel.okara' id="sing-button" className='btn btn-red w-75'>Sing</a>
                        </div>)
                        : (
                            <div className='text-center p-2'>
                                <a href='https://apps.apple.com/us/app/okara-laos-karaoke/id1331935480' id="sing-button" className='btn btn-red w-75'>Sing</a>
                            </div>
                        );
                } else {
                    return (
                        <div className='text-center p-2'>
                            <a id="sing-button" className='btn btn-red w-75' data-toggle="modal" data-target="#modalGetVipOkara">Sing</a>
                        </div>);
                }
            } else {
                return (
                    <div className='text-center p-2'>
                        <Link to="/login" id="sing-button" className='btn btn-red w-75'>Sing</Link>
                    </div>);
            }
        } else {
            if ((user != null)) {
                if (user.vipOkara) {
                    return detectMob() == "Android" ? (
                        <div className='text-center p-2'>
                            <a href='https://play.google.com/store/apps/details?id=la.unitel.okara' id="sing-button" className='btn btn-red w-75'>ຮ້ອງເພງ</a>
                        </div>)
                        : (
                            <div className='text-center p-2'>
                                <a href='https://apps.apple.com/us/app/okara-laos-karaoke/id1331935480' id="sing-button" className='btn btn-red w-75'>ຮ້ອງເພງ</a>
                            </div>
                        );
                } else {
                    return (
                        <div className='text-center p-2'>
                            <a id="sing-button" className='btn btn-red w-75' data-toggle="modal" data-target="#modalGetVipOkara">ຮ້ອງເພງ</a>
                        </div>);
                }
            } else {
                return (
                    <div className='text-center p-2'>
                        <Link to="/login" id="sing-button" className='btn btn-red w-75'>ຮ້ອງເພງ</Link>
                    </div>);
            }
        }
    }

    render() {

    if (detectApp("my.okara")) {
        return (
            <div id="sing-button" className='fixed-bottom d-block d-lg-none text-center m-3'>
                {this.renderSingButton()}
            </div>
            )  
        }
        return (
            <div id="sing-button" className='fixed-bottom d-md-none d-block text-center m-3'>
                {this.renderSingButton()}
            </div>
        )
    }
}


SingButton.contextType = MyContext;
export default withTranslation()(SingButton)