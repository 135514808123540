import React, {Component} from 'react';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import bindModel from '../../../../common/bindModel.js';
import ShowGameTypes from './components/ShowGameTypes.js';
import AddGameType from './components/AddGameType.js';
import axios from 'axios';

export default class CreateAndUpdateGameLevelPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            gameLevel: {
                gameTypes: []
            },
            gameTypes: [],
            iGameType: null,
            loading: false
        }

        this.saveGameLevel = this.saveGameLevel.bind(this);
        this.getGameTypes = this.getGameTypes.bind(this);
        this.addGameType = this.addGameType.bind(this);
        this.removeGameType = this.removeGameType.bind(this);
        this.getGameLevel = this.getGameLevel.bind(this);
    }

    componentDidMount(){
        let gameLevelId = this.props.match.params.gameLevelId;
    
        if(gameLevelId != null) {
            this.getGameLevel(gameLevelId);
        } else {
            let gameLevel = {
                gameTypes: []
            }

            this.setState({
                gameLevel : gameLevel
            });
        }

        this.getGameTypes();

    }

    // componentWillMount() {
    //     let gameTypes = [
    //         {
    //             id: 1,
    //             activeImage: "https://s3.us-west-002.backblazeb2.com/ikara-data/images/banners/e6976e9a-cf9f-5d68-46a0-36434c0c7177.png",
    //             disabledImage: "https://s3.us-west-002.backblazeb2.com/ikara-data/images/banners/e6976e9a-cf9f-5d68-46a0-36434c0c7177.png",
    //             name: "Nghe nhạc đoán tên bài hát",
    //             title: "test_title1",
    //             description: "test_description1",
    //             gameQuestions: [
    //                 {
    //                     id: 1,
    //                     content: "Bạn muốn ăn gì",
    //                     description: "Bạn muốn ăn gì trong bữa tối",
    //                     imageQuestion: "https://s3.us-west-002.backblazeb2.com/ikara-data/images/banners/e6976e9a-cf9f-5d68-46a0-36434c0c7177.png",
    //                     imageAnswer: "https://s3.us-west-002.backblazeb2.com/ikara-data/images/banners/e6976e9a-cf9f-5d68-46a0-36434c0c7177.png",
    //                     audioQuestion: "https://data3.ikara.co/data/karaokes/youtube/Ghu6TbYnY4w.mp3",
    //                     answerId: 2,
    //                     gameAnswers: [
    //                         {
    //                             id: 1,
    //                             content: "Cơm",
    //                             description: "Cơm mặn",
    //                             isRight: false                            
    //                         },
    //                         {
    //                             id: 2,
    //                             content: "Bún",
    //                             description: "Bún chả",
    //                             isRight: false                            
    //                         },
    //                         {
    //                             id: 3,
    //                             content: "Mì",
    //                             description: "Mì xào",
    //                             isRight: true                            
    //                         },
    //                         {
    //                             id: 4,
    //                             content: "Cơm",
    //                             description: "Cơm mặn",
    //                             isRight: false                            
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     id: 2,
    //                     content: "Bạn muốn đi đâu",
    //                     description: "Bạn muốn đi đâu vào ngày mai",
    //                     imageQuestion: "https://s3.us-west-002.backblazeb2.com/ikara-data/images/banners/e6976e9a-cf9f-5d68-46a0-36434c0c7177.png",
    //                     imageAnswer: "https://s3.us-west-002.backblazeb2.com/ikara-data/images/banners/e6976e9a-cf9f-5d68-46a0-36434c0c7177.png",
    //                     audioQuestion: "https://data3.ikara.co/data/karaokes/youtube/Ghu6TbYnY4w.mp3",
    //                     answerId: 3,
    //                     gameAnswers: [
    //                         {
    //                             id: 5,
    //                             content: "Mĩ",
    //                             description: "Mĩ Tho",
    //                             isRight: false                            
    //                         },
    //                         {
    //                             id: 6,
    //                             content: "Nghệ",
    //                             description: "Nghệ An",
    //                             isRight: false                            
    //                         },
    //                         {
    //                             id: 7,
    //                             content: "Quảng",
    //                             description: "Quảng Bình",
    //                             isRight: false                            
    //                         },
    //                         {
    //                             id: 8,
    //                             content: "Phú",
    //                             description: "Phú Yên",
    //                             isRight: true                            
    //                         }
    //                     ]
    //                 },
    //                 {
    //                     id: 3,
    //                     content: "Bài hát này có tên là gì",
    //                     description: "Bài hát này có tên là gì ?",
    //                     imageQuestion: "https://s3.us-west-002.backblazeb2.com/ikara-data/images/banners/e6976e9a-cf9f-5d68-46a0-36434c0c7177.png",
    //                     imageAnswer: "https://s3.us-west-002.backblazeb2.com/ikara-data/images/banners/e6976e9a-cf9f-5d68-46a0-36434c0c7177.png",
    //                     audioQuestion: "https://data3.ikara.co/data/karaokes/youtube/Ghu6TbYnY4w.mp3",
    //                     answerId: 0,
    //                     gameAnswers: [
    //                         {
    //                             id: 9,
    //                             content: "Anh sai rồi",
    //                             description: "Sơn tùng MTP",
    //                             isRight: true                            
    //                         },
    //                         {
    //                             id: 10,
    //                             content: "Anh nắng của anh",
    //                             description: "Đức Phúc",
    //                             isRight: false                            
    //                         },
    //                         {
    //                             id: 11,
    //                             content: "Ngày em đẹp nhất",
    //                             description: "Lofi",
    //                             isRight: false                            
    //                         },
    //                         {
    //                             id: 12,
    //                             content: "Hoa cỏ lau",
    //                             description: "Phong Max",
    //                             isRight: false                            
    //                         }
    //                     ]
    //                 }
    //             ]
    //         },
    //         {
    //             id: 2,
    //             activeImage: "https://s3.us-west-002.backblazeb2.com/ikara-data/images/banners/e6976e9a-cf9f-5d68-46a0-36434c0c7177.png",
    //             disabledImage: "https://s3.us-west-002.backblazeb2.com/ikara-data/images/banners/e6976e9a-cf9f-5d68-46a0-36434c0c7177.png",
    //             name: "Nhìn hình đoán tên bài hát",
    //             title: "test_title2",
    //             description: "test_description2",
    //             gameQuestions: []
    //         }
    //     ]

    //     this.setState({
    //         gameLevel : {
    //             level: 1,
    //             gameTypes: []
    //         }
    //     })

    //     this.getGameTypes();
    // }


    saveGameLevel() {
        this.setState({
            loading: true
        })

        const {gameLevel} = this.state;
        
        axios.post(global.config.apiDomain + '/rest/game/level/save', JSON.stringify(gameLevel), {
          headers : {
            'Content-Type': 'application/json'
          }
        }).then((res) => {
            const {status, message, gameLevel} = res.data;
        
            if(status == "OK") {
                toast.success(message);

                this.setState({
                    gameLevel: gameLevel
                });

                // this.props.history.push("/admin/game-manager")
            } else {
                toast.error(message);
            }
            this.setState({
                loading: false
            })
        })
    }

    addGameType(iGameType) {
        let {gameLevel, gameTypes} = this.state;
        if(iGameType != null && iGameType != "") {
            let gameType = JSON.parse(JSON.stringify(gameTypes[iGameType]));

            gameLevel.gameTypes.push(gameType);

            this.setState({
                gameLevel: gameLevel
            });
        } else {
            toast.warn("Vui lòng chọn thể loại game mà bạn muốn thêm !!!")
        }
    }

    removeGameType(index) {
        let {gameLevel} = this.state;

        gameLevel.gameTypes.splice(index, 1);

        this.setState({
            gameLevel: gameLevel
        });

    }

    getGameTypes() {
        // let gameTypes = [
        //     {
        //         id: 1,
        //         activeImage: "https://s3.us-west-002.backblazeb2.com/ikara-data/images/banners/e6976e9a-cf9f-5d68-46a0-36434c0c7177.png",
        //         disabledImage: "https://s3.us-west-002.backblazeb2.com/ikara-data/images/banners/e6976e9a-cf9f-5d68-46a0-36434c0c7177.png",
        //         name: "Nghe nhạc đoán tên bài hát",
        //         title: "test_title1",
        //         description: "test_description1",
        //         gameQuestions: []
        //     },
        //     {
        //         id: 2,
        //         activeImage: "https://s3.us-west-002.backblazeb2.com/ikara-data/images/banners/e6976e9a-cf9f-5d68-46a0-36434c0c7177.png",
        //         disabledImage: "https://s3.us-west-002.backblazeb2.com/ikara-data/images/banners/e6976e9a-cf9f-5d68-46a0-36434c0c7177.png",
        //         name: "Nhìn hình đoán tên bài hát",
        //         title: "test_title2",
        //         description: "test_description2",
        //         gameQuestions: []
        //     },
        //     {
        //         id: 3,
        //         activeImage: "https://s3.us-west-002.backblazeb2.com/ikara-data/images/banners/e6976e9a-cf9f-5d68-46a0-36434c0c7177.png",
        //         disabledImage: "https://s3.us-west-002.backblazeb2.com/ikara-data/images/banners/e6976e9a-cf9f-5d68-46a0-36434c0c7177.png",
        //         name: "Ghép lời bài hát theo câu",
        //         title: "test_title3",
        //         description: "test_description3",
        //         gameQuestions: []
        //     },
        //     {
        //         id: 4,
        //         activeImage: "https://s3.us-west-002.backblazeb2.com/ikara-data/images/banners/e6976e9a-cf9f-5d68-46a0-36434c0c7177.png",
        //         disabledImage: "https://s3.us-west-002.backblazeb2.com/ikara-data/images/banners/e6976e9a-cf9f-5d68-46a0-36434c0c7177.png",
        //         name: "Nhìn lời đoán tên bài hát",
        //         title: "test_title3",
        //         description: "test_description3",
        //         gameQuestions: []
        //     }
        // ]
        // this.setState({
        //     gameTypes: gameTypes
        // })

        axios.post(global.config.apiDomain + '/rest/game/type/list').then((res) => {
            const gameTypes = res.data;
      
            this.setState({
                gameTypes: gameTypes
            });
        })
    }

    getGameLevel(gameLevelId) {

        axios.post(global.config.apiDomain + '/rest/game/level/detail', JSON.stringify(gameLevelId), {
          headers : {
            'Content-Type': 'application/json'
          }
        }).then((res) => {
          const gameLevel = res.data;
    
          this.setState({
            gameLevel: gameLevel
          });
        })
    }

    render() {
        const model = bindModel(this);
        let {gameLevel, gameTypes, loading} = this.state;
        console.log(gameLevel.id == undefined)
        return (
            <div id="content">
                <div className="d-flex justify-content-between">
                    <Link style={{ width: "100px" }} to="/admin/game-manager" className="btn btn-secondary my-auto"><i className="fa fa-arrow-left" aria-hidden="true"></i>&nbsp;&nbsp; Trở về</Link>
                    <h1>Tạo Level</h1>
                    <div></div>
                </div>
                <div className="mt-3">
                    <div className="row justify-content-center mt-2">
                        <div className="col-4 col-lg-2 my-auto">
                            <label className="m-0">Level:</label>
                        </div>
                        <div className="col-8 col-lg-5">
                            <input
                                className="form-control"
                                type="number"
                                name="gameLevel.level"
                                {...model('gameLevel.level')}
                            />
                        </div>
                    </div>

                    <ShowGameTypes 
                        gameTypesAdded={gameLevel.gameTypes} 
                        gameTypes={gameTypes} 
                        removeGameType={this.removeGameType}>
                    </ShowGameTypes>

                    <AddGameType 
                        gameTypes={gameTypes} 
                        addGameType={this.addGameType}>
                    </AddGameType>

                    <div className="row justify-content-center my-3">
                        {loading ?
                        (
                            <button 
                                style={{ width: "120px" }} 
                                className="btn btn-primary" 
                                type="button" disabled
                            >
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                {gameLevel.id ? (<>Lưu <i className="fa fa-refresh" aria-hidden="true"></i></>) : <>Tạo <i className="fa fa-plus" aria-hidden="true"></i></>}
                            </button>
                        ) :
                        (
                            <button 
                                style={{ width: "120px" }} 
                                type="button" 
                                className="btn btn-primary" 
                                onClick={this.saveGameLevel}
                            >
                                {gameLevel.id ? (<>Lưu <i className="fa fa-refresh" aria-hidden="true"></i></>) : <>Tạo <i className="fa fa-plus" aria-hidden="true"></i></>}
                            </button>
                        )}
                    </div>
                </div>

            </div>
        );
    }
}