import React, {Component} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';
import bindModel from '../../../common/bindModel.js'


const $ = window.$;
export default class ModalBanOnllyAcc extends Component {
    constructor(props) {
        super(props);

        this.state = {
            time: 1,
            reason: "",
            loading: false,
            banType: "ban"
        }

        this.banAcc = this.banAcc.bind(this);
    }

    banAcc() {
      console.log('vao banAcc()');
        this.setState({
          loading: true
        });
        let json = {
            id: this.props.id,
            time: this.state.time,
            reason: this.state.reason,
            banType: this.state.banType
        }
        console.log("json")
        console.log(json)
        axios.post(global.config.apiDomain + '/rest/admin/banOnlyUser', JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            
            this.setState({
                loading: false
            });
            if(res.data.status == "OK") {
                $('#modalBanOnllyAcc').modal('hide');
                toast.success(res.data.message);
                this.props.resetAccount();
            } else {
                toast.warning(res.data.message);
            }
        }, err => {
            this.setState({
              loading: false
            });
            toast.error("Khóa tài khoản thất bại");
        })

    }


    render() {
        const model = bindModel(this);
        return (
            <div className="modal fade" id="modalBanOnllyAcc" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog  modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Khóa tài khoản</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                  <div >
                    <label>Loại cấm</label>
                    <select
                      className="form-control"
                      {...model('banType')}>
                      <option value="ban">Cấm tài khoản</option>
                      <option value="banSpeech">Cấm phát ngôn</option>
                    </select>
                  </div><br></br>
                    <div>
                        <label>Thời hạn</label>
                        <select 
                            className="form-control"
                            {...model('time')}>
                            <option value={1}>1 ngày</option>
                            <option value={2}>3 ngày</option>
                            <option value={3}>7 ngày</option>
                            <option value={4}>15 ngày</option>
                            <option value={5}>1 tháng</option>
                            <option value={6}>Vô thời hạn</option>
                        </select>
                    </div><br></br>
                    <div>
                        <label>Lý do</label>
                        <textarea 
                            className="form-control" 
                            rows={4} 
                            {...model('reason')}/>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                    {this.state.loading ? 
                    (
                    <button style={{width: "120px"}} className="btn btn-red" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Xác nhận...
                    </button>
                    ) :
                    (
                    <button style={{width: "120px"}} type="button" className="btn btn-red" onClick={this.banAcc}>Xác nhận</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
    }
}