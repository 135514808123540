import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';

import bindModel from '../../../common/bindModel.js';
import $ from 'jquery';
import AWS from 'aws-sdk';

export default class ModalUpdateGiftLevel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            promotion: null,
            types: [],
            loading: false,
            gifts: [],
        }

        this.saveGiftLevel = this.saveGiftLevel.bind(this);
        this.handleCheckGift = this.handleCheckGift.bind(this);
    }

    componentWillReceiveProps(props) {
        let giftLevel = JSON.parse(JSON.stringify(props.giftLevel));

        this.setState({
            giftLevel: giftLevel
        })
    }

    saveGiftLevel() {
        this.setState({
            loading: true
        });

        axios.post(global.config.apiDomain + '/rest/gift-level/save',
            {
                name: this.state.giftLevel.name,
                level: this.state.giftLevel.level,
            }, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                loading: false
            });
            if (res.data.status == "OK") {
                window.$("#modalUpdateGiftLevel").modal("hide");
                toast.success(res.data.message);
                this.props.resetList();
            } else {
                toast.warning(res.data.message);
            }
        }, error => {
            toast.error('Cập nhật thất bại');
        })
    }

    handleCheckGift() {
        axios.post(global.config.apiDomain + '/rest/gifts/getDetail', this.state.giftLevel.name, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                let gift = res.data.gift;
                console.log("gift: ", gift);
                let giftLevel = this.state.giftLevel;
                console.log("bf:", giftLevel);
                giftLevel.image = gift.url || null;
                giftLevel.name = gift.id || null;
                giftLevel.icoin = gift.sellPrice || 0;
                console.log("after: ", giftLevel)
                this.setState({ giftLevel: giftLevel })

            })
            .catch(error => {
                console.error("Error:", error);
            });;
    }

    render() {
        const model = bindModel(this);
        const { giftLevel, gifts } = this.state;

        return (
            <div>
                <div className="modal fade" id="modalUpdateGiftLevel" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật quà level</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div id="level" className="row mb-2">
                                    <div className="col-3">
                                        <label>Level</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="level"
                                            // readOnly={true}
                                            {...model('giftLevel.level')} />
                                    </div>
                                </div>
                                <div id="name" className="row mb-2">
                                    <div className="col-3">
                                        <label>Name</label>
                                    </div>
                                    <div className="col-8">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="name"
                                            // readOnly={true}
                                            {...model('giftLevel.name')} />
                                    </div>
                                    <div className="col-1">
                                        <button
                                            className="btn btn-outline-success"
                                            type="button"
                                            id="button-addon2"
                                            onClick={this.handleCheckGift}>
                                            <i className="fa fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                                <div id="image" className="row mb-2">
                                    <div className="col-3"></div>
                                    <div className="col-9">
                                        {giftLevel && giftLevel.image !== null ? <img
                                            src={giftLevel.image}
                                        // alt={giftLevel.name}
                                        // style={{ width: '200px', marginTop: '20px' }} 
                                        /> : ""}
                                    </div>
                                </div>
                                <div id="icoin" className="row mb-2">
                                    <div className="col-3">
                                        <label>Icoin</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="icoin"
                                            readOnly={true}
                                            {...model('giftLevel.icoin')} />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.saveGiftLevel}>Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}