import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import bindModel from '../../../common/bindModel.js'
import $ from 'jquery';

const jQuery  = window.$;
export default class ModalChangeAvatar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            avatar: "",
            imagePreview: null
        }

        this.changeAvatar = this.changeAvatar.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
    }

    uploadFile(event) {
        var target = event.target;
        var allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];

        if (!allowedImageTypes.includes(target.files[0].type)) {
            toast.warn("Vui lòng chọn một file ảnh hợp lệ (JPEG, PNG, hoặc GIF).");
            return;
        }

        this.setState({
            loading: true
        });

        var id = target.id;
        var bucketName = "ikara-data";
        var keyName = "avatars/" + target.files[0].name;
        var contentType = target.files[0].type;

        var self = this;
        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType);

            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data);
                    this.setState({
                        loading: false
                    });

                    var imageUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;

                    this.setState({
                        avatar: imageUrl,
                        imagePreview: imageUrl
                    });
                    toast.success("Tải ảnh lên thành công.");
                    console.log(imageUrl);
                }.bind(this),
                error: function (jqXHR, exception) {
                    toast.error("Lỗi khi tải lên ảnh.");
                    console.error(jqXHR, exception);
                    this.setState({
                        loading: false
                    });
                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);
    }

    changeAvatar() {
        this.setState({
            loading: true
        });
        let json = {
            avatar: this.state.avatar,
            userId: this.props.account.id,
            language: this.props.account.language
        }
        console.log("json")
        console.log(json)
        axios.post(global.config.apiDomain + '/rest/admin/updateAvatar', JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {

            this.setState({
                loading: false
            });
            if (res.data.status == "OK") {
                jQuery('#modalChangeAvatar').modal('hide');
                toast.success(res.data.message);
                this.props.resetAccount();
            } else {
                toast.warning(res.data.message);
            }
        }, err => {
            this.setState({
                loading: false
            });
            toast.error("Thay đổi Avatar thất bại");
        })

    }

    render() {
        const { imagePreview } = this.state;
        const model = bindModel(this);
        return (
            <div className="modal fade" id="modalChangeAvatar" tabIndex="-1" role="dialog" aria-labelledby="modalChangeAvatarLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modalChangeAvatarLabel">Chọn ảnh mới</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <input
                                type="file"
                                onChange={this.uploadFile}
                                className="form-control-file"
                                accept=".jpg, .jpeg, .png" />
                            {imagePreview && (
                                <img src={imagePreview} alt="Ảnh đại diện" className="img-fluid mt-2" />
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Đóng</button>
                            <button type="button" className="btn btn-primary" onClick={this.changeAvatar} disabled={!this.state.imagePreview}>Xác nhận</button>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
