import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import SVGA from 'svgaplayerweb'
import $ from 'jquery';

export default class ModalAddShopItemForUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            account: null,
            shopItems: [],
            showFrame: [],
            addItem: null,
            loading: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.getFrameByType = this.getFrameByType.bind(this);
        this.handleAddFrame = this.handleAddFrame.bind(this);
        this.handleChoiceFrame = this.handleChoiceFrame.bind(this);
    }

    componentWillReceiveProps(){
        const { account, allItems } = this.props;

        if(account != null && allItems != null){
            this.setState({
                account: account,
                shopItems: allItems,
                addItem: null,
            })
        }
    }

    getFrameByType(type){
        const { shopItems } = this.state;

        let arr = [];
       if(shopItems != null && shopItems.length > 0){
            for(let i=0; i < shopItems.length ; i++){
                if(shopItems[i].type == type){
                    arr.push(shopItems[i])
                }
            }
            this.setState({
                showFrame: arr
            })
       }
    }

    loadSVGA4(url) {
        var player = new SVGA.Player('#demoCanvas4');
        var parser = new SVGA.Parser('#demoCanvas4');
        parser.load(url, function (videoItem) {
            console.log(videoItem)
            player.setVideoItem(videoItem);
            player.startAnimation();
        })
    }

    handleChoiceFrame(e){
        const { showFrame} = this.state;

        let valueAdd = e.target.value;
        for(let i = 0 ; i < showFrame.length; i++){
            if(showFrame[i].idPublic == valueAdd){
                this.setState({
                    addItem: showFrame[i]
                });

                if (showFrame[i].resourceUrl != null && showFrame[i].resourceUrl.endsWith("svga")) {
                    $("#show-animation_resourceUrl").children().replaceWith("<div style=\"height: 300px !important\" id=\"demoCanvas4\"></div>");
                    this.loadSVGA4(showFrame[i].resourceUrl);
                }else  if (showFrame[i].resourceUrl != null) {
                    $("#show-animation_resourceUrl").children().replaceWith("<img style=\"height: 300px !important\" src=\"" + showFrame[i].resourceUrl + "\"  />");
                }
            }
        }
    }

    handleAddFrame(){
        const { account, addItem } = this.state;

        if(addItem == null ){
            toast.success("Chọn vật phẩm")
            return
        }   

        this.setState({
            loading: true
        });


        if(account != null && addItem != null) {
            let language =   account.appUsing == "YOKARA" ? "en.yokara": "vi";
            var json = {
                userId: account.id,
                language: language,
                shopItem: addItem
            }
            
            axios.post(global.config.apiDomain + '/rest/admin/addItemForUser', json, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                if(res.data.status == "OK"){
                    window.$("#modalAddShopItemForUser").modal("hide");
                    this.setState({
                        loading: false
                    });
                    toast.success("thêm thành công")
                }else{
                    toast.success("thêm thất bại, vui lòng kiểm tra lại thông tin")
                }
            })
        }
    }

    handleChange(event){
        let value = event.target.value;
        switch(value){
            case "userDecoration": 
                this.getFrameByType("UserDecoration");
                break;
            case "roomEntryEffect": 
                this.getFrameByType("RoomEntryEffect");
                break;
            case "personalCard": 
                this.getFrameByType("PersonalCard");
                break;
            case "commentFrame": 
                this.getFrameByType("CommentFrame");
                break;
            default :
                this.getFrameByType("AvatarFrame");

        }
    }

    render(){

        const { showFrame } = this.state;

        return (
            <div>
            <div className="modal fade" id="modalAddShopItemForUser" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Thêm Vật phẩm vào túi người dùng</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div id="order" className="row mb-2">
                                <div className="col-3">
                                    <label>Loại vật phẩm</label>
                                </div>
                                <div className="col-9">
                                    <select name="choiceType" id="choiceType"
                                    onChange={this.handleChange}>
                                        <option value="avatarFrame">Chọn loại vật phẩm</option>
                                        <option value="avatarFrame">Avatar Frame</option>
                                        <option value="userDecoration">User Decoration</option>
                                        <option value="roomEntryEffect">Room Entry Effect</option>
                                        <option value="personalCard">Personal Card</option>
                                        <option value="commentFrame">Comment Frame</option>
                                    </select>
                                </div>
                            </div>
                            <div id="order" className="row mb-2">
                                <div className="col-3">
                                    <label>Vật phẩm thêm</label>
                                </div>
                                <div className="col-9">
                                    <select name="choiceFrame" id="choiceFrame"
                                    onChange={this.handleChoiceFrame}>
                                        <option value="-1" key={"default"}>Chọn</option>
                                        {
                                            showFrame.length > 0 && 
                                            showFrame.map((item,index) => {
                                               return  <option value={item.idPublic} key={index}>{item.name}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="row mb-2">
                                    <div className="col-3">
                                    </div>
                                    <div id="show-animation_resourceUrl" className="col-9" style={{ maxHeight: "300px" }}>
                                        <div width={500} height={500} id="canvas"></div>
                                    </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal" >Hủy</button>
                                {this.state.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.handleAddFrame}>Xác nhận</button>
                                    )}
                            </div>
                    </div>
                </div>
            </div>
       </div>
        )
    }
}