import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import Firebase from '../../components/Firebase.js';
import lodash from 'lodash'
import MessageList from './components/MessageList.js';
import MessageDetailList from './components/MessageDetailList.js';
import ReactScroll from 'react-scrollable-feed';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import MyContext from '../../common/MyContext';

import { Base64 } from 'js-base64';

import "./css/chatforadminpage.css";

export default class SupportUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: null,
            targetUser: null,
            roomId: "",
            messages: [],
            messageDetails: [],
            searchUsers: [],
            cursorMessageDetails: null,
            loaded: false,
            isFirst: false,
            loading: false,
            listenFirebaseMessages: null,
            listenFirebaseMessgeDetails: null,
            message: "",
            searchKeyword: ""
        }

        this.ikaraNode = Firebase.database().ref("ikara");
        this.functions = Firebase.functions();
        this.auth = Firebase.auth();

        this.getFirebaseToken = this.getFirebaseToken.bind(this);
        this.accessToken = this.accessToken.bind(this);
        this.listenFirebaseMessages = this.listenFirebaseMessages.bind(this);
        this.listenFirebaseMessgeDetails = this.listenFirebaseMessgeDetails.bind(this);
        this.getMessageDetail = this.getMessageDetail.bind(this);
        this.loadMoreMessageDetails = this.loadMoreMessageDetails.bind(this);
        this.offListen = this.offListen.bind(this);
        this.handleTargetUser = this.handleTargetUser.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.searchUsers = this.searchUsers.bind(this)

        this.uploadImage = this.uploadImage.bind(this);
        this.acceptSendVideo = this.acceptSendVideo.bind(this);
    }


    componentDidMount() {
        this.getFirebaseToken();
    }


    handleScroll() {
        const { loading, loaded } = this.state;
        if ($('.chat-box').scrollTop() < 150 && !loading && loaded) {
            this.state.loading = true;
            console.log("loadmore")
            this.loadMoreMessageDetails()
        }
        console.log($('.chat-box').scrollTop())
    }

    getFirebaseToken() {
        let { user } = this.context;
        console.log(user);
        // if(user == null) {
        //     user = {
        //         facebookId: "122675726976908"
        //     }
        // }
        console.log(user)
        axios.post(global.config.apiDomain + "/rest/admin/getFirebaseToken", user.facebookId, {
            headers : {
                "Content-Type": "application/json"
            }
        })
            .then(res => {
                console.log(res.data);
                console.log(this.state.targetUser);
                if (res.data != null) {
                    this.state.user = res.data.user;
                    this.accessToken();
                }
            });
    }

    acceptSendVideo() {
        let acceptSendVideo = false;
        if (this.state.messageDetails && this.state.messageDetails.length > 1) {
            let firstUser = this.state.messageDetails[0].userId;
            this.state.messageDetails.map(value => {
                if (value.userId && value.userId !== firstUser) {
                    acceptSendVideo = true;
                }
        })
        }
        return acceptSendVideo;
    }

    accessToken() {
        const { user } = this.state;
        this.auth.signInWithCustomToken(user.firebaseToken)
            .then(res => {
                this.listenFirebaseMessages();
            });
    }

    uploadImage = (event) => {
        if(window.confirm("Bạn có chắc muốn gửi file này ?")) {

            var target = event.target;
            
            if (target.files[0].size > 10495760 ){
                toast.error("Kích thước file phải dưới 10mb.");
                return;
            }

            this.setState({
                loading: true
            });
            var bucketName = "ikara-data/chat";

            var extension = target.files[0].name.split('.').pop().toLowerCase();
            var keyName = this.guid() + "." + extension;

            var contentType = "image/jpeg";

            if (extension == "mp4") {
                contentType = "video/mp4";
            }

            if (extension == "mov") {
                contentType = "video/mov";
            }

            if(extension !== "jpeg" && extension !== "jpg" && extension !== "png" && extension !== "mp4" && extension !== "mov") {
                toast.warn("Không đúng định dạng")
                this.setState({
                    loading: false
                });
                return;
            }

            if ((extension === "mp4" || extension === "mov") && !this.acceptSendVideo()) {
                toast.warn("Không thể gửi video do chưa xác lập hội thoại")
                this.setState({
                    loading: false
                });
                return;
            }

            var fileReader = new FileReader();

            fileReader.onload = function () {
                var fd = new FormData();
                fd.append('file', target.files[0]);
                fd.append('bucketName', bucketName);
                fd.append('objectKey', keyName);
                fd.append('contentType', contentType)
                $.ajax({
                    url: global.config.apiDomain + '/web.UploadFile ',
                    data: fd,
                    processData: false,
                    contentType: false,
                    type: 'POST',
                    success: function (data) {
                        console.log(data);
                        this.setState({
                            loading: false,
                            message: '["https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName + '"]'
                        });
                        if (extension === "mp4") {
                            this.sendMessage("video");
                        } else {
                            this.sendMessage("image");
                        }
                        
                    }.bind(this),error: function(jqXHR, exception) {
                        toast.error(exception);
                        this.setState({
                            loading: false
                        });
                    }.bind(this),
                });
            }.bind(this);

            fileReader.readAsArrayBuffer(target.files[0]);
        }
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }


    getMessageDetail() {
        const { user, targetUser } = this.state;

        var roomId = "";
        if (user.facebookId > targetUser.fromUserId) {
            roomId = targetUser.fromUserId + "-" + user.facebookId;
        } else {
            roomId = user.facebookId + "-" + targetUser.fromUserId;
        }

        this.state.roomId = roomId;


        this.ikaraNode
            .child("privateChats")
            .child(roomId)
            .orderByKey()
            .limitToLast(100)
            .get()
            .then(snapshot => {
                var messageDetails = [];
                snapshot.forEach(value => {

                    var chatItem = value.val();
                    chatItem.key = value.key;

                    if (!this.state.messageDetails.some(o => o.key == chatItem.key)
                        && chatItem.key != "pushNotification"
                        && chatItem.key != "readLimit"
                        && chatItem.key != "properties") {

                        if(chatItem.type != null && !(chatItem.type == "text" || chatItem.type == "image")) {
                            messageDetails.originalMessage = "Chưa hỗ trợ xem data";
                        }
                        messageDetails.push(chatItem);
                    }
                })

                if (messageDetails.length < 9) {
                    this.state.cursorMessageDetails = "END";
                } else if (messageDetails.length > 0) {
                    this.state.cursorMessageDetails = messageDetails[0].key;
                }

                this.state.messageDetails = messageDetails;

                this.setState({
                    loaded: true
                });
            });

        this.listenFirebaseMessgeDetails();
    }


    loadMoreMessageDetails() {
        const { roomId } = this.state;
        let { cursorMessageDetails } = this.state;

        if (cursorMessageDetails == "END") return;

        this.ikaraNode
            .child("privateChats")
            .child(roomId)
            .orderByKey()
            .endAt(cursorMessageDetails)
            .limitToLast(10)
            .get()
            .then(snapshot => {
                var messageDetails = [];
                snapshot.forEach(value => {
                    var chatItem = value.val();
                    chatItem.key = value.key;

                    if (!this.state.messageDetails.some(o => o.key == chatItem.key)) {
                        messageDetails.push(chatItem);
                    }

                })

                if (messageDetails.length < 9) {
                    this.state.cursorMessageDetails = "END";
                } else if (messageDetails.length > 0) {
                    this.state.cursorMessageDetails = messageDetails[0].key;
                }

                this.setState({
                    messageDetails: messageDetails.concat(this.state.messageDetails),
                });
                this.state.loading = false;

            });
    }


    listenFirebaseMessages() {
        const { user } = this.state;

        var listenFirebaseMessages = this.ikaraNode
            .child("users")
            .child(user.facebookId)
            .child("privateChats")

        this.state.listenFirebaseMessages = listenFirebaseMessages;

        listenFirebaseMessages.on('value', snapshot => {
            var messages = [];
            snapshot.forEach(value => {
                var message = value.val();
                message.fromUserId = value.key;
                messages.push(message);
            });

            messages = lodash.orderBy(messages, "dateTime", 'desc');

            this.setState({
                messages: messages
            });

            if (messages != null && messages.length > 0) {
                // this.state.targetUser = messages[0];

                if (!this.state.isFirst) {

                    if (this.state.targetUser.isRead && !this.state.targetUser.isRead) {
                        this.changeIsReadConversation(this.state.targetUser);
                    }

                    this.state.isFirst = true;
                    this.getMessageDetail();
                }
            }
        });

    }

    offListen() {
        const { listenFirebaseMessages, listenFirebaseMessgeDetails } = this.state;
        // listenFirebaseMessages.off();
        listenFirebaseMessgeDetails.off();
    }

    listenFirebaseMessgeDetails() {
        const { user, targetUser } = this.state;
        console.log(targetUser + " list firebase");
        let { listenFirebaseMessgeDetails } = this.state;

        var roomId = "";

        if (user.facebookId > targetUser.fromUserId) {
            roomId = targetUser.fromUserId + "-" + user.facebookId;
        } else {
            roomId = user.facebookId + "-" + targetUser.fromUserId;
        }

        this.state.roomId = roomId;

        listenFirebaseMessgeDetails = this.ikaraNode
            .child("privateChats")
            .child(roomId)
            .orderByChild("dateTime")
            // .orderByKey()
            .limitToLast(1);

        listenFirebaseMessgeDetails.on('child_added', snapshot => {
            var messageDetails = [];
            var chatItem = snapshot.val();
            chatItem.key = chatItem.dateTime;

            if (!this.state.messageDetails.some(o => o.key == chatItem.key) && chatItem.key != undefined) {
                messageDetails.push(chatItem)
            }

            this.setState({
                messageDetails: this.state.messageDetails.concat(messageDetails),
            });

        });

        this.state.listenFirebaseMessgeDetails = listenFirebaseMessgeDetails;
    }

    handleTargetUser(targetUser) {
        const oldTargetUser = this.state.targetUser;
        console.log(oldTargetUser);
        if(oldTargetUser != null && targetUser.fromUserId == oldTargetUser.fromUserId) {
            return;
        }

        if (!this.state.messages.some(o => o.fromUserId == targetUser.fromUserId)) {
            this.state.messages.unshift(targetUser);
        }

        this.state.searchKeyword = "";
        this.state.searchUsers = [];

        if (!targetUser.isRead) {
            this.changeIsReadConversation(targetUser);
        }

        if(oldTargetUser != null) {
            this.offListen();
        }
        this.state.targetUser = targetUser;
        this.getMessageDetail();
    }

    changeIsReadConversation(targetUser) {
        console.log(targetUser + " dòng 418");
        const json = {
            toFacebookId: targetUser.fromUserId
        }

        const jsonBase64 = Base64.encode(JSON.stringify(json));

        const parameters = {
            "parameters": jsonBase64
        }

        const request = this.functions.httpsCallable("v5-ChangeIsReadConversation");
        request(parameters)
            .then(result => {
                // console.log(result)
            }).catch(error => {
                alert(error);
            });

    }

    sendMessage = (type) => {
        const { user, targetUser, message} = this.state;
        console.log(targetUser + " dòng 441");

        if (message == "") {
            return;
        }

        const fromUser = {
            facebookId: user.facebookId,
            name: user.name,
            profileImageLink: user.profileImageLink
        }

        const toUser = {
            facebookId: targetUser.fromUserId,
            name: targetUser.userName,
            profileImageLink: targetUser.userProfile
        }

        const json = {
            fromUser: fromUser,
            toUser: toUser,
            message: message,
            type: type,
            firebaseId: this.ikaraNode.push(json).key
        }        

        const jsonBase64 = Base64.encode(JSON.stringify(json))

        var parameters = {
            "parameters": jsonBase64
        }


        const addMessage = this.functions.httpsCallable("v10-SendMessage");
        addMessage(parameters).then(result => {
            this.setState({
                message: ""
            });
        }).catch(error => {
            toast.error("Đã có lỗi xảy ra khi gửi tin nhắn ");
        })

    }

    searchUsers() {
        const {searchKeyword} = this.state;
        let {user} = this.context;
        // if(user == null) {
        //     user = {
        //         facebookId: "122675726976908"
        //     }
        // }

        let {targetUser} = this.state;
        console.log(targetUser + " sreach");
        if(searchKeyword == "") {
            return;
        }

        axios.post(global.config.apiDomain + "/rest/searchUser", searchKeyword, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => {
            console.log(res.data)
            let messages = [];
            if(res.data != null) {
                for(let i = 0; i< res.data.length; i++) {
                    if(user.facebookId === res.data[i].facebookId) continue;
                    let message = {
                        fromUserId: res.data[i].facebookId,
                        userProfile : res.data[i].profileImageLink,
                        userName : res.data[i].name,
                        lastMessage: "",
                        dateTime: null
                    };
                    targetUser= message
                    messages.push(message)
                }

                this.handleTargetUser(targetUser)
            }

            this.setState({
                searchUsers: messages
                // searchKeyword: ""
            })
        })
    }


    render() {
        const { user, targetUser, messages, messageDetails, searchUsers, loaded, message, searchKeyword } = this.state;

        return (
            <div id="content">
                <div className="container p-3">
                    {/* For demo purpose*/}
                    <header className="text-center">
                        <h1 className="display-4">Hỗ trợ người dùng</h1>
                        <div style={{display: 'flex', justifyContent: 'end', marginTop: '-55px', marginBottom: '20px' }}>
                            <Link to="/admin/list-chat" className="btn btn-primary">CSKH</Link>
                        </div>
                        {/* <p className=" lead mb-0">Hỗ trợ người dùng</p> */}
                    </header>
                    <div className="row rounded-lg overflow-hidden shadow">
                        {/* Users box*/}
                        <div className="col-5 px-0">
                            <div className="bg-white">
                                <input
                                    value={searchKeyword}
                                    onChange={(event) => {
                                        if(event.target.value == "") {
                                            this.state.searchUsers = []
                                        }
                                        this.setState({
                                            searchKeyword: event.target.value
                                        })
                                    }}
                                    onKeyPress={(event) => {
                                        if(event.key == "Enter") {
                                            this.searchUsers()
                                        }
                                    }}
                                    type="text"
                                    placeholder="Search"
                                    aria-describedby="button-addon2"
                                    className="form-control rounded-0 border-0 py-4 bg-light" 
                                />
                                <div
                                    style={{
                                        height: "500px",
                                        overflowY: "auto",
                                    }}
                                    className="messages-box">
                                    <div className="list-group rounded-0">
                                        {loaded && (searchUsers.length == 0 || searchKeyword == "") ? (
                                            <MessageList 
                                                messages={messages}
                                                user={user}
                                                targetUser={targetUser}
                                                handleTargetUser={this.handleTargetUser}
                                            ></MessageList>
                                        ) : (
                                            <MessageList 
                                                messages={searchUsers}
                                                user={user}
                                                targetUser={targetUser}
                                                handleTargetUser={this.handleTargetUser}
                                            ></MessageList>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Chat Box*/}
                        <div className="col-7 px-0">
                            <div
                                style={{
                                    height: "500px",
                                    overflowY: "auto",
                                }}
                            >
                                <ReactScroll
                                    className="px-4 py-5 chat-box bg-white">
                                    <MessageDetailList
                                        messageDetails={messageDetails}
                                        user={user}
                                        targetUser={targetUser}
                                    ></MessageDetailList>
                                </ReactScroll>
                            </div>
                            {/* Typing area */}
                            <div className="bg-light">
                                <div className="input-group">
                                    <input
                                        onKeyPress={(event) => {
                                            if (event.key == "Enter") {
                                                this.sendMessage("text")
                                            }
                                        }}
                                        value={message}
                                        onChange={(event) => { this.setState({ message: event.target.value }) }}
                                        type="text"
                                        placeholder="Type a message"
                                        aria-describedby="button-addon2"
                                        className="form-control rounded-0 border-0 py-4 bg-light" 
                                    />
                                        
                                    <div className="input-group-append">
                                    {this.state.loading ?
                                        (<button
                                            id="button-addon2"
                                            className="btn btn-link"
                                            onClick={() => this.sendMessage("text")}
                                            type="button"
                                            disabled
                                        >
                                            <i className="fa fa-spinner fa-spin" />
                                        </button>) : 
                                        (<button
                                            id="button-addon2"
                                            className="btn btn-link"
                                            onClick={() => this.sendMessage("text")}
                                            type="button"
                                        >
                                            <i className="fa fa-paper-plane" />
                                        </button>) 
                                        }
                                    </div>

                                    <div className="input-group-append">
                                        <label htmlFor="imageAnswer" className="file-input-label" style={{ cursor: 'pointer', backgroundColor: '#007bff', color: '#fff', padding: '10px', borderRadius: '5px' }}>
                                                <i className="fa fa-image"></i>
                                        </label>
                                        <input
                                            type="file"
                                            id="imageAnswer"
                                            className="custom-file-input"
                                            onChange={this.uploadImage}
                                            style={{ display: 'none' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

SupportUser.contextType = MyContext;