import React, { Component } from 'react'
import {withTranslation} from 'react-i18next';
class LoadMoreComments extends Component {
    render() {
        const {loadMoreCommentsFunction, t} = this.props;
        return (
            <div className="load_more _5o4h">
                <button className="_1gl3 _4jy0 _4jy3 _517h _51sy _42ft" role="button" onClick={loadMoreCommentsFunction} type="submit" value="1">
                    <em className="_4qba" data-intl-translation="Tải thêm bình luận" data-intl-trid="">
                        {t("recording_page.load_more", "Xem thêm")}
                    </em>
                </button>
            </div>
        )
    }
}

export default withTranslation()(LoadMoreComments)

