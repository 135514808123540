import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import { Parser, Player } from 'svga'
import ModalUpdateMedal from './components/ModalUpdateMedal';
import SVGA from 'svgaplayerweb'
import { toast } from 'react-toastify';


export default class MedalManagerPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            medals: [],
            medal: null,
        };
        this.getAllMedal = this.getAllMedal.bind(this);
        this.handleDeleteMedal = this.handleDeleteMedal.bind(this);
        this.loadSVGA = this.loadSVGA.bind(this);
        this.handleUpdateState = this.handleUpdateState.bind(this);
        this.handleEnableItem = this.handleEnableItem.bind(this);
    }

    getAllMedal(){
        let url = global.config.apiDomain + "/rest/medal/getAll"

        axios.post(url)
        .then(res =>{
            this.setState({
                medals: res.data.medals
            })
        })
        .catch(()=>{
            this.setState({
                medals:[
                    {
                      "id": 5573092702027776,
                      "name": "Huy hiệu bài thu t7",
                      "description": "Huy hiệu bài thu t7",
                      "type": "EVENT",
                      "thumbnailUrl": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/medal/0aab2603-1740-6910-a4a1-4a4c16737163.png",
                      "resourceUrl": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/medal/94748fda-3d46-ba73-538c-f7d2f757ef5c.png",
                      "backgroundColor": "#FA82D5",
                      "frameColor": "#F463C7",
                      "shadowColor": "#CE58A9",
                      "pinColor": "#FFD3F1",
                      "deleted": false,
                      "order": 100,
                      "addTime": 1688632597112,
                      "isUse": false
                    },
                    {
                      "id": 5017393022500864,
                      "name": "Huy hiệu hường",
                      "description": "Huy hiệu hường phấn bánh bèo",
                      "type": "EVENT",
                      "thumbnailUrl": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/medal/a0306185-0305-80e9-e728-d2db7aab6613.png",
                      "resourceUrl": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/medal/c34984f4-4f1a-5430-0d14-a381b55a4db8.png",
                      "backgroundColor": "#FFABAF",
                      "frameColor": "#FF858B",
                      "shadowColor": "#CB595E",
                      "pinColor": "#FFCDCF",
                      "deleted": false,
                      "order": 2,
                      "addTime": 1688433020296,
                      "isUse": false
                    },
                    {
                      "id": 4823939063611392,
                      "name": "Huy hiệu top bài thu",
                      "description": "",
                      "type": "EVENT",
                      "thumbnailUrl": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/decorationItemType/f64f08f2-4936-e39c-9d36-341531cf0e37.png",
                      "resourceUrl": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/decorationItemType/f2e18730-3b32-f49e-ab83-8fe447fd3e5c.png",
                      "backgroundColor": "#DEA615",
                      "frameColor": "#DEA615",
                      "shadowColor": "#DEA615",
                      "pinColor": "#DEA615",
                      "deleted": false,
                      "order": 1,
                      "addTime": 1688699645859,
                      "isUse": false
                    },
                    {
                      "id": 5122265302564864,
                      "name": "Ngôi sao tháng 8",
                      "description": "Huy hiệu giành cho người chiến thắng cuộc thi",
                      "type": "EVENT",
                      "thumbnailUrl": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/medal/7ece8537-0373-8744-a8a6-2cb4769c247e.png",
                      "resourceUrl": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/medal/6a279891-7962-96a6-abbb-7bd636ab9b68.svga",
                      "backgroundColor": "#FFCA97",
                      "frameColor": "#FFBB7C",
                      "shadowColor": "#EE831F",
                      "pinColor": "#FFE7D1",
                      "deleted": false,
                      "order": 1,
                      "addTime": 1691633236967,
                      "isUse": false
                    },
                    {
                      "id": 5952857736871936,
                      "name": "Huy hiệu Tình Ca Tháng 7",
                      "description": "Huy hiệu dành cho người chiến thắng cuộc thi Tình Ca Tháng 7",
                      "type": "EVENT",
                      "thumbnailUrl": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/medal/1a8a7ecf-209c-62b3-49b5-80170f9efd4b.png",
                      "resourceUrl": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/medal/61547ba0-75f3-0076-9a5f-50b768866990.png",
                      "backgroundColor": "#FA82D5",
                      "frameColor": "#F463C7",
                      "shadowColor": "#CE58A9",
                      "pinColor": "#FFD3F1",
                      "deleted": false,
                      "order": 1,
                      "addTime": 1689051883221,
                      "isUse": false
                    },
                    {
                      "id": 6085347109634048,
                      "name": "Ngày Của Cha",
                      "description": "Huy hiệu dành cho người chiến thắng cuộc thi hát Ngày của cha",
                      "type": "EVENT",
                      "thumbnailUrl": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/medal/36f6f07d-edda-2837-147d-594a21f46dc4.png",
                      "resourceUrl": "https://s3.us-west-002.backblazeb2.com/ikara-data/images/medal/fce4c655-c400-3c5b-a565-fd3ee96a2691.png",
                      "backgroundColor": "#7DDEFB",
                      "frameColor": "#6FC9E4",
                      "shadowColor": "#59ADC6",
                      "pinColor": "#C9F3FF",
                      "deleted": false,
                      "order": 1,
                      "addTime": 1688373962146,
                      "isUse": false
                    }
                  ]
            })
        })
    }

    handleUpdateState(dataUpdate){
        this.setState({
            medals: dataUpdate
        })
    }

    handleEnableItem(medal){
        let url = global.config.apiDomain + "/rest/medal/createOrUpdateMedal"
        medal.deleted = true;
        axios.post(url,medal)
        .then(res =>{
            if(res.data.status == "OK"){
                toast.success("Cập nhật thành công");
                this.setState({
                    medals: res.data.medals,
                    loading: false
                })
                // window.$("#modalUpdateMedal").modal("hide");
            }else{
                toast.warning("Cập nhật thất bại")
                this.setState({
                    loading: false
                })
            }
        })
    }

    handleDeleteMedal(medal){
        let url = global.config.apiDomain + "/rest/medal/deleteMedal"
        this.setState({
            loading : true
        })

        axios.post(url,medal)
        .then(res =>{
            if(res.data.status == "OK"){
                toast.success("Xóa thành công")
                this.setState({
                    medals: res.data.medals,
                    loading: false
                })
            }else{
                toast.warning("Xóa thất bại")
                this.setState({
                    loading: false
                })
            }
        })
    }

    transferMedalData(medal){
        let url = global.config.apiDomain + "/rest/medal/transferDataMedal"
        this.setState({
            loading : true
        })

        axios.post(url,medal)
        .then(res =>{
            if(res.data.status == "OK"){
                toast.success(res.data.message)
            }else{
                toast.warning("Error: " + res.data.message)
            }
        })
    }

    loadSVGA(url, canvasId) {
        var parser = new SVGA.Parser("#" + canvasId);
            parser.load(url, function (videoItem) {
            var player = new SVGA.Player("#" + canvasId);
            player.setVideoItem(videoItem);
            // player.setFrame(0, 0, 750, 750);
            player.setContentMode("AspectFill")
            player.startAnimation();
        })
    }

    componentDidMount(){
        this.getAllMedal();
    }

    componentDidUpdate(){
        const { medals } = this.state;
        medals.map((medals, index) => {
            if (medals.resourceUrl != null && medals.resourceUrl.endsWith(".svga")) {
                let idResource = "show-animation_resourceUrl" + index;
                this.loadSVGA(medals.resourceUrl, idResource);
            } 
        })
    }

    render(){
        const { medals, medal } = this.state;

        return (
            <>
            <ModalUpdateMedal medal={medal} handleUpdateState={this.handleUpdateState}></ModalUpdateMedal>
            <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí Huy hiệu</h1>
                        <button 
                            className="btn btn-red mb-1" 
                            data-toggle="modal" 
                            data-target="#modalUpdateMedal"
                            onClick={()=>{
                                this.setState({
                                    medal: null
                                })
                            }}
                            >+ Tạo Mới Huy hiệu
                        </button>
                        <table className="table table-hover">
                            <thead>
                            <tr className="">
                                <th className="align-middle text-center" scope="col">#</th>
                                <th className="align-middle text-center" scope="col">id</th>
                                <th className="align-middle text-center" scope="col">Độ ưu tiên</th>
                                <th className="align-middle text-center" scope="col">Name</th>
                                <th className="align-middle text-center" scope="col">Mô tả</th>
                                <th className="align-middle text-center" scope="col">Ảnh huy hiệu</th>    
                                <th className="align-middle text-center" scope="col" style={{width: '200px'}}>Ảnh mô tả</th>  
                                <th className="align-middle text-center" scope="col">Type</th>
                                <th className="align-middle text-center" scope="col">Màu Nền</th>                                                
                                <th className="align-middle text-center" scope="col">Màu khung</th>                                                
                                <th className="align-middle text-center" scope="col">Màu ghim</th>                                                
                                <th className="align-middle text-center" scope="col">Màu đổ bóng</th>                                                
                                <th className="align-middle text-center" scope="col">Trạng thái</th>
                                <th className="align-middle text-center" scope="col" colSpan={2}>Tùy chỉnh</th>
                                <th className="align-middle text-center" scope="col">Chuyển dữ liệu</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                                {medals && medals.map( (item,index)=>{
                                    let idResource = "show-animation_resourceUrl" + index;
                                    return (
                                        <tr>
                                            <td className="align-middle text-center" scope="col">{index +1}</td>
                                            <td className="align-middle text-center" scope="col">{item.id}</td>
                                            <td className="align-middle text-center" scope="col">{item.order}</td>
                                            <td className="align-middle text-center" scope="col">{item.name}</td>
                                            <td className="align-middle text-center" scope="col">{item.description}</td>
                                            <td className="align-middle text-center" scope="col">
                                            {/* <div id={item.id} style={{ maxHeight: "50px", maxWidth: "50px", align: 'center' }}>
                                                        <div width={50} height={50} id="canvas"></div>
                                            </div> */}
                                                {item.resourceUrl && item.resourceUrl.endsWith(".svga") ? 
                                                    <div id={idResource} style={{ maxHeight: "50px", maxWidth: "50px", align: 'center' }}>
                                                        <div width={50} height={50} id="canvas"></div>
                                                    </div> 
                                             :
                                                    <img id={idResource} src={item.resourceUrl} alt=""  width={50} height={50} />}
                                            </td>
                                            <td className="align-middle text-center" scope="col"> <img src={item.resourceUrl} alt=""  width={50} height={50} /></td>
                                            <td className="align-middle text-center" scope="col">{item.type}</td>
                                            <td className="align-middle text-center" scope="col">{item.backgroundColor}</td>
                                            <td className="align-middle text-center" scope="col">{item.frameColor}</td>
                                            <td className="align-middle text-center" scope="col">{item.pinColor}</td>
                                            <td className="align-middle text-center" scope="col">{item.shadowColor}</td>
                                            <td className="align-middle text-center" scope="col">
                                                {item.deleted ? <button className='btn btn-secondary'>Vô hiệu</button> : 
                                                <button className='btn btn-success'>Khả dụng</button>}
                                            </td>
                                            <td className="align-middle text-center" scope="col">
                                                <button className='btn btn-success'
                                                    data-toggle="modal" 
                                                    data-target="#modalUpdateMedal"
                                                    onClick={()=>{
                                                        this.setState({
                                                            medal: item
                                                        })
                                                    }}
                                                    >Chỉnh sửa
                                                </button></td>
                                            <td className="align-middle text-center" scope="col">
                                            {item.deleted ? 
                                            <button className='btn btn-info'
                                                onClick={()=>{
                                                    if(window.confirm("Bạn có chắc là muốn khôi phục ?")){
                                                        this.handleEnableItem(item)
                                                    }
                                                }}
                                            >Kích Hoạt</button> : 
                                            <button className='btn btn-danger'
                                            onClick={()=>{
                                                if(window.confirm("Bạn có chắc là muốn xóa ?")){
                                                    this.handleDeleteMedal(item)
                                                }
                                            }}
                                        >Xóa</button>}
                                            </td>
                                            <td className="align-middle text-center" scope="col">
                                                <button className='btn btn-success'
                                                    onClick={()=>{
                                                        if(window.confirm("Bạn có chắc là muốn chuyển dữ liệu: " + item.name + " ?")){
                                                            this.transferMedalData(item)
                                                        }
                                                    }}
                                                    >Chuyển
                                                </button></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
            </div>
            </>
        )
    }
}